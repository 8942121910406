/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      title
      firstName
      lastName
      phone
      mobile
      email
      location
      roles
      status
      securityQuestion
      securityAnswer
      lastLogin
      lastActiveMinute
      currentTab
      config
      createdAt
      updatedAt
      owner
      groups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          timestamp
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      groupName
      description
      buckets
      dfStudioAccess
      createdAt
      updatedAt
      owner
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          create
          read
          update
          delete
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const groupByName = /* GraphQL */ `
  query GroupByName(
    $groupName: String
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupByName(
      groupName: $groupName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserGroup = /* GraphQL */ `
  query GetUserGroup($id: ID!) {
    getUserGroup(id: $id) {
      id
      userId
      groupId
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listUserGroups = /* GraphQL */ `
  query ListUserGroups(
    $filter: ModelUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        groupId
        createdAt
        updatedAt
        user {
          id
          title
          firstName
          lastName
          phone
          mobile
          email
          location
          roles
          status
          securityQuestion
          securityAnswer
          lastLogin
          lastActiveMinute
          currentTab
          config
          createdAt
          updatedAt
          owner
          __typename
        }
        group {
          id
          groupName
          description
          buckets
          dfStudioAccess
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userGroupByUser = /* GraphQL */ `
  query UserGroupByUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userGroupByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        createdAt
        updatedAt
        user {
          id
          title
          firstName
          lastName
          phone
          mobile
          email
          location
          roles
          status
          securityQuestion
          securityAnswer
          lastLogin
          lastActiveMinute
          currentTab
          config
          createdAt
          updatedAt
          owner
          __typename
        }
        group {
          id
          groupName
          description
          buckets
          dfStudioAccess
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userGroupByGroup = /* GraphQL */ `
  query UserGroupByGroup(
    $groupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userGroupByGroup(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        createdAt
        updatedAt
        user {
          id
          title
          firstName
          lastName
          phone
          mobile
          email
          location
          roles
          status
          securityQuestion
          securityAnswer
          lastLogin
          lastActiveMinute
          currentTab
          config
          createdAt
          updatedAt
          owner
          __typename
        }
        group {
          id
          groupName
          description
          buckets
          dfStudioAccess
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPermission = /* GraphQL */ `
  query GetPermission($id: ID!) {
    getPermission(id: $id) {
      id
      create
      read
      update
      delete
      createdAt
      updatedAt
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listPermissions = /* GraphQL */ `
  query ListPermissions(
    $filter: ModelPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        create
        read
        update
        delete
        createdAt
        updatedAt
        group {
          id
          groupName
          description
          buckets
          dfStudioAccess
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        page {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      description
      parentRole
      config
      idletime
      user
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        parentRole
        config
        idletime
        user
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rolesByParent = /* GraphQL */ `
  query RolesByParent(
    $parentRole: String
    $sortDirection: ModelSortDirection
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rolesByParent(
      parentRole: $parentRole
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        parentRole
        config
        idletime
        user
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const roleByName = /* GraphQL */ `
  query RoleByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roleByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        parentRole
        config
        idletime
        user
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSettings = /* GraphQL */ `
  query GetSettings($id: ID!) {
    getSettings(id: $id) {
      id
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      name
      values
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSettingss = /* GraphQL */ `
  query ListSettingss(
    $filter: ModelsettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group {
          id
          groupName
          description
          buckets
          dfStudioAccess
          createdAt
          updatedAt
          owner
          __typename
        }
        name
        values
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActivity = /* GraphQL */ `
  query GetActivity($id: ID!) {
    getActivity(id: $id) {
      id
      timestamp
      details
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listActivitys = /* GraphQL */ `
  query ListActivitys(
    $filter: ModelActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        details
        createdAt
        updatedAt
        user {
          id
          title
          firstName
          lastName
          phone
          mobile
          email
          location
          roles
          status
          securityQuestion
          securityAnswer
          lastLogin
          lastActiveMinute
          currentTab
          config
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        page {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCollection = /* GraphQL */ `
  query GetCollection($id: ID!) {
    getCollection(id: $id) {
      id
      name
      email
      createdBy
      sharedUsers
      sharedGroups
      objects
      type
      target
      expiry
      publicUrl
      remarks
      title
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCollections = /* GraphQL */ `
  query ListCollections(
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        createdBy
        sharedUsers
        sharedGroups
        objects
        type
        target
        expiry
        publicUrl
        remarks
        title
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const collectionsByName = /* GraphQL */ `
  query CollectionsByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCollectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    collectionsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        createdBy
        sharedUsers
        sharedGroups
        objects
        type
        target
        expiry
        publicUrl
        remarks
        title
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      order
      url
      title
      icon
      active
      moduleId
      createdAt
      updatedAt
      owner
      module {
        id
        order
        name
        lastUsedPage {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        icon
        active
        createdAt
        updatedAt
        pages {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const pagesByOrder = /* GraphQL */ `
  query PagesByOrder(
    $moduleId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pagesByOrder(
      moduleId: $moduleId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getModule = /* GraphQL */ `
  query GetModule($id: ID!) {
    getModule(id: $id) {
      id
      order
      name
      lastUsedPage {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      icon
      active
      createdAt
      updatedAt
      pages {
        items {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listModules = /* GraphQL */ `
  query ListModules(
    $filter: ModelModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        name
        lastUsedPage {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        icon
        active
        createdAt
        updatedAt
        pages {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activeModulesByOrder = /* GraphQL */ `
  query ActiveModulesByOrder(
    $active: Int
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeModulesByOrder(
      active: $active
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        order
        name
        lastUsedPage {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        icon
        active
        createdAt
        updatedAt
        pages {
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNetwork = /* GraphQL */ `
  query GetNetwork($id: ID!) {
    getNetwork(id: $id) {
      id
      name
      description
      order
      image
      link
      active
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listNetworks = /* GraphQL */ `
  query ListNetworks(
    $filter: ModelNetworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNetworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        order
        image
        link
        active
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activeNetworks = /* GraphQL */ `
  query ActiveNetworks(
    $active: Int
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNetworkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeNetworks(
      active: $active
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        order
        image
        link
        active
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShow = /* GraphQL */ `
  query GetShow($id: ID!) {
    getShow(id: $id) {
      id
      name
      description
      order
      image
      link
      active
      networkId
      createdAt
      updatedAt
      network {
        id
        name
        description
        order
        image
        link
        active
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      dayParts {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pictures {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listShows = /* GraphQL */ `
  query ListShows(
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const activeShows = /* GraphQL */ `
  query ActiveShows(
    $active: Int
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    activeShows(
      active: $active
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const showsByNetwork = /* GraphQL */ `
  query ShowsByNetwork(
    $networkId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showsByNetwork(
      networkId: $networkId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDaypart = /* GraphQL */ `
  query GetDaypart($id: ID!) {
    getDaypart(id: $id) {
      id
      name
      description
      active
      order
      image
      link
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listDayparts = /* GraphQL */ `
  query ListDayparts(
    $filter: ModelDaypartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDayparts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        active
        order
        image
        link
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const daypartsByOrder = /* GraphQL */ `
  query DaypartsByOrder(
    $active: Int
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDaypartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    daypartsByOrder(
      active: $active
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        active
        order
        image
        link
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShowDaypart = /* GraphQL */ `
  query GetShowDaypart($id: ID!) {
    getShowDaypart(id: $id) {
      id
      showId
      daypartId
      showOrder
      daypartOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      daypart {
        id
        name
        description
        active
        order
        image
        link
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listShowDayparts = /* GraphQL */ `
  query ListShowDayparts(
    $filter: ModelShowDaypartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShowDayparts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showId
        daypartId
        showOrder
        daypartOrder
        createdAt
        updatedAt
        show {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        daypart {
          id
          name
          description
          active
          order
          image
          link
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const showsOfGivenDaypartByOrder = /* GraphQL */ `
  query ShowsOfGivenDaypartByOrder(
    $daypartId: ID
    $showOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowDaypartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showsOfGivenDaypartByOrder(
      daypartId: $daypartId
      showOrder: $showOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showId
        daypartId
        showOrder
        daypartOrder
        createdAt
        updatedAt
        show {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        daypart {
          id
          name
          description
          active
          order
          image
          link
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const daypartsofGivenShowByOrder = /* GraphQL */ `
  query DaypartsofGivenShowByOrder(
    $showId: ID
    $daypartOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowDaypartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    daypartsofGivenShowByOrder(
      showId: $showId
      daypartOrder: $daypartOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showId
        daypartId
        showOrder
        daypartOrder
        createdAt
        updatedAt
        show {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        daypart {
          id
          name
          description
          active
          order
          image
          link
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPicture = /* GraphQL */ `
  query GetPicture($id: ID!) {
    getPicture(id: $id) {
      id
      name
      description
      image
      createdAt
      updatedAt
      owner
      show {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listPictures = /* GraphQL */ `
  query ListPictures(
    $filter: ModelPictureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPictures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const picturesByName = /* GraphQL */ `
  query PicturesByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelPictureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    picturesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShowPicture = /* GraphQL */ `
  query GetShowPicture($id: ID!) {
    getShowPicture(id: $id) {
      id
      showId
      pictureId
      showOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listShowPictures = /* GraphQL */ `
  query ListShowPictures(
    $filter: ModelShowPictureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShowPictures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showId
        pictureId
        showOrder
        createdAt
        updatedAt
        show {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const showsInPicture = /* GraphQL */ `
  query ShowsInPicture(
    $pictureId: ID
    $showOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowPictureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showsInPicture(
      pictureId: $pictureId
      showOrder: $showOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showId
        pictureId
        showOrder
        createdAt
        updatedAt
        show {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const picturesInShow = /* GraphQL */ `
  query PicturesInShow(
    $showId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelShowPictureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    picturesInShow(
      showId: $showId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showId
        pictureId
        showOrder
        createdAt
        updatedAt
        show {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getKeyword = /* GraphQL */ `
  query GetKeyword($id: ID!) {
    getKeyword(id: $id) {
      id
      keyword
      description
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listKeywords = /* GraphQL */ `
  query ListKeywords(
    $filter: ModelKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeywords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const keywordsByText = /* GraphQL */ `
  query KeywordsByText(
    $keyword: String
    $sortDirection: ModelSortDirection
    $filter: ModelKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keywordsByText(
      keyword: $keyword
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPictureKeyword = /* GraphQL */ `
  query GetPictureKeyword($id: ID!) {
    getPictureKeyword(id: $id) {
      id
      pictureId
      keywordId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listPictureKeywords = /* GraphQL */ `
  query ListPictureKeywords(
    $filter: ModelPictureKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPictureKeywords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pictureId
        keywordId
        createdAt
        updatedAt
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        keyword {
          id
          keyword
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const picturesInKeyword = /* GraphQL */ `
  query PicturesInKeyword(
    $keywordId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPictureKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    picturesInKeyword(
      keywordId: $keywordId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pictureId
        keywordId
        createdAt
        updatedAt
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        keyword {
          id
          keyword
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const keywordsInPicture = /* GraphQL */ `
  query KeywordsInPicture(
    $pictureId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPictureKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keywordsInPicture(
      pictureId: $pictureId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pictureId
        keywordId
        createdAt
        updatedAt
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        keyword {
          id
          keyword
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      description
      address
      long
      lat
      image
      link
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const locationsByName = /* GraphQL */ `
  query LocationsByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPictureLocation = /* GraphQL */ `
  query GetPictureLocation($id: ID!) {
    getPictureLocation(id: $id) {
      id
      pictureId
      locationId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listPictureLocations = /* GraphQL */ `
  query ListPictureLocations(
    $filter: ModelPictureLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPictureLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pictureId
        locationId
        createdAt
        updatedAt
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        location {
          id
          name
          description
          address
          long
          lat
          image
          link
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const picturesInLocation = /* GraphQL */ `
  query PicturesInLocation(
    $locationId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPictureLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    picturesInLocation(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pictureId
        locationId
        createdAt
        updatedAt
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        location {
          id
          name
          description
          address
          long
          lat
          image
          link
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const locationsInPicture = /* GraphQL */ `
  query LocationsInPicture(
    $pictureId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPictureLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationsInPicture(
      pictureId: $pictureId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pictureId
        locationId
        createdAt
        updatedAt
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        location {
          id
          name
          description
          address
          long
          lat
          image
          link
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocationKeyword = /* GraphQL */ `
  query GetLocationKeyword($id: ID!) {
    getLocationKeyword(id: $id) {
      id
      locationId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listLocationKeywords = /* GraphQL */ `
  query ListLocationKeywords(
    $filter: ModelLocationKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocationKeywords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        keywordId
        createdAt
        updatedAt
        keyword {
          id
          keyword
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        location {
          id
          name
          description
          address
          long
          lat
          image
          link
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const locationsInKeyword = /* GraphQL */ `
  query LocationsInKeyword(
    $locationId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLocationKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationsInKeyword(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        keywordId
        createdAt
        updatedAt
        keyword {
          id
          keyword
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        location {
          id
          name
          description
          address
          long
          lat
          image
          link
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const keywordsInLocation = /* GraphQL */ `
  query KeywordsInLocation(
    $keywordId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLocationKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keywordsInLocation(
      keywordId: $keywordId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationId
        keywordId
        createdAt
        updatedAt
        keyword {
          id
          keyword
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        location {
          id
          name
          description
          address
          long
          lat
          image
          link
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPeople = /* GraphQL */ `
  query GetPeople($id: ID!) {
    getPeople(id: $id) {
      id
      name
      nickName
      description
      date
      links
      image
      notes
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Keywords {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shows {
        items {
          id
          showId
          showOrder
          peopleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listPeoples = /* GraphQL */ `
  query ListPeoples(
    $filter: ModelPeopleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeoples(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const peopleByName = /* GraphQL */ `
  query PeopleByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelPeopleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    peopleByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPeoplePicture = /* GraphQL */ `
  query GetPeoplePicture($id: ID!) {
    getPeoplePicture(id: $id) {
      id
      peopleId
      pictureId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listPeoplePictures = /* GraphQL */ `
  query ListPeoplePictures(
    $filter: ModelPeoplePictureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeoplePictures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        peopleId
        pictureId
        createdAt
        updatedAt
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        people {
          id
          name
          nickName
          description
          date
          links
          image
          notes
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const peopleInPicture = /* GraphQL */ `
  query PeopleInPicture(
    $pictureId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPeoplePictureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    peopleInPicture(
      pictureId: $pictureId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        peopleId
        pictureId
        createdAt
        updatedAt
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        people {
          id
          name
          nickName
          description
          date
          links
          image
          notes
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const picturesInPeople = /* GraphQL */ `
  query PicturesInPeople(
    $peopleId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPeoplePictureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    picturesInPeople(
      peopleId: $peopleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        peopleId
        pictureId
        createdAt
        updatedAt
        picture {
          id
          name
          description
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        people {
          id
          name
          nickName
          description
          date
          links
          image
          notes
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPeopleKeyword = /* GraphQL */ `
  query GetPeopleKeyword($id: ID!) {
    getPeopleKeyword(id: $id) {
      id
      peopleId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listPeopleKeywords = /* GraphQL */ `
  query ListPeopleKeywords(
    $filter: ModelPeopleKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeopleKeywords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        peopleId
        keywordId
        createdAt
        updatedAt
        keyword {
          id
          keyword
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        people {
          id
          name
          nickName
          description
          date
          links
          image
          notes
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const peopleInKeyword = /* GraphQL */ `
  query PeopleInKeyword(
    $keywordId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPeopleKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    peopleInKeyword(
      keywordId: $keywordId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        peopleId
        keywordId
        createdAt
        updatedAt
        keyword {
          id
          keyword
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        people {
          id
          name
          nickName
          description
          date
          links
          image
          notes
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const keywordsInPeople = /* GraphQL */ `
  query KeywordsInPeople(
    $peopleId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPeopleKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keywordsInPeople(
      peopleId: $peopleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        peopleId
        keywordId
        createdAt
        updatedAt
        keyword {
          id
          keyword
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        people {
          id
          name
          nickName
          description
          date
          links
          image
          notes
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShowPeople = /* GraphQL */ `
  query GetShowPeople($id: ID!) {
    getShowPeople(id: $id) {
      id
      showId
      showOrder
      peopleId
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listShowPeoples = /* GraphQL */ `
  query ListShowPeoples(
    $filter: ModelShowPeopleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShowPeoples(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showId
        showOrder
        peopleId
        createdAt
        updatedAt
        show {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        people {
          id
          name
          nickName
          description
          date
          links
          image
          notes
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const showsInPeople = /* GraphQL */ `
  query ShowsInPeople(
    $peopleId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelShowPeopleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    showsInPeople(
      peopleId: $peopleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showId
        showOrder
        peopleId
        createdAt
        updatedAt
        show {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        people {
          id
          name
          nickName
          description
          date
          links
          image
          notes
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const peopleInShow = /* GraphQL */ `
  query PeopleInShow(
    $showId: ID
    $showOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShowPeopleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    peopleInShow(
      showId: $showId
      showOrder: $showOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showId
        showOrder
        peopleId
        createdAt
        updatedAt
        show {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        people {
          id
          name
          nickName
          description
          date
          links
          image
          notes
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMetaData = /* GraphQL */ `
  query GetMetaData($id: ID!) {
    getMetaData(id: $id) {
      id
      bucket
      mediaFilename
      mediaDesciption
      fullFileName
      name
      network
      show
      seasonNo
      daypart
      episodeName
      job
      thumbnail
      format
      people
      city
      country
      region
      fileType
      type
      category
      imageAttributesData
      cameraAttributesData
      exif
      exifDump
      day
      year
      keywords
      notes
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      transferRequestId
      scanRequestId
      timestamp
      error
      clickTime
      reindex
      dfsName
      dfsId
      dfsProject
      dfsSetup
      dfsBadge
      description
      personInImage
      captionAbstract
      specialInstructions
      copyrightNotice
      credit
      source
      captionWriter
      headline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMetaDatas = /* GraphQL */ `
  query ListMetaDatas(
    $filter: ModelMetaDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetaDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bucket
        mediaFilename
        mediaDesciption
        fullFileName
        name
        network
        show
        seasonNo
        daypart
        episodeName
        job
        thumbnail
        format
        people
        city
        country
        region
        fileType
        type
        category
        imageAttributesData
        cameraAttributesData
        exif
        exifDump
        day
        year
        keywords
        notes
        awsS3FileLocation {
          bucket
          key
          region
          url
          size
          mimeType
          __typename
        }
        transferRequestId
        scanRequestId
        timestamp
        error
        clickTime
        reindex
        dfsName
        dfsId
        dfsProject
        dfsSetup
        dfsBadge
        description
        personInImage
        captionAbstract
        specialInstructions
        copyrightNotice
        credit
        source
        captionWriter
        headline
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const metaDataByFileName = /* GraphQL */ `
  query MetaDataByFileName(
    $mediaFilename: String
    $sortDirection: ModelSortDirection
    $filter: ModelMetaDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    metaDataByFileName(
      mediaFilename: $mediaFilename
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bucket
        mediaFilename
        mediaDesciption
        fullFileName
        name
        network
        show
        seasonNo
        daypart
        episodeName
        job
        thumbnail
        format
        people
        city
        country
        region
        fileType
        type
        category
        imageAttributesData
        cameraAttributesData
        exif
        exifDump
        day
        year
        keywords
        notes
        awsS3FileLocation {
          bucket
          key
          region
          url
          size
          mimeType
          __typename
        }
        transferRequestId
        scanRequestId
        timestamp
        error
        clickTime
        reindex
        dfsName
        dfsId
        dfsProject
        dfsSetup
        dfsBadge
        description
        personInImage
        captionAbstract
        specialInstructions
        copyrightNotice
        credit
        source
        captionWriter
        headline
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchMetaDatas = /* GraphQL */ `
  query SearchMetaDatas(
    $filter: SearchableMetaDataFilterInput
    $sort: SearchableMetaDataSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchMetaDatas(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        bucket
        mediaFilename
        mediaDesciption
        fullFileName
        name
        network
        show
        seasonNo
        daypart
        episodeName
        job
        thumbnail
        format
        people
        city
        country
        region
        fileType
        type
        category
        imageAttributesData
        cameraAttributesData
        exif
        exifDump
        day
        year
        keywords
        notes
        awsS3FileLocation {
          bucket
          key
          region
          url
          size
          mimeType
          __typename
        }
        transferRequestId
        scanRequestId
        timestamp
        error
        clickTime
        reindex
        dfsName
        dfsId
        dfsProject
        dfsSetup
        dfsBadge
        description
        personInImage
        captionAbstract
        specialInstructions
        copyrightNotice
        credit
        source
        captionWriter
        headline
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const getS3Events = /* GraphQL */ `
  query GetS3Events($id: ID!) {
    getS3Events(id: $id) {
      id
      timestamp
      eventTime
      principalId
      sourceIPAddress
      bucketName
      bucketArn
      objectKey
      objectSize
      eTag
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listS3Eventss = /* GraphQL */ `
  query ListS3Eventss(
    $filter: ModelS3EventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listS3Eventss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        eventTime
        principalId
        sourceIPAddress
        bucketName
        bucketArn
        objectKey
        objectSize
        eTag
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAsperaTransferLog = /* GraphQL */ `
  query GetAsperaTransferLog($id: ID!) {
    getAsperaTransferLog(id: $id) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      s3Object {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAsperaTransferLogs = /* GraphQL */ `
  query ListAsperaTransferLogs(
    $filter: ModelAsperaTransferLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsperaTransferLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        sessionId
        requestId
        direction
        page
        userId
        s3Object {
          bucket
          key
          region
          url
          size
          mimeType
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAsperaTransferSummary = /* GraphQL */ `
  query GetAsperaTransferSummary($id: ID!) {
    getAsperaTransferSummary(id: $id) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      attempted
      completed
      skipped
      failed
      finalStatus
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAsperaTransferSummarys = /* GraphQL */ `
  query ListAsperaTransferSummarys(
    $filter: ModelAsperaTransferSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsperaTransferSummarys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        sessionId
        requestId
        direction
        page
        userId
        attempted
        completed
        skipped
        failed
        finalStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const asperaTransferSummaryByRequestId = /* GraphQL */ `
  query AsperaTransferSummaryByRequestId(
    $requestId: String
    $sortDirection: ModelSortDirection
    $filter: ModelAsperaTransferSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    asperaTransferSummaryByRequestId(
      requestId: $requestId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timestamp
        sessionId
        requestId
        direction
        page
        userId
        attempted
        completed
        skipped
        failed
        finalStatus
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRekogLog = /* GraphQL */ `
  query GetRekogLog($id: ID!) {
    getRekogLog(id: $id) {
      id
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      rekogResult
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRekogLogs = /* GraphQL */ `
  query ListRekogLogs(
    $filter: ModelRekogLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRekogLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        awsS3FileLocation {
          bucket
          key
          region
          url
          size
          mimeType
          __typename
        }
        rekogResult
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAsperaTransferRequest = /* GraphQL */ `
  query GetAsperaTransferRequest($id: ID!) {
    getAsperaTransferRequest(id: $id) {
      id
      user
      requestId
      timestamp
      sessionId
      direction
      bucket
      destinationFolder
      sourceFiles
      completion
      network
      daypart
      show
      year
      job
      name
      people
      city
      country
      region
      category
      fileType
      type
      textRekog
      objectRekog
      celebrityRekog
      notes
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAsperaTransferRequests = /* GraphQL */ `
  query ListAsperaTransferRequests(
    $filter: ModelAsperaTransferRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsperaTransferRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        requestId
        timestamp
        sessionId
        direction
        bucket
        destinationFolder
        sourceFiles
        completion
        network
        daypart
        show
        year
        job
        name
        people
        city
        country
        region
        category
        fileType
        type
        textRekog
        objectRekog
        celebrityRekog
        notes
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const asperaTransfersByRequestId = /* GraphQL */ `
  query AsperaTransfersByRequestId(
    $requestId: String
    $timestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsperaTransferRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    asperaTransfersByRequestId(
      requestId: $requestId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        requestId
        timestamp
        sessionId
        direction
        bucket
        destinationFolder
        sourceFiles
        completion
        network
        daypart
        show
        year
        job
        name
        people
        city
        country
        region
        category
        fileType
        type
        textRekog
        objectRekog
        celebrityRekog
        notes
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScanAnalysisLog = /* GraphQL */ `
  query GetScanAnalysisLog($id: ID!) {
    getScanAnalysisLog(id: $id) {
      id
      bucket
      folder
      requestId
      searchedCount
      eligibleCount
      cumulativeEligibleCount
      nextS3Key
      nextToken
      limit
      error
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listScanAnalysisLogs = /* GraphQL */ `
  query ListScanAnalysisLogs(
    $filter: ModelScanAnalysisLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScanAnalysisLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bucket
        folder
        requestId
        searchedCount
        eligibleCount
        cumulativeEligibleCount
        nextS3Key
        nextToken
        limit
        error
        user
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getScanRequest = /* GraphQL */ `
  query GetScanRequest($id: ID!) {
    getScanRequest(id: $id) {
      id
      requestId
      scanRequestDetails
      user
      status
      error
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listScanRequests = /* GraphQL */ `
  query ListScanRequests(
    $filter: ModelScanRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScanRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        requestId
        scanRequestDetails
        user
        status
        error
        retryFor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchScanRequests = /* GraphQL */ `
  query SearchScanRequests(
    $filter: SearchableScanRequestFilterInput
    $sort: SearchableScanRequestSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchScanRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        requestId
        scanRequestDetails
        user
        status
        error
        retryFor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const getDfsPullRequest = /* GraphQL */ `
  query GetDfsPullRequest($id: ID!) {
    getDfsPullRequest(id: $id) {
      id
      requestId
      dfsPullRequestDetails
      user
      status
      error
      nextOffset
      nextToken
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDfsPullRequests = /* GraphQL */ `
  query ListDfsPullRequests(
    $filter: ModelDfsPullRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDfsPullRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        requestId
        dfsPullRequestDetails
        user
        status
        error
        nextOffset
        nextToken
        retryFor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dfsPullRequestByRequestId = /* GraphQL */ `
  query DfsPullRequestByRequestId(
    $requestId: String
    $sortDirection: ModelSortDirection
    $filter: ModelDfsPullRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dfsPullRequestByRequestId(
      requestId: $requestId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestId
        dfsPullRequestDetails
        user
        status
        error
        nextOffset
        nextToken
        retryFor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchDfsPullRequests = /* GraphQL */ `
  query SearchDfsPullRequests(
    $filter: SearchableDfsPullRequestFilterInput
    $sort: SearchableDfsPullRequestSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDfsPullRequests(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        requestId
        dfsPullRequestDetails
        user
        status
        error
        nextOffset
        nextToken
        retryFor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const getScanRequestSummary = /* GraphQL */ `
  query GetScanRequestSummary($id: ID!) {
    getScanRequestSummary(id: $id) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listScanRequestSummarys = /* GraphQL */ `
  query ListScanRequestSummarys(
    $filter: ModelScanRequestSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScanRequestSummarys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestId
        user
        requestCount
        completionCount
        success
        failed
        retryFor
        emailSent
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDfsPullRequestSummary = /* GraphQL */ `
  query GetDfsPullRequestSummary($id: ID!) {
    getDfsPullRequestSummary(id: $id) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDfsPullRequestSummarys = /* GraphQL */ `
  query ListDfsPullRequestSummarys(
    $filter: ModelDfsPullRequestSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDfsPullRequestSummarys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestId
        user
        requestCount
        completionCount
        success
        failed
        retryFor
        emailSent
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventTrigger = /* GraphQL */ `
  query GetEventTrigger($id: ID!) {
    getEventTrigger(id: $id) {
      id
      eventDetails
      nextTrigger
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listEventTriggers = /* GraphQL */ `
  query ListEventTriggers(
    $filter: ModelEventTriggerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventTriggers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventDetails
        nextTrigger
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
