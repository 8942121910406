import React, { useState, useEffect, useCallback } from "react";

import { makeStyles } from "@material-ui/core/styles";

import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";
import Button from "../button/Button";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import CardBody from "../card/CardBody";
import CardFooter from "../card/CardFooter";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import CancelIcon from "@material-ui/icons/Cancel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InputLabel from "@material-ui/core/InputLabel";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AdminTabs from "./AdminTab";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import UsersList from "./userList";
import RolesTable from "./RolesTable";
import GroupsList from "./groupList";
import BucketsList from "./bucketList";
import { useSelector } from "react-redux";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  adminUserSetFieldValue,
  adminUserResetValues,
} from "store/admin-user-reducer";
import {
  adminGroupSetFieldValue,
  adminGroupResetValues,
} from "store/admin-group-reducer";
import {
  adminRoleSetFieldValue,
  adminRoleResetValues,
} from "store/admin-roles-reducer";

import { dispatch } from "redux-store";
import {
  createAdminUser,
  updateAdminUser,
  createAdminGroup,
  updateAdminGroup,
  createAdminRoles,
  updateAdminRoles,
  createOneUserGroup,
  createOneGroupUser,
  fetchRoleLists,
  getUserByemail,
  getUserByEmail,
  getRoleByName,
  getGroupByName,
  getGroupByUser,
} from "service/api.service";

import config from "../../aws-exports";
import { awsConfig, Dfstudio } from "../../config";
import invoke from "../../service/lambda.service";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useAppUserContext } from "lib/contextLib";
import { current } from "../../../node_modules/@reduxjs/toolkit/dist/index";
const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formControl: {
    minWidth: 120,
  },
  userLogFormControl: {
    minWidth: 300,
  },
  iconCss: {
    margin: "20px 20px 0px 0px",
    cursor: "pointer",
  },
  dialogTitle: {
    display: "flex",
    "justify-content": "center",
    padding: "35px 24px",
  },
  dialogCard: {
    "margin-top": "0",
    "min-height": "300px",
  },
  modalWrapper: {
    minWidth: "400px",
  },
};

const useStyles = makeStyles(styles);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Admin(props) {
  const classes = useStyles();
  const [modalItem, setModalItem] = useState(false);
  /**
   * Control to show or hide form
   */
  const [showForm, toggleFormState] = useState(false); // Show / hide form
  const [buttonText, buttonTextState] = useState(false);
  const [showUsers, showUsersState] = useState(true);
  const [showGroups, showGroupsState] = useState(false);
  const [showRoles, showRolesState] = useState(false);
  const [showUserLog, showUserLogState] = useState(false);
  const [userLogDateRange, setUserLogDateRange] = useState([null, null]);
  const [isEditedRoles, showEditedRolesState] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [rolesLabel, setRolesLabel] = useState("ROLES");
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const [isGroupUpdated, setIsGroupUpdated] = useState(false);
  const [isRoleUpdated, setIsRoleUpdated] = useState(false);
  const [adminTableValue, setAdminTableValue] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const currentUser = useAppUserContext();

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });

  const { vertical, horizontal, open, severity, message } = state;

  const handleInputChange = (e) => {
    if (e.target.name === "roles") {
      dispatch(adminUserSetFieldValue({ [e.target.name]: e.target.value }));
      return;
    }
    dispatch(adminUserSetFieldValue({ [e.target.name]: e.target.value }));
  };
  const handleGroupChange = (e) => {
    dispatch(adminGroupSetFieldValue({ [e.target.name]: e.target.value }));
  };
  const handleRoleChange = (e) => {
    dispatch(adminRoleSetFieldValue({ [e.target.name]: e.target.value }));
  };

  /**
   * handle user add/edit
   * @params - evnt - rowdata
   */

  function handleTableEvent(evt) {
    buttonTextState(true);
    toggleFormState(true);
    showUsersState(true);
    showGroupsState(false);
    showRolesState(false);
    showUserLogState(false);
    setTabValue(0);
    if (userInfo !== "") {
      setRolesData([]);
      fetchRoles(userInfo);
    }

    /**
     * dispatch users values
     *
     */
    dispatch(
      adminUserSetFieldValue({
        id: evt.id,
        firstName: evt.firstName,
        lastName: evt.lastName,
        email: evt.email,
        roles: evt.roles,
        title: evt.title,
        groups: evt.groups,
        status: evt.status,
      })
    );

    //  setTitle(evt.target.value)
  }

  /**
   * handle user add/edit
   * @params - evnt - rowdata
   */
  function tableGroupsEvents(evt) {
    buttonTextState(true);
    toggleFormState(true);
    showGroupsState(true);
    showUsersState(false);
    showRolesState(false);
    showUserLogState(false);
    setTabValue(1);

    /**
     * dispatch users values
     *
     */
    dispatch(
      adminGroupSetFieldValue({
        id: evt.id,
        groupName: evt.groupName,
        description: evt.description,
        bucket: evt.buckets,
        users: evt.users,
        dfStudioAccess: evt.dfStudioAccess,
      })
    );
  }

  /**
   * handle user add/edit
   * @params - evnt - rowdata
   */
  function tableRolesEvents(evt) {
    buttonTextState(true);
    toggleFormState(true);
    showRolesState(true);
    showGroupsState(false);
    showUsersState(false);
    showUserLogState(false);
    showEditedRolesState(true);
    setTabValue(2);
    if (evt) {
      setRolesLabel(evt.rolename);
    }
    let configData;
    if (evt && evt.config && typeof evt.config === "string") {
      configData = JSON.parse(evt.config);
    }
    /**
     * dispatch users values
     *
     */
    dispatch(
      adminRoleSetFieldValue({
        id: evt.id,
        name: evt.name,
        description: evt.description,
        idletime: evt.idletime,
        userAccess: configData?.userAccess || "[]",
        paths: configData?.paths || [],
        user: configData?.user,
        directory: evt.directory,
        userData: configData?.userData,
      })
    );
  }

  /**
   * handle tab chane
   * @params - event
   * @params - newValue
   */
  const handleTabChange = (event, newValue) => {
    if (showGroups && !showRoles && !showUsers && !showUserLog) {
      setTabValue(1);
      return;
    }
    if (showUsers && !showGroups && !showRoles && !showUserLog) {
      setTabValue(0);
      return;
    }
    if (showRoles && !showGroups && !showUsers && !showUserLog) {
      setTabValue(2);
      return;
    }

    if (showUserLog && !showRoles && !showGroups && !showUsers) {
      setTabValue(3);
      return;
    }

    setTabValue(newValue);
  };
  const handleCheckboxChange = (e) => {
    dispatch(adminGroupSetFieldValue({ [e.target.name]: e.target.checked }));
  };
  const handleShowForm = () => {
    dispatch(adminUserResetValues());
    dispatch(adminGroupResetValues());
    dispatch(adminRoleResetValues());
    buttonTextState(false);
    toggleFormState(true);
    showUsersState(adminTableValue === 0);
    showGroupsState(adminTableValue === 1);
    showRolesState(adminTableValue === 2);
    showUserLogState(adminTableValue === 3);
    setTabValue(adminTableValue);

    showEditedRolesState(false);
  };
  const handleCloseForm = () => {
    toggleFormState(false);
  };
  const handleClose = () => {
    setModalItem({});
  };

  /**
   * store selector
   */
  const adminuser = useSelector((state) => state.adminuser);
  const admingroup = useSelector((state) => state.admingroup);
  const adminrole = useSelector((state) => state.adminrole);
  const [rolesData, setRolesData] = useState([]);

  useEffect(() => {
    getGroupByUser("1262fb35-0a0a-45a0-bf54-8199d22526b2");
    getUserByemail()
      .then((finalData) => {
        if (finalData)
          if (finalData.userData && finalData.userData.length) {
            setUserInfo(finalData.userData[0]);
            fetchRoles(finalData.userData[0]);
          }
      })
      .catch(() => { });
  }, []);

  /**
   *
   */
  const fetchRoles = async (userData) => {
    await fetchRoleLists()
      .then((result) => {
        const pos = result.items
          .map(function (e) {
            return e.name;
          })
          .indexOf("Super Admin");
        if (pos !== -1 && userData.roles[0] !== "Super Admin") {
          result.items.splice(pos, 1);
        }
        setRolesData(result.items);
      })
      .catch(() => { });
  };

  /**
   * add user
   */
  const addUser = async () => {
    await getUserByEmail(adminuser.email)
      .then((res) => {
        if (res?.userByEmail?.items && res?.userByEmail?.items.length > 0) {
          if (res?.userByEmail?.items[0].email === adminuser.email) {
            setState({
              ...state,
              open: true,
              message: "Email Already Exist!",
              severity: "error",
            });
          }
          return;
        }
        createUserAdmin();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const createUserAdmin = useCallback(async () => {
    await createAdminUser()
      .then((admiuserRes) => {
        toggleFormState(false);

        setState({
          ...state,
          open: true,
          message: "Saved Successfully!",
          severity: "success",
        });
        invoke("wdtvamtoolCognitoUtils-" + awsConfig.environment, {
          data: {
            region: config.aws_project_region,
            action: "add-user",
            email: admiuserRes.email,
            phone: "+11231231234",
            userPoolId: config.aws_user_pools_id,
          },
        }, currentUser.creds)
          .then((cogRes) => { })
          .catch((err) => {
            console.dir(err);
          });

        dispatch(adminUserResetValues());
        setIsUserUpdated(true);
      })
      .catch(() => {
        setState({
          ...state,
          open: true,
          message: "Date deleted Successfully!",
          severity: "error",
        });
      });
  });
  /**
   * update user
   */
  const updateUser = useCallback(async () => {
    await updateAdminUser()
      .then(() => {
        toggleFormState(false);

        setState({
          ...state,
          open: true,
          message: "Saved Successfully!",
          severity: "success",
        });
        createOneUserGroup();
        setIsUserUpdated(true);
      })
      .catch(() => {
        setState({
          ...state,
          open: true,
          message: "Error!",
          severity: "error",
        });
      });
  }, []);
  /**
   * create group
   */
  const addGroup = async () => {
    await getGroupByName(admingroup.groupName)
      .then((res) => {
        if (res?.groupByName?.items && res?.groupByName?.items.length > 0) {
          if (res?.groupByName?.items[0].groupName === admingroup.groupName) {
            setState({
              ...state,
              open: true,
              message: "Group Name Already Exist!",
              severity: "error",
            });
          }
          return;
        }

        createGroupAdmin();
      })
      .catch((error) => { });
  };

  const createGroupAdmin = useCallback(async () => {
    await createAdminGroup()
      .then((addGroupRes) => {
        invoke("wdtvamtoolCognitoUtils-" + awsConfig.environment, {
          data: {
            region: config.aws_project_region,
            action: "add-group",
            groupName: addGroupRes.groupName,
            dfStudioAccess: addGroupRes.dfStudioAccess,
            description: addGroupRes.description,
            userPoolId: config.aws_user_pools_id,
          },
        }, currentUser.creds)
          .then((cogRes) => { })
          .catch((err) => {
            console.dir(err);
          });

        toggleFormState(false);

        setState({
          ...state,
          open: true,
          message: "Saved Successfully!",
          severity: "success",
        });
      })
      .catch(() => {
        setState({
          ...state,
          open: true,
          message: "Error!",
          severity: "error",
        });
      });
    dispatch(adminGroupResetValues());
    setIsGroupUpdated(true);
  }, []);
  /**
   * update group
   */
  const updateGroup = useCallback(async () => {
    await updateAdminGroup()
      .then((updateGroupRes) => {
        toggleFormState(false);

        setState({
          ...state,
          open: true,
          message: "Saved Successfully!",
          severity: "success",
        });
        createOneGroupUser(currentUser.creds);
        setIsGroupUpdated(true);
      })
      .catch(() => {
        setState({
          ...state,
          open: true,
          message: "Error!",
          severity: "error",
        });
      });
  }, []);
  /**
   * create role
   */
  const addRole = async () => {
    await getRoleByName(adminrole.name)
      .then((res) => {
        if (res?.roleByName?.items && res?.roleByName?.items.length > 0) {
          if (res?.roleByName?.items[0].name === adminrole.name) {
            setState({
              ...state,
              open: true,
              message: "Role Name Already Exist!",
              severity: "error",
            });
          }
          return;
        }
        createRolesAdmin();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createRolesAdmin = useCallback(async () => {
    await createAdminRoles()
      .then(() => {
        toggleFormState(false);
        setState({
          ...state,
          open: true,
          message: "Saved Successfully!",
          severity: "success",
        });
      })
      .catch(() => {
        setState({
          ...state,
          open: true,
          message: "Error!",
          severity: "error",
        });
      });
    dispatch(adminRoleResetValues());
    setIsRoleUpdated(true);
  }, []);
  /**
   * update role
   */
  const updateRole = useCallback(async () => {
    let userData = adminrole.userAccess ? adminrole.userAccess : localStorage.getItem("selectedNodes");
    userData = JSON.parse(adminrole.userAccess);
    let checkSpecificPath = userData.filter((val) => val.path === "4.children.9");
    if (checkSpecificPath.length == 0 && adminrole.userData?.length) {
      let data = adminrole.userData;

      data = data.map((element, index) => {

        if (adminrole.bucket == element.bucket) {
          return {
            ...element, // copy all properties from the original object
            paths: [], // set a new value for the paths property
          };

        }
        return element;

      });
      dispatch(adminRoleSetFieldValue({ userData: data }));
      dispatch(adminRoleSetFieldValue({ paths: [] }));

    }

    dispatch(
      adminRoleSetFieldValue({
        userAccess: adminrole.userAccess
          ? adminrole.userAccess
          : localStorage.getItem("selectedNodes"),
      })
    );
    await updateAdminRoles()
      .then(() => {
        toggleFormState(false);

        setState({
          ...state,
          open: true,
          message: "Saved Successfully!",
          severity: "success",
        });
        getUserByemail();
      })
      .catch(() => {
        setState({
          ...state,
          open: true,
          message: "Error!",
          severity: "error",
        });
      });
    setIsRoleUpdated(true);
  }, [adminrole]);

  /**
   * Alert Success
   */
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };
  return (
    <div style={{ width: "100%" }}>
      {showForm ? (
        <div>
          <Dialog
            disableEnforceFocus
            open={showForm}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            fullWidth="true"
            maxWidth="lg"
            classes={{ paper: classes.modalWrapper }}
          >
            <DialogTitle className={classes.dialogTitle} disableTypography>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  System Administration
                </h4>
              </CardHeader>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ borderBottom: 1, borderColor: "primary" }}>
                <Tabs
                  value={0}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  {showUsers && <Tab label="USERS" />}
                  {showGroups && <Tab label="GROUP" />}
                  {showRoles && <Tab label={rolesLabel} />}
                  {showUserLog && <Tab label="USER LOGS" />}
                </Tabs>
              </Box>
              <Box hidden={tabValue !== 0}>
                <Card className={classes.dialogCard}>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={5} sm={12} md={5}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              className="input-style"
                              fullWidth
                              id="First Name"
                              label="First Name"
                              variant="outlined"
                              name="firstName"
                              value={adminuser.firstName}
                              onChange={handleInputChange}
                            />
                          </GridItem>
                          <span> &nbsp; </span>

                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              className="input-style"
                              fullWidth
                              id="Email"
                              label="Email"
                              name="email"
                              placeholder="username@email.com"
                              variant="outlined"
                              value={adminuser.email}
                              onChange={handleInputChange}
                            />
                          </GridItem>

                          <span> &nbsp; </span>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-multiple-chip-label">
                                Role
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                label="roles"
                                value={adminuser.roles}
                                name="roles"
                                onChange={handleInputChange}
                                input={
                                  <OutlinedInput
                                    id="select-multiple-chip"
                                    label="Role"
                                  />
                                }
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {rolesData.map((data) => (
                                  <MenuItem key={data.name} value={data.name}>
                                    {data.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                          <span> &nbsp; </span>
                          {adminuser.id && (
                            <GridItem xs={12} sm={12} md={12}>
                              <FormControl
                                fullWidth
                                className={classes.formControl}
                              >
                                <InputLabel id="demo-multiple-chip-label">
                                  Status
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  label="status"
                                  name="status"
                                  variant="outlined"
                                  value={adminuser.status}
                                  onChange={handleInputChange}
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  <MenuItem value={"pending"}>Pending</MenuItem>
                                  <MenuItem value={"active"}>Active</MenuItem>
                                  <MenuItem value={"suspended"}>
                                    Suspended
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </GridItem>
                          )}
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}></GridItem>
                      <GridItem xs={5} sm={12} md={5}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              className="input-style"
                              fullWidth
                              id="LastName"
                              name="lastName"
                              label="LastName"
                              variant="outlined"
                              value={adminuser.lastName}
                              onChange={handleInputChange}
                            />
                          </GridItem>

                          <span> &nbsp; </span>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              className="input-style"
                              fullWidth
                              id="Title"
                              label="Title"
                              variant="outlined"
                              name="title"
                              value={adminuser.title}
                              onChange={handleInputChange}
                            />
                          </GridItem>

                          <span> &nbsp; </span>
                          {adminuser.id && <GroupsList />}
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Grid container justify="center">
                      <Grid item justify="center" xs={5} sm={12} md={2}>
                        {buttonText ? (
                          <Button
                            fullWidth
                            color="primary"
                            onClick={updateUser}
                          >
                            Update User
                          </Button>
                        ) : (
                          <Button fullWidth color="primary" onClick={addUser}>
                            Add User
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </CardFooter>
                </Card>
              </Box>
              <Box hidden={tabValue !== 1}>
                <Card className={classes.dialogCard}>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={5} sm={12} md={5}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              className="input-style"
                              fullWidth
                              id="Group Name"
                              label="Group Name"
                              variant="outlined"
                              name="groupName"
                              value={admingroup.groupName}
                              onChange={handleGroupChange}
                            />
                          </GridItem>

                          <span> &nbsp; </span>
                          <GridItem xs={12} sm={12} md={12}>
                            <BucketsList />
                          </GridItem>
                          <span> &nbsp; </span>
                          {Dfstudio.enable && (
                            <GridItem xs={12} sm={12} md={12}>
                              <FormControlLabel
                                style={{ marginLeft: "0px !important" }}
                                control={
                                  <Checkbox
                                    checked={admingroup.dfStudioAccess}
                                    value={admingroup.dfStudioAccess}
                                    onChange={handleCheckboxChange}
                                    name="dfStudioAccess"
                                    color="primary"
                                  />
                                }
                                label="DF Studio"
                                labelPlacement="end"
                              />
                            </GridItem>
                          )}
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}></GridItem>
                      <GridItem xs={5} sm={12} md={5}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              className="input-style"
                              fullWidth
                              id="Description"
                              label="Description"
                              variant="outlined"
                              name="description"
                              value={admingroup.description}
                              onChange={handleGroupChange}
                            />
                          </GridItem>
                          <span> &nbsp; </span>
                          <GridItem xs={12} sm={12} md={12}>
                            {admingroup.id && <UsersList />}
                          </GridItem>

                          <span> &nbsp; </span>

                          <span> &nbsp; </span>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Grid container justify="center">
                      <Grid item justify="center" xs={5} sm={12} md={2}>
                        {buttonText ? (
                          <Button
                            fullWidth
                            color="primary"
                            onClick={updateGroup}
                          >
                            Update Group
                          </Button>
                        ) : (
                          <Button fullWidth color="primary" onClick={addGroup}>
                            Add Group
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </CardFooter>
                </Card>
              </Box>

              <Box hidden={tabValue !== 2}>
                <Card className={classes.dialogCard}>
                  <CardBody>
                    <GridContainer>
                      {isEditedRoles ? (
                        <RolesTable />
                      ) : (
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                  className="input-style"
                                  fullWidth
                                  id="Role Name"
                                  label="Role Name"
                                  variant="outlined"
                                  name="name"
                                  value={adminrole.name}
                                  onChange={handleRoleChange}
                                />
                              </GridItem>

                              <span> &nbsp; </span>
                              <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                  className="input-style"
                                  fullWidth
                                  id="Description"
                                  label="Description"
                                  variant="outlined"
                                  name="description"
                                  value={adminrole.description}
                                  onChange={handleRoleChange}
                                />
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      )}
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Grid container justify="center">
                      <Grid item justify="center" xs={4} sm={12} md={6}>
                        {buttonText ? (
                          <Button
                            fullWidth
                            color="primary"
                            onClick={updateRole}
                          >
                            Update Role
                          </Button>
                        ) : (
                          <Button fullWidth color="primary" onClick={addRole}>
                            Add Role
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </CardFooter>
                </Card>
              </Box>
              <Box hidden={tabValue !== 3}>
                <Card className={classes.dialogCard}>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <InputLabel id="demo-multiple-chip-label">
                          Select daterange
                        </InputLabel>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className={classes.userLogFormControl}
                        >
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={{
                              start: "Start date",
                              end: "End date",
                            }}
                          >
                            <DateRangePicker
                              value={userLogDateRange}
                              onChange={(newValue) => {
                                setUserLogDateRange(newValue);
                              }}
                              renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                  <TextField {...startProps} />
                                  <Box sx={{ mx: 2 }}> to </Box>
                                  <TextField {...endProps} />
                                </React.Fragment>
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </GridItem>
                      <span> &nbsp; </span>
                      <span> &nbsp; </span>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.userLogFormControl}
                        >
                          <InputLabel id="demo-multiple-chip-label">
                            Download Format
                          </InputLabel>
                          <Select
                            labelId="downloadMenu-label"
                            name="downloadMenu"
                            id="downloadMenu-id"
                            label="downloadMenu-label"
                          >
                            <MenuItem value="csv">Download CSV</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Grid container justify="center">
                      <Grid item justify="center" xs={4} sm={12} md={6}>
                        <Button
                          fullWidth
                          color="primary"
                          onClick={() => handleCloseForm()}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </CardFooter>
                </Card>
              </Box>
            </DialogContent>
            <div style={{ textAlign: "right" }}>
              <Button
                contained
                color="secondary"
                startIcon={<CancelIcon />}
                onClick={() => handleCloseForm()}
              >
                Close Form
              </Button>
            </div>
          </Dialog>
        </div>
      ) : (
        <div style={{ textAlign: "right" }}>
          <Icon
            className={classes.iconCss}
            color="primary"
            sx={{ fontSize: 40 }}
            startIcon={<PersonAddIcon />}
            onClick={() => handleShowForm()}
          >
            add_circle
          </Icon>
        </div>
      )}
      <AdminTabs
        isUserUpdated={isUserUpdated}
        isGroupUpdated={isGroupUpdated}
        isRoleUpdated={isRoleUpdated}
        setIsUserUpdated={setIsUserUpdated}
        setIsGroupUpdated={setIsGroupUpdated}
        setIsRoleUpdated={setIsRoleUpdated}
        tableUsersEvents={handleTableEvent}
        tableGroupsEvents={tableGroupsEvents}
        tableRolesEvents={tableRolesEvents}
        setAdminTableValue={setAdminTableValue}
      />

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}
