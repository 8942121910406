import React from "react";
// core components
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";
import Button from "../button/Button";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import CardBody from "../card/CardBody";
import CardFooter from "../card/CardFooter";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";


const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
   formControl: {
    minWidth: 120,
  },
};

const useStyles = makeStyles(styles);

export default function TypographyPage() {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Add New People </h4>
        
      </CardHeader>
      <CardBody>
      <GridContainer>
      <GridItem xs={2} sm={2} md={3}>
              </GridItem>
              
              <GridItem xs={5} sm={12} md={6}>
                  <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="DayParts" label="Name" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                            <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={4}
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                  />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="order" label="Date" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="Name" label="Link" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  
                  <Button color="primary" variant="contained" component="label">
                      Choose Media file <input type="file" hidden />
                  </Button>
                
                </GridItem>  
                  <span> &nbsp; </span>
                    
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="RelatedPictures" label="Related Pictures" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                    
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="Keywords" label="Keywords" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span> 
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="Notes" label="Notes" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                    
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="Show" label="Shows" variant="outlined" />
                  </GridItem>
                  
                  <span> &nbsp; </span>
                </GridContainer>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
              </GridItem>
              <Grid container justify='center'>
      <Grid item justify='center' xs={5} sm={12} md={2}>
      <Button fullWidth color="primary">Submit</Button>
      </Grid>
      </Grid>  
            </GridContainer>
                  
      </CardBody>
      <CardFooter>

      
            </CardFooter>
    </Card>
  );
}
