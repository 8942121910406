import { createAction, createReducer , createSelector } from "@reduxjs/toolkit";

/**
 * Imagelist CRUD Actions
 */
export const setImageList = createAction("IMAGE_LIST_SET_FIELD_VALUES");

/**
 * Imagelist State
 */

export const imageListDefaultValue = [];


/**
 * Imagelist Reducers
 */
const imageListReducer = createReducer(imageListDefaultValue, (builder) => {
  builder.addCase(setImageList, (state, { payload }) => {
    Object.assign(state, payload);
    return state;
  });
});

export default imageListReducer;
