import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import invoke from 'service/lambda.service';
import AssetBrowserListItem from './AssetBrowserListItem';
import AssetBrowserBreadcrumbs from './AssetBrowserBreadcrumbs';
import { awsConfig } from '../../config';
import { useAppUserContext } from 'lib/contextLib';

const useStyles = makeStyles((theme) => createStyles({
  content: {
    minWidth: '300px',
    minHeight: '400px',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function AssetBrowser({ bucket, foldersOnly, onSubmit, isUpload }) {
  const [objects, setObjects] = useState([]);
  const [currentDir, setCurrentDir] = useState('/');
  const userRolesData = localStorage.getItem("userRoles");
  const classes = useStyles();
  const currentUser = useAppUserContext();

  useEffect(() => {
    if (bucket) {
      const adminRole = JSON.parse(userRolesData);
      let include =[]
      if(adminRole.bucketBasedOnUser && adminRole.bucketBasedOnUser.length){
        const index = adminRole.bucketBasedOnUser.map(object => object.bucket).indexOf(bucket);
        if(index !== -1) {
          if(adminRole.bucketBasedOnUser[index]?.paths) {
            adminRole.bucketBasedOnUser[index].paths.forEach((val) => {
              include.push(val.path);
            })
          }
          
        }
      }else {
        if (adminRole && adminRole.paths) {
          adminRole.paths.forEach((val)=>{
            include.push(val.path);
          })
        }
      }


      invoke('wdtvamtoolAsperaNAPI-' + awsConfig.environment, {
        args: {
          service: 'list',
          data: {
            bucket,
            path: currentDir,
            include: isUpload ? include : []
          },
        }
      },
      currentUser.creds,
      ).then(res => {
        const respAsObject = JSON.parse(res['Payload']);
       setObjects(respAsObject.body.items?.filter((item) => (!foldersOnly || item?.type === 'directory')));
      })
    }
  }, [bucket, currentDir, foldersOnly]);

  const handleOpenDir = (newPath) => {
    setCurrentDir(newPath);
  }
  const handleSubmit = () => {
    onSubmit({ dir: currentDir, selectedFiles: [] });
  }
  return (
    <Dialog open>
      <DialogTitle id="simple-dialog-title">Asset Browser</DialogTitle>
      <DialogContent className={classes.content}>
        <AssetBrowserBreadcrumbs bucket={bucket} currentPath={currentDir} openDir={handleOpenDir} />
        <List dense>
          {objects?.map(o => (<AssetBrowserListItem item={o} key={o?.path} openDir={handleOpenDir} />))}
        </List>
      </DialogContent>
      <Fab aria-label='select' color='primary' className={classes.fab} onClick={handleSubmit}>
        <CheckIcon />
      </Fab>
    </Dialog>
  )
}
