import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import { dispatch } from "redux-store";
import {
  adminUserSetFieldValue
} from "store/admin-user-reducer";
import {
  fetchGroupLists,
  listAllUserGroups,
  deleteOneGroupUser,
} from "service/api.service";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function GroupsList() {
  const [groupName, setGroupName] = React.useState([]);
  const [groupData, setGroupData] = useState([]);
  const [listAllUsers, setListAllUsers] = React.useState([]);

  /**
   * store selector
   */
  const adminuser = useSelector((state) => state.adminuser);

  /**
   *
   */

  useEffect(() => {
    fetchGroups();
  }, []);

  /**
   *
   */
  async function fetchGroups() {
    let data = [];
    await fetchGroupLists().then((success) => {
      if (success && success.items) {
        success.items.forEach((val) => {
          data.push({ label: val.groupName, groupid: val.id });
        });
      }
      setGroupData(data);
      fetchAllUserGroups(data);
    });


  }

  /**
   *
   * @param {*} event
   */
  async function fetchAllUserGroups(data) {
    await listAllUserGroups().then((success) => {
      setListAllUsers(success?.items);
      const groups = [];
      success?.items.map((val) => {
        if (val.userId === adminuser.id) {
          groups.push({ label: val.group.groupName, groupid: val.group.id, id: val.id });
        }
      });


      setGroupName(groups)
      dispatch(adminUserSetFieldValue({ groups: groups }));
    });

  }
  /**
   * 
   * @param  {...any} params 
   * @returns 
   */
  const handleDropdownChange = (...params) => {
    if (params && params[2] === "clear") {
      setGroupName([]);
      dispatch(adminUserSetFieldValue({ systemUsers: [] }));
      return;
    }
    const changedOption = params[3].option;
    const selectionType = params[2]; //
    const allSelected = params[1]; //
    addRomoveUsers(allSelected, changedOption, selectionType);
  };
  /**
   * 
   * @param {*} allSelected 
   * @param {*} changedOption 
   * @param {*} selectionType 
   */
  const addRomoveUsers = (allSelected, changedOption, selectionType) => {
    if (selectionType === "selectOption") {
      setGroupName(allSelected);
      dispatch(adminUserSetFieldValue({ groups: allSelected }));

    }
    if (selectionType === "removeOption") {
      deleteOneGroupUser(changedOption.id);
      const pos = allSelected
        .map(function (e) {
          return e.label;
        })
        .indexOf(changedOption.label);
        
      if (pos !== -1) {
        deleteOneGroupUser(allSelected[pos].id);
        allSelected.splice(pos, 1); // 2nd parameter means remove one item only 
      }
      
      setGroupName(allSelected);
      dispatch(adminUserSetFieldValue({ groups: allSelected }));
    }
  };
  return (
    <FormControl sx={{ m: 1, width: 350 }}>
      <Autocomplete
        multiple
        limitTags={2}
        id="tags-standard"
        options={groupData}
        getOptionLabel={(option) => option.label || ""}
        value={adminuser.groups}
        onChange={handleDropdownChange}
        filterSelectedOptions
        sx={{ width: 485 }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="GROUPS"

          />
        )}
      />
    </FormControl>
  );
}
