import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";
import Button from "../button/Button";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import CardBody from "../card/CardBody";
import CardFooter from "../card/CardFooter";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from "@mui/material/OutlinedInput";
import { useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  adminUserSetFieldValue,
} from "store/admin-user-reducer";
import { dispatch } from "redux-store";
import {
  updateAdminUser,
  fetchRoleLists,
  getUserByemail
} from "service/api.service";
import Box from '@material-ui/core/Box';
const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  formControl: {
    minWidth: 120,
  },
};

const useStyles = makeStyles(styles);
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Profile() {
  const classes = useStyles();
  const [rolesData, setRolesData] = useState([]);
  const adminuser = useSelector((state) => state.adminuser);
  const [showLoading, setShowLoading] = useState(false);
  const [pageActive, setPageActive] = useState(true);

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });
  const { vertical, horizontal, open, severity, message } = state;

  useEffect(() => {
    setShowLoading(true);
    getUserByemail().then((finalData) => {
      if (finalData.userData && finalData.userData.length) {
        if (finalData.userData[0].status.toLowerCase() === "pending") {
          setPageActive(false);
          setState({
            ...state,
            open: true,
            message: "“Your permissions must be configured by system admin,please try again later”!",

            severity: "error",
          });
          setShowLoading(false);
          return
        }
        fetchRoles(finalData.userData[0]);
      }

      if (finalData && finalData.userData && finalData.userData.length) {
        dispatch(
          adminUserSetFieldValue({
            id: finalData.userData[0].id,
            firstName: finalData.userData[0].firstName,
            lastName: finalData.userData[0].lastName,
            email: finalData.userData[0].email,
            roles: finalData.userData[0].roles,
            mobile: finalData.userData[0].mobile,
            phone: finalData.userData[0].phone,
            location: finalData.userData[0].location,
            securityAnswer: finalData.userData[0].securityAnswer,
            securityQuestion: finalData.userData[0].securityQuestion,

          })
        );
      }
      setShowLoading(false);
    }).catch(() => {
      setShowLoading(false);
    });
  }, []);


  /**
*
*/
  const fetchRoles = async (userData) => {
    await fetchRoleLists().then((result) => {
      const pos = result.items.map(function (e) { return e.name; }).indexOf("Super Admin");
      if (pos !== -1 && userData.roles[0] !== "Super Admin") {
        result.items.splice(pos, 1);
      }
      setRolesData(result.items);
    }).catch(() => {

    });
  }

  const handleInputChange = (e) => {
    if (e.target.name === "roles") {
      dispatch(adminUserSetFieldValue({ [e.target.name]: e.target.value }));
      return;
    }
    dispatch(adminUserSetFieldValue({ [e.target.name]: e.target.value }));
  };
  const updateProfile = useCallback(async () => {
    await updateAdminUser()
      .then(() => {
        setState({
          ...state,
          open: true,
          message: "Saved Successfully!",
          severity: "success",
        });

      })
      .catch(() => {
        setState({
          ...state,
          open: true,
          message: "Error!",
          severity: "error",
        });
      });
  }, []);


  /**
   * Alert Success
   */
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };
  return (
    <Box>
      {pageActive && (
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>User Profile</h4>
            
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={5} sm={12} md={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField className="input-style" fullWidth id="First Name" label="First Name" variant="outlined" value={adminuser.firstName} name="firstName" onChange={handleInputChange} />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField className="input-style" fullWidth id="Phone" label="Phone" variant="outlined" value={adminuser.phone} name="phone" onChange={handleInputChange} />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField className="input-style" fullWidth id="Email" label="Email" placeholder="username@email.com" variant="outlined" value={adminuser.email} name="email" onChange={handleInputChange} />
                  </GridItem>

                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                    
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>

                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="roles"
                        value={adminuser.roles}
                        name="roles"
                        onChange={handleInputChange}
                        input={<OutlinedInput id="select-multiple-chip" />}>
                        {rolesData.map((data) => (
                          <MenuItem
                            key={data.name}
                            value={data.name}
                          >
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>


                    </FormControl>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
              </GridItem>
              <GridItem xs={5} sm={12} md={5}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField className="input-style" fullWidth id="LastName" label="LastName" variant="outlined" value={adminuser.lastName} name="lastName" onChange={handleInputChange} />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField className="input-style" fullWidth id="Mobile" label="Mobile" variant="outlined" value={adminuser.mobile} name="mobile" onChange={handleInputChange} />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField className="input-style" fullWidth id="Location" label="Location" variant="outlined" value={adminuser.location} name="location" onChange={handleInputChange} />
                  </GridItem>
                  <span> &nbsp; </span>
                  
                  <GridContainer>
                  </GridContainer>
                </GridContainer>
              </GridItem>

            </GridContainer>
            

          </CardBody>
          <CardFooter>

            <Grid container justify='center'>
              <Grid item justify='center' xs={5} sm={12} md={2}>
                <Button fullWidth color="primary" onClick={updateProfile}>Save</Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
      )}

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
}
