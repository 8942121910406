import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Auth } from "aws-amplify";
import React, { useCallback, useState } from 'react';
import { useFormFields } from '../../lib/hooksLib';
import LoginFooter from './LoginFooter';
import Alert from '@material-ui/lab/Alert';
import UserConfirmCard from 'components/auth/UserConfirmCard';
import {forceUserSetFieldValues} from '../../store/force-user-change-password.reducer';
import { dispatch } from "redux-store"; 

import awsExports from "../../aws-exports";
import jwt_decode from "jwt-decode";

import Cookies from 'universal-cookie';
import { goTo } from 'redux-store';
import { useAppUserContext } from 'lib/contextLib.js'
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const cookies = new Cookies();

// const oauthProviderName = 'OKTA';
const oauthProviderName = 'DisneyMyID';
const token_endpoint = ('https://wdpmt.auth.<region>.amazoncognito.com/oauth2/token').replace('<region>', awsExports.aws_project_region);

const redirectUrl = window.location.protocol + '//' + window.location.hostname + '/login';

const oauth = {
  domain: ('wdpmt.auth.<region>.amazoncognito.com').replace('<region>', awsExports.aws_project_region),
  scope: ["phone", "email", "openid", "aws.cognito.signin.user.admin","profile"],
  redirectSignIn: redirectUrl,
  redirectSignOut: redirectUrl,
  responseType: "code"
};

const LoginForm = () => {

  const authenticatedUser = useAppUserContext();

  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: '',
    confirmationCode: ''
  });

  const processLoginFederation = () => {
    return new Promise(async (resolve, reject) => {

      const already_federated_response = cookies.get('federation_response');
      if (already_federated_response) { //---already federated and you are here because of a page refresh (from myid session)
       resolve({id_token: already_federated_response});
      } else if (window.location.href.includes('?code=')) {
        console.log('Access code received from myID. Will use it get access token for federation...');

        const params = new URLSearchParams(window.location.href.split('?')[1]);
        
          
        if (params) { 
          const code = params.get('code');
          const lastAccessCode = cookies.get('lac');
          if (lastAccessCode && lastAccessCode === code && authenticatedUser?.authData) {
            resolve(null);
          } else {
            cookies.set('lac', code, {path: '/', maxAge: 60});
          }

          const formData = new URLSearchParams();
          formData.append('grant_type', 'authorization_code');
          formData.append('client_id', awsExports.aws_user_pools_web_client_id);
          formData.append('client_secret', "");
          formData.append('code', code);
          formData.append('redirect_uri', oauth.redirectSignIn);

  
          await fetch(token_endpoint, {
            method: 'POST',
            mode: 'cors',
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/x-www-form-urlencoded',
  
            },
            body: formData,
          }).then((result) => {
            resolve(result.json());
          }).catch((err)=>{
           resolve(null);
          });
        }
      } else {
        resolve(null);
      }
    });
  }

  
  if (!cookies.get('refresh_token_in_progress') && !cookies.get('duplicate_session')) {
    processLoginFederation().then(async (resp)=>{

      if (resp && resp.id_token) {

        const now = new Date();

        const decodedIdToken = jwt_decode(resp.id_token);
        const exp = new Date(0);
        exp.setSeconds(decodedIdToken.exp);
        const expiryInSeconds = (exp.getTime() - now.getTime()) / 1000;
        cookies.set('federation_response', resp.id_token, {path: '/', maxAge: expiryInSeconds}); //---expiry taken from decoded id token
        if (resp.refresh_token) {
          cookies.set('refresh_token', resp.refresh_token, {path: '/', maxAge: 86400}); //---cognito refresh_token expiry set to 24 hours in cognito console.
        }
       const cognitoProviderId = 'cognito-idp.' 
                                    + awsExports.aws_cognito_region
                                    + '.amazonaws.com/'
                                    + awsExports.aws_user_pools_id;

        const expiresAt = new Date(now.getTime() + (expiryInSeconds * 1000));


        await Auth.federatedSignIn(
          cognitoProviderId, {
            token: resp.id_token,
            expires_at: expiresAt //--This is supposed to be set to the expiry in the token provided by myId - which is 1 hour.
          },
          {email: decodedIdToken.email, sub: decodedIdToken.sub}
        ).then((cred)=>{
          
        }).catch((err)=>{
          console.log('Federation failed:', err);
        });

      } else {
        console.log('Federated login not performed as id token not obtained from myId...');
      }
      
    }).catch((err)=>{
      console.dir(err);
    });
  }
  // });

  const [showUserConfirmation, setShowUserConfirmation] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const setErrorMessage = useCallback((message) => {
    setErrMsg(message);
    setTimeout(() => {
      setErrMsg('');
    }, 5000)
  }, []);

  const trySignIn = useCallback(async () => {
    setErrMsg('');
    setIsSubmitting(true);
    setShowLoading(true)
    try {
      const user = await Auth.signIn(fields.email, fields.password);
      if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
       dispatch(forceUserSetFieldValues({ user: user }));

        setTimeout(()=>{
          goTo('/force-change-password');          
        }, 2000);
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        try {
          await Auth.resendSignUp(fields.email);
          setShowUserConfirmation(true);
        } catch (err) {
          console.log('error resending code: ', err);
        }
      }
      else if (err.code === "NotAuthorizedException") {
        setErrorMessage(err.message);
      } else {
        setErrorMessage(err.message);
        console.log('error signing in', err);
      }
    }
    setIsSubmitting(false);
    setShowLoading(false)
  }, [fields.email, fields.password, setErrorMessage]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    trySignIn();
  }, [trySignIn]);
  const onUserConfirmation = useCallback(() => {
    setShowUserConfirmation(false);
    trySignIn()
  },[trySignIn]);

  if (showUserConfirmation) {
    return <UserConfirmCard email={fields.email} onSuccess={onUserConfirmation} />
  }
  return (
    <form noValidate onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="User Name"
        name="email"
        autoComplete="User Name"
        autoFocus
        onChange={handleFieldChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={handleFieldChange}
      />

      {errMsg && <Alert severity="error">{errMsg}</Alert>}
      <Button
        type="submit"
        fullWidth
        variant="contained"
      >
        Sign In
      </Button>

      <Button
          fullWidth
          variant="contained"

          onClick={
          async ()=> {
            setIsSubmitting(true);
            setShowLoading(true)
            awsExports.oauth = oauth;
            // Auth.configure({...awsExports, storage: SecretStorageService});
            // await Auth.federatedSignIn({provider: oauthProviderName});

            //--Important Note: The callback url set in SOLO/myID is: https://wdpmt.auth.<region>.amazoncognito.com/oauth2/idpresponse
            //--Important Note: Below domain (https://wdpmt.auth.<region>.amazoncognito.com) is also set up as the domain in cognito user pool.
            const loc = ('https://wdpmt.auth.<region>.amazoncognito.com/oauth2/authorize'
                                    + '?identity_provider=' + oauthProviderName
                                            + '&redirect_uri='+oauth.redirectSignIn
                                            + '&response_type=CODE'
                                            + '&state=abracadabraca'
                                            + '&client_id=' + awsExports.aws_user_pools_web_client_id
                                            + '&scope=aws.cognito.signin.user.admin+email+openid+phone+profile')
                                            .replace('<region>', awsExports.aws_project_region);

           
            window.location.href = loc;
          }
        }
      >
        MyID
      </Button>
      <LoginFooter />
      <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
        <CircularProgress />
      </Backdrop>
    </form>
  );
}

export default LoginForm;
