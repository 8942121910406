import React from "react";
import { useSelector } from "react-redux";
import {
    adminRoleSetFieldValue
} from "store/admin-roles-reducer";
import { dispatch } from "redux-store";
import TextField from "@material-ui/core/TextField";

export const TimeInput = () => {
    const adminrole = useSelector((state) => state.adminrole);

    const onChange = (event) => {
        dispatch(adminRoleSetFieldValue({ [event.target.name]: event.target.value }));
    };

    const onBlur = (event) => {
        const value = event.target.value;
        const seconds = Math.max(0, getSecondsFromHHMMSS(value));

        const time = toHHMMSS(seconds);
        dispatch(adminRoleSetFieldValue({ [event.target.name]: time }));
    };

    const getSecondsFromHHMMSS = (value) => {
        const [str1, str2, str3] = value.split(":");

        const val1 = Number(str1);
        const val2 = Number(str2);
        const val3 = Number(str3);

        if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
            return val1;
        }

        if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
            return val1 * 60 + val2;
        }

        if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
            return val1 * 60 * 60 + val2 * 60 + val3;
        }

        return 0;
    };

    const toHHMMSS = (secs) => {
        const secNum = parseInt(secs.toString(), 10);
        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor(secNum / 60) % 60;
        const seconds = secNum % 60;

        return [hours, minutes, seconds]
            .map((val) => (val < 10 ? `0${val}` : val))
            .filter((val, index) => val !== "00" || index > 0)
            .join(":")
            .replace(/^0/, "");
    };

    return (
        <TextField
            className={`input-style`}
            fullWidth
            id="Idle Time"
            label="Idle Time"
            variant="outlined"
            name="idletime"
            value={adminrole.idletime}
            onChange={onChange}
            onBlur={onBlur}
        />

    );
};

export default TimeInput;