import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Auth } from "aws-amplify";
import { useFormFields } from '../../lib/hooksLib';
import { goTo } from 'redux-store';

const ForgotPasswordConfirmationForm = ({ email }) => {
  const [fields, handleFieldChange] = useFormFields({
    code: '',
    password: '',
  });
  const [errMsg, setErrMsg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback(async(e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const setErrorMessage = (message) => {
      setErrMsg(message);
      setTimeout(() => {
        setErrMsg('');
      }, 5000)
    };
    try {
      await Auth.forgotPasswordSubmit(email, fields.code, fields.password);
      const client= localStorage.getItem('currentClient');
      goTo('/login/'+ client);
    } catch (err) {
      if(err.code === "CodeMismatchException"){
        setErrorMessage(err.message)
      } else {
        setErrorMessage(err.message)
      }
      
    }
    setIsSubmitting(false);
  }, [email, fields.code, fields.password]);
  return <form noValidate onSubmit={handleSubmit}>
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="email"
      label="E-mail"
      name="username"
      autoComplete="User Name"
      value={email}
      readonly
      disabled
    />
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      label="Verification Code"
      name="code"
      autoFocus
      value={fields.code}
      onChange={handleFieldChange}
      disabled={isSubmitting}
      />
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type="password"
      value={fields.password}
      onChange={handleFieldChange}
      disabled={isSubmitting}
      />
      {errMsg && <Alert severity="error">{errMsg}</Alert>}
      <Button fullWidth
        variant="contained"
        type="submit"
        disabled={isSubmitting}
      >
        Reset Password
      </Button>
  </form>
}

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [codeDeliveryDetails, setCodeDeliveryDetails] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const seterrorMessage = (message) => {
      setErrMsg(message);
      setTimeout(() => {
        setErrMsg('');
      }, 5000)
    };
    try {
      const { CodeDeliveryDetails } = await Auth.forgotPassword(email);
      setCodeDeliveryDetails(CodeDeliveryDetails);
    } catch (err) {
      
      seterrorMessage(err.message);
    }
    setIsSubmitting(false);
  }, [email]);
  
  if (codeDeliveryDetails.Destination) {
    return <ForgotPasswordConfirmationForm email={email} />
  }
  return (
    <form noValidate onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="E-mail"
        name="username"
        autoComplete="User Name"
        autoFocus
        value={email}
        onChange={handleEmailChange}
        disabled={isSubmitting}
      />
      {errMsg && <Alert severity="error">{errMsg}</Alert>}
      <Button fullWidth
        variant="contained"
        type="submit"
        disabled={isSubmitting}
      >
        Send Password Reset Link
      </Button>
    </form>
  );
}

export default ForgotPasswordForm;
