import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import { dispatch } from "redux-store";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
import invoke from '../../service/lambda.service';
import {
  adminGroupSetFieldValue
} from "store/admin-group-reducer";
import config from '../../aws-exports';
import {awsConfig} from '../../config';
import { useAppUserContext } from "lib/contextLib";

export default function BucketsList() {
  const theme = useTheme();
  const [bucketLists, setBucketLists] = React.useState([]);
  const [uploadBuckets, setUploadBuckets] = React.useState([]);
  const currentUser = useAppUserContext();  

  /**
   * store selector
   */

  const admingroup = useSelector((state) => state.admingroup);

  
  useEffect(() => {
    let selectedData = [];
    if (admingroup.bucket.length >= 0) {
      admingroup.bucket.map((e) => {
        selectedData.push({ Name: e });
      });
      setBucketLists(selectedData);
    }
  }, [admingroup.bucket]);

  useEffect(() => {
    invoke('wdtvamtoolS3Utils-' + awsConfig.environment,
      {
        "activity": "list-buckets",
        "data": {
          "region": config.aws_project_region,
        }
      }, currentUser.creds).then((resData) => {
        if (resData && resData.Payload) {
          const data = JSON.parse(resData.Payload);
          setUploadBuckets(data.body.Buckets);
        }
      })
      .catch((err) => {
        console.dir(err);
      });
  }, []);

  const handleDropdownChange = (...params) => {
    if (params && params[2] === "clear") {
      dispatch(adminGroupSetFieldValue({ bucket: [] }));
      return;
    }
    const changedOption = params[3].option;
    const selectionType = params[2]; //
    const allSelected = params[1]; //
    addRomoveUsers(allSelected, changedOption, selectionType);
  };
  /**
   *
   * @param {*} allSelected
   * @param {*} changedOption
   * @param {*} selectionType
   */
  const addRomoveUsers = async (allSelected, changedOption, selectionType) => {
    if (selectionType === "selectOption") {
      let slectedData = [];
      await allSelected.map((e) => slectedData.push(e.Name));
      dispatch(adminGroupSetFieldValue({ bucket: slectedData }));
    }
    if (selectionType === "removeOption") {
      // deleteOneGroupUser(changedOption.id);

      const pos = allSelected
        .map(function (e) {
          return e.Name;
        })
        .indexOf(changedOption.Name);
      if (pos !== -1) {
        allSelected.splice(pos, 1); // 2nd parameter means remove one item only
      }

      let slectedData = [];
      await allSelected.map((e) => slectedData.push(e.Name));
      dispatch(adminGroupSetFieldValue({ bucket: slectedData }));
    }
  };

  return (
    <div>
      <FormControl sx={{ width: 350 }}>
        <Autocomplete
          multiple
          limitTags={2}
          id="tags-standard"
          options={uploadBuckets}
          getOptionLabel={(option) => option.Name || ""}
          value={bucketLists}
          onChange={handleDropdownChange}
          filterSelectedOptions
          sx={{ width: 485 }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Source" />
          )}
        />
      </FormControl>
    </div>
  );
}
