import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../button/Button";
import {
  fetchUserLists,
  fetchGroupLists,
  fetchRoleLists,
  listAllUserGroups,
  deleteOneGroupUser,
  updateUserActiveTime,
  listScanRequestSummary,
  searchScanRequest,
  listDfsRequestSummary,
  searchDfsRequest,
} from "service/api.service";
import { adminRoleSetFieldValue } from "store/admin-roles-reducer";
import {
  createAdminRoles,
  updateAdminRoles,
  deleteAdminGroup,
  deleteAdminUser,
  deleteAdminRole,
  createAdminGroup,
} from "service/api.service";
import { adminUserSetFieldValue } from "store/admin-user-reducer";
import { adminGroupSetFieldValue } from "store/admin-group-reducer";
import { dispatch } from "redux-store";
import { useSelector } from "react-redux";
import config from "../../aws-exports";
import { awsConfig } from "../../config";
import invoke from "../../service/lambda.service";
import { getUserByemail } from "service/api.service";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import "./admin.css";
import { format } from "date-fns";
import { useAppUserContext } from "lib/contextLib";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const styles = {
  opacityTr: {
    opacity: "0.5",
  },
  disableDiv: {
    pointerEvents: "none",
    opacity: "0.4",
  },
  formControl: {
    outline: "none",
  },
};
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const useStyles = makeStyles(styles);

export default function AdminTabs(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [showForm, toggleFormState] = useState(false);
  const [modalItem, setModalItem] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [scanReqData, setScanReqData] = useState([]);
  const [dfsReqData, setDfsReqData] = useState([]);
  const [currentDeleteModal, setcurrentDeleteModal] = useState("");
  const [listAllUsers, setListAllUsers] = useState([]);
  const [readOnly, setReadOnly] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [showUsersModal, setShowUsersModal] = React.useState(false);
  const [failedModalData, setFailedModalData] = React.useState([]);


  const currentUser = useAppUserContext();
  let listAllUsersData = [];

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });

  const { vertical, horizontal, open, severity, message } = state;
  /**
   * store selector
   */
  const adminuser = useSelector((state) => state.adminuser);
  const admingroup = useSelector((state) => state.admingroup);

  const [showUser, setShowUsers] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [showRole, setShowRole] = useState(false);
  const [showUserLog, setShowUserLog] = useState(false);
  const [showScanReq, setShowScanReq] = useState(false);
  const [showDfsReq, setShowDfsReq] = useState(false);
  const [currentLoggedInRole, setCurrentLoggedInRole] = useState("");
  useEffect(() => {
    setShowLoading(true);
    getUserByemail()
      .then((finalData) => {
        if (finalData) enableAdminTabs(finalData);
        setShowLoading(false);
      })
      .catch(() => {
        setShowLoading(false);
      });
  }, []);
  useEffect(() => {
    if (userData && userData.length) {
      userData.map((data) => {
        if (currentLoggedInRole === "Super Admin") {
          data.editable = true;
        }
        if (
          currentLoggedInRole === "admin" &&
          data &&
          data.length &&
          data?.roles[0] === "Super Admin"
        ) {
          data.editable = false;
        } else {
          data.editable = true;
        }
      });
    }
    if (rolesData && rolesData.length) {
      rolesData.map((data) => {
        data.editable = true;
        if (currentLoggedInRole === "Super Admin") {
          data.editable = true;
        }
        if (currentLoggedInRole === "admin" && data.name === "Super Admin") {
          data.editable = false;
        } else if (currentLoggedInRole === data.name) {
          data.editable = true;
        }
      });
    }
  }, [currentLoggedInRole]);
  const enableAdminTabs = (userRolesData) => {
    const adminRole = userRolesData;
    if (adminRole.userData && adminRole.userData.length) {
      if (
        adminRole.userData[0].status.toLowerCase() === "pending" ||
        (adminRole.userData[0].status.toLowerCase() !== "pending" &&
          !adminRole.roles[0])
      ) {
        setState({
          ...state,
          open: true,
          message:
            "“Your permissions must be configured by system admin,please try again later”!",
          severity: "error",
        });
        return;
      }
    }
    if (adminRole && adminRole.roles) {
      if (adminRole.roles.indexOf("Super Admin") !== -1) {
        setShowUsers(true);
        setShowGroup(true);
        setShowRole(true);
        setShowUserLog(true);
        setShowScanReq(true);
        setShowDfsReq(true);
        setValue(0);
        props.setAdminTableValue(0);
        setCurrentLoggedInRole("Super Admin");
      } else if (
        adminRole.roles.indexOf("admin") !== -1 ||
        adminRole.roles.indexOf("Admin") !== -1 ||
        adminRole.roles.indexOf("ADMIN") !== -1
      ) {
        setCurrentLoggedInRole("admin");
      } else {
        setCurrentLoggedInRole("nonadmin");
      }
    }
    if (adminRole && adminRole.resultData && adminRole.resultData.length) {
      adminRole.resultData.forEach((val) => {
        if (val.path === "2") {
          setShowUsers(true);
          setShowGroup(true);
          setShowRole(true);
          setShowUserLog(true);
          setShowScanReq(true);
          setShowDfsReq(true);
          setValue(0);
          props.setAdminTableValue(0);
        }
        if (val.path === "2.children.0") {
          setShowGroup(true);
          setValue(1);
          props.setAdminTableValue(1);
        }
        if (val.path === "2.children.1") {
          setShowRole(true);
          setValue(2);
          props.setAdminTableValue(2);
        }
        if (val.path === "2.children.2") {
          setShowUsers(true);
          setValue(0);
          props.setAdminTableValue(0);
        }
        if (val.path === "2.children.3") {
          setShowUserLog(true);
          setValue(3);
          props.setAdminTableValue(0);
        }
        if (val.path === "2.children.4") {
          setShowScanReq(true);
          setValue(4);
          props.setAdminTableValue(4);
        }
        if (val.path === "2.children.5") {
          setShowDfsReq(true);
          setValue(5);
          props.setAdminTableValue(5);
        }
      });
    }
  };

  /**
   *
   */

  useEffect(() => {
    fetchAllUserGroups();
  }, []);

  useEffect(() => {
    fetchUsers();
    props.setIsUserUpdated(false);
  }, [props.isUserUpdated, currentLoggedInRole]);

  useEffect(() => {
    fetchGroups();
    props.setIsGroupUpdated(false);
  }, [props.isGroupUpdated]);

  useEffect(() => {
    fetchRoles();
    props.setIsRoleUpdated(false);
  }, [props.isRoleUpdated]);

  /**
   *
   */
  async function fetchUsers() {
    setShowLoading(true);
    await fetchUserLists()
      .then((success) => {
        listAllUserGroups().then((result) => {
          if (success?.items && success?.items.length) {
            success?.items.forEach((val, ind) => {
              const data = result?.items.filter(
                (value) => val.id === value.userId
              );
              const groups = data.map((item) => item.group.groupName);
              const groupsList = data.map((item) => item.group);
              const usergroupIds = data.map((item) => item.id);
              val["groupsList"] = groupsList;
              val["groups"] = groups;
              val["usergroupids"] = usergroupIds;
            });
            success?.items.map((data) => {
              if (data.roles && data.roles.length) data.roles = data.roles[0];

              if (currentLoggedInRole === "Super Admin") {
                data.editable = true;
              }
              if (
                currentLoggedInRole === "admin" &&
                data.roles === "Super Admin"
              ) {
                data.editable = false;
              } else {
                data.editable = true;
              }
            });
            setUserData(success?.items);
            listScanRequestSummaryApi(success?.items);
            listDfsRequestSummaryApi(success?.items);
            setShowLoading(false);
          }
        });
      })
      .catch((error) => {
        setShowLoading(false);
      });
  }
  /**
   *
   * @param {*} event
   */
  async function fetchAllUserGroups(flag) {
    const { items } = await listAllUserGroups();
    setListAllUsers(items);
    listAllUsersData = items;
  }
  /**
   *
   */
  async function fetchGroups() {
    setShowLoading(true);
    await fetchGroupLists()
      .then((success) => {
        listAllUserGroups().then((result) => {
          if (success?.items && success?.items.length) {
            success?.items.forEach((val, ind) => {
              const data = result?.items.filter(
                (value) => val.id === value.groupId
              );
              const users = data.map((item) => item.user.firstName);
              const usersList = data.map((item) => item.user);
              const groupuserIds = data.map((item) => item.id);
              val["usersList"] = usersList;
              val["users"] = users;
              val["groupuserids"] = groupuserIds;
            });
            setGroupData(success?.items);
            setShowLoading(false);
          }
        });
      })
      .catch((error) => {
        setShowLoading(false);
      });
  }

  /**
   *
   */
  async function fetchRoles() {
    const { items } = await fetchRoleLists();
    items.map((data) => {
      if (currentLoggedInRole === "Super Admin") {
        data.editable = true;
      }
      if (currentLoggedInRole === "admin" && data.name === "Super Admin") {
        data.editable = false;
      } else {
        data.editable = true;
      }
    });
    setRolesData(items);
  }

  /**
   *
   */
  async function listScanRequestSummaryApi(userData) {
    const { items } = await listScanRequestSummary();
    let resultAray = [];
    const Result = Promise.all(
      items.map(async (record, index) => {
        const Uindex = userData.findIndex((object) => {
          return object.email === record.user;
        });
        if (Uindex !== -1) {
          record["firstName"] = userData[Uindex].firstName;
          record["lastName"] = userData[Uindex].lastName;
          record["email"] = userData[Uindex].email;

          await searchScanRequest({
            filter: {
              requestId: { eq: record.requestId },
              status: { matchPhrase: "done" },
            },

          }).then((res) => {
            record["scanned"] = res.total;
          });
          await searchScanRequest({
            filter: {
              requestId: { eq: record.requestId },
              status: { eq: "error" },
            },

          }).then((res) => {
            record["failed"] = res.total;
            if (res && res.items && res.items.length) {
              record["scanRequestDetails"] = res.items;
            }
          });
          record["status"] = record["scanned"] + record["failed"] === record["requestCount"]
            ? "Completed"
            : "In Progress";

          if (record["scanned"] === record["requestCount"]) {
            record["scanned"] = 0;
          }
          record["editable"] = record["failed"] > 0;
          resultAray.push(record);
        }
      })
    )
      .then((res) => {
        setScanReqData(resultAray);
      })
      .catch((err) => {
        setScanReqData([]);
      });
  }

  /**
   *
   */
  async function listDfsRequestSummaryApi(userData) {
    const { items } = await listDfsRequestSummary();
    let resultAray = [];
    const DFSResult = Promise.all(
      items.map(async (record, index) => {
        const Uindex = userData.findIndex((object) => {
          return object.email === record.user;
        });
        if (Uindex !== -1) {
          record["firstName"] = userData[Uindex].firstName;
          record["lastName"] = userData[Uindex].lastName;
          record["email"] = userData[Uindex].email;

          /**
           * Success
           */
          await searchDfsRequest({
            filter: {
              requestId: { eq: record.requestId },
              status: { matchPhrase: "done" },
            }
          }).then((res) => {
            record["requested"] = res.total;
          });
          /**
           * Failure 
           */
          await searchDfsRequest({
            filter: {
              requestId: { eq: record.requestId },
              status: { eq: "failed" },
            }
          }).then((res) => {
            record["failed"] = res.total;
            if (res && res.items && res.items.length) {
              record["dfsPullRequestDetails"] = res.items;
            }
          });
          record["status"] = record["requested"] + record["failed"] === record["requestCount"]
            ? "Completed"
            : "InProgress";
          if (record["requested"] === record["requestCount"]) {
            record["requested"] = 0;
          }


          record["editable"] = record["failed"] > 0;
          resultAray.push(record);
        }
      })
    )
      .then((res) => {
        setDfsReqData(resultAray);
      })
      .catch((err) => {
        setDfsReqData([]);
      });
  }
  /**
   *
   * @param {*} event
   * @param {*} newValue
   */
  const handleChange = (event, newValue) => {
    switch (event.target.innerHTML) {
      case "GROUPS":
        newValue = 1;
        break;
      case "ROLES":
        newValue = 2;
        break;
      case "USER LOGS":
        newValue = 3;
        break;
      case "SCAN REQ.SUMMARY":
        newValue = 4;
        break;
      case "DFS REQ.SUMMARY":
        newValue = 5;
        break;
      case "USERS":
      default:
        newValue = 0;
        break;
    }
    setValue(newValue);
    props.setAdminTableValue(newValue);
  };
  const handleRoleChange = (rowData, e) => {
    const data = JSON.parse(JSON.stringify(rolesData));
    data[rowData.tabIndex]["name"] = e.target.value;
    setRolesData(data);
    dispatch(
      adminRoleSetFieldValue({
        id: rolesData[rowData.tabIndex].id,
        name: e.target.value,
        description: rolesData[rowData.tabIndex].description,
        userAccess: rolesData[rowData.tabIndex].userAccess,
        paths: rolesData[rowData.tabIndex].paths,
        directory: rolesData[rowData.tabIndex].directory,
      })
    );
    updateRole();
  };
  const editRoleName = (i, e) => {
    setReadOnly(false);
  };
  /**
   * update role
   */
  const updateRole = useCallback(async () => {
    await updateAdminRoles();
  }, []);
  const tableRowCopy = (row) => {
    let configData;
    if (row && row.config && typeof row.config === "string") {
      configData = JSON.parse(row.config);
    }
    dispatch(
      adminRoleSetFieldValue({
        name: row.name + " - Copy",
        description: row.description,
        idletime: row.idletime,
        userAccess: configData?.userAccess || "[]",
        paths: configData?.paths || [],
        user: configData?.user,
        directory: row.directory,
        userData: configData?.userData,
      })
    );

    addRole();
  };
  const tableGroupCopy = (row) => {
    /**
     * dispatch users values
     *
     */
    setShowLoading(true);
    dispatch(
      adminGroupSetFieldValue({
        groupName: row.groupName + " - Copy",
        description: row.description,
        dfStudioAccess: row.dfStudioAccess,
        bucket: row.buckets,
        users: row.users,
      })
    );

    addGroup();
  };
  /**
   * create Group
   */
  const addGroup = useCallback(async () => {
    await createAdminGroup().then(() => {
      fetchGroups();
    });
  }, []);
  /**
   * create role
   */
  const addRole = async () => {
    setShowLoading(true);
    await createAdminRoles()
      .then((res) => {
        setShowLoading(false);
        dispatch(
          adminRoleSetFieldValue({
            id: res.id,
          })
        );
        updateRole();
        fetchRoles();
      })
      .catch(() => {
        setShowLoading(false);
      });
  };
  /**
   *
   */
  const deleteUserData = (evt) => {
    toggleFormState(true);
    setcurrentDeleteModal("user");
    dispatch(
      adminUserSetFieldValue({
        id: evt.id,
        firstName: evt.firstName,
        lastName: evt.lastName,
        email: evt.email,
        roles: evt.role,
        title: evt.title,
        groups: evt.groups,
        usergroupids: evt.usergroupids,
        groupsList: evt.groupsList,
      })
    );
  };
  const clearCache = async (evt) => {
    await updateUserActiveTime({
      id: evt.id,
      currentTab: "",
    });
  };
  const retryScanReq = async (evnt) => {
    let resp = await invoke(
      "wdtvamtoolScanUtils-" + awsConfig.environment,
      {
        "action": "retry",
        data: {
          "requestId": evnt.requestId,
          "limit": 1000,
          "user": localStorage.getItem("userEmail"),
        }

      },
      currentUser.creds
    );

    if (resp) {
      const data = JSON.parse(resp.Payload);
      setShowLoading(false);
      setState({
        ...state,
        open: true,
        message:
          data.data,
        severity: "success",
      });
    }
  };
  const retryDFSReq = async (evnt) => {
    let resp = await invoke(
      "wdtvamtoolDFUtils-" + awsConfig.environment,
      {
        "action": "retry",
        "requestId": evnt.requestId,
        "user": localStorage.getItem("userEmail"),
      },
      currentUser.creds
    );

    if (resp) {
      const data = JSON.parse(resp.Payload);
      setShowLoading(false);
      setState({
        ...state,
        open: true,
        message:
          data.data,
        severity: "success",
      });
    }
  }
  /**
   *
   * @param {*} event
   */
  const deleteGroupData = (evt) => {
    toggleFormState(true);
    setcurrentDeleteModal("group");
    dispatch(
      adminGroupSetFieldValue({
        id: evt.id,
        groupName: evt.groupName,
        dfStudioAccess: evt.dfStudioAccess,
        description: evt.description,
        bucket: evt.buckets,
        users: evt.users,
        usersList: evt.usersList,
        groupuserids: evt.groupuserids,
      })
    );
  };
  /**
   *
   * @param {*} event
   */
  const deleteRoleData = (evt) => {
    toggleFormState(true);
    setcurrentDeleteModal("role");
    dispatch(
      adminRoleSetFieldValue({
        id: evt.id,
        name: evt.name,
        description: evt.description,
        userAccess: evt.userAccess,
        paths: evt.paths,
        directory: evt.directory,
      })
    );
  };
  /**
   *
   */
  const deleteRecord = async (evt) => {
    if (currentDeleteModal === "user") {
      await deleteAdminUser().then((res) => {
        if (adminuser.usergroupids && adminuser.usergroupids.length) {
          adminuser.usergroupids.forEach((val) => {
            deleteOneGroupUser(val);
          });
        }
        invoke(
          "wdtvamtoolCognitoUtils-" + awsConfig.environment,
          {
            data: {
              region: config.aws_project_region,
              action: "get-user",
              email: adminuser.email,
              userPoolId: config.aws_user_pools_id,
            },
          },
          currentUser.creds
        )
          .then((cogRes) => {
            const data = JSON.parse(cogRes.Payload);

            invoke(
              "wdtvamtoolCognitoUtils-" + awsConfig.environment,
              {
                data: {
                  region: config.aws_project_region,
                  action: "delete-user",
                  userName: data.body.Username,
                  userPoolId: config.aws_user_pools_id,
                },
              },
              currentUser.creds
            )
              .then((cogRes) => {
                setShowLoading(false);
              })
              .catch((err) => {
                console.dir(err);
                setShowLoading(false);
              });
          })
          .catch((err) => {
            console.dir(err);
            setShowLoading(false);
          });
        fetchUsers();
      });
    }
    if (currentDeleteModal === "group") {
      await deleteAdminGroup().then(() => {
        if (admingroup.groupuserids && admingroup.groupuserids.length) {
          admingroup.groupuserids.forEach((val) => {
            deleteOneGroupUser(val);
          });
          admingroup.usersList.forEach((val) => {
            invoke(
              "wdtvamtoolCognitoUtils-" + awsConfig.environment,
              {
                data: {
                  region: config.aws_project_region,
                  action: "get-user",
                  email: val.email,
                  userPoolId: config.aws_user_pools_id,
                },
              },
              currentUser.creds
            )
              .then((cogRes) => {
                const data = JSON.parse(cogRes.Payload);

                invoke(
                  "wdtvamtoolCognitoUtils-" + awsConfig.environment,
                  {
                    data: {
                      region: config.aws_project_region,
                      action: "delete-user-from-group",
                      userName: data.body.Username,
                      groupName: admingroup.groupName,
                      dfStudioAccess: admingroup.dfStudioAccess,
                      userPoolId: config.aws_user_pools_id,
                    },
                  },
                  currentUser.creds
                )
                  .then((cogRes) => { })
                  .catch((err) => { });
              })
              .catch((err) => { });
          });

          invoke(
            "wdtvamtoolCognitoUtils-" + awsConfig.environment,
            {
              data: {
                region: config.aws_project_region,
                action: "delete-group",
                groupName: admingroup.groupName,
                dfStudioAccess: admingroup.dfStudioAccess,
                userPoolId: config.aws_user_pools_id,
              },
            },
            currentUser.creds
          )
            .then((cogRes) => {
              console.log("delete-group success...");
              console.dir(cogRes);
            })
            .catch((err) => {
              console.log("delete-group error");
              console.dir(err);
            });
        }
        fetchGroups();
      });
    }
    if (currentDeleteModal === "role") {
      await deleteAdminRole().then(() => {
        fetchRoles();
      });
    }
    handleClose();
  };
  /**
   *
   */
  const handleClose = () => {
    toggleFormState(false);
    setModalItem({});
  };
  const showUsers = (rowData) => {
    const arrayData = []
    if (rowData?.dfsPullRequestDetails && rowData?.dfsPullRequestDetails.length) {
      rowData?.dfsPullRequestDetails.forEach((res) => {
        const data = JSON.parse(res.dfsPullRequestDetails);
        arrayData.push(data.fileName || (data.name + '.' + data.preferredImageType))
      })

    }
    setShowUsersModal(true);
    setFailedModalData(arrayData);
  }
  const showFailScans = (rowData) => {
    const arrayData = []
    if (rowData?.scanRequestDetails && rowData?.scanRequestDetails.length) {
      rowData?.scanRequestDetails.forEach((res) => {
        const data = JSON.parse(res.scanRequestDetails);
        arrayData.push(data.file)
      })

    }
    setShowUsersModal(true);
    setFailedModalData(arrayData);
  }

  const handleCloseFailedModal = () => {
    setShowUsersModal(false);
    setModalItem({});
  }

  const usersolumns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: (rowData) => [
        <div className={!rowData.row.editable ? classes.disableDiv : ""}>
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  props.tableUsersEvents(rowData.row);
                }}
              >
                edit_circle
              </Icon>
            }
            label="Edit"
          />
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  deleteUserData(rowData.row);
                }}
              >
                delete_circle
              </Icon>
            }
            label="Delete"
          />
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  clearCache(rowData.row);
                }}
              >
                cached
              </Icon>
            }
            label="Cached"
          />
        </div>,
      ],
    },
    { field: "firstName", headerName: "First Name", width: 270 },
    { field: "lastName", headerName: "Last Name", width: 250 },
    { field: "email", headerName: "Email", width: 280 },
    { field: "groups", headerName: "Groups", width: 350 },
    {
      field: "roles",
      headerName: "Roles",
      width: 250,
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
    },
  ];

  const userLogColumnList = [
    { field: "email", headerName: "Email ID", width: 270 },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      valueFormatter: (params) =>
        params?.value.charAt(0).toUpperCase() + params?.value.slice(1),
    },
    { field: "roles", headerName: "Roles", width: 280 },
    {
      field: "lastLogin",
      headerName: "Log in time",
      width: 250,
      valueFormatter: (params) =>
        format(new Date(params?.value), "dd/MM/yyyy hh:mm a"),
    },
    {
      field: "lastActiveMinute",
      headerName: "Last active session date & time",
      width: 350,
      valueFormatter: (params) =>
        format(new Date(params?.value), "dd/MM/yyyy hh:mm a"),
    }
  ];
  const scanReqColumnList = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: (rowData) => [
        <div className={!rowData.row.editable ? classes.disableDiv : ""}>
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  setShowLoading(true);
                  retryScanReq(rowData.row);
                }}
              >
                cached
              </Icon>
            }
            label="Cached"
          />
        </div>,
      ],
    },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "lastName", headerName: "Last Name", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "createdAt",
      headerName: "Date (ET)",
      width: 200,
      valueFormatter: (params) => new Date(params?.value).toLocaleString('en-US', {
        timeZone: 'America/New_York',
      }),
    },
    {
      field: "scanned",
      headerName: "In Progress",
      width: 100,
    },
    {
      field: "failed", headerName: "Failed", width: 80,
      renderCell: (rowData) => [
        <div>
          {(rowData.row.failed > 0) &&
            <a href="#" onClick={() => {
              showFailScans(rowData.row);
            }}> {rowData.row.failed} </a>
          }

          {(rowData.row.failed === 0) &&
            <span>{rowData.row.failed}</span>
          }

        </div>,
      ]
    },
    { field: "requestCount", headerName: "Total", width: 80 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <strong
          style={{ color: params.value === "Completed" ? "green" : "red" }}
        >
          {params.value}
        </strong>
      ),
    },
  ];

  const dfsReqColumnList = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: (rowData) => [
        <div className={!rowData.row.editable ? classes.disableDiv : ""}>
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  setShowLoading(true);
                  retryDFSReq(rowData.row);
                }}
              >
                cached
              </Icon>
            }
            label="Cached"
          />
        </div>,
      ],
    },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "lastName", headerName: "Last Name", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "createdAt",
      headerName: "Date (ET)",
      width: 200,
      valueFormatter: (params) => new Date(params?.value).toLocaleString('en-US', {
        timeZone: 'America/New_York',
        format: 'MM/DD/YYYY'
      }),
    },
    {
      field: "requested",
      headerName: "In Progress",
      width: 100,
    },
    {
      field: "failed", headerName: "Failed", width: 80,
      renderCell: (rowData) => [
        <div>
          {(rowData.row.failed > 0) &&
            <a href="#" onClick={() => {
              showUsers(rowData.row);
            }}> {rowData.row.failed} </a>
          }

          {(rowData.row.failed === 0) &&
            <span>{rowData.row.failed}</span>
          }

        </div>,
      ]
    },
    { field: "requestCount", headerName: "Total", width: 80 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <strong
          style={{ color: params.value === "Completed" ? "green" : "red" }}
        >
          {params.value}
        </strong>
      ),
    },
  ];
  const GroupColumns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: (rowData) => [
        <div>
          <GridActionsCellItem
            icon={
              <Icon color="primary" onClick={() => tableGroupCopy(rowData.row)}>
                content_copy
              </Icon>
            }
            label="Copy"
          />
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  props.tableGroupsEvents(rowData.row);
                }}
              >
                edit_circle
              </Icon>
            }
            label="Edit"
          />
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  deleteGroupData(rowData.row);
                }}
              >
                delete_circle
              </Icon>
            }
            label="Delete"
          />{" "}
        </div>,
      ],
    },
    { field: "groupName", headerName: "Group Name", width: 300 },
    { field: "description", headerName: "Description", width: 300 },
    { field: "buckets", headerName: "bucket", width: 300 },
    {
      field: "users",
      headerName: "Users",
      width: 300,
    },
  ];

  const rolesColumns = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      getActions: (rowData) => [
        <div className={!rowData.row.editable ? classes.disableDiv : ""}>
          <GridActionsCellItem
            icon={
              <Icon color="primary" onClick={() => tableRowCopy(rowData.row)}>
                content_copy
              </Icon>
            }
            label="Copy"
          />
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  props.tableRolesEvents(rowData.row);
                }}
              >
                edit_circle
              </Icon>
            }
            label="Edit"
          />
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  deleteRoleData(rowData.row);
                }}
              >
                delete_circle
              </Icon>
            }
            label="Delete"
          />
        </div>,
      ],
    },
    { field: "name", headerName: "Role Name", width: 500, editable: true },
    { field: "description", headerName: "Description", width: 500 },
  ];
  /**
   * Alert Success
   */
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };
  const isDisabled = (index, flag) => {
    if (flag) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "primary" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label="USERS"
            disabled={isDisabled(0, showUser)}
            {...a11yProps(0)}
          />
          <Tab
            label="GROUPS"
            disabled={isDisabled(1, showGroup)}
            {...a11yProps(1)}
          />
          <Tab
            label="ROLES"
            disabled={isDisabled(2, showRole)}
            {...a11yProps(2)}
          />
          <Tab
            label="USER LOGS"
            disabled={isDisabled(3, showUserLog)}
            {...a11yProps(3)}
          />
          <Tab
            label="SCAN REQ.SUMMARY"
            disabled={isDisabled(4, showScanReq)}
            {...a11yProps(4)}
          />
          <Tab
            label="DFS REQ.SUMMARY"
            disabled={isDisabled(5, showDfsReq)}
            {...a11yProps(5)}
          />
        </Tabs>
      </Box>
      {showUser && (
        <TabPanel value={value} index={0}>
          <div style={{ height: 500, width: "100%", overflow: "auto" }}>
            <DataGrid rows={userData} columns={usersolumns} />
          </div>
        </TabPanel>
      )}
      {showGroup && (
        <TabPanel value={value} index={1}>
          <div style={{ height: 500, width: "100%", overflow: "auto" }}>
            <DataGrid rows={groupData} columns={GroupColumns} />
          </div>
        </TabPanel>
      )}
      {showRole && (
        <TabPanel value={value} index={2}>
          <div style={{ height: 500, width: "100%", overflow: "auto" }}>
            <DataGrid
              rows={rolesData}
              columns={rolesColumns}
              onCellEditStop={(params, e) => handleRoleChange(params, e)}
            />
          </div>
        </TabPanel>
      )}
      {showUserLog && (
        <TabPanel value={value} index={3}>
          <div style={{ height: 500, width: "100%", overflow: "auto" }}>
            <DataGrid rows={userData} columns={userLogColumnList} />
          </div>
        </TabPanel>
      )}
      {showScanReq && (
        <TabPanel value={value} index={4}>
          <div style={{ height: 500, width: "100%", overflow: "auto" }}>
            <DataGrid rows={scanReqData} columns={scanReqColumnList} />
          </div>
        </TabPanel>
      )}
      {showDfsReq && (
        <TabPanel value={value} index={5}>
          <div style={{ height: 500, width: "100%", overflow: "auto" }}>
            <DataGrid rows={dfsReqData} columns={dfsReqColumnList} />
          </div>
        </TabPanel>
      )}
      {showForm && (
        <div>
          <Dialog
            open={showForm}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={deleteRecord}>
                YES
              </Button>
              <Button color="primary" onClick={handleClose} autoFocus>
                NO
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      {showUsersModal && (
        <div>
          <Dialog
            open={showUsersModal}
            onClose={handleCloseFailedModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">DFS REQ.SUMMARY</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {failedModalData.map((row, index) => (
                  <div className="sharedUsersByMe"> <span>{index + 1} . {row}</span></div>
                ))}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleCloseFailedModal} autoFocus>
                CLOSE
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
}
