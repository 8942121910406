import React from "react";
import { makeStyles, createTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { CardMedia } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import logodisney from "../../assets/dinsey-new-logo.png";
import logopmt from "../../assets/PMT_Logo.jpeg";
import logobiz from "../../assets/bizDAMz_Logo._Hz.png";
import logobizdamz from "../../assets/bizDAMz_Logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    background: "#0078BA",
  },
  card: {
    margin: theme.spacing(3),
    primary: "#0078BA",
    paddingBottom: "10vh",
  },
  media: {
    height: 134,
    backgroundSize: "contain",
  },
  hiddenLogo: {
    display: 'none',
  },
  logoText: {
    fontSize: "15px",
    fontWeight: "bold",
  },
}));

const LoginTheme = createTheme({
  palette: {
    primary: {
      main: "#0069AF",
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      root: {
        marginTop: "2vh",
      },
      text: {
        // Some CSS
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        backgroundColor: "#0069AF",
        borderRadius: 3,
        border: 0,
        color: "white",
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      },
      contained: {
        "&:hover": {
          backgroundColor: "#0069AF",
        },
        textTransform: "capitalize",
        backgroundColor: "#0069A2",
        color: "#ffffff",
        borderRadius: 50,
        minHeight: "5vh",
      },
    },
    MuiLink: {
      root: {
        marginTop: "20vh",
      },
    },
    MuiTextField: {
      root: {
      //  marginTop: "15px",
      },
      "&$cssFocused $notchedOutline": {
        borderColor: "#0069AF",
      },
    },
    MuiTypography: {
      root: {
        color: "#545454",
      },
    },
  },
});

const AuthLayout = ({ children }) => {
  const classes = useStyles();
  const client = localStorage.getItem("currentClient");
  let currentLogo = logodisney;
  let currentTool = logopmt;
  let classesLogo = classes.media;

  if (client === "bizdamz") {
    currentLogo = logobiz;
    currentTool = logobizdamz;
    classesLogo = `${classes.hiddenLogo} ${classes.media}`;
  }

  return (
    <Grid
      container
      justify="center"
      align="center"
      component="main"
      className={classes.root}
    >
      {/* <Grid item sm>
        <img className={classes.img} alt="complex" src={loginBackroundImg} />
      </Grid> */}
      <Box display="flex" justifyContent="center" alignItems="center">
        <Card className={classes.card} variant="outlined">
          {/* <CardMedia
            style={{ height: "100px" }}
            className={classesLogo}
            image={currentLogo}
            alt="Wald-Disney-logo"
            title="Logo"
          /> */}
          <CardContent>
            <CardMedia
              className={classes.media}
              image={currentTool}
              alt="Photo-Management-Tool-Logo"
              title="Logo"
            />
            {/* <span className={classes.logoText}> Digital Asset Management </span> */}
            {/* <Typography variant="h4" paragraph>General Entertainment Content</Typography> */}
            {/* <Typography variant="h5" align="center">
              Photo Management Tool
            </Typography> */}
            <MuiThemeProvider theme={LoginTheme}>{children}</MuiThemeProvider>
          </CardContent>
        </Card>
      </Box>
      {/* <Grid item sm>
      </Grid> */}
    </Grid>
  );
};

export default AuthLayout;
