import { createAction, createReducer } from "@reduxjs/toolkit";

export const adminUserSetFieldValue = createAction(
  "ADMIN_USER_SET_FIELD_VALUE"
);
export const adminUserResetValues = createAction("ADMIN_USER_RESET_VALUES");

const defaultState = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  roles: [],
  title: "",
  groups: [],
  users: '',
  status: '',
  groupsList: [],
  usergroupids: []
};

const adminUserFormReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(adminUserSetFieldValue, (state, { payload }) => {
      Object.assign(state, payload);
      return state;
    })
    .addCase(adminUserResetValues, () => ({
      ...defaultState,
    }));
});

export default adminUserFormReducer;
