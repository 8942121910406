import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import { bytesToSize } from "service/util.service";
import { Fragment } from "react";
import { exifRemoveList, summaryDetails, removeExif } from "./exifData";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Paper } from "@mui/material";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Carousel from "react-material-ui-carousel";
import invoke from "service/lambda.service";
import { awsConfig } from "config";
import config from "../../aws-exports";
import { apiListShows } from "service/api.service";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ImageDetailUpdate } from "../../config";
import { summaryDetailsMapper } from "./exifData";
import { useAppUserContext } from "lib/contextLib";
import { dispatch, goTo } from "redux-store";
import Wimg from "./wimg";

const useStyles = makeStyles({
  dialogTitle: {
    padding: "0",
  },
  imageDetailPopup: {
    display: "flex",
    marginBottom: "10px",
  },
  icon: {
    cursor: "pointer",
  },
  fontBold: {
    fontWeight: "bold",
  },
  carosuelContainer: {
    display: "inline-flex",
    height: "538px",
    "overflow-x": "hidden",
    "overflow-y": "scroll",
    width: "90%",
    "margin-top": "20px",
  },
});

const getEXIFDetails = (imageItem) => {
  let exifDetailList = [];
  if (imageItem && imageItem.exif) {
    exifDetailList = imageItem.exif.filter((val, ind) => {
      if (val) return removeExif.indexOf(val.split("   :")[0].trim()) == -1;
    });
  }
  return exifDetailList;
};

const getSummaryDetails = (imageItem) => {
  let result = {};
  Object.values(summaryDetailsMapper).forEach((el) => {
    result[el] =
      imageItem[el] === null || imageItem[el] === undefined
        ? ""
        : imageItem[el];
  });
  return result;
};

const getFilteredEXIFDetails = (imageItem) => {
  let filterExifList = [];
  if (imageItem && imageItem.exif) {
    filterExifList = getEXIFDetails(imageItem).filter(function (val, ind) {
      const matchVal = val.split("   :")[0].trim();
      if (matchVal.match("Create Date")) {
        const getVal = val.split("   :")[1].trim();
        const splitData = getVal.split(" ")[0].split(":");
        imageItem = {
          ...imageItem,
          ...{
            dateCreated: splitData[1] + "/" + splitData[2] + "/" + splitData[0],
            year: splitData[0],
          },
        };
      }
      if (val) return exifRemoveList.indexOf(val.split("   :")[0].trim()) == -1;
    });

    if (filterExifList && filterExifList.length) {
      let captionInd = null;
      let thumbInd = null;

      filterExifList.map((val, ind) => {
        const matchVal = val.split("   :")[0].trim();
        if (matchVal.match("Caption-Abstract")) {
          captionInd = ind;
          filterExifList.splice(captionInd, 1);
        }

        if (matchVal.match("Description")) {
          filterExifList.splice(ind, 1);
          filterExifList.splice(captionInd, 0, val);
        }
        if (matchVal.match("Thumbnail Length")) {
          thumbInd = ind;
        }
        if (matchVal.match("City")) {
          filterExifList.splice(ind, 1);
          filterExifList.splice(thumbInd + 1, 0, val);
        }
      });
    }
  }
  return filterExifList;
};

const ImageDetails = ({ imageItem, onSuccess, onFailure }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [isCustomEdit, setCustomEdit] = React.useState(false);
  const [isDetailsEdit, setDetailsEdit] = React.useState(false);
  const [isKeywordsEdit, setKeywordsEdit] = React.useState(false);
  const [actions, setActions] = React.useState("Custom");
  const [bulkUpdateValue, setBulkUpdateValue] = useState({});
  const [network, setNetworkValue] = useState("");
  const [dayPart, setDayPartValue] = useState("");
  const [fileType, setFileTypeValue] = useState("");
  const [fileCategory, setFileCategory] = useState("");
  const [type, setTypeValue] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [slideIndex, setSlideIndex] = useState(imageItem.selectedIndex);
  const { email } = useAppUserContext();

  const currentUser = useAppUserContext();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const networkValue = bulkUpdateValue?.network
      ? bulkUpdateValue?.network
      : selectedImageList[slideIndex]?.network
        ? selectedImageList[slideIndex]?.network
        : imageItem.network;
    setNetworkValue(networkValue);

    const dayPartValue = bulkUpdateValue?.daypart
      ? bulkUpdateValue?.daypart
      : selectedImageList[slideIndex]?.daypart
        ? selectedImageList[slideIndex]?.daypart
        : imageItem.daypart;
    setDayPartValue(dayPartValue);

    const fileTypeValue = bulkUpdateValue?.fileType
      ? bulkUpdateValue?.fileType
      : selectedImageList[slideIndex]?.fileType
        ? selectedImageList[slideIndex]?.fileType
        : imageItem.fileType;
    setFileTypeValue(fileTypeValue);

    const typeValue = bulkUpdateValue?.type
      ? bulkUpdateValue?.type
      : selectedImageList[slideIndex]?.type
        ? selectedImageList[slideIndex]?.type
        : imageItem.type;
    setTypeValue(typeValue);

    const categoryValue = bulkUpdateValue?.category
      ? bulkUpdateValue?.category
      : selectedImageList[slideIndex]?.category
        ? selectedImageList[slideIndex]?.category
        : imageItem.category;
    setFileCategory(categoryValue);
  }, [bulkUpdateValue, slideIndex]);

  const setFileName = (thumbnail) => {
    let thumbArray = thumbnail
      .split("/")
    [thumbnail.split("/").length - 1].split(".");
    return thumbArray.join(".");
  };

  const onChangeHandler = (event) => {
    if (event.target.name !== "size") {
      setBulkUpdateValue({
        ...bulkUpdateValue,
        ...{ [event.target.name]: event.target.value },
      });
    }
    if (event.target.name === "size") {
      setBulkUpdateValue({
        ...bulkUpdateValue,
        ...{ awsS3FileLocation: { [event.target.name]: event.target.value } },
      });
    }
  };

  const SearchShowInput = () => {
    const [shows, setShows] = useState([]);
    useEffect(() => {
      fetchSearchResults();
    }, []);
    const fetchSearchResults = () => {
      let data = [];
      apiListShows()
        .then(({ items }) => {
          if (items && items.length) {
            items.forEach((val, ind) => {
              data.push({
                show: val.name,
                id: ind,
              });
            });
          }
          setShows(data);
        })
        .catch((err) => {
          console.error(err);
        });
    };
    const value =
      bulkUpdateValue.show || bulkUpdateValue.show === ""
        ? bulkUpdateValue.show
        : selectedImageList[slideIndex]?.show
          ? selectedImageList[slideIndex]?.show
          : imageItem.show;
    const handleDropdownChange = useCallback((e) => {
      setBulkUpdateValue({
        ...bulkUpdateValue,
        ...{ show: e.target.textContent },
      });
    }, []);

    return (
      <FormControl size="'small'">
        <Autocomplete
          id="image-detail-show-dropdown"
          style={{ minWidth: 220 }}
          options={shows}
          getOptionLabel={(option) => option.show}
          value={{ show: value }}
          onChange={handleDropdownChange}
          renderInput={(params) => (
            <TextField {...params} label="Show" variant="outlined" fullWidth />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.show}
              </li>
            );
          }}
        />
      </FormControl>
    );
  };

  const SearchFileTypeInput = () => {
    return (
      <Select
        style={{ minWidth: 220 }}
        labelId="demo-simple-select-outlined-label"
        name="fileType"
        id="fileType"
        label="File Type"
        value={fileType}
        onChange={onChangeHandler}
      >
        <MenuItem value="ALL">ALL</MenuItem>
        <MenuItem value="jpg">JPEG</MenuItem>
        <MenuItem value="png">PNG</MenuItem>
        <MenuItem value="raw">RAW</MenuItem>
        <MenuItem value="tiff">TIFF</MenuItem>
      </Select>
    );
  };

  const SearchCategoryInput = ({ disabled }) => {
    return (

      <Select
        style={{ minWidth: 220 }}
        labelId="demo-simple-select-outlined-label"
        name="category"
        id="demo-simple-select-outlined"
        label="Category"
        value={fileCategory}
        onChange={onChangeHandler}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="BTS">BTS</MenuItem>
        <MenuItem value="Event">Event</MenuItem>
        <MenuItem value="Gallery">Gallery</MenuItem>
        <MenuItem value="Unit">Unit</MenuItem>
        <MenuItem value="Backplates">Backplates</MenuItem>
        <MenuItem value="Selects">Selects</MenuItem>
        <MenuItem value="Full">Full</MenuItem>
      </Select>
    );
  };
  const SearchTypeInput = () => {
    return (
      <Select
        style={{ minWidth: 220 }}
        labelId="demo-simple-select-outlined-label"
        name="type"
        id="demo-simple-select-outlined"
        label="Type"
        value={type}
        onChange={onChangeHandler}
      >
        <MenuItem value="Select">Select</MenuItem>
        <MenuItem value="Raw">Raw</MenuItem>
        <MenuItem value="Full">Full</MenuItem>
      </Select>
    );
  };
  const selectedImageList =
    JSON.stringify(Object.values(imageItem)) ===
      JSON.stringify(Object.values({}))
      ? []
      : Object.values(imageItem.selectedObj);

  const dfstudioContent = (imageItem) => (
    <Grid container justifyContent="center">
      <div className={classes.carosuelContainer}>
        <Grid item xs={12} sm={12} md={6} style={{ paddingRight: "50px" }}>
          <img
            src={`data:image/jpeg;base64,${imageItem.thumbnail?.data}`}
            alt={imageItem.name || "Unknown"}
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography className={classes.imageDetailPopup}>
            <Grid item xs={4} sm={4} md={4}>
              <span className={classes.fontBold}>Name</span>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <span className={classes.fontBold}> : </span>{" "}
              <span> {imageItem.name != "" &&
                imageItem.name + "." + imageItem.fileType} </span>
            </Grid>
          </Typography>
          <Typography className={classes.imageDetailPopup}>
            <Grid item xs={4} sm={4} md={4}>
              <span className={classes.fontBold}>Project</span>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <span className={classes.fontBold}> : </span>{" "}
              <span> {imageItem.project} </span>
            </Grid>
          </Typography>
          <Typography className={classes.imageDetailPopup}>
            <Grid item xs={4} sm={4} md={4}>
              <span className={classes.fontBold}>Location</span>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <span className={classes.fontBold}> : </span>{" "}
              <span> {imageItem.location} </span>
            </Grid>
          </Typography>
          <Typography className={classes.imageDetailPopup}>
            <Grid item xs={4} sm={4} md={4}>
              <span className={classes.fontBold}>Folder</span>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <span className={classes.fontBold}> : </span>{" "}
              <span> {imageItem.folder} </span>
            </Grid>
          </Typography>
          <Typography className={classes.imageDetailPopup}>
            <Grid item xs={4} sm={4} md={4}>
              <span className={classes.fontBold}>Setup</span>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <span className={classes.fontBold}> : </span>{" "}
              <span> {imageItem.setup} </span>
            </Grid>
          </Typography>
          <Typography className={classes.imageDetailPopup}>
            <Grid item xs={4} sm={4} md={4}>
              <span className={classes.fontBold}>DFS Id</span>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <span className={classes.fontBold}> : </span>{" "}
              <span> {imageItem.dfsId} </span>
            </Grid>
          </Typography>
          <Typography className={classes.imageDetailPopup}>
            <Grid item xs={4} sm={4} md={4}>
              <span className={classes.fontBold}>Added to Studio</span>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <span className={classes.fontBold}> : </span>{" "}
              <span> {imageItem.addedToStudio} </span>
            </Grid>
          </Typography>
          <Typography className={classes.imageDetailPopup}>
            <Grid item xs={4} sm={4} md={4}>
              <span className={classes.fontBold}>Approval State</span>
            </Grid>
            <Grid item xs={8} sm={8} md={8}>
              <span className={classes.fontBold}> : </span>{" "}
              <span> {imageItem.approvalState} </span>
            </Grid>
          </Typography>
        </Grid>
      </div>
    </Grid>
  );

  const ImageDetailModalContent = (imageItem) => (
    <Grid container justifyContent="center">
      <div className={classes.carosuelContainer}>
        <Grid item xs={12} sm={12} md={6} style={{ paddingRight: "50px" }}>
          {/*--Summary--*/}
          <Wimg
            fullData={imageItem}
            src={imageItem.thumbnail_url}
            alt={imageItem.name || "Unknown"}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            waterMarks={[
              {position: 'right-bottom', text: 'BIZDAMZ', size: 48},
              {position: 'diagonal', text: email, size: 24 }
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div>
            <div style={{ marginBottom: "10px" }}>
              <h3>
                <span
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={() => {
                    actions == "Custom" ? setActions("") : setActions("Custom");
                  }}
                >
                  {actions == "Custom" ? "-" : "+"}
                </span>
                Custom
              </h3>
            </div>
            {actions == "Custom" && (
              <Fragment>
                {ImageDetailUpdate.enableIndividualImageUpdate && (
                  <div
                    className={classes.disableDiv}
                    style={{ marginLeft: "80%" }}
                  >
                    <Icon
                      color="primary"
                      onClick={() => {
                        setCustomEdit(true);
                      }}
                      className={classes.icon}
                    >
                      edit_circle
                    </Icon>
                    <Icon
                      color="primary"
                      onClick={editCustomImageMetaData}
                      className={classes.icon}
                    >
                      done_circle
                    </Icon>
                    <Icon
                      color="primary"
                      onClick={() => {
                        setBulkUpdateValue({});
                        setCustomEdit(false);
                      }}
                      className={classes.icon}
                    >
                      cached
                    </Icon>
                  </div>
                )}

                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}> Show </span>
                  </Grid>
                  {isCustomEdit ? (
                    <SearchShowInput></SearchShowInput>
                  ) : (
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.show} </span>
                    </Grid>
                  )}
                </Typography>

                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}>File Name</span>
                  </Grid>
                  {
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {setFileName(imageItem.thumbnail)} </span>
                    </Grid>
                  }
                </Typography>

                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}>Job Number</span>
                  </Grid>
                  {isCustomEdit ? (
                    <TextField
                      style={{ marginTop: "7px" }}
                      defaultValue={imageItem.job}
                      id=" Job Number"
                      label=" Job Number"
                      variant="outlined"
                      name="job"
                      size="small"
                      onChange={onChangeHandler}
                    />
                  ) : (
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.job} </span>
                    </Grid>
                  )}
                </Typography>

                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}> Network </span>
                  </Grid>
                  {isCustomEdit ? (
                    <Select
                      style={{ minWidth: 220 }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Network"
                      name="network"
                      value={network}
                      onChange={onChangeHandler}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="ABC">ABC</MenuItem>
                      <MenuItem value="FREEFORM">FREEFORM</MenuItem>
                      <MenuItem value="Disney Channel">Disney Channel</MenuItem>
                      <MenuItem value="Disney Junior">Disney Junior</MenuItem>
                      <MenuItem value="Disney XD">Disney XD</MenuItem>
                      <MenuItem value="Radio Disney">Radio Disney</MenuItem>
                      <MenuItem value="Corporate">Corporate</MenuItem>
                      <MenuItem value="Disney Plus">Disney Plus</MenuItem>
                      <MenuItem value="Hulu">Hulu</MenuItem>{" "}
                      <MenuItem value="National Geographic">
                        National Geographic
                      </MenuItem>
                      <MenuItem value="Off Network">Off Network</MenuItem>{" "}
                      <MenuItem value="SoapNet">SoapNet</MenuItem>{" "}
                      <MenuItem value="Walt Disney TV Animation">
                        Walt Disney TV Animation
                      </MenuItem>
                    </Select>
                  ) : (
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.network} </span>
                    </Grid>
                  )}
                </Typography>

                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}> Daypart </span>
                  </Grid>
                  {isCustomEdit ? (
                    <Grid item xs={8} sm={8} md={8}>
                      <Select
                        labelId="search-daypart-label"
                        name="daypart"
                        id="search-daypart"
                        label="Daypart"
                        style={{ minWidth: 220 }}
                        value={dayPart}
                        onChange={onChangeHandler}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Primetime Scripted">
                          Primetime - Scripted
                        </MenuItem>
                        <MenuItem value="Primetime Unscripted">
                          Primetime - Unscripted
                        </MenuItem>
                        <MenuItem value="Movies">Movies</MenuItem>
                        <MenuItem value="Specials">Specials</MenuItem>
                        <MenuItem value="Late-Night">Late-Night</MenuItem>
                        <MenuItem value="Daytime">Daytime</MenuItem>
                        <MenuItem value="News">News</MenuItem>
                        <MenuItem value="Sports">Sports</MenuItem>
                        <MenuItem value="Mini Series">Mini Series</MenuItem>
                      </Select>
                    </Grid>
                  ) : (
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.daypart} </span>
                    </Grid>
                  )}
                </Typography>

                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}> City </span>
                  </Grid>
                  {isCustomEdit ? (
                    <TextField
                      style={{ marginTop: "7px" }}
                      defaultValue={imageItem.city}
                      id=" City"
                      label="City"
                      variant="outlined"
                      name="city"
                      size="small"
                      onChange={onChangeHandler}
                    />
                  ) : (
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.city} </span>
                    </Grid>
                  )}
                </Typography>

                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}> Country </span>
                  </Grid>
                  {isCustomEdit ? (
                    <TextField
                      style={{ marginTop: "7px" }}
                      defaultValue={imageItem.country}
                      id=" Country"
                      label=" Country"
                      variant="outlined"
                      name="country"
                      size="small"
                      onChange={onChangeHandler}
                    />
                  ) : (
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.country} </span>
                    </Grid>
                  )}
                </Typography>

                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}> Year </span>
                  </Grid>
                  {
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.year} </span>
                    </Grid>
                  }
                </Typography>

                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}>File Type</span>
                  </Grid>
                  {isCustomEdit ? (
                    <SearchFileTypeInput />
                  ) : (
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.fileType} </span>
                    </Grid>
                  )}
                </Typography>
                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}>Category</span>
                  </Grid>
                  {isCustomEdit ? (
                    <SearchCategoryInput />
                  ) : (
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.category}</span>
                    </Grid>
                  )}
                </Typography>

                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}>Saved Upload Name</span>
                  </Grid>
                  {isCustomEdit ? (
                    <TextField
                      style={{ marginTop: "7px" }}
                      defaultValue={imageItem.name}
                      id=" Saved Upload Name"
                      label=" Saved Upload Name"
                      variant="outlined"
                      name="name"
                      size="small"
                      onChange={onChangeHandler}
                    />
                  ) : (
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>
                      <span> {imageItem.name} </span>
                    </Grid>
                  )}
                </Typography>

                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}> Path </span>
                  </Grid>
                  {
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.mediaFilename} </span>
                    </Grid>
                  }
                </Typography>
              </Fragment>
            )}
          </div>
          <div>
            <div style={{ marginBottom: "10px" }}>
              <h3>
                <span
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={() =>
                    actions == "Detail" ? setActions("") : setActions("Detail")
                  }
                >
                  {actions == "Detail" ? "-" : "+"}
                </span>
                Details
              </h3>
            </div>
            {actions == "Detail" && (
              <Fragment>
                <div
                  className={classes.disableDiv}
                  style={{ marginLeft: "80%" }}
                >
                  <Icon
                    color="primary"
                    onClick={() => {
                      setDetailsEdit(true);
                    }}
                    className={classes.icon}
                  >
                    edit_circle
                  </Icon>
                  <Icon
                    color="primary"
                    onClick={editDetailsImageMetaData}
                    className={classes.icon}
                  >
                    done_circle
                  </Icon>
                  <Icon
                    color="primary"
                    onClick={() => {
                      setBulkUpdateValue({});
                      setDetailsEdit(false);
                    }}
                    className={classes.icon}
                  >
                    cached
                  </Icon>
                </div>
                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}> Show </span>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <span className={classes.fontBold}> : </span>{" "}
                    <span> {imageItem.show} </span>
                  </Grid>
                </Typography>
                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}>File Name</span>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <span className={classes.fontBold}> : </span>{" "}
                    <span> {setFileName(imageItem.thumbnail)} </span>
                  </Grid>
                </Typography>
                <Typography className={classes.imageDetailPopup}>
                  <Grid item xs={4} sm={4} md={4}>
                    <span className={classes.fontBold}> Network </span>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <span className={classes.fontBold}> : </span>{" "}
                    <span> {setFileName(imageItem.network)} </span>
                  </Grid>
                </Typography>

                {Object.keys(summaryDetailsMapper).map(
                  (summaryDetail, index) => (
                    <Typography
                      key={summaryDetail + index}
                      className={classes.imageDetailPopup}
                    >
                      <Grid item xs={4} sm={4} md={4}>
                        <span className={classes.fontBold}>
                          {summaryDetail}
                        </span>
                      </Grid>
                      {isDetailsEdit ? (
                        <TextField
                          style={{ marginTop: "7px" }}
                          defaultValue={
                            imageItem[summaryDetailsMapper[summaryDetail]]
                          }
                          id={summaryDetailsMapper[summaryDetail]}
                          label={summaryDetail}
                          variant="outlined"
                          name={summaryDetailsMapper[summaryDetail]}
                          size="small"
                          onChange={onChangeHandler}
                        />
                      ) : (
                        <Grid item xs={8} sm={8} md={8}>
                          <span className={classes.fontBold}> : </span>
                          {imageItem[summaryDetailsMapper[summaryDetail]]}
                        </Grid>
                      )}
                    </Typography>
                  )
                )}
              </Fragment>
            )}
          </div>
          {imageItem?.dfsId && (
            <div>
              <div style={{ marginBottom: "10px" }}>
                <h3>
                  <span
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() =>
                      actions == "DFS-Details"
                        ? setActions("")
                        : setActions("DFS-Details")
                    }
                  >
                    {actions == "DFS-Details" ? "-" : "+"}
                  </span>
                  DFS Details
                </h3>
              </div>
              {actions == "DFS-Details" && (
                <Fragment>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}> DFS ID </span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.dfsId} </span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>DFS Name</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.dfsName} </span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}> DFS Project </span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.dfsProject} </span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}> DFS Setup </span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.dfsSetup} </span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}> DFS Badge </span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.dfsBadge} </span>
                    </Grid>
                  </Typography>
                </Fragment>
              )}
            </div>
          )}
          <div>
            <div style={{ marginBottom: "10px" }}>
              <h3>
                <span
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={() =>
                    actions == "Keywords"
                      ? setActions("")
                      : setActions("Keywords")
                  }
                >
                  {actions == "Keywords" ? "-" : "+"}
                </span>
                Keywords
              </h3>
            </div>
            {actions == "Keywords" && (
              <Fragment>
                <div
                  className={classes.disableDiv}
                  style={{ marginLeft: "80%" }}
                >
                  <Icon
                    color="primary"
                    onClick={() => {
                      setKeywordsEdit(true);
                    }}
                    className={classes.icon}
                  >
                    edit_circle
                  </Icon>
                  <Icon
                    color="primary"
                    onClick={editKeywordsImageMetaData}
                    className={classes.icon}
                  >
                    done_circle
                  </Icon>
                  <Icon
                    color="primary"
                    onClick={() => {
                      setKeywordsEdit(false);
                    }}
                    className={classes.icon}
                  >
                    cached
                  </Icon>
                </div>
                {(imageItem.keywords || imageItem.notes) &&
                  !isKeywordsEdit &&
                  ((imageItem.keywords + imageItem.notes).indexOf(",") !== -1
                    ? (imageItem.keywords + imageItem.notes).split(",")
                    : (imageItem.keywords + imageItem.notes).split(" ")
                  ).map((keyword, index) => {
                    return (
                      <Typography key={keyword + index}>{keyword}</Typography>
                    );
                  })}
                {isKeywordsEdit && (
                  <div>
                    <TextField
                      style={{ marginTop: "7px" }}
                      defaultValue={imageItem.notes
                        .split(",")
                        .splice(0, imageItem.notes.split(",").length - 1)
                        .join(",")}
                      id="notes"
                      label="Keywords"
                      variant="outlined"
                      name="notes"
                      size="medium"
                      onChange={onChangeHandler}
                    />
                    <Typography key="filePathKeyword">
                      {imageItem.notes.split(",").splice(-1)[0]}
                    </Typography>
                  </div>
                )}
              </Fragment>
            )}
          </div>
          <div>
            <div style={{ marginBottom: "10px" }}>
              <h3>
                <span
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={() =>
                    actions == "EXIF" ? setActions("") : setActions("EXIF")
                  }
                >
                  {actions == "EXIF" ? "-" : "+"}
                </span>
                EXIF
              </h3>
            </div>
            {actions == "EXIF" && (
              <Fragment>
                {getFilteredEXIFDetails(imageItem).map((exif, index) => (
                  <Typography
                    key={exif + index}
                    className={classes.imageDetailPopup}
                  >
                    <Grid item xs={5} sm={5} md={5}>
                      <span className={classes.fontBold}>
                        {exif.split("   :")[0]}
                      </span>
                    </Grid>
                    <Grid item xs={7} sm={7} md={7}>
                      <span className={classes.fontBold}> : </span>
                      {exif
                        .split("   :")[1]
                        ?.replace(/[\u{00B4}-\u{FFFF}]/gu, "")
                        .substring(
                          0,
                          exif.toUpperCase().startsWith("AF ")
                            ? 100
                            : exif.split("   :")[1]?.length
                        )}
                    </Grid>
                  </Typography>
                ))}
              </Fragment>
            )}
          </div>
        </Grid>
      </div>
    </Grid>
  );

  const editCustomImageMetaData = () => {
    if (!isCustomEdit || Object.keys(bulkUpdateValue).length === 0) {
      return;
    }
    setShowLoading(true);
    invoke(
      "wdtvamtoolCollectionUtils-" + awsConfig.environment,
      {
        region: config.aws_project_region,
        action: "metadata-update",
        files: [
          selectedImageList[slideIndex]?.mediaFilename
            ? selectedImageList[slideIndex].mediaFilename
            : imageItem.mediaFilename,
        ],
        fields: bulkUpdateValue,
      },
      currentUser.creds
    )
      .then(() => {
        setShowLoading(false);
        onSuccess();
        setCustomEdit(false);
      })
      .catch(() => {
        setShowLoading(false);
        onFailure();
      });
  };

  const editDetailsImageMetaData = () => {
    if (!isDetailsEdit || Object.keys(bulkUpdateValue).length === 0) {
      return;
    }
    setShowLoading(true);
    let updatedValue = {};
    const currentImage =
      selectedImageList && selectedImageList?.length
        ? selectedImageList[slideIndex]
        : imageItem;
    Object.values(summaryDetailsMapper).forEach((el) => {
      updatedValue = { ...updatedValue, ...{ [el]: currentImage[el] } };
    });
    updatedValue = { ...updatedValue, ...bulkUpdateValue };
    invoke(
      "wdtvamtoolCollectionUtils-" + awsConfig.environment,
      {
        region: config.aws_project_region,
        action: "metadata-update",
        files: [
          selectedImageList[slideIndex]?.mediaFilename
            ? selectedImageList[slideIndex].mediaFilename
            : imageItem.mediaFilename,
        ],
        fields: updatedValue,
      },
      currentUser.creds
    )
      .then(() => {
        setShowLoading(false);
        onSuccess();
        setDetailsEdit(false);
      })
      .catch(() => {
        setShowLoading(false);
        onFailure();
      });
  };

  const editKeywordsImageMetaData = () => {
    if (!isKeywordsEdit || Object.keys(bulkUpdateValue).length === 0) {
      return;
    }
    setShowLoading(true);
    const currentImage =
      selectedImageList && selectedImageList?.length
        ? selectedImageList[slideIndex]
        : imageItem;
    const notesSplits = currentImage.notes.split(",");
    const updatedValue = {
      notes: [
        ...bulkUpdateValue.notes.split(","),
        notesSplits[notesSplits.length - 1],
      ].toString(),
    };
    invoke(
      "wdtvamtoolCollectionUtils-" + awsConfig.environment,
      {
        region: config.aws_project_region,
        action: "metadata-update",
        files: [
          selectedImageList[slideIndex]?.mediaFilename
            ? selectedImageList[slideIndex].mediaFilename
            : imageItem.mediaFilename,
        ],
        fields: updatedValue,
      },
      currentUser.creds
    )
      .then(() => {
        setShowLoading(false);
        onSuccess();
        setKeywordsEdit(false);
      })
      .catch(() => {
        setShowLoading(false);
        onFailure();
      });
  };

  const onChangeSlide = (event) => {
    setBulkUpdateValue({});
    setSlideIndex(event);
    setCustomEdit(false);
  };

  return (
    <>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <AppBar position="static" color="default">
          {imageItem?.bucket !== "DF Studio" &&
            imageItem?.bucket !== undefined && (
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Summary" />
                <Tab label="Preview" />
                <Tab label="Custom" />
                <Tab label="Exif" />
                <Tab label="Keywords" />
                <Tab label="Camera" />
                <Tab label="Media Data" />
              </Tabs>
            )}
          {imageItem?.bucket === undefined && (
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="DF Studio" />
            </Tabs>
          )}
        </AppBar>
      </DialogTitle>
      <DialogContent>
        {imageItem.id && (
          <Box minHeight="450px" width="1000px">
            {imageItem?.bucket === undefined ? (
              <Box hidden={tabValue !== 0}>
                {selectedImageList.length > 1 ? (
                  <Carousel
                    indicators={false}
                    interval={null}
                    navButtonsAlwaysVisible={true}
                    cycleNavigation={false}
                    swipe={false}
                    index={imageItem.id}
                    onChange={onChangeSlide}
                  >
                    {selectedImageList.map((imageItem) => (
                      <Paper>
                        {" "}
                        <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
                          <CircularProgress />
                        </Backdrop>
                        {dfstudioContent(imageItem)}
                      </Paper>
                    ))}
                  </Carousel>
                ) : (
                  <div>
                    <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
                      <CircularProgress />
                    </Backdrop>
                    {dfstudioContent(imageItem)}
                  </div>
                )}
              </Box>
            ) : (
              <Box hidden={tabValue !== 0}>
                {selectedImageList.length > 1 ? (
                  <Carousel
                    indicators={false}
                    interval={null}
                    navButtonsAlwaysVisible={true}
                    cycleNavigation={false}
                    swipe={false}
                    index={imageItem.selectedIndex}
                    onChange={onChangeSlide}
                  >
                    {selectedImageList.map((imageItem) => (
                      <Paper>
                        {" "}
                        <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
                          <CircularProgress />
                        </Backdrop>
                        {ImageDetailModalContent(imageItem)}
                      </Paper>
                    ))}
                  </Carousel>
                ) : (
                  <div>
                    <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
                      <CircularProgress />
                    </Backdrop>
                    {ImageDetailModalContent(imageItem)}
                  </div>
                )}
              </Box>
            )}
            {imageItem?.bucket !== "DF Studio" &&
              imageItem?.bucket !== undefined && (
                <Box
                  display={tabValue === 1 ? "flex" : "none"}
                  justifyContent="center"
                  alignItems="center"
                  hidden={tabValue !== 1}
                >
                  <TransformWrapper>
                    <TransformComponent style={{ cursor: "zoom-in" }}>
                      {/*-----Preview------*/}
                      <Wimg
                        fullData={imageItem}
                        src={imageItem.thumbnail_url}
                        alt={imageItem.name || "Unknown"}
                        style={{ maxWidth: "100%"}}
                        waterMarks={[
                          {position: 'right-bottom', text: 'BIZDAMZ', size: 48},
                          {position: 'diagonal', text: email, size: 24 }
                        ]}
                      />  
                      {/* <img
                        src={imageItem.thumbnail_url}
                        alt={imageItem.name || "Unknown"}
                        style={{ maxWidth: "100%" }}
                      /> */}
                    </TransformComponent>
                  </TransformWrapper>

                  {/* <img src={imageItem.thumbnail_url} alt={imageItem.name || 'Unknown'} style={{ maxWidth: '100%' }} /> */}
                </Box>
              )}
            {imageItem?.bucket !== "DF Studio" &&
              imageItem?.bucket !== undefined && (
                <Box hidden={tabValue !== 2}>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Name</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.name} </span>
                    </Grid>
                  </Typography>

                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Bucket</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.bucket}</span>
                    </Grid>
                  </Typography>

                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Path</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.mediaFilename}</span>
                    </Grid>
                  </Typography>

                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>MimeType</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.awsS3FileLocation.mimeType}</span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Size</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span>
                        {" "}
                        {bytesToSize(imageItem.awsS3FileLocation.size)}
                      </span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Thumbnail Path</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.thumbnail}</span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Network</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.network}</span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Show</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.show}</span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Daypart</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.daypart}</span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Job Number</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.job} </span>
                    </Grid>
                  </Typography>

                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>City</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.city}</span>
                    </Grid>
                  </Typography>

                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Country</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.country}</span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Category</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.category}</span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>File Type</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.fileType}</span>
                    </Grid>
                  </Typography>
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Year</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.year}</span>
                    </Grid>
                  </Typography>
                  {imageItem.transferRequestId && (
                    <Typography className={classes.imageDetailPopup}>
                      <Grid item xs={4} sm={4} md={4}>
                        <span className={classes.fontBold}>Request Id</span>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8}>
                        <span className={classes.fontBold}> : </span>{" "}
                        <span> {imageItem.yetransferRequestIdar}</span>
                      </Grid>
                    </Typography>
                  )}
                  <Typography className={classes.imageDetailPopup}>
                    <Grid item xs={4} sm={4} md={4}>
                      <span className={classes.fontBold}>Uploaded At</span>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8}>
                      <span className={classes.fontBold}> : </span>{" "}
                      <span> {imageItem.createdAt}</span>
                    </Grid>
                  </Typography>
                </Box>
              )}
            {imageItem?.bucket !== "DF Studio" &&
              imageItem?.bucket !== undefined && (
                <Box hidden={tabValue !== 3}>
                  {getEXIFDetails(imageItem).map((exif, index) => (
                    <Typography
                      key={exif + index}
                      className={classes.imageDetailPopup}
                    >
                      <Grid item xs={5} sm={5} md={5}>
                        <span className={classes.fontBold}>
                          {exif.split("   :")[0]}
                        </span>
                      </Grid>
                      <Grid item xs={7} sm={7} md={7}>
                        <span className={classes.fontBold}> : </span>
                        {exif
                          .split("   :")[1]
                          ?.replace(/[\u{00B4}-\u{FFFF}]/gu, "")
                          .substring(
                            0,
                            exif.toUpperCase().startsWith("AF ")
                              ? 100
                              : exif.split("   :")[1]?.length
                          )}
                      </Grid>
                    </Typography>
                  ))}
                </Box>
              )}
            {imageItem?.bucket !== "DF Studio" &&
              imageItem?.bucket !== undefined && (
                <Box hidden={tabValue !== 4}>
                  {/* {imageItem.keywords && (imageItem.keywords.indexOf(',') !== -1 ? imageItem.keywords.split(',') : imageItem.keywords.split(' ')).map((keyword, index) => (
            <Typography key={keyword + index}>{keyword}</Typography>
          ))} */}
                  {(imageItem.keywords || imageItem.notes) &&
                    ((imageItem.keywords + imageItem.notes).indexOf(",") !== -1
                      ? (imageItem.keywords + imageItem.notes).split(",")
                      : (imageItem.keywords + imageItem.notes).split(" ")
                    ).map((keyword, index) => (
                      <Typography key={keyword + index}>{keyword}</Typography>
                    ))}
                </Box>
              )}
            {imageItem?.bucket !== "DF Studio" &&
              imageItem?.bucket !== undefined && (
                <Box hidden={tabValue !== 5}>
                  {imageItem.cameraAttributesData &&
                    imageItem.cameraAttributesData.map((exif, index) => (
                      <Typography
                        key={exif + index}
                        className={classes.imageDetailPopup}
                      >
                        <Grid item xs={4} sm={4} md={4}>
                          <span className={classes.fontBold}>
                            {exif.split("   :")[0]}
                          </span>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                          <span className={classes.fontBold}> : </span>
                          {exif
                            .split("   :")[1]
                            ?.replace(/[\u{00B4}-\u{FFFF}]/gu, "")
                            .substring(
                              0,
                              exif.toUpperCase().startsWith("AF ")
                                ? 100
                                : exif.split("   :")[1]?.length
                            )}
                        </Grid>
                      </Typography>
                    ))}
                </Box>
              )}
            {imageItem?.bucket !== "DF Studio" &&
              imageItem?.bucket !== undefined && (
                <Box hidden={tabValue !== 6}>
                  {imageItem.imageAttributesData &&
                    imageItem.imageAttributesData.map((exif, index) => (
                      <Typography
                        key={exif + index}
                        className={classes.imageDetailPopup}
                      >
                        <Grid item xs={4} sm={4} md={4}>
                          <span className={classes.fontBold}>
                            {exif.split("   :")[0]}
                          </span>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8}>
                          <span className={classes.fontBold}> : </span>
                          {exif
                            .split("   :")[1]
                            ?.replace(/[\u{00B4}-\u{FFFF}]/gu, "")
                            .substring(
                              0,
                              exif.toUpperCase().startsWith("AF ")
                                ? 100
                                : exif.split("   :")[1]?.length
                            )}
                        </Grid>
                      </Typography>
                    ))}
                </Box>
              )}
          </Box>
        )}
      </DialogContent>
    </>
  );
};

export default ImageDetails;
