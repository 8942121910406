import React from 'react';
import { Route, Switch } from 'react-router';
import SearchPage from './SearchPage';
import SearchResults from './SearchResults';
import UploadTransferStatus from '../upload/UploadTransferStatus';
import SecureRoute from '../secure-route/SecureRoute';
import MyCollections  from './MyCollections';

export default function SearchContainerRoutes() {
  return <Switch>
    <SecureRoute path="/search/results" component={SearchResults} />
    <SecureRoute path="/search/collections" component={MyCollections} />
    <SecureRoute path="/search/progress" component={UploadTransferStatus} />
    <SecureRoute path="/search" component={SearchPage} />

  </Switch>
} 