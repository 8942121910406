/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchAddS3Events = /* GraphQL */ `
  mutation BatchAddS3Events($s3Events: [CreateS3EventsInput!]) {
    batchAddS3Events(s3Events: $s3Events) {
      id
      timestamp
      eventTime
      principalId
      sourceIPAddress
      bucketName
      bucketArn
      objectKey
      objectSize
      eTag
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const batchAddAsperaTransferLog = /* GraphQL */ `
  mutation BatchAddAsperaTransferLog(
    $asperaTransferLog: [CreateAsperaTransferLogInput!]
  ) {
    batchAddAsperaTransferLog(asperaTransferLog: $asperaTransferLog) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      s3Object {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const batchAddMetaData = /* GraphQL */ `
  mutation BatchAddMetaData($metaData: [CreateMetaDataInput!]) {
    batchAddMetaData(metaData: $metaData) {
      id
      bucket
      mediaFilename
      mediaDesciption
      fullFileName
      name
      network
      show
      seasonNo
      daypart
      episodeName
      job
      thumbnail
      format
      people
      city
      country
      region
      fileType
      type
      category
      imageAttributesData
      cameraAttributesData
      exif
      exifDump
      day
      year
      keywords
      notes
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      transferRequestId
      scanRequestId
      timestamp
      error
      clickTime
      reindex
      dfsName
      dfsId
      dfsProject
      dfsSetup
      dfsBadge
      description
      personInImage
      captionAbstract
      specialInstructions
      copyrightNotice
      credit
      source
      captionWriter
      headline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const batchUpdateMetaData = /* GraphQL */ `
  mutation BatchUpdateMetaData($metaDataUpd: [UpdateMetaDataInput!]) {
    batchUpdateMetaData(metaDataUpd: $metaDataUpd) {
      id
      bucket
      mediaFilename
      mediaDesciption
      fullFileName
      name
      network
      show
      seasonNo
      daypart
      episodeName
      job
      thumbnail
      format
      people
      city
      country
      region
      fileType
      type
      category
      imageAttributesData
      cameraAttributesData
      exif
      exifDump
      day
      year
      keywords
      notes
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      transferRequestId
      scanRequestId
      timestamp
      error
      clickTime
      reindex
      dfsName
      dfsId
      dfsProject
      dfsSetup
      dfsBadge
      description
      personInImage
      captionAbstract
      specialInstructions
      copyrightNotice
      credit
      source
      captionWriter
      headline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const batchAddScanRequest = /* GraphQL */ `
  mutation BatchAddScanRequest($scanRequest: [CreateScanRequestInput!]) {
    batchAddScanRequest(scanRequest: $scanRequest) {
      id
      requestId
      scanRequestDetails
      user
      status
      error
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const batchUpdateScanRequest = /* GraphQL */ `
  mutation BatchUpdateScanRequest($scanRequest: [UpdateScanRequestInput!]) {
    batchUpdateScanRequest(scanRequest: $scanRequest) {
      id
      requestId
      scanRequestDetails
      user
      status
      error
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const batchAddDfsPullRequest = /* GraphQL */ `
  mutation BatchAddDfsPullRequest(
    $dfsPullRequest: [CreateDfsPullRequestInput!]
  ) {
    batchAddDfsPullRequest(dfsPullRequest: $dfsPullRequest) {
      id
      requestId
      dfsPullRequestDetails
      user
      status
      error
      nextOffset
      nextToken
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const batchUpdateDfsPullRequest = /* GraphQL */ `
  mutation BatchUpdateDfsPullRequest(
    $dfsPullRequest: [UpdateDfsPullRequestInput!]
  ) {
    batchUpdateDfsPullRequest(dfsPullRequest: $dfsPullRequest) {
      id
      requestId
      dfsPullRequestDetails
      user
      status
      error
      nextOffset
      nextToken
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      title
      firstName
      lastName
      phone
      mobile
      email
      location
      roles
      status
      securityQuestion
      securityAnswer
      lastLogin
      lastActiveMinute
      currentTab
      config
      createdAt
      updatedAt
      owner
      groups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          timestamp
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      title
      firstName
      lastName
      phone
      mobile
      email
      location
      roles
      status
      securityQuestion
      securityAnswer
      lastLogin
      lastActiveMinute
      currentTab
      config
      createdAt
      updatedAt
      owner
      groups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          timestamp
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      title
      firstName
      lastName
      phone
      mobile
      email
      location
      roles
      status
      securityQuestion
      securityAnswer
      lastLogin
      lastActiveMinute
      currentTab
      config
      createdAt
      updatedAt
      owner
      groups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          timestamp
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      groupName
      description
      buckets
      dfStudioAccess
      createdAt
      updatedAt
      owner
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          create
          read
          update
          delete
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      groupName
      description
      buckets
      dfStudioAccess
      createdAt
      updatedAt
      owner
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          create
          read
          update
          delete
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      groupName
      description
      buckets
      dfStudioAccess
      createdAt
      updatedAt
      owner
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          create
          read
          update
          delete
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createUserGroup = /* GraphQL */ `
  mutation CreateUserGroup(
    $input: CreateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    createUserGroup(input: $input, condition: $condition) {
      id
      userId
      groupId
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateUserGroup = /* GraphQL */ `
  mutation UpdateUserGroup(
    $input: UpdateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    updateUserGroup(input: $input, condition: $condition) {
      id
      userId
      groupId
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteUserGroup = /* GraphQL */ `
  mutation DeleteUserGroup(
    $input: DeleteUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    deleteUserGroup(input: $input, condition: $condition) {
      id
      userId
      groupId
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createPermission = /* GraphQL */ `
  mutation CreatePermission(
    $input: CreatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    createPermission(input: $input, condition: $condition) {
      id
      create
      read
      update
      delete
      createdAt
      updatedAt
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission(
    $input: UpdatePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    updatePermission(input: $input, condition: $condition) {
      id
      create
      read
      update
      delete
      createdAt
      updatedAt
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deletePermission = /* GraphQL */ `
  mutation DeletePermission(
    $input: DeletePermissionInput!
    $condition: ModelPermissionConditionInput
  ) {
    deletePermission(input: $input, condition: $condition) {
      id
      create
      read
      update
      delete
      createdAt
      updatedAt
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      name
      description
      parentRole
      config
      idletime
      user
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      description
      parentRole
      config
      idletime
      user
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      description
      parentRole
      config
      idletime
      user
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSettings = /* GraphQL */ `
  mutation CreateSettings(
    $input: CreateSettingsInput!
    $condition: ModelsettingsConditionInput
  ) {
    createSettings(input: $input, condition: $condition) {
      id
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      name
      values
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSettings = /* GraphQL */ `
  mutation UpdateSettings(
    $input: UpdateSettingsInput!
    $condition: ModelsettingsConditionInput
  ) {
    updateSettings(input: $input, condition: $condition) {
      id
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      name
      values
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSettings = /* GraphQL */ `
  mutation DeleteSettings(
    $input: DeleteSettingsInput!
    $condition: ModelsettingsConditionInput
  ) {
    deleteSettings(input: $input, condition: $condition) {
      id
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      name
      values
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      timestamp
      details
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      timestamp
      details
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      timestamp
      details
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createCollection = /* GraphQL */ `
  mutation CreateCollection(
    $input: CreateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    createCollection(input: $input, condition: $condition) {
      id
      name
      email
      createdBy
      sharedUsers
      sharedGroups
      objects
      type
      target
      expiry
      publicUrl
      remarks
      title
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCollection = /* GraphQL */ `
  mutation UpdateCollection(
    $input: UpdateCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    updateCollection(input: $input, condition: $condition) {
      id
      name
      email
      createdBy
      sharedUsers
      sharedGroups
      objects
      type
      target
      expiry
      publicUrl
      remarks
      title
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCollection = /* GraphQL */ `
  mutation DeleteCollection(
    $input: DeleteCollectionInput!
    $condition: ModelCollectionConditionInput
  ) {
    deleteCollection(input: $input, condition: $condition) {
      id
      name
      email
      createdBy
      sharedUsers
      sharedGroups
      objects
      type
      target
      expiry
      publicUrl
      remarks
      title
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      order
      url
      title
      icon
      active
      moduleId
      createdAt
      updatedAt
      owner
      module {
        id
        order
        name
        lastUsedPage {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        icon
        active
        createdAt
        updatedAt
        pages {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      order
      url
      title
      icon
      active
      moduleId
      createdAt
      updatedAt
      owner
      module {
        id
        order
        name
        lastUsedPage {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        icon
        active
        createdAt
        updatedAt
        pages {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      order
      url
      title
      icon
      active
      moduleId
      createdAt
      updatedAt
      owner
      module {
        id
        order
        name
        lastUsedPage {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        icon
        active
        createdAt
        updatedAt
        pages {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const createModule = /* GraphQL */ `
  mutation CreateModule(
    $input: CreateModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    createModule(input: $input, condition: $condition) {
      id
      order
      name
      lastUsedPage {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      icon
      active
      createdAt
      updatedAt
      pages {
        items {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateModule = /* GraphQL */ `
  mutation UpdateModule(
    $input: UpdateModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    updateModule(input: $input, condition: $condition) {
      id
      order
      name
      lastUsedPage {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      icon
      active
      createdAt
      updatedAt
      pages {
        items {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteModule = /* GraphQL */ `
  mutation DeleteModule(
    $input: DeleteModuleInput!
    $condition: ModelModuleConditionInput
  ) {
    deleteModule(input: $input, condition: $condition) {
      id
      order
      name
      lastUsedPage {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      icon
      active
      createdAt
      updatedAt
      pages {
        items {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createNetwork = /* GraphQL */ `
  mutation CreateNetwork(
    $input: CreateNetworkInput!
    $condition: ModelNetworkConditionInput
  ) {
    createNetwork(input: $input, condition: $condition) {
      id
      name
      description
      order
      image
      link
      active
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateNetwork = /* GraphQL */ `
  mutation UpdateNetwork(
    $input: UpdateNetworkInput!
    $condition: ModelNetworkConditionInput
  ) {
    updateNetwork(input: $input, condition: $condition) {
      id
      name
      description
      order
      image
      link
      active
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteNetwork = /* GraphQL */ `
  mutation DeleteNetwork(
    $input: DeleteNetworkInput!
    $condition: ModelNetworkConditionInput
  ) {
    deleteNetwork(input: $input, condition: $condition) {
      id
      name
      description
      order
      image
      link
      active
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createShow = /* GraphQL */ `
  mutation CreateShow(
    $input: CreateShowInput!
    $condition: ModelShowConditionInput
  ) {
    createShow(input: $input, condition: $condition) {
      id
      name
      description
      order
      image
      link
      active
      networkId
      createdAt
      updatedAt
      network {
        id
        name
        description
        order
        image
        link
        active
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      dayParts {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pictures {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateShow = /* GraphQL */ `
  mutation UpdateShow(
    $input: UpdateShowInput!
    $condition: ModelShowConditionInput
  ) {
    updateShow(input: $input, condition: $condition) {
      id
      name
      description
      order
      image
      link
      active
      networkId
      createdAt
      updatedAt
      network {
        id
        name
        description
        order
        image
        link
        active
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      dayParts {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pictures {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteShow = /* GraphQL */ `
  mutation DeleteShow(
    $input: DeleteShowInput!
    $condition: ModelShowConditionInput
  ) {
    deleteShow(input: $input, condition: $condition) {
      id
      name
      description
      order
      image
      link
      active
      networkId
      createdAt
      updatedAt
      network {
        id
        name
        description
        order
        image
        link
        active
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      dayParts {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pictures {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createDaypart = /* GraphQL */ `
  mutation CreateDaypart(
    $input: CreateDaypartInput!
    $condition: ModelDaypartConditionInput
  ) {
    createDaypart(input: $input, condition: $condition) {
      id
      name
      description
      active
      order
      image
      link
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateDaypart = /* GraphQL */ `
  mutation UpdateDaypart(
    $input: UpdateDaypartInput!
    $condition: ModelDaypartConditionInput
  ) {
    updateDaypart(input: $input, condition: $condition) {
      id
      name
      description
      active
      order
      image
      link
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteDaypart = /* GraphQL */ `
  mutation DeleteDaypart(
    $input: DeleteDaypartInput!
    $condition: ModelDaypartConditionInput
  ) {
    deleteDaypart(input: $input, condition: $condition) {
      id
      name
      description
      active
      order
      image
      link
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createShowDaypart = /* GraphQL */ `
  mutation CreateShowDaypart(
    $input: CreateShowDaypartInput!
    $condition: ModelShowDaypartConditionInput
  ) {
    createShowDaypart(input: $input, condition: $condition) {
      id
      showId
      daypartId
      showOrder
      daypartOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      daypart {
        id
        name
        description
        active
        order
        image
        link
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateShowDaypart = /* GraphQL */ `
  mutation UpdateShowDaypart(
    $input: UpdateShowDaypartInput!
    $condition: ModelShowDaypartConditionInput
  ) {
    updateShowDaypart(input: $input, condition: $condition) {
      id
      showId
      daypartId
      showOrder
      daypartOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      daypart {
        id
        name
        description
        active
        order
        image
        link
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteShowDaypart = /* GraphQL */ `
  mutation DeleteShowDaypart(
    $input: DeleteShowDaypartInput!
    $condition: ModelShowDaypartConditionInput
  ) {
    deleteShowDaypart(input: $input, condition: $condition) {
      id
      showId
      daypartId
      showOrder
      daypartOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      daypart {
        id
        name
        description
        active
        order
        image
        link
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createPicture = /* GraphQL */ `
  mutation CreatePicture(
    $input: CreatePictureInput!
    $condition: ModelPictureConditionInput
  ) {
    createPicture(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
      owner
      show {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updatePicture = /* GraphQL */ `
  mutation UpdatePicture(
    $input: UpdatePictureInput!
    $condition: ModelPictureConditionInput
  ) {
    updatePicture(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
      owner
      show {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deletePicture = /* GraphQL */ `
  mutation DeletePicture(
    $input: DeletePictureInput!
    $condition: ModelPictureConditionInput
  ) {
    deletePicture(input: $input, condition: $condition) {
      id
      name
      description
      image
      createdAt
      updatedAt
      owner
      show {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createShowPicture = /* GraphQL */ `
  mutation CreateShowPicture(
    $input: CreateShowPictureInput!
    $condition: ModelShowPictureConditionInput
  ) {
    createShowPicture(input: $input, condition: $condition) {
      id
      showId
      pictureId
      showOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateShowPicture = /* GraphQL */ `
  mutation UpdateShowPicture(
    $input: UpdateShowPictureInput!
    $condition: ModelShowPictureConditionInput
  ) {
    updateShowPicture(input: $input, condition: $condition) {
      id
      showId
      pictureId
      showOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteShowPicture = /* GraphQL */ `
  mutation DeleteShowPicture(
    $input: DeleteShowPictureInput!
    $condition: ModelShowPictureConditionInput
  ) {
    deleteShowPicture(input: $input, condition: $condition) {
      id
      showId
      pictureId
      showOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createKeyword = /* GraphQL */ `
  mutation CreateKeyword(
    $input: CreateKeywordInput!
    $condition: ModelKeywordConditionInput
  ) {
    createKeyword(input: $input, condition: $condition) {
      id
      keyword
      description
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateKeyword = /* GraphQL */ `
  mutation UpdateKeyword(
    $input: UpdateKeywordInput!
    $condition: ModelKeywordConditionInput
  ) {
    updateKeyword(input: $input, condition: $condition) {
      id
      keyword
      description
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteKeyword = /* GraphQL */ `
  mutation DeleteKeyword(
    $input: DeleteKeywordInput!
    $condition: ModelKeywordConditionInput
  ) {
    deleteKeyword(input: $input, condition: $condition) {
      id
      keyword
      description
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPictureKeyword = /* GraphQL */ `
  mutation CreatePictureKeyword(
    $input: CreatePictureKeywordInput!
    $condition: ModelPictureKeywordConditionInput
  ) {
    createPictureKeyword(input: $input, condition: $condition) {
      id
      pictureId
      keywordId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updatePictureKeyword = /* GraphQL */ `
  mutation UpdatePictureKeyword(
    $input: UpdatePictureKeywordInput!
    $condition: ModelPictureKeywordConditionInput
  ) {
    updatePictureKeyword(input: $input, condition: $condition) {
      id
      pictureId
      keywordId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deletePictureKeyword = /* GraphQL */ `
  mutation DeletePictureKeyword(
    $input: DeletePictureKeywordInput!
    $condition: ModelPictureKeywordConditionInput
  ) {
    deletePictureKeyword(input: $input, condition: $condition) {
      id
      pictureId
      keywordId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      name
      description
      address
      long
      lat
      image
      link
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      name
      description
      address
      long
      lat
      image
      link
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      name
      description
      address
      long
      lat
      image
      link
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPictureLocation = /* GraphQL */ `
  mutation CreatePictureLocation(
    $input: CreatePictureLocationInput!
    $condition: ModelPictureLocationConditionInput
  ) {
    createPictureLocation(input: $input, condition: $condition) {
      id
      pictureId
      locationId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updatePictureLocation = /* GraphQL */ `
  mutation UpdatePictureLocation(
    $input: UpdatePictureLocationInput!
    $condition: ModelPictureLocationConditionInput
  ) {
    updatePictureLocation(input: $input, condition: $condition) {
      id
      pictureId
      locationId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deletePictureLocation = /* GraphQL */ `
  mutation DeletePictureLocation(
    $input: DeletePictureLocationInput!
    $condition: ModelPictureLocationConditionInput
  ) {
    deletePictureLocation(input: $input, condition: $condition) {
      id
      pictureId
      locationId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createLocationKeyword = /* GraphQL */ `
  mutation CreateLocationKeyword(
    $input: CreateLocationKeywordInput!
    $condition: ModelLocationKeywordConditionInput
  ) {
    createLocationKeyword(input: $input, condition: $condition) {
      id
      locationId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateLocationKeyword = /* GraphQL */ `
  mutation UpdateLocationKeyword(
    $input: UpdateLocationKeywordInput!
    $condition: ModelLocationKeywordConditionInput
  ) {
    updateLocationKeyword(input: $input, condition: $condition) {
      id
      locationId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteLocationKeyword = /* GraphQL */ `
  mutation DeleteLocationKeyword(
    $input: DeleteLocationKeywordInput!
    $condition: ModelLocationKeywordConditionInput
  ) {
    deleteLocationKeyword(input: $input, condition: $condition) {
      id
      locationId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createPeople = /* GraphQL */ `
  mutation CreatePeople(
    $input: CreatePeopleInput!
    $condition: ModelPeopleConditionInput
  ) {
    createPeople(input: $input, condition: $condition) {
      id
      name
      nickName
      description
      date
      links
      image
      notes
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Keywords {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shows {
        items {
          id
          showId
          showOrder
          peopleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updatePeople = /* GraphQL */ `
  mutation UpdatePeople(
    $input: UpdatePeopleInput!
    $condition: ModelPeopleConditionInput
  ) {
    updatePeople(input: $input, condition: $condition) {
      id
      name
      nickName
      description
      date
      links
      image
      notes
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Keywords {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shows {
        items {
          id
          showId
          showOrder
          peopleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deletePeople = /* GraphQL */ `
  mutation DeletePeople(
    $input: DeletePeopleInput!
    $condition: ModelPeopleConditionInput
  ) {
    deletePeople(input: $input, condition: $condition) {
      id
      name
      nickName
      description
      date
      links
      image
      notes
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Keywords {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shows {
        items {
          id
          showId
          showOrder
          peopleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createPeoplePicture = /* GraphQL */ `
  mutation CreatePeoplePicture(
    $input: CreatePeoplePictureInput!
    $condition: ModelPeoplePictureConditionInput
  ) {
    createPeoplePicture(input: $input, condition: $condition) {
      id
      peopleId
      pictureId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updatePeoplePicture = /* GraphQL */ `
  mutation UpdatePeoplePicture(
    $input: UpdatePeoplePictureInput!
    $condition: ModelPeoplePictureConditionInput
  ) {
    updatePeoplePicture(input: $input, condition: $condition) {
      id
      peopleId
      pictureId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deletePeoplePicture = /* GraphQL */ `
  mutation DeletePeoplePicture(
    $input: DeletePeoplePictureInput!
    $condition: ModelPeoplePictureConditionInput
  ) {
    deletePeoplePicture(input: $input, condition: $condition) {
      id
      peopleId
      pictureId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createPeopleKeyword = /* GraphQL */ `
  mutation CreatePeopleKeyword(
    $input: CreatePeopleKeywordInput!
    $condition: ModelPeopleKeywordConditionInput
  ) {
    createPeopleKeyword(input: $input, condition: $condition) {
      id
      peopleId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updatePeopleKeyword = /* GraphQL */ `
  mutation UpdatePeopleKeyword(
    $input: UpdatePeopleKeywordInput!
    $condition: ModelPeopleKeywordConditionInput
  ) {
    updatePeopleKeyword(input: $input, condition: $condition) {
      id
      peopleId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deletePeopleKeyword = /* GraphQL */ `
  mutation DeletePeopleKeyword(
    $input: DeletePeopleKeywordInput!
    $condition: ModelPeopleKeywordConditionInput
  ) {
    deletePeopleKeyword(input: $input, condition: $condition) {
      id
      peopleId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createShowPeople = /* GraphQL */ `
  mutation CreateShowPeople(
    $input: CreateShowPeopleInput!
    $condition: ModelShowPeopleConditionInput
  ) {
    createShowPeople(input: $input, condition: $condition) {
      id
      showId
      showOrder
      peopleId
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateShowPeople = /* GraphQL */ `
  mutation UpdateShowPeople(
    $input: UpdateShowPeopleInput!
    $condition: ModelShowPeopleConditionInput
  ) {
    updateShowPeople(input: $input, condition: $condition) {
      id
      showId
      showOrder
      peopleId
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteShowPeople = /* GraphQL */ `
  mutation DeleteShowPeople(
    $input: DeleteShowPeopleInput!
    $condition: ModelShowPeopleConditionInput
  ) {
    deleteShowPeople(input: $input, condition: $condition) {
      id
      showId
      showOrder
      peopleId
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createMetaData = /* GraphQL */ `
  mutation CreateMetaData(
    $input: CreateMetaDataInput!
    $condition: ModelMetaDataConditionInput
  ) {
    createMetaData(input: $input, condition: $condition) {
      id
      bucket
      mediaFilename
      mediaDesciption
      fullFileName
      name
      network
      show
      seasonNo
      daypart
      episodeName
      job
      thumbnail
      format
      people
      city
      country
      region
      fileType
      type
      category
      imageAttributesData
      cameraAttributesData
      exif
      exifDump
      day
      year
      keywords
      notes
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      transferRequestId
      scanRequestId
      timestamp
      error
      clickTime
      reindex
      dfsName
      dfsId
      dfsProject
      dfsSetup
      dfsBadge
      description
      personInImage
      captionAbstract
      specialInstructions
      copyrightNotice
      credit
      source
      captionWriter
      headline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMetaData = /* GraphQL */ `
  mutation UpdateMetaData(
    $input: UpdateMetaDataInput!
    $condition: ModelMetaDataConditionInput
  ) {
    updateMetaData(input: $input, condition: $condition) {
      id
      bucket
      mediaFilename
      mediaDesciption
      fullFileName
      name
      network
      show
      seasonNo
      daypart
      episodeName
      job
      thumbnail
      format
      people
      city
      country
      region
      fileType
      type
      category
      imageAttributesData
      cameraAttributesData
      exif
      exifDump
      day
      year
      keywords
      notes
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      transferRequestId
      scanRequestId
      timestamp
      error
      clickTime
      reindex
      dfsName
      dfsId
      dfsProject
      dfsSetup
      dfsBadge
      description
      personInImage
      captionAbstract
      specialInstructions
      copyrightNotice
      credit
      source
      captionWriter
      headline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMetaData = /* GraphQL */ `
  mutation DeleteMetaData(
    $input: DeleteMetaDataInput!
    $condition: ModelMetaDataConditionInput
  ) {
    deleteMetaData(input: $input, condition: $condition) {
      id
      bucket
      mediaFilename
      mediaDesciption
      fullFileName
      name
      network
      show
      seasonNo
      daypart
      episodeName
      job
      thumbnail
      format
      people
      city
      country
      region
      fileType
      type
      category
      imageAttributesData
      cameraAttributesData
      exif
      exifDump
      day
      year
      keywords
      notes
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      transferRequestId
      scanRequestId
      timestamp
      error
      clickTime
      reindex
      dfsName
      dfsId
      dfsProject
      dfsSetup
      dfsBadge
      description
      personInImage
      captionAbstract
      specialInstructions
      copyrightNotice
      credit
      source
      captionWriter
      headline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createS3Events = /* GraphQL */ `
  mutation CreateS3Events(
    $input: CreateS3EventsInput!
    $condition: ModelS3EventsConditionInput
  ) {
    createS3Events(input: $input, condition: $condition) {
      id
      timestamp
      eventTime
      principalId
      sourceIPAddress
      bucketName
      bucketArn
      objectKey
      objectSize
      eTag
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateS3Events = /* GraphQL */ `
  mutation UpdateS3Events(
    $input: UpdateS3EventsInput!
    $condition: ModelS3EventsConditionInput
  ) {
    updateS3Events(input: $input, condition: $condition) {
      id
      timestamp
      eventTime
      principalId
      sourceIPAddress
      bucketName
      bucketArn
      objectKey
      objectSize
      eTag
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteS3Events = /* GraphQL */ `
  mutation DeleteS3Events(
    $input: DeleteS3EventsInput!
    $condition: ModelS3EventsConditionInput
  ) {
    deleteS3Events(input: $input, condition: $condition) {
      id
      timestamp
      eventTime
      principalId
      sourceIPAddress
      bucketName
      bucketArn
      objectKey
      objectSize
      eTag
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAsperaTransferLog = /* GraphQL */ `
  mutation CreateAsperaTransferLog(
    $input: CreateAsperaTransferLogInput!
    $condition: ModelAsperaTransferLogConditionInput
  ) {
    createAsperaTransferLog(input: $input, condition: $condition) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      s3Object {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAsperaTransferLog = /* GraphQL */ `
  mutation UpdateAsperaTransferLog(
    $input: UpdateAsperaTransferLogInput!
    $condition: ModelAsperaTransferLogConditionInput
  ) {
    updateAsperaTransferLog(input: $input, condition: $condition) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      s3Object {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAsperaTransferLog = /* GraphQL */ `
  mutation DeleteAsperaTransferLog(
    $input: DeleteAsperaTransferLogInput!
    $condition: ModelAsperaTransferLogConditionInput
  ) {
    deleteAsperaTransferLog(input: $input, condition: $condition) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      s3Object {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAsperaTransferSummary = /* GraphQL */ `
  mutation CreateAsperaTransferSummary(
    $input: CreateAsperaTransferSummaryInput!
    $condition: ModelAsperaTransferSummaryConditionInput
  ) {
    createAsperaTransferSummary(input: $input, condition: $condition) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      attempted
      completed
      skipped
      failed
      finalStatus
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAsperaTransferSummary = /* GraphQL */ `
  mutation UpdateAsperaTransferSummary(
    $input: UpdateAsperaTransferSummaryInput!
    $condition: ModelAsperaTransferSummaryConditionInput
  ) {
    updateAsperaTransferSummary(input: $input, condition: $condition) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      attempted
      completed
      skipped
      failed
      finalStatus
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAsperaTransferSummary = /* GraphQL */ `
  mutation DeleteAsperaTransferSummary(
    $input: DeleteAsperaTransferSummaryInput!
    $condition: ModelAsperaTransferSummaryConditionInput
  ) {
    deleteAsperaTransferSummary(input: $input, condition: $condition) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      attempted
      completed
      skipped
      failed
      finalStatus
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createRekogLog = /* GraphQL */ `
  mutation CreateRekogLog(
    $input: CreateRekogLogInput!
    $condition: ModelRekogLogConditionInput
  ) {
    createRekogLog(input: $input, condition: $condition) {
      id
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      rekogResult
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateRekogLog = /* GraphQL */ `
  mutation UpdateRekogLog(
    $input: UpdateRekogLogInput!
    $condition: ModelRekogLogConditionInput
  ) {
    updateRekogLog(input: $input, condition: $condition) {
      id
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      rekogResult
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteRekogLog = /* GraphQL */ `
  mutation DeleteRekogLog(
    $input: DeleteRekogLogInput!
    $condition: ModelRekogLogConditionInput
  ) {
    deleteRekogLog(input: $input, condition: $condition) {
      id
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      rekogResult
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAsperaTransferRequest = /* GraphQL */ `
  mutation CreateAsperaTransferRequest(
    $input: CreateAsperaTransferRequestInput!
    $condition: ModelAsperaTransferRequestConditionInput
  ) {
    createAsperaTransferRequest(input: $input, condition: $condition) {
      id
      user
      requestId
      timestamp
      sessionId
      direction
      bucket
      destinationFolder
      sourceFiles
      completion
      network
      daypart
      show
      year
      job
      name
      people
      city
      country
      region
      category
      fileType
      type
      textRekog
      objectRekog
      celebrityRekog
      notes
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAsperaTransferRequest = /* GraphQL */ `
  mutation UpdateAsperaTransferRequest(
    $input: UpdateAsperaTransferRequestInput!
    $condition: ModelAsperaTransferRequestConditionInput
  ) {
    updateAsperaTransferRequest(input: $input, condition: $condition) {
      id
      user
      requestId
      timestamp
      sessionId
      direction
      bucket
      destinationFolder
      sourceFiles
      completion
      network
      daypart
      show
      year
      job
      name
      people
      city
      country
      region
      category
      fileType
      type
      textRekog
      objectRekog
      celebrityRekog
      notes
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAsperaTransferRequest = /* GraphQL */ `
  mutation DeleteAsperaTransferRequest(
    $input: DeleteAsperaTransferRequestInput!
    $condition: ModelAsperaTransferRequestConditionInput
  ) {
    deleteAsperaTransferRequest(input: $input, condition: $condition) {
      id
      user
      requestId
      timestamp
      sessionId
      direction
      bucket
      destinationFolder
      sourceFiles
      completion
      network
      daypart
      show
      year
      job
      name
      people
      city
      country
      region
      category
      fileType
      type
      textRekog
      objectRekog
      celebrityRekog
      notes
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createScanAnalysisLog = /* GraphQL */ `
  mutation CreateScanAnalysisLog(
    $input: CreateScanAnalysisLogInput!
    $condition: ModelScanAnalysisLogConditionInput
  ) {
    createScanAnalysisLog(input: $input, condition: $condition) {
      id
      bucket
      folder
      requestId
      searchedCount
      eligibleCount
      cumulativeEligibleCount
      nextS3Key
      nextToken
      limit
      error
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateScanAnalysisLog = /* GraphQL */ `
  mutation UpdateScanAnalysisLog(
    $input: UpdateScanAnalysisLogInput!
    $condition: ModelScanAnalysisLogConditionInput
  ) {
    updateScanAnalysisLog(input: $input, condition: $condition) {
      id
      bucket
      folder
      requestId
      searchedCount
      eligibleCount
      cumulativeEligibleCount
      nextS3Key
      nextToken
      limit
      error
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteScanAnalysisLog = /* GraphQL */ `
  mutation DeleteScanAnalysisLog(
    $input: DeleteScanAnalysisLogInput!
    $condition: ModelScanAnalysisLogConditionInput
  ) {
    deleteScanAnalysisLog(input: $input, condition: $condition) {
      id
      bucket
      folder
      requestId
      searchedCount
      eligibleCount
      cumulativeEligibleCount
      nextS3Key
      nextToken
      limit
      error
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createScanRequest = /* GraphQL */ `
  mutation CreateScanRequest(
    $input: CreateScanRequestInput!
    $condition: ModelScanRequestConditionInput
  ) {
    createScanRequest(input: $input, condition: $condition) {
      id
      requestId
      scanRequestDetails
      user
      status
      error
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateScanRequest = /* GraphQL */ `
  mutation UpdateScanRequest(
    $input: UpdateScanRequestInput!
    $condition: ModelScanRequestConditionInput
  ) {
    updateScanRequest(input: $input, condition: $condition) {
      id
      requestId
      scanRequestDetails
      user
      status
      error
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteScanRequest = /* GraphQL */ `
  mutation DeleteScanRequest(
    $input: DeleteScanRequestInput!
    $condition: ModelScanRequestConditionInput
  ) {
    deleteScanRequest(input: $input, condition: $condition) {
      id
      requestId
      scanRequestDetails
      user
      status
      error
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDfsPullRequest = /* GraphQL */ `
  mutation CreateDfsPullRequest(
    $input: CreateDfsPullRequestInput!
    $condition: ModelDfsPullRequestConditionInput
  ) {
    createDfsPullRequest(input: $input, condition: $condition) {
      id
      requestId
      dfsPullRequestDetails
      user
      status
      error
      nextOffset
      nextToken
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDfsPullRequest = /* GraphQL */ `
  mutation UpdateDfsPullRequest(
    $input: UpdateDfsPullRequestInput!
    $condition: ModelDfsPullRequestConditionInput
  ) {
    updateDfsPullRequest(input: $input, condition: $condition) {
      id
      requestId
      dfsPullRequestDetails
      user
      status
      error
      nextOffset
      nextToken
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDfsPullRequest = /* GraphQL */ `
  mutation DeleteDfsPullRequest(
    $input: DeleteDfsPullRequestInput!
    $condition: ModelDfsPullRequestConditionInput
  ) {
    deleteDfsPullRequest(input: $input, condition: $condition) {
      id
      requestId
      dfsPullRequestDetails
      user
      status
      error
      nextOffset
      nextToken
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createScanRequestSummary = /* GraphQL */ `
  mutation CreateScanRequestSummary(
    $input: CreateScanRequestSummaryInput!
    $condition: ModelScanRequestSummaryConditionInput
  ) {
    createScanRequestSummary(input: $input, condition: $condition) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateScanRequestSummary = /* GraphQL */ `
  mutation UpdateScanRequestSummary(
    $input: UpdateScanRequestSummaryInput!
    $condition: ModelScanRequestSummaryConditionInput
  ) {
    updateScanRequestSummary(input: $input, condition: $condition) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteScanRequestSummary = /* GraphQL */ `
  mutation DeleteScanRequestSummary(
    $input: DeleteScanRequestSummaryInput!
    $condition: ModelScanRequestSummaryConditionInput
  ) {
    deleteScanRequestSummary(input: $input, condition: $condition) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createDfsPullRequestSummary = /* GraphQL */ `
  mutation CreateDfsPullRequestSummary(
    $input: CreateDfsPullRequestSummaryInput!
    $condition: ModelDfsPullRequestSummaryConditionInput
  ) {
    createDfsPullRequestSummary(input: $input, condition: $condition) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateDfsPullRequestSummary = /* GraphQL */ `
  mutation UpdateDfsPullRequestSummary(
    $input: UpdateDfsPullRequestSummaryInput!
    $condition: ModelDfsPullRequestSummaryConditionInput
  ) {
    updateDfsPullRequestSummary(input: $input, condition: $condition) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteDfsPullRequestSummary = /* GraphQL */ `
  mutation DeleteDfsPullRequestSummary(
    $input: DeleteDfsPullRequestSummaryInput!
    $condition: ModelDfsPullRequestSummaryConditionInput
  ) {
    deleteDfsPullRequestSummary(input: $input, condition: $condition) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createEventTrigger = /* GraphQL */ `
  mutation CreateEventTrigger(
    $input: CreateEventTriggerInput!
    $condition: ModelEventTriggerConditionInput
  ) {
    createEventTrigger(input: $input, condition: $condition) {
      id
      eventDetails
      nextTrigger
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEventTrigger = /* GraphQL */ `
  mutation UpdateEventTrigger(
    $input: UpdateEventTriggerInput!
    $condition: ModelEventTriggerConditionInput
  ) {
    updateEventTrigger(input: $input, condition: $condition) {
      id
      eventDetails
      nextTrigger
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEventTrigger = /* GraphQL */ `
  mutation DeleteEventTrigger(
    $input: DeleteEventTriggerInput!
    $condition: ModelEventTriggerConditionInput
  ) {
    deleteEventTrigger(input: $input, condition: $condition) {
      id
      eventDetails
      nextTrigger
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
