import React from "react";
import SidebarNavItem from "components/sidebar/SidebarNavItem";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { dispatch } from "redux-store";
import { searchSetFieldValue } from "store/search-reducer";
export default function SearchLeftSideBar() {
  const searchResult = () => {
    //dispatch(searchSetFieldValue({ isFromSearch: true }));
  }
  return (
    <>
      <SidebarNavItem
        to="/search"
        label="Filters"
        Icon={ImageSearchIcon}
        matchExtact
      />
      <SidebarNavItem
        to={{
          pathname: "/search/results",
          state: { from: "results" }
        }}
        label="Results"
        Icon={CheckBoxIcon}
        onClick={searchResult}
      />
      <SidebarNavItem
        to="/search/collections"
        label="My Collections"
        Icon={ImageSearchIcon}

      />
      
    </>
  );
}
