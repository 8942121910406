import { createAction, createReducer } from '@reduxjs/toolkit';

export const rolesListSetFieldValue = createAction('ROLES_LIST_SET_FIELD_VALUE');
export const rolesListResetValues = createAction('ROLES_LIST_RESET_VALUES');

const defaultState = {
    RolesDataList : []
}

const rolesListFormReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(rolesListSetFieldValue, (state, { payload }) => {
      Object.assign(state, payload);
      return state;
    }).addCase(rolesListResetValues, () => ({
      ...defaultState,
    }));
});

export default rolesListFormReducer;
