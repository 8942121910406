import { dispatch, store } from "redux-store";
import { API, graphqlOperation } from "aws-amplify";
import {
  searchMetaDatas,
  listUsers,
  listGroups,
  listShows,
  userByEmail,
  listUserGroups,
  listRoles,
  listMetaDatas,
  listCollections,
  getCollection,
  collectionsByName,
  groupByName,
  userGroupByUser,
  roleByName,
  listScanRequestSummarys,
  searchScanRequests,
  listDfsPullRequestSummarys,
  searchDfsPullRequests,
} from "graphql/queries";
import {
  batchAddScanRequest,
  createAsperaTransferRequest,
  createShow,
  createUser,
  createGroup,
  updateGroup,
  createUserGroup,
  updateUserGroup,
  deleteUserGroup,
  updateUser,
  createRole,
  updateRole,
  deleteUser,
  deleteGroup,
  deleteRole,
  createCollection,
  updateCollection,
  deleteCollection,
  createScanRequestSummary,
} from "graphql/mutations";

import { awsConfig } from "../config";
import wordImg from "../assets/word.jpg";
import pdfImg from "../assets/pdf.png";
import pptImg from "../assets/ppt.jpg";
import excelImg from "../assets/excel.jpg";
import config from "../aws-exports";
import invoke from "../service/lambda.service";
import { onUpdateUser } from "graphql/subscriptions";

export const today = new Date();
export const daybeforeSevenDays = new Date(today.setDate(today.getDate() - 6))
  .toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
  .split("/")
  .reverse()
  .join("-");

export const buildElasticSearchFilter = (token, sort) => {
  const currentState = store.getState();
  const formData = { ...currentState.search };
  const filterQuery = { and: [] };

  if (formData.fileType === "ALL") {
    filterQuery.and.push({
      or: [{ mediaFilename: { wildcard: "*" } }],
    });
  }

  if (formData.fileType === "jpg") {
    filterQuery.and.push({
      or: [
        { mediaFilename: { wildcard: "*jpeg" } },
        { mediaFilename: { wildcard: "*jpg" } },
      ],
    });
  }

  if (formData.fileType === "png") {
    filterQuery.and.push({
      or: [{ mediaFilename: { wildcard: "*png" } }],
    });
  }

  if (formData.fileType === "psd") {
    filterQuery.and.push({
      or: [{ mediaFilename: { wildcard: "*psd" } }],
    });
  }
  if (formData.fileType === "pdf") {
    filterQuery.and.push({
      or: [{ mediaFilename: { wildcard: "*pdf" } }],
    });
  }
  if (formData.fileType === "mp4") {
    filterQuery.and.push({
      or: [{ mediaFilename: { wildcard: "*mp4" } }],
    });
  }
  if (formData.fileType === "mov") {
    filterQuery.and.push({
      or: [{ mediaFilename: { wildcard: "*mov" } }],
    });
  }
  if (formData.fileType === "avi") {
    filterQuery.and.push({
      or: [{ mediaFilename: { wildcard: "*avi" } }],
    });
  }
  if (formData.fileType === "webm") {
    filterQuery.and.push({
      or: [{ mediaFilename: { wildcard: "*webm" } }],
    });
  } 
  if (formData.fileType === "flv") {
    filterQuery.and.push({
      or: [{ mediaFilename: { wildcard: "*flv" } }],
    });
  }

  if (formData.fileType === "tiff") {
    filterQuery.and.push({
      or: [
        { mediaFilename: { wildcard: "*tiff" } },
        { mediaFilename: { wildcard: "*tif" } },
      ],
    });
  }
  if (formData.fileType === "raw") {
    filterQuery.and.push({
      not: {
        or: [
          { mediaFilename: { wildcard: "*jpeg" } },
          { mediaFilename: { wildcard: "*jpg" } },
          { mediaFilename: { wildcard: "*png" } },
        ],
      },
    });
  }

  const dfsq = getDateFilterSubQry(formData);
  if (dfsq && dfsq !== {}) {
    filterQuery.and.push(dfsq);
  }

  if (formData.bucket) {
    filterQuery.and.push({
      bucket: { eq: formData.bucket },
    });
  }

  const specificPathSubQry = getSpecificPathSubQry(formData);
  if (specificPathSubQry && specificPathSubQry?.or.length > 0) {
    filterQuery.and.push(specificPathSubQry);
  }
  if (formData.category) {
    const categorySubQry = getCategorySubQry(formData);
    if (categorySubQry && categorySubQry?.or.length > 0) {
      filterQuery.and.push(categorySubQry);
    }
  }
  delete formData.bucket;
  delete formData.directory;
  delete formData.destinationPath;
  delete formData.fileType;
  delete formData.selectedDate;
  delete formData.datefilter;
  delete formData.datefilterlimit;
  delete formData.isFromSearch;
  delete formData.category;
  const strategy = formData['searchType'];
  delete formData['searchType'];


  return Promise.all(
    Object.keys(formData).map(async (formField) => {

      if (formData[formField]) {
        if ([formField] == "keywords") {
          const keywordMasterQuery = { or: [] };

          const jobQry = await fieldQry("job", formData[formField], strategy);
          const exifDumpQry = await fieldQry("exifDump", formData[formField], strategy);
          const notesQry = await fieldQry("notes", formData[formField], strategy);
          const keywordsQry = await fieldQry("keywords", formData[formField], strategy);
          const showQry = await fieldQry("show", formData[formField], 'all'); //---use exact for show, Since it is chosen from drop down.
          const dfsIdQry = await fieldQry("dfsId", formData[formField], strategy);
          const dfsProjectQry = await fieldQry(
            "dfsProject",
            formData[formField],
            strategy
          );
          const dfsSetupQry = await fieldQry("dfsSetup", formData[formField], strategy);
          const dfsNameQry = await fieldQry("dfsName", formData[formField], strategy);
          const dfsBadgeQry = await fieldQry("dfsBadge", formData[formField], strategy);

          const savedUploadNameQry = await fieldQry(
            "name",
            formData[formField],
            strategy
          );

          await keywordMasterQuery.or.push(jobQry);
          await keywordMasterQuery.or.push(exifDumpQry);
          await keywordMasterQuery.or.push(notesQry);
          await keywordMasterQuery.or.push(keywordsQry);
          await keywordMasterQuery.or.push(showQry);
          await keywordMasterQuery.or.push(savedUploadNameQry);
          await keywordMasterQuery.or.push(dfsIdQry);
          await keywordMasterQuery.or.push(dfsProjectQry);
          await keywordMasterQuery.or.push(dfsSetupQry);
          await keywordMasterQuery.or.push(dfsNameQry);
          await keywordMasterQuery.or.push(dfsBadgeQry);

          await filterQuery.and.push(keywordMasterQuery);
        } else {
          const fq = fieldQry(formField, formData[formField], (formField === 'show') ? 'all' : strategy); //---use exact for show, Since it is chosen from drop down.
          await filterQuery.and.push(fq);
        }
      }
    })
  ).then(() => {
    return filterQuery;
  });
};

const getSpecificPathSubQry = (formData) => {
  const userRolesData = localStorage.getItem("userRoles");
  const adminRole = JSON.parse(userRolesData);
  let subQry = { or: [] };
  if (
    formData.directory &&
    formData.directory.length &&
    formData.directory[0] != "All"
  ) {
    formData.directory.forEach((val) => {
      subQry.or.push({ mediaFilename: { matchPhrase: val.label } });
    });
    return subQry;
  }
  if (adminRole.bucketBasedOnUser && adminRole.bucketBasedOnUser.length) {
    const index = adminRole.bucketBasedOnUser
      .map((object) => object.bucket)
      .indexOf(formData.bucket);
    if (index !== -1) {
      if (adminRole.bucketBasedOnUser[index]?.paths) {
        adminRole.bucketBasedOnUser[index].paths.forEach((val) => {
          if (val.path) {
            subQry.or.push({ mediaFilename: { matchPhrase: val.path } });
          }
        });
      }
    }
  } else {
    if (adminRole && adminRole.paths) {
      adminRole.paths.forEach((val) => {
        if (val.path) {
          subQry.or.push({ mediaFilename: { matchPhrase: val.path } });
        }
      });
    }
  }

  return subQry;
};

const getCategorySubQry = (formData) => {
  let subQry = { or: [] };
  subQry.or.push({ mediaFilename: { matchPhrase: formData.category } });
  return subQry;
};
const getDateFilterSubQry = (formData) => {
  const today = new Date();
  const oneDay = 24 * 60 * 60 * 1000;

  let dfsq = {};
  let date1;
  let date2;

  if (formData?.datefilter) {
    if (formData?.datefilter === "range") {
      if (formData?.selectedDate) {
        date1 = new Date(formData.selectedDate.setHours(0, 0, 0)).getTime();
      }
      if (formData?.dateToDate) {
        date2 = new Date(formData.dateToDate.setHours(23, 59, 59)).getTime();
      }
    }

    if (formData?.datefilter !== "range") {
      if (formData?.selectedDate) {
        date1 = new Date(formData.selectedDate.setHours(0, 0, 0)).getTime();
      }
    }

    switch (formData.datefilter) {
      case "before":
        dfsq = { clickTime: { lt: daysFrom1970(date1) } };
        break;

      case "after":
        dfsq = { clickTime: { gt: daysFrom1970(date1) } };
        break;

      case "range":
        dfsq = {
          and: [
            { clickTime: { gt: daysFrom1970(date1) } },
            { clickTime: { lt: daysFrom1970(date2) } },
          ],
        };
        break;

      case "today":
        date1 = today.setHours(0, 0, 0).getTime();
        date2 = today.setHours(23, 59, 59).getTime();
        dfsq = {
          and: [
            { clickTime: { gt: daysFrom1970(date1) } },
            { clickTime: { lt: daysFrom1970(date2) } },
          ],
        };
        break;

      case "yesterday":
        date1 = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 1,
          0,
          0,
          0
        ).getTime();
        date2 = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 1,
          23,
          59,
          59
        ).getTime();
        dfsq = {
          and: [
            { clickTime: { gt: daysFrom1970(date1) } },
            { clickTime: { lt: daysFrom1970(date2) } },
          ],
        };
        break;

      case "thismonth":
        date1 = new Date(
          today.getFullYear(),
          today.getMonth(),
          1,
          0,
          0,
          0
        ).getTime();
        for (
          let nextDay = date1;
          nextDay.getMonth() === date1.getMonth();
          nextDay = new Date(nextDay.getTime() + 24 * 60 * 60 * 1000)
        ) {
          date2 = nextDay.setHours(23, 59, 59);
        }

        dfsq = {
          and: [
            { clickTime: { gt: daysFrom1970(date1) } },
            { clickTime: { lt: daysFrom1970(date2) } },
          ],
        };
        break;

      case "lastmonth":
        date2 =
          new Date(
            today.getFullYear(),
            today.getMonth(),
            1,
            23,
            59,
            59
          ).getTime() - oneDay;

        for (
          let prevDay = date2;
          prevDay.getMonth() === date2.getMonth();
          prevDay = new Date(prevDay.getTime() - oneDay)
        ) {
          date1 = prevDay.setHours(0, 0, 0);
        }

        dfsq = {
          and: [
            { clickTime: { gt: daysFrom1970(date1) } },
            { clickTime: { lt: daysFrom1970(date2) } },
          ],
        };
        break;

      case "thisyear":
        date1 = new Date(today.getFullYear(), 0, 1, 0, 0, 0).getTime();
        date2 = new Date(today.getFullYear(), 11, 31, 23, 59, 59).getTime();

        dfsq = {
          and: [
            { clickTime: { gt: daysFrom1970(date1) } },
            { clickTime: { lt: daysFrom1970(date2) } },
          ],
        };
        break;

      case "lastyear":
        date1 = new Date(today.getFullYear() - 1, 0, 1, 0, 0, 0).getTime();
        date2 = new Date(today.getFullYear() - 1, 11, 31, 23, 59, 59).getTime();

        dfsq = {
          and: [
            { clickTime: { gt: daysFrom1970(date1) } },
            { clickTime: { lt: daysFrom1970(date2) } },
          ],
        };
        break;

      default:
        break;
    }
  }

  return dfsq;
};

/**
 *
 * @param {*} date : epoch date
 * @returns
 */
const daysFrom1970 = (date) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const daysFrom1970 = Math.floor(
    (date - new Date(1970, 0, 1).getTime()) / oneDay
  );
  return daysFrom1970;
};

const paginationQry = (token, sort) => {
  if (sort === "asc") {
    return { timestamp: { gt: token } };
  } else {
    return { timestamp: { lt: token } };
  }
};

/**
 * 
 * @param {*} fieldName 
 * @param {*} searchText 
 * @param {*} strategy: All: Each word in searchText should be present.
 *                      Any: Any word in  searchText should be present.
 *                      Exact: Do not search word by word. Search for whole text.
 * @returns 
 */
const fieldQry = (fieldName, searchText, strategy='all') => {

  const qryForField = {};
  let operator = 'and';
  
  if (strategy.toLowerCase() === 'any') {
    operator = 'or';
  }

  if (strategy.toLowerCase() !== 'exact') {
    const refinedSearchText = searchText
                                .toString()
                                .replace(/([\!\*\+\&\|\(\)\[\]\{\}\^\~\?\:\"\.\-\/\,])/g, " ")
                                .toLowerCase();

    const textArr = refinedSearchText.split(" ");

    qryForField[operator] = [];
    textArr.forEach((text) => {
      if (text.trim().length > 0) {
        qryForField[operator].push({ [fieldName]: { wildcard: "*" + text + "*" } });
      }
    });
  } else {
    qryForField[fieldName] = {matchPhrase: searchText}
  }

  console.log(strategy, ' -- Field query for: ', fieldName, JSON.stringify(qryForField));

  return qryForField;
};

export const searchAsset = async (
  limit,
  token,
  sort,
  onlyFileNames = false
) => {
  const filt = await buildElasticSearchFilter(token, sort);

  const resp = await apiSearchMetaDatas(
    filt,
    sort,
    limit,
    token,
    onlyFileNames
  );

  resp.items.forEach((item) => {
    if (item.thumbnail) {
      item.thumbnail_url =
        awsConfig.ThumbnailDomain + encodeURIComponent(item.thumbnail);
    }
    if (!item.thumbnail && (item.mediaFilename.match("docx") !== null || item.mediaFilename.match("doc") !== null)) {
      item.thumbnail_url = wordImg;
    }
    if (!item.thumbnail && item.mediaFilename.match("pdf") !== null) {
      item.thumbnail_url = pdfImg;
    }
    if (!item.thumbnail && (item.mediaFilename.match("xlsx") !== null || item.mediaFilename.match("xls") !== null)) {
      item.thumbnail_url = excelImg;
    }
    if (!item.thumbnail && (item.mediaFilename.match("ppt") !== null || item.mediaFilename.match("pptx") !== null)) {
      item.thumbnail_url = pptImg;
    }
    item.size = item.awsS3FileLocation.size;
    item.mimeType = item.awsS3FileLocation.mimeType;
  });
  return resp;
};

export const apiCreateAsperaTransferRequest = async (transferStatus) => {
  const currentState = store.getState();

  console.log(
    "Creating aspera transfer request. search-store: ",
    currentState.search
  );

  const gqlAPIServiceArguments = {
    input: {
      user: localStorage.getItem("userEmail"),
      timestamp: new Date().toISOString(),
      sessionId: "",
      requestId: transferStatus.transferId,
      direction: transferStatus.direction,
      bucket: transferStatus.bucket,
      destinationFolder: transferStatus.destionationPath,
      sourceFiles: [],
      network: currentState.search.network,
      show: currentState.search.show,
      daypart: currentState.search.daypart,
      year: currentState.search.year,
      job: currentState.search.job,
      name: currentState.search.name,
      people: currentState.search.people,
      city: currentState.search.city,
      country: currentState.search.country,
      region: currentState.search.region,
      category: currentState.search.category,
      fileType: currentState.search.fileType,
      type: currentState.search.type,
      celebrityRekog: currentState.search.celebrityRekog || false,
      objectRekog: currentState.search.objectRekog || false,
      textRekog: currentState.search.textRekog || false,
      notes: currentState.search.keywords || false,
    },
  };

  transferStatus.sourceFiles.forEach((sf) => {
    gqlAPIServiceArguments.input.sourceFiles.push(sf.mediaFilename || sf.name);
  });

  const { data } = await API.graphql({
    query: createAsperaTransferRequest,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });
  return data.createAsperaTransferRequest;
};

/**
 *
 * @param {*} filter
 * @param {*} limit
 * @param {*} nextToken
 * @returns
 */
export const apiListShows = async (filter, limit, nextToken) => {
  const gqlAPIServiceArguments = {};
  gqlAPIServiceArguments.limit = 2000;
  const response = await API.graphql({
    query: listShows,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return response.data.listShows;
};

/**
 * apiCreateShow
 */
export const apiCreateShow = async (transferStatus) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      name: currentState.search.showtitle,
      order: 1,
      active: 1,
      networkId: 0,
    },
  };

  const { data } = await API.graphql({
    query: createShow,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.createShow;
};

export const apiCreateScan = async (transferStatus) => {
  const currentState = store.getState();
  const scanRequestDetailsBase = {
    file: "",
    size: 0,
    folder: transferStatus.selectedDir,
    completion: null,
    user: localStorage.getItem("userEmail"),
    timestamp: new Date().toISOString(),
    sessionId: "",
    requestId: transferStatus.transferId,
    bucket: currentState.search.bucket,
    network: currentState.search.network,
    show: currentState.search.show,
    year: currentState.search.year,
    job: currentState.search.job,
    name: currentState.search.name,
    people: currentState.search.people,
    city: currentState.search.city,
    country: currentState.search.country,
    region: currentState.search.region,
    category: currentState.search.category,
    fileType: currentState.search.fileType,
    type: currentState.search.type,
    celebrityRekog: currentState.search.celebrityRekog || false,
    objectRekog: currentState.search.objectRekog || false,
    textRekog: currentState.search.textRekog || false,
    notes: currentState.search.keywords || false,
  };

  const scanRequestSummaryInput = {
    requestId: transferStatus.transferId,
    requestCount: transferStatus.selectedFiles?.length || 0,
    completionCount: 0,
    user: localStorage.getItem("userEmail"),
  };
  await API.graphql({
    query: createScanRequestSummary,
    variables: { input: scanRequestSummaryInput },
    authMode: "AWS_IAM",
  });

  const requestDetailsArr = [[]];
  transferStatus.selectedFiles.forEach((sf) => {
    if (requestDetailsArr[requestDetailsArr.length - 1].length === 20) {
      requestDetailsArr.push([]);
    }
    requestDetailsArr[requestDetailsArr.length - 1].push({
      user: localStorage.getItem("userEmail"),
      requestId: transferStatus.transferId,
      status: "",
      scanRequestDetails: JSON.stringify({
        ...scanRequestDetailsBase,
        file: sf.Key,
        size: sf.Size,
      }),
    });
  });
  for (const requestDetails of requestDetailsArr) {
    await API.graphql({
      query: batchAddScanRequest,
      variables: { scanRequest: requestDetails },
      authMode: "AWS_IAM",
    });
  }
};

//-----sample code to create group
export const createOneGroup = async () => {
  const createGroupInput = {
    groupName: "group1",
    description: "Group one",
    buckets: ["buk1", "buk2"],
  };

  const resp = await API.graphql({
    query: createGroup,
    variables: { input: createGroupInput },
    authMode: "AWS_IAM",
  });

  return resp.data.createGroup;
};

export const createOneUser = async () => {
  const createUserInput = {
    firstName: "aaa",
    lastName: "aaa",
    phone: "321323123",
    mobile: "23423434",
    email: "abc@abc.abc",
    location: "disney",
  };

  const resp = await API.graphql({
    query: createUser,
    variables: { input: createUserInput },
    authMode: "AWS_IAM",
  });

  return resp.data.createUser;
};

//----sample code to create one user-group relationship
export const createOneUserGroup = async (user, group) => {
  const currentState = store.getState();
  if (currentState.adminuser?.groups) {
    currentState.adminuser?.groups.forEach((val) => {
      if (val.id) {
        createOneUserGroupApiUpdate(
          val.id,
          currentState.adminuser?.id,
          val.groupid
        );
      } else {
        createOneUserGroupApiCreate(currentState.adminuser?.id, val.groupid);
      }
    });
  }
};
/**
 *
 * @param {*} user
 * @param {*} group
 * @returns
 */
export const createOneGroupUser = async (creds) => {
  const currentState = store.getState();
  if (currentState.admingroup?.users) {
    currentState.admingroup?.users.forEach((val) => {
      if (val.id) {
        createOneUserGroupApiUpdate(
          val.id,
          val.userid,
          currentState.admingroup?.id
        );
      } else {
        createOneUserGroupApiCreate(val.userid, currentState.admingroup?.id);
      }

      invoke(
        "wdtvamtoolCognitoUtils-" + awsConfig.environment,
        {
          data: {
            region: config.aws_project_region,
            action: "get-user",
            email: val.email,
            userPoolId: config.aws_user_pools_id,
          },
        },
        creds
      )
        .then((cogRes) => {
          const data = JSON.parse(cogRes.Payload);

          invoke(
            "wdtvamtoolCognitoUtils-" + awsConfig.environment,
            {
              data: {
                region: config.aws_project_region,
                action: "add-user-to-group",
                groupName: currentState.admingroup.groupName,
                userName: data.body.Username,
                userPoolId: config.aws_user_pools_id,
              },
            },
            creds
          )
            .then((cogRes) => {
              const data = JSON.parse(cogRes.Payload);
            })
            .catch((err) => {
              console.dir(err);
            });
        })
        .catch((err) => {
          console.dir(err);
        });
    });
  }
};

/**
 *
 * @param {*} userId
 * @param {*} groupId
 * @returns
 */
export const createOneUserGroupApiCreate = async (userId, groupId) => {
  const createUserGroupInput = {
    userId: userId,
    groupId: groupId,
  };

  const resp = await API.graphql({
    query: createUserGroup,
    variables: { input: createUserGroupInput },
    authMode: "AWS_IAM",
  });

  return resp.data.createUserGroup;
};

/**
 *
 * @param {*} userId
 * @param {*} groupId
 * @returns
 */
export const createOneUserGroupApiUpdate = async (id, userId, groupId) => {
  const updateUserGroupInput = {
    id: id,
    userId: userId,
    groupId: groupId,
  };

  const resp = await API.graphql({
    query: updateUserGroup,
    variables: { input: updateUserGroupInput },
    authMode: "AWS_IAM",
  });

  return resp.data.updateUserGroup;
};

/**
 * Delete createOneGroupUser
 * @param {*}
 * @returns
 */
export const deleteOneGroupUser = async (usergroupid) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      id: usergroupid,
    },
  };

  const { data } = await API.graphql({
    query: deleteUserGroup,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.deleteUserGroup;
};

//-----sample code to get user by email
export const getUserByEmail = async (email) => {
  const resp = await API.graphql({
    query: userByEmail,
    variables: { email: email },
    authMode: "AWS_IAM",
  });
  return resp.data;
};

export const getUserByRole = async (role) => {
  const resp = await API.graphql({
    query: listUsers,
    variables: { filter: { roles: { contains: role } } },
    authMode: "AWS_IAM",
  });
  return resp.data;
};

//-----sample code to get role by name
export const getGroupByName = async (name) => {
  const resp = await API.graphql({
    query: groupByName,
    variables: { groupName: name },
    authMode: "AWS_IAM",
  });
  return resp.data;
};

//-----sample code to get role by name
export const getGroupByUser = async (userId) => {
  const resp = await API.graphql({
    query: userGroupByUser,
    variables: { userId: userId },
    authMode: "AWS_IAM",
  });

  return resp.data;
};

//-----sample code to get role by name
export const getRoleByName = async (name) => {
  const resp = await API.graphql({
    query: roleByName,
    variables: { name: name },
    authMode: "AWS_IAM",
  });
  return resp.data;
};

export const getRolesByNames = async (names) => {
  if (names) {
    const condition = { or: [] };
    names.map((name) => {
      condition.or.push({ name: { eq: name } });
    });

    const resp = await API.graphql({
      query: listRoles,
      variables: { filter: condition },
      authMode: "AWS_IAM",
    });

    return resp.data;
  } else {
    return null;
  }
};

//----Sample code to list all usergroups ...
export const listAllUserGroups = async () => {
  const resp = await API.graphql({
    query: listUserGroups,
    authMode: "AWS_IAM",
  });
  return resp.data.listUserGroups;
};

export const listScanRequestSummary = async () => {
  const resp = await API.graphql({
    query: listScanRequestSummarys,
    variables: { filter: { requestId: { ge: `Scan-${daybeforeSevenDays}` } } },
    authMode: "AWS_IAM",
  });
  return resp.data.listScanRequestSummarys;
};

export const searchScanRequest = async (filter) => {
  const resp = await API.graphql({
    query: searchScanRequests,
    variables: filter,
    authMode: "AWS_IAM",
  });
  return resp.data.searchScanRequests;
};
export const listDfsRequestSummary = async () => {
  const resp = await API.graphql({
    query: listDfsPullRequestSummarys,
    variables: { filter: { requestId: { ge: `DFS-${daybeforeSevenDays}` } } },
    authMode: "AWS_IAM",
  });
  return resp.data.listDfsPullRequestSummarys;
};

export const searchDfsRequest = async (filter) => {
  const resp = await API.graphql({
    query: searchDfsPullRequests,
    variables: filter,
    authMode: "AWS_IAM",
  });
  return resp.data.searchDfsPullRequests;
};

export const listUserGroupsByUserId = async (userId) => {
  const resp = await API.graphql({
    query: listUserGroups,
    variables: { filter: { userId: { eq: userId } } },
    authMode: "AWS_IAM",
  });
  return resp.data.listUserGroups;
};

//---sample to create role
export const createOneRole = async () => {
  const createRoleInput = {
    name: "role1",
    description: "Role One",
    parentRole: "parent1",
    config: "config",
  };

  const resp = await API.graphql({
    query: createRole,
    variables: { input: createRoleInput },
    authMode: "AWS_IAM",
  });

  return resp.data.createRole;
};

export const apiSearchMetaDatas = async (
  filter,
  sort,
  limit,
  nextToken,
  onlyFileNames = false
) => {
  const gqlAPIServiceArguments = {};

  if (filter) {
    gqlAPIServiceArguments.filter = filter;
  }
  if (sort) {
    gqlAPIServiceArguments.sort = sort;
  }
  if (limit) {
    gqlAPIServiceArguments.limit = limit;
  }
  if (nextToken) {
    gqlAPIServiceArguments.nextToken = nextToken;
  }

  let response = null;
  if (onlyFileNames === true) {
    const searchMetaDataForFileNames =
      /* GraphQL */
      `
        query SearchMetaDatasForFileNames(
          $filter: SearchableMetaDataFilterInput
          $sort: SearchableMetaDataSortInput
          $limit: Int
          $nextToken: String
          $from: Int
        ) {
          searchMetaDatas(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
            from: $from
          ) {
            items {
              mediaFilename
              awsS3FileLocation {
                bucket
                key
              }
            }
          }
        }
      `;

    response = await API.graphql({
      query: searchMetaDataForFileNames,
      variables: gqlAPIServiceArguments,
      authMode: "AWS_IAM",
    });
  } else {
    response = await API.graphql({
      query: searchMetaDatas,
      variables: gqlAPIServiceArguments,
      authMode: "AWS_IAM",
    });
  }

  return response.data.searchMetaDatas;
};

/**
 * list users
 * @returns
 */
export const fetchUserLists = async () => {
  const response = await API.graphql({ query: listUsers, authMode: "AWS_IAM" });
  return response.data.listUsers;
};

/**
 * Create user
 * @param {*} userData
 * @returns
 */
export const createAdminUser = async (userData) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      firstName: currentState.adminuser.firstName,
      lastName: currentState.adminuser.lastName,
      email: currentState.adminuser.email,
      title: currentState.adminuser.title,
      roles: currentState.adminuser.roles,
      status: "pending",
    },
  };

  const { data } = await API.graphql({
    query: createUser,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.createUser;
};
/**
 * update user
 * @param {*} userData
 * @returns
 */
export const updateAdminUser = async (userData) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      id: currentState.adminuser.id,
      firstName: currentState.adminuser.firstName,
      lastName: currentState.adminuser.lastName,
      email: currentState.adminuser.email,
      title: currentState.adminuser.title,
      roles: currentState.adminuser.roles,
    },
  };
  if (currentState?.adminuser?.status) {
    gqlAPIServiceArguments.input.status = currentState?.adminuser?.status;
  }
  if (currentState?.adminuser?.mobile) {
    gqlAPIServiceArguments.input.mobile = currentState?.adminuser?.mobile;
  }
  if (currentState?.adminuser?.phone) {
    gqlAPIServiceArguments.input.phone = currentState?.adminuser?.phone;
  }
  if (currentState?.adminuser?.location) {
    gqlAPIServiceArguments.input.location = currentState?.adminuser?.location;
  }
  if (currentState?.adminuser?.securityAnswer) {
    gqlAPIServiceArguments.input.securityAnswer =
      currentState?.adminuser?.securityAnswer;
  }
  if (currentState?.adminuser?.securityQuestion) {
    gqlAPIServiceArguments.input.securityQuestion =
      currentState?.adminuser?.securityQuestion;
  }

  const { data } = await API.graphql({
    query: updateUser,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.updateUser;
};
/**
 * update user
 * @param {*} userData
 * @returns
 */
export const updateAdminUserConfig = async (userData) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      id: userData?.user?.userid,
      config: JSON.stringify(userData.paths),
    },
  };

  const { data } = await API.graphql({
    query: updateUser,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.updateUser;
};
/**
 * Delete
 * @param {*} userData
 * @returns
 */
export const deleteAdminUser = async (userData) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      id: currentState.adminuser.id,
    },
  };

  const { data } = await API.graphql({
    query: deleteUser,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.deleteUser;
};

export const updateUserActiveTime = async (user) => {
  const gqlAPIServiceArguments = {
    input: user,
  };

  const { data } = await API.graphql({
    query: updateUser,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.updateUser;
};

export const onUserLoggedIn = async (ownerId, callback) => {
  const gqlAPIServiceArguments = {
    owner: ownerId,
  };

  await API.graphql({
    query: onUpdateUser,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  }).subscribe({
    next: ({ provider, value }) => callback(provider, value),
    err: (err) => alert(err),
  });
};

/**
 * Group api's
 */

/**
 * list group
 * @returns
 */
export const fetchGroupLists = async () => {
  const response = await API.graphql({
    query: listGroups,
    authMode: "AWS_IAM",
  });
  return response.data.listGroups;
};

export const createAdminGroup = async (groupData) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      groupName: currentState.admingroup.groupName,
      description: currentState.admingroup.description,
      buckets: currentState.admingroup.bucket,
    },
  };

  const { data } = await API.graphql({
    query: createGroup,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.createGroup;
};
export const updateAdminGroup = async (groupData) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      id: currentState.admingroup.id,
      groupName: currentState.admingroup.groupName,
      dfStudioAccess: currentState.admingroup.dfStudioAccess,
      description: currentState.admingroup.description,
      buckets: currentState.admingroup.bucket,
    },
  };

  const { data } = await API.graphql({
    query: updateGroup,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.updateGroup;
};
/**
 * Delete
 * @param {*} gqlAPIServiceArguments
 * @returns
 */
export const deleteAdminGroup = async (groupData) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      id: currentState.admingroup.id,
    },
  };

  const { data } = await API.graphql({
    query: deleteGroup,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.deleteGroup;
};

/**
 * Roles api's
 */

/**
 * list group
 * @returns
 */
export const fetchRoleLists = async () => {
  const response = await API.graphql({ query: listRoles, authMode: "AWS_IAM" });
  return response.data.listRoles;
};

export const createAdminRoles = async (roleData) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      name: currentState.adminrole.name,
      description: currentState.adminrole.description,
    },
  };

  const { data } = await API.graphql({
    query: createRole,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.createRole;
};
export const updateAdminRoles = async (roleData) => {
  const currentState = store.getState();

  const config = {
    user: currentState.adminrole.user,
    bucket: currentState.adminrole.bucket,
    paths: currentState.adminrole.paths,
    userAccess: currentState.adminrole.userAccess,
    userData: currentState.adminrole.userData,
  };
  const gqlAPIServiceArguments = {
    input: {
      id: currentState.adminrole.id,
      name: currentState.adminrole.name,
      description: currentState.adminrole.description,
      idletime: currentState.adminrole.idletime,
      config: JSON.stringify(config),
    },
  };

  const { data } = await API.graphql({
    query: updateRole,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  if (data) {
    updateAdminUserConfig(currentState.adminrole);
  }

  return data.updateRole;
};
/**
 * Delete
 * @param {*} roleData
 * @returns
 */
export const deleteAdminRole = async (roleData) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      id: currentState.adminrole.id,
    },
  };

  const { data } = await API.graphql({
    query: deleteRole,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.deleteRole;
};

export const listSelectedMetaData = async (mdIdList) => {
  const gqlAPIServiceArguments = {};

  const filter = { or: [] };

  if (mdIdList) {
    mdIdList.forEach((mdi) => {
      filter.or.push({ id: { eq: mdi } });
    });
  }

  gqlAPIServiceArguments.filter = filter;

  const { data } = await API.graphql({
    query: searchMetaDatas,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data?.searchMetaDatas?.items;
};

export const selectedMetaDataByFileName = async (fileName) => {
  const gqlAPIServiceArguments = {};

  const filter = { mediaFilename: { eq: fileName } };

  gqlAPIServiceArguments.filter = filter;

  const { data } = await API.graphql({
    query: searchMetaDatas,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data?.searchMetaDatas?.items;
};

/**
 * getCollection by id
 * @returns
 */
export const fetchCollection = async () => {
  const response = await API.graphql({
    query: getCollection,
    authMode: "AWS_IAM",
  });
  return response.data.getCollection;
};

/**
 * getCollection lists
 * @returns
 */
export const fetchCollectionList = async () => {
  const response = await API.graphql({
    query: listCollections,
    authMode: "AWS_IAM",
  });

  return response.data.listCollections;
};

/**
 *
 * @param {*} groups -- Array of strings (group names)
 */
export const collectionsSharedWithGroups = async (groups) => {
  const response = await API.graphql({
    query: listCollections,
    variables: { filter: { or: groups } },
    authMode: "AWS_IAM",
  });

  return response.data.listCollections;
};

/**
 *
 * @param {*} showSystem
 * @returns
 */
export const createSearchCollection = async (showSystem) => {
  const currentState = store.getState();
  let users = [];
  let groups = [];
  let objects = [];

  const gqlAPIServiceArguments = {
    input: {
      createdBy: currentState.collections.createdBy,
    },
  };
  if (currentState?.collections?.name) {
    gqlAPIServiceArguments.input.name = currentState?.collections?.name;
  }
  if (currentState?.collections?.title) {
    gqlAPIServiceArguments.input.title = currentState?.collections?.title;
  }
  if (
    currentState?.collections?.systemUsers &&
    currentState?.collections?.systemUsers.length &&
    showSystem === "user"
  ) {
    currentState?.collections?.systemUsers.forEach((val) => {
      users.push(val.email);
    });
    gqlAPIServiceArguments.input.sharedUsers = users;
  }
  if (
    currentState?.collections?.systemGroups &&
    currentState?.collections?.systemGroups.length &&
    showSystem === "group"
  ) {
    currentState?.collections?.systemGroups.forEach((val) => {
      groups.push(val.label);
    });
    gqlAPIServiceArguments.input.sharedGroups = groups;
  }
  if (currentState?.collections?.objects) {
    Object.keys(currentState.collections.objects).forEach((val) => {
      objects.push(currentState.collections.objects[val].id);
    });
    gqlAPIServiceArguments.input.objects = objects;
  }
  if (currentState?.collections?.email) {
    gqlAPIServiceArguments.input.email = currentState?.collections?.email;
  }
  if (currentState?.collections?.target) {
    gqlAPIServiceArguments.input.target = currentState?.collections?.target;
  }
  if (currentState?.collections?.action) {
    gqlAPIServiceArguments.input.type = currentState?.collections?.action;
  }
  if (currentState?.collections?.shareVia) {
    gqlAPIServiceArguments.input.remarks = currentState?.collections?.shareVia;
  }

  const { data } = await API.graphql({
    query: createCollection,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.createCollection;
};

/**
 *
 * @param {*} collectionData
 * @returns
 */
export const updateSearchCollection = async (collectionData) => {
  const currentState = store.getState();
  let users = [];
  let groups = [];
  let objects = [];

  const gqlAPIServiceArguments = {
    input: {
      id: currentState.collections.id,
      name: currentState.collections.name,
      createdBy: currentState.collections.createdBy,
    },
  };

  if (currentState?.collections?.title) {
    gqlAPIServiceArguments.input.title = currentState?.collections?.title;
  }

  if (
    currentState?.collections?.systemUsers &&
    currentState?.collections?.systemUsers.length
  ) {
    currentState?.collections?.systemUsers.forEach((val) => {
      if (currentState?.collections?.shareVia == "systemuser") {
        users.push(val.email);
      }
    });
    gqlAPIServiceArguments.input.sharedUsers = users;
  }
  if (
    !currentState?.collections?.systemUsers ||
    !currentState?.collections?.systemUsers.length
  ) {
    gqlAPIServiceArguments.input.sharedUsers = null;
  }

  if (
    currentState?.collections?.systemGroups &&
    currentState?.collections?.systemGroups.length
  ) {
    currentState?.collections?.systemGroups.forEach((val) => {
      if (currentState?.collections?.shareVia == "systemgroup") {
        groups.push(val.label);
      }
    });
    gqlAPIServiceArguments.input.sharedGroups = groups;
  }
  if (
    !currentState?.collections?.systemGroups ||
    !currentState?.collections?.systemGroups.length
  ) {
    gqlAPIServiceArguments.input.sharedGroups = null;
  }
  if (currentState?.collections?.objects) {
    Object.keys(currentState.collections.objects).forEach((val) => {
      objects.push(currentState.collections.objects[val].id);
    });
    gqlAPIServiceArguments.input.objects = objects;
  }
  if (currentState?.collections?.email) {
    gqlAPIServiceArguments.input.email = currentState?.collections?.email;
  }
  if (currentState?.collections?.target) {
    gqlAPIServiceArguments.input.target = currentState?.collections?.target;
  }
  if (currentState?.collections?.action) {
    gqlAPIServiceArguments.input.type = currentState?.collections?.action;
  }
  if (currentState?.collections?.shareVia) {
    gqlAPIServiceArguments.input.remarks = currentState?.collections?.shareVia;
  }

  const { data } = await API.graphql({
    query: updateCollection,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });
  return data.updateCollection;
};

/**
 * Delete
 * @param {*} roleData
 * @returns
 */
export const deleteSearchCollection = async (roleData) => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    input: {
      id: currentState.collections.id,
    },
  };

  const { data } = await API.graphql({
    query: deleteCollection,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.deleteCollection;
};

/**
 * Delete
 * @param {*}
 * @returns
 */
export const fetchCollectionsByName = async () => {
  const currentState = store.getState();
  const gqlAPIServiceArguments = {
    name: currentState.collections.name,
  };

  const { data } = await API.graphql({
    query: collectionsByName,
    variables: gqlAPIServiceArguments,
    authMode: "AWS_IAM",
  });

  return data.collectionsByName;
};

export const getUserByemail = async () => {
  let userRoles = [];
  let resultData = [];
  let finalData = {};
  let paths;
  let roles = [];
  let userData;
  let bucketBasedOnUser;
  return getUserByEmail(localStorage.getItem("userEmail")).then((user) => {
    if (user?.userByEmail?.items && user?.userByEmail?.items.length > 0) {
      roles = user?.userByEmail?.items[0]?.roles;
      userData = user?.userByEmail?.items;
      if (roles && roles.length) {
        return fetchRoleLists().then((result) => {
          if (roles && roles.length) {
            roles.forEach((val) => {
              userRoles = result.items.filter((item) => item.name === val);
            });
            userRoles.forEach((data) => {
              resultData = traverseAminRolesList(RolesDataList);
              const config = JSON.parse(data.config);
              if (config && config.paths) {
                paths = config.paths;
              }
              if (config && config.paths) {
                paths = config.paths;
              }
              if (config && config.userData) {
                bucketBasedOnUser = config.userData;
              }

              if (config && config.paths) {
                const userAccess = JSON.parse(config.userAccess);
                if (userAccess) {
                  userAccess.forEach((val) => {
                    const index = resultData.findIndex(
                      (value) => value.label === val.label
                    );
                    resultData[index] = { ...resultData[index], ...val };
                    if (
                      resultData[index].children &&
                      resultData[index].children.length
                    ) {
                      resultData[index].children.forEach((childrenVal) => {
                        const indexVal = resultData.findIndex(
                          (value) => value.label === childrenVal.label
                        );
                        if (indexVal != -1) {
                          childrenVal["checked"] = true;
                          resultData[indexVal] = {
                            ...resultData[indexVal],
                            ...childrenVal,
                          };
                        }
                        if (
                          childrenVal.children &&
                          childrenVal.children.length
                        ) {
                          childrenVal.children.forEach((children) => {
                            const indexVal = resultData.findIndex(
                              (value) => value.label === children.label
                            );
                            if (indexVal != -1) {
                              childrenVal["checked"] = true;
                              resultData[indexVal] = {
                                ...resultData[indexVal],
                                ...children,
                              };
                            }
                          });
                        }
                      });
                    }
                  });
                }
              }
            });
            finalData = {
              resultData: resultData,
              roles: roles,
              paths: paths,
              userData: userData,
              bucketBasedOnUser: bucketBasedOnUser,
            };

            localStorage.setItem("userRoles", JSON.stringify(finalData));
            return finalData;
          }
        });
      }
      finalData = {
        resultData: resultData,
        roles: roles,
        paths: paths,
        userData: userData,
        bucketBasedOnUser: bucketBasedOnUser,
      };

      localStorage.setItem("userRoles", JSON.stringify(finalData));
      return finalData;
    }
  });
};

export const traverseAminRolesList = (array) => {
  var result = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(traverseAminRolesList(a.children));
    }
  });
  return result;
};
const RolesDataList = [
  {
    label: "Search",
    tagClassName: "special",
    children: [
      {
        label: "General Search",
      },
      {
        label: "Additional Fields",
      },
      {
        label: "Date Fields",
      },
    ],
  },
  {
    label: "Results",
    children: [
      {
        label: "Download Target",
      },
      {
        label: "Download Options",
        children: [
          {
            label: "Full Size Image",
          },
          {
            label: "Low-Res Image",
          },
          {
            label: "Save a Collection",
          },
          {
            label: "Edit/Update Collection",
          },
          {
            label: "Create a PDF",
          },
          {
            label: "Compressed Zip",
          },

          {
            label: "Text Data File",
          },
          {
            label: "Update Metadata",
          },
        ],
      },
      {
        label: "Share (Delivery Method)",
        children: [
          {
            label: "Collection",
          },
          {
            label: "Share W/User",
          },
          {
            label: "Share W/Group(s)",
          },
          {
            label: "Share File",
          },
          {
            label: "Downloads File",
          },
          {
            label: "Private Link",
          },
          {
            label: "Public Link",
          },
        ],
      },
      {
        label: "Collections",
        children: [
          {
            label: "Saved",
          },
          {
            label: "Shared with Me",
          },
          {
            label: "Shared with My Groups",
          },
          {
            label: "Shared File",
          },
        ],
      },
    ],
  },
  {
    label: "Admin",
    children: [
      {
        label: "Groups",
      },
      {
        label: "Roles",
      },
      {
        label: "Users",
      },
      {
        label: "User Log",
      },
      {
        label: "Scan Req.Summary",
      }, {
        label: "DFS Req.Summary",
      }
    ],
  },
  {
    label: "Scan",
  },
  {
    label: "Upload",
    children: [
      {
        label: "Upload Destination",
      },
      {
        label: "Keywords",
      },
      {
        label: "Directory",
      },
      {
        label: "Upload Files",
      },
      {
        label: "General Search",
      },
      {
        label: "Additional Data Fields",
      },
      {
        label: "Recognize Text",
      },
      {
        label: "Recognize Objects",
      },
      {
        label: "Recognize Celebrities",
      },
      {
        label: "*Specific  Path(s)",
      },
      {
        label: "*Restrict to Path",
      },
    ],
  },
  {
    label: "Media Request Form",
  },
];
