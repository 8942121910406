import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import dialogsReducer from "./dialogs-reducer";
import uploadReducer from "./upload-reducer";
import searchFormReducer from "./search-reducer";
import adminUserFormReducer from "./admin-user-reducer";
import adminGroupFormReducer from "./admin-group-reducer";
import adminRoleFormReducer from "./admin-roles-reducer";
import collapseFormReducer from "./collapse-reducer";
import collectionFormReducer from "./collections-reducer";
import rolesListFormReducer from "./roles-list-reducer";
import forceUserFormReducer from "./force-user-change-password.reducer";
import imageListReducer from "./image-list-reducer";

export default function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    dialogs: dialogsReducer,
    upload: uploadReducer,
    search: searchFormReducer,
    adminuser: adminUserFormReducer,
    admingroup: adminGroupFormReducer,
    adminrole: adminRoleFormReducer,
    collapse: collapseFormReducer,
    collections: collectionFormReducer,
    roleslist: rolesListFormReducer,
    forceUser: forceUserFormReducer,
    imageList: imageListReducer
  });
}