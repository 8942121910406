/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String) {
    onCreateUser(owner: $owner) {
      id
      title
      firstName
      lastName
      phone
      mobile
      email
      location
      roles
      status
      securityQuestion
      securityAnswer
      lastLogin
      lastActiveMinute
      currentTab
      config
      createdAt
      updatedAt
      owner
      groups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          timestamp
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String) {
    onUpdateUser(owner: $owner) {
      id
      title
      firstName
      lastName
      phone
      mobile
      email
      location
      roles
      status
      securityQuestion
      securityAnswer
      lastLogin
      lastActiveMinute
      currentTab
      config
      createdAt
      updatedAt
      owner
      groups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          timestamp
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String) {
    onDeleteUser(owner: $owner) {
      id
      title
      firstName
      lastName
      phone
      mobile
      email
      location
      roles
      status
      securityQuestion
      securityAnswer
      lastLogin
      lastActiveMinute
      currentTab
      config
      createdAt
      updatedAt
      owner
      groups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      activities {
        items {
          id
          timestamp
          details
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup($owner: String) {
    onCreateGroup(owner: $owner) {
      id
      groupName
      description
      buckets
      dfStudioAccess
      createdAt
      updatedAt
      owner
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          create
          read
          update
          delete
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup($owner: String) {
    onUpdateGroup(owner: $owner) {
      id
      groupName
      description
      buckets
      dfStudioAccess
      createdAt
      updatedAt
      owner
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          create
          read
          update
          delete
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup($owner: String) {
    onDeleteGroup(owner: $owner) {
      id
      groupName
      description
      buckets
      dfStudioAccess
      createdAt
      updatedAt
      owner
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      permissions {
        items {
          id
          create
          read
          update
          delete
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateUserGroup = /* GraphQL */ `
  subscription OnCreateUserGroup($owner: String) {
    onCreateUserGroup(owner: $owner) {
      id
      userId
      groupId
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateUserGroup = /* GraphQL */ `
  subscription OnUpdateUserGroup($owner: String) {
    onUpdateUserGroup(owner: $owner) {
      id
      userId
      groupId
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteUserGroup = /* GraphQL */ `
  subscription OnDeleteUserGroup($owner: String) {
    onDeleteUserGroup(owner: $owner) {
      id
      userId
      groupId
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreatePermission = /* GraphQL */ `
  subscription OnCreatePermission($owner: String) {
    onCreatePermission(owner: $owner) {
      id
      create
      read
      update
      delete
      createdAt
      updatedAt
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePermission = /* GraphQL */ `
  subscription OnUpdatePermission($owner: String) {
    onUpdatePermission(owner: $owner) {
      id
      create
      read
      update
      delete
      createdAt
      updatedAt
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePermission = /* GraphQL */ `
  subscription OnDeletePermission($owner: String) {
    onDeletePermission(owner: $owner) {
      id
      create
      read
      update
      delete
      createdAt
      updatedAt
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateRole = /* GraphQL */ `
  subscription OnCreateRole($owner: String) {
    onCreateRole(owner: $owner) {
      id
      name
      description
      parentRole
      config
      idletime
      user
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateRole = /* GraphQL */ `
  subscription OnUpdateRole($owner: String) {
    onUpdateRole(owner: $owner) {
      id
      name
      description
      parentRole
      config
      idletime
      user
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteRole = /* GraphQL */ `
  subscription OnDeleteRole($owner: String) {
    onDeleteRole(owner: $owner) {
      id
      name
      description
      parentRole
      config
      idletime
      user
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateSettings = /* GraphQL */ `
  subscription OnCreateSettings($owner: String) {
    onCreateSettings(owner: $owner) {
      id
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      name
      values
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateSettings = /* GraphQL */ `
  subscription OnUpdateSettings($owner: String) {
    onUpdateSettings(owner: $owner) {
      id
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      name
      values
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteSettings = /* GraphQL */ `
  subscription OnDeleteSettings($owner: String) {
    onDeleteSettings(owner: $owner) {
      id
      group {
        id
        groupName
        description
        buckets
        dfStudioAccess
        createdAt
        updatedAt
        owner
        users {
          nextToken
          __typename
        }
        permissions {
          nextToken
          __typename
        }
        __typename
      }
      name
      values
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateActivity = /* GraphQL */ `
  subscription OnCreateActivity($owner: String) {
    onCreateActivity(owner: $owner) {
      id
      timestamp
      details
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateActivity = /* GraphQL */ `
  subscription OnUpdateActivity($owner: String) {
    onUpdateActivity(owner: $owner) {
      id
      timestamp
      details
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteActivity = /* GraphQL */ `
  subscription OnDeleteActivity($owner: String) {
    onDeleteActivity(owner: $owner) {
      id
      timestamp
      details
      createdAt
      updatedAt
      user {
        id
        title
        firstName
        lastName
        phone
        mobile
        email
        location
        roles
        status
        securityQuestion
        securityAnswer
        lastLogin
        lastActiveMinute
        currentTab
        config
        createdAt
        updatedAt
        owner
        groups {
          nextToken
          __typename
        }
        activities {
          nextToken
          __typename
        }
        __typename
      }
      owner
      page {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCollection = /* GraphQL */ `
  subscription OnCreateCollection($owner: String) {
    onCreateCollection(owner: $owner) {
      id
      name
      email
      createdBy
      sharedUsers
      sharedGroups
      objects
      type
      target
      expiry
      publicUrl
      remarks
      title
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateCollection = /* GraphQL */ `
  subscription OnUpdateCollection($owner: String) {
    onUpdateCollection(owner: $owner) {
      id
      name
      email
      createdBy
      sharedUsers
      sharedGroups
      objects
      type
      target
      expiry
      publicUrl
      remarks
      title
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteCollection = /* GraphQL */ `
  subscription OnDeleteCollection($owner: String) {
    onDeleteCollection(owner: $owner) {
      id
      name
      email
      createdBy
      sharedUsers
      sharedGroups
      objects
      type
      target
      expiry
      publicUrl
      remarks
      title
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreatePage = /* GraphQL */ `
  subscription OnCreatePage($owner: String) {
    onCreatePage(owner: $owner) {
      id
      order
      url
      title
      icon
      active
      moduleId
      createdAt
      updatedAt
      owner
      module {
        id
        order
        name
        lastUsedPage {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        icon
        active
        createdAt
        updatedAt
        pages {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePage = /* GraphQL */ `
  subscription OnUpdatePage($owner: String) {
    onUpdatePage(owner: $owner) {
      id
      order
      url
      title
      icon
      active
      moduleId
      createdAt
      updatedAt
      owner
      module {
        id
        order
        name
        lastUsedPage {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        icon
        active
        createdAt
        updatedAt
        pages {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePage = /* GraphQL */ `
  subscription OnDeletePage($owner: String) {
    onDeletePage(owner: $owner) {
      id
      order
      url
      title
      icon
      active
      moduleId
      createdAt
      updatedAt
      owner
      module {
        id
        order
        name
        lastUsedPage {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        icon
        active
        createdAt
        updatedAt
        pages {
          nextToken
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`;
export const onCreateModule = /* GraphQL */ `
  subscription OnCreateModule($owner: String) {
    onCreateModule(owner: $owner) {
      id
      order
      name
      lastUsedPage {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      icon
      active
      createdAt
      updatedAt
      pages {
        items {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateModule = /* GraphQL */ `
  subscription OnUpdateModule($owner: String) {
    onUpdateModule(owner: $owner) {
      id
      order
      name
      lastUsedPage {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      icon
      active
      createdAt
      updatedAt
      pages {
        items {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteModule = /* GraphQL */ `
  subscription OnDeleteModule($owner: String) {
    onDeleteModule(owner: $owner) {
      id
      order
      name
      lastUsedPage {
        id
        order
        url
        title
        icon
        active
        moduleId
        createdAt
        updatedAt
        owner
        module {
          id
          order
          name
          icon
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        __typename
      }
      icon
      active
      createdAt
      updatedAt
      pages {
        items {
          id
          order
          url
          title
          icon
          active
          moduleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateNetwork = /* GraphQL */ `
  subscription OnCreateNetwork($owner: String) {
    onCreateNetwork(owner: $owner) {
      id
      name
      description
      order
      image
      link
      active
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateNetwork = /* GraphQL */ `
  subscription OnUpdateNetwork($owner: String) {
    onUpdateNetwork(owner: $owner) {
      id
      name
      description
      order
      image
      link
      active
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteNetwork = /* GraphQL */ `
  subscription OnDeleteNetwork($owner: String) {
    onDeleteNetwork(owner: $owner) {
      id
      name
      description
      order
      image
      link
      active
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          name
          description
          order
          image
          link
          active
          networkId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateShow = /* GraphQL */ `
  subscription OnCreateShow($owner: String) {
    onCreateShow(owner: $owner) {
      id
      name
      description
      order
      image
      link
      active
      networkId
      createdAt
      updatedAt
      network {
        id
        name
        description
        order
        image
        link
        active
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      dayParts {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pictures {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateShow = /* GraphQL */ `
  subscription OnUpdateShow($owner: String) {
    onUpdateShow(owner: $owner) {
      id
      name
      description
      order
      image
      link
      active
      networkId
      createdAt
      updatedAt
      network {
        id
        name
        description
        order
        image
        link
        active
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      dayParts {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pictures {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteShow = /* GraphQL */ `
  subscription OnDeleteShow($owner: String) {
    onDeleteShow(owner: $owner) {
      id
      name
      description
      order
      image
      link
      active
      networkId
      createdAt
      updatedAt
      network {
        id
        name
        description
        order
        image
        link
        active
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      dayParts {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      pictures {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDaypart = /* GraphQL */ `
  subscription OnCreateDaypart($owner: String) {
    onCreateDaypart(owner: $owner) {
      id
      name
      description
      active
      order
      image
      link
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDaypart = /* GraphQL */ `
  subscription OnUpdateDaypart($owner: String) {
    onUpdateDaypart(owner: $owner) {
      id
      name
      description
      active
      order
      image
      link
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteDaypart = /* GraphQL */ `
  subscription OnDeleteDaypart($owner: String) {
    onDeleteDaypart(owner: $owner) {
      id
      name
      description
      active
      order
      image
      link
      createdAt
      updatedAt
      owner
      shows {
        items {
          id
          showId
          daypartId
          showOrder
          daypartOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateShowDaypart = /* GraphQL */ `
  subscription OnCreateShowDaypart($owner: String) {
    onCreateShowDaypart(owner: $owner) {
      id
      showId
      daypartId
      showOrder
      daypartOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      daypart {
        id
        name
        description
        active
        order
        image
        link
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateShowDaypart = /* GraphQL */ `
  subscription OnUpdateShowDaypart($owner: String) {
    onUpdateShowDaypart(owner: $owner) {
      id
      showId
      daypartId
      showOrder
      daypartOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      daypart {
        id
        name
        description
        active
        order
        image
        link
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteShowDaypart = /* GraphQL */ `
  subscription OnDeleteShowDaypart($owner: String) {
    onDeleteShowDaypart(owner: $owner) {
      id
      showId
      daypartId
      showOrder
      daypartOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      daypart {
        id
        name
        description
        active
        order
        image
        link
        createdAt
        updatedAt
        owner
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreatePicture = /* GraphQL */ `
  subscription OnCreatePicture($owner: String) {
    onCreatePicture(owner: $owner) {
      id
      name
      description
      image
      createdAt
      updatedAt
      owner
      show {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePicture = /* GraphQL */ `
  subscription OnUpdatePicture($owner: String) {
    onUpdatePicture(owner: $owner) {
      id
      name
      description
      image
      createdAt
      updatedAt
      owner
      show {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePicture = /* GraphQL */ `
  subscription OnDeletePicture($owner: String) {
    onDeletePicture(owner: $owner) {
      id
      name
      description
      image
      createdAt
      updatedAt
      owner
      show {
        items {
          id
          showId
          pictureId
          showOrder
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateShowPicture = /* GraphQL */ `
  subscription OnCreateShowPicture($owner: String) {
    onCreateShowPicture(owner: $owner) {
      id
      showId
      pictureId
      showOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateShowPicture = /* GraphQL */ `
  subscription OnUpdateShowPicture($owner: String) {
    onUpdateShowPicture(owner: $owner) {
      id
      showId
      pictureId
      showOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteShowPicture = /* GraphQL */ `
  subscription OnDeleteShowPicture($owner: String) {
    onDeleteShowPicture(owner: $owner) {
      id
      showId
      pictureId
      showOrder
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateKeyword = /* GraphQL */ `
  subscription OnCreateKeyword($owner: String) {
    onCreateKeyword(owner: $owner) {
      id
      keyword
      description
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateKeyword = /* GraphQL */ `
  subscription OnUpdateKeyword($owner: String) {
    onUpdateKeyword(owner: $owner) {
      id
      keyword
      description
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteKeyword = /* GraphQL */ `
  subscription OnDeleteKeyword($owner: String) {
    onDeleteKeyword(owner: $owner) {
      id
      keyword
      description
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      locations {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      people {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePictureKeyword = /* GraphQL */ `
  subscription OnCreatePictureKeyword($owner: String) {
    onCreatePictureKeyword(owner: $owner) {
      id
      pictureId
      keywordId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdatePictureKeyword = /* GraphQL */ `
  subscription OnUpdatePictureKeyword($owner: String) {
    onUpdatePictureKeyword(owner: $owner) {
      id
      pictureId
      keywordId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeletePictureKeyword = /* GraphQL */ `
  subscription OnDeletePictureKeyword($owner: String) {
    onDeletePictureKeyword(owner: $owner) {
      id
      pictureId
      keywordId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation($owner: String) {
    onCreateLocation(owner: $owner) {
      id
      name
      description
      address
      long
      lat
      image
      link
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation($owner: String) {
    onUpdateLocation(owner: $owner) {
      id
      name
      description
      address
      long
      lat
      image
      link
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation($owner: String) {
    onDeleteLocation(owner: $owner) {
      id
      name
      description
      address
      long
      lat
      image
      link
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          pictureId
          locationId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      keywords {
        items {
          id
          locationId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePictureLocation = /* GraphQL */ `
  subscription OnCreatePictureLocation($owner: String) {
    onCreatePictureLocation(owner: $owner) {
      id
      pictureId
      locationId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdatePictureLocation = /* GraphQL */ `
  subscription OnUpdatePictureLocation($owner: String) {
    onUpdatePictureLocation(owner: $owner) {
      id
      pictureId
      locationId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeletePictureLocation = /* GraphQL */ `
  subscription OnDeletePictureLocation($owner: String) {
    onDeletePictureLocation(owner: $owner) {
      id
      pictureId
      locationId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateLocationKeyword = /* GraphQL */ `
  subscription OnCreateLocationKeyword($owner: String) {
    onCreateLocationKeyword(owner: $owner) {
      id
      locationId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateLocationKeyword = /* GraphQL */ `
  subscription OnUpdateLocationKeyword($owner: String) {
    onUpdateLocationKeyword(owner: $owner) {
      id
      locationId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteLocationKeyword = /* GraphQL */ `
  subscription OnDeleteLocationKeyword($owner: String) {
    onDeleteLocationKeyword(owner: $owner) {
      id
      locationId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      location {
        id
        name
        description
        address
        long
        lat
        image
        link
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreatePeople = /* GraphQL */ `
  subscription OnCreatePeople($owner: String) {
    onCreatePeople(owner: $owner) {
      id
      name
      nickName
      description
      date
      links
      image
      notes
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Keywords {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shows {
        items {
          id
          showId
          showOrder
          peopleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePeople = /* GraphQL */ `
  subscription OnUpdatePeople($owner: String) {
    onUpdatePeople(owner: $owner) {
      id
      name
      nickName
      description
      date
      links
      image
      notes
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Keywords {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shows {
        items {
          id
          showId
          showOrder
          peopleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePeople = /* GraphQL */ `
  subscription OnDeletePeople($owner: String) {
    onDeletePeople(owner: $owner) {
      id
      name
      nickName
      description
      date
      links
      image
      notes
      createdAt
      updatedAt
      owner
      pictures {
        items {
          id
          peopleId
          pictureId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      Keywords {
        items {
          id
          peopleId
          keywordId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      shows {
        items {
          id
          showId
          showOrder
          peopleId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePeoplePicture = /* GraphQL */ `
  subscription OnCreatePeoplePicture($owner: String) {
    onCreatePeoplePicture(owner: $owner) {
      id
      peopleId
      pictureId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdatePeoplePicture = /* GraphQL */ `
  subscription OnUpdatePeoplePicture($owner: String) {
    onUpdatePeoplePicture(owner: $owner) {
      id
      peopleId
      pictureId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeletePeoplePicture = /* GraphQL */ `
  subscription OnDeletePeoplePicture($owner: String) {
    onDeletePeoplePicture(owner: $owner) {
      id
      peopleId
      pictureId
      createdAt
      updatedAt
      picture {
        id
        name
        description
        image
        createdAt
        updatedAt
        owner
        show {
          nextToken
          __typename
        }
        keywords {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreatePeopleKeyword = /* GraphQL */ `
  subscription OnCreatePeopleKeyword($owner: String) {
    onCreatePeopleKeyword(owner: $owner) {
      id
      peopleId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdatePeopleKeyword = /* GraphQL */ `
  subscription OnUpdatePeopleKeyword($owner: String) {
    onUpdatePeopleKeyword(owner: $owner) {
      id
      peopleId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeletePeopleKeyword = /* GraphQL */ `
  subscription OnDeletePeopleKeyword($owner: String) {
    onDeletePeopleKeyword(owner: $owner) {
      id
      peopleId
      keywordId
      createdAt
      updatedAt
      keyword {
        id
        keyword
        description
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        locations {
          nextToken
          __typename
        }
        people {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateShowPeople = /* GraphQL */ `
  subscription OnCreateShowPeople($owner: String) {
    onCreateShowPeople(owner: $owner) {
      id
      showId
      showOrder
      peopleId
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onUpdateShowPeople = /* GraphQL */ `
  subscription OnUpdateShowPeople($owner: String) {
    onUpdateShowPeople(owner: $owner) {
      id
      showId
      showOrder
      peopleId
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onDeleteShowPeople = /* GraphQL */ `
  subscription OnDeleteShowPeople($owner: String) {
    onDeleteShowPeople(owner: $owner) {
      id
      showId
      showOrder
      peopleId
      createdAt
      updatedAt
      show {
        id
        name
        description
        order
        image
        link
        active
        networkId
        createdAt
        updatedAt
        network {
          id
          name
          description
          order
          image
          link
          active
          createdAt
          updatedAt
          owner
          __typename
        }
        owner
        dayParts {
          nextToken
          __typename
        }
        pictures {
          nextToken
          __typename
        }
        __typename
      }
      people {
        id
        name
        nickName
        description
        date
        links
        image
        notes
        createdAt
        updatedAt
        owner
        pictures {
          nextToken
          __typename
        }
        Keywords {
          nextToken
          __typename
        }
        shows {
          nextToken
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`;
export const onCreateMetaData = /* GraphQL */ `
  subscription OnCreateMetaData {
    onCreateMetaData {
      id
      bucket
      mediaFilename
      mediaDesciption
      fullFileName
      name
      network
      show
      seasonNo
      daypart
      episodeName
      job
      thumbnail
      format
      people
      city
      country
      region
      fileType
      type
      category
      imageAttributesData
      cameraAttributesData
      exif
      exifDump
      day
      year
      keywords
      notes
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      transferRequestId
      scanRequestId
      timestamp
      error
      clickTime
      reindex
      dfsName
      dfsId
      dfsProject
      dfsSetup
      dfsBadge
      description
      personInImage
      captionAbstract
      specialInstructions
      copyrightNotice
      credit
      source
      captionWriter
      headline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMetaData = /* GraphQL */ `
  subscription OnUpdateMetaData {
    onUpdateMetaData {
      id
      bucket
      mediaFilename
      mediaDesciption
      fullFileName
      name
      network
      show
      seasonNo
      daypart
      episodeName
      job
      thumbnail
      format
      people
      city
      country
      region
      fileType
      type
      category
      imageAttributesData
      cameraAttributesData
      exif
      exifDump
      day
      year
      keywords
      notes
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      transferRequestId
      scanRequestId
      timestamp
      error
      clickTime
      reindex
      dfsName
      dfsId
      dfsProject
      dfsSetup
      dfsBadge
      description
      personInImage
      captionAbstract
      specialInstructions
      copyrightNotice
      credit
      source
      captionWriter
      headline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMetaData = /* GraphQL */ `
  subscription OnDeleteMetaData {
    onDeleteMetaData {
      id
      bucket
      mediaFilename
      mediaDesciption
      fullFileName
      name
      network
      show
      seasonNo
      daypart
      episodeName
      job
      thumbnail
      format
      people
      city
      country
      region
      fileType
      type
      category
      imageAttributesData
      cameraAttributesData
      exif
      exifDump
      day
      year
      keywords
      notes
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      transferRequestId
      scanRequestId
      timestamp
      error
      clickTime
      reindex
      dfsName
      dfsId
      dfsProject
      dfsSetup
      dfsBadge
      description
      personInImage
      captionAbstract
      specialInstructions
      copyrightNotice
      credit
      source
      captionWriter
      headline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateS3Events = /* GraphQL */ `
  subscription OnCreateS3Events($owner: String) {
    onCreateS3Events(owner: $owner) {
      id
      timestamp
      eventTime
      principalId
      sourceIPAddress
      bucketName
      bucketArn
      objectKey
      objectSize
      eTag
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateS3Events = /* GraphQL */ `
  subscription OnUpdateS3Events($owner: String) {
    onUpdateS3Events(owner: $owner) {
      id
      timestamp
      eventTime
      principalId
      sourceIPAddress
      bucketName
      bucketArn
      objectKey
      objectSize
      eTag
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteS3Events = /* GraphQL */ `
  subscription OnDeleteS3Events($owner: String) {
    onDeleteS3Events(owner: $owner) {
      id
      timestamp
      eventTime
      principalId
      sourceIPAddress
      bucketName
      bucketArn
      objectKey
      objectSize
      eTag
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateAsperaTransferLog = /* GraphQL */ `
  subscription OnCreateAsperaTransferLog($owner: String) {
    onCreateAsperaTransferLog(owner: $owner) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      s3Object {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateAsperaTransferLog = /* GraphQL */ `
  subscription OnUpdateAsperaTransferLog($owner: String) {
    onUpdateAsperaTransferLog(owner: $owner) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      s3Object {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteAsperaTransferLog = /* GraphQL */ `
  subscription OnDeleteAsperaTransferLog($owner: String) {
    onDeleteAsperaTransferLog(owner: $owner) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      s3Object {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateAsperaTransferSummary = /* GraphQL */ `
  subscription OnCreateAsperaTransferSummary($owner: String) {
    onCreateAsperaTransferSummary(owner: $owner) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      attempted
      completed
      skipped
      failed
      finalStatus
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateAsperaTransferSummary = /* GraphQL */ `
  subscription OnUpdateAsperaTransferSummary($owner: String) {
    onUpdateAsperaTransferSummary(owner: $owner) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      attempted
      completed
      skipped
      failed
      finalStatus
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteAsperaTransferSummary = /* GraphQL */ `
  subscription OnDeleteAsperaTransferSummary($owner: String) {
    onDeleteAsperaTransferSummary(owner: $owner) {
      id
      timestamp
      sessionId
      requestId
      direction
      page
      userId
      attempted
      completed
      skipped
      failed
      finalStatus
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateRekogLog = /* GraphQL */ `
  subscription OnCreateRekogLog($owner: String) {
    onCreateRekogLog(owner: $owner) {
      id
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      rekogResult
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateRekogLog = /* GraphQL */ `
  subscription OnUpdateRekogLog($owner: String) {
    onUpdateRekogLog(owner: $owner) {
      id
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      rekogResult
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteRekogLog = /* GraphQL */ `
  subscription OnDeleteRekogLog($owner: String) {
    onDeleteRekogLog(owner: $owner) {
      id
      awsS3FileLocation {
        bucket
        key
        region
        url
        size
        mimeType
        __typename
      }
      rekogResult
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateAsperaTransferRequest = /* GraphQL */ `
  subscription OnCreateAsperaTransferRequest($owner: String) {
    onCreateAsperaTransferRequest(owner: $owner) {
      id
      user
      requestId
      timestamp
      sessionId
      direction
      bucket
      destinationFolder
      sourceFiles
      completion
      network
      daypart
      show
      year
      job
      name
      people
      city
      country
      region
      category
      fileType
      type
      textRekog
      objectRekog
      celebrityRekog
      notes
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateAsperaTransferRequest = /* GraphQL */ `
  subscription OnUpdateAsperaTransferRequest($owner: String) {
    onUpdateAsperaTransferRequest(owner: $owner) {
      id
      user
      requestId
      timestamp
      sessionId
      direction
      bucket
      destinationFolder
      sourceFiles
      completion
      network
      daypart
      show
      year
      job
      name
      people
      city
      country
      region
      category
      fileType
      type
      textRekog
      objectRekog
      celebrityRekog
      notes
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteAsperaTransferRequest = /* GraphQL */ `
  subscription OnDeleteAsperaTransferRequest($owner: String) {
    onDeleteAsperaTransferRequest(owner: $owner) {
      id
      user
      requestId
      timestamp
      sessionId
      direction
      bucket
      destinationFolder
      sourceFiles
      completion
      network
      daypart
      show
      year
      job
      name
      people
      city
      country
      region
      category
      fileType
      type
      textRekog
      objectRekog
      celebrityRekog
      notes
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateScanAnalysisLog = /* GraphQL */ `
  subscription OnCreateScanAnalysisLog {
    onCreateScanAnalysisLog {
      id
      bucket
      folder
      requestId
      searchedCount
      eligibleCount
      cumulativeEligibleCount
      nextS3Key
      nextToken
      limit
      error
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateScanAnalysisLog = /* GraphQL */ `
  subscription OnUpdateScanAnalysisLog {
    onUpdateScanAnalysisLog {
      id
      bucket
      folder
      requestId
      searchedCount
      eligibleCount
      cumulativeEligibleCount
      nextS3Key
      nextToken
      limit
      error
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteScanAnalysisLog = /* GraphQL */ `
  subscription OnDeleteScanAnalysisLog {
    onDeleteScanAnalysisLog {
      id
      bucket
      folder
      requestId
      searchedCount
      eligibleCount
      cumulativeEligibleCount
      nextS3Key
      nextToken
      limit
      error
      user
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateScanRequest = /* GraphQL */ `
  subscription OnCreateScanRequest {
    onCreateScanRequest {
      id
      requestId
      scanRequestDetails
      user
      status
      error
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateScanRequest = /* GraphQL */ `
  subscription OnUpdateScanRequest {
    onUpdateScanRequest {
      id
      requestId
      scanRequestDetails
      user
      status
      error
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteScanRequest = /* GraphQL */ `
  subscription OnDeleteScanRequest {
    onDeleteScanRequest {
      id
      requestId
      scanRequestDetails
      user
      status
      error
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDfsPullRequest = /* GraphQL */ `
  subscription OnCreateDfsPullRequest {
    onCreateDfsPullRequest {
      id
      requestId
      dfsPullRequestDetails
      user
      status
      error
      nextOffset
      nextToken
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDfsPullRequest = /* GraphQL */ `
  subscription OnUpdateDfsPullRequest {
    onUpdateDfsPullRequest {
      id
      requestId
      dfsPullRequestDetails
      user
      status
      error
      nextOffset
      nextToken
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDfsPullRequest = /* GraphQL */ `
  subscription OnDeleteDfsPullRequest {
    onDeleteDfsPullRequest {
      id
      requestId
      dfsPullRequestDetails
      user
      status
      error
      nextOffset
      nextToken
      retryFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateScanRequestSummary = /* GraphQL */ `
  subscription OnCreateScanRequestSummary($owner: String) {
    onCreateScanRequestSummary(owner: $owner) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateScanRequestSummary = /* GraphQL */ `
  subscription OnUpdateScanRequestSummary($owner: String) {
    onUpdateScanRequestSummary(owner: $owner) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteScanRequestSummary = /* GraphQL */ `
  subscription OnDeleteScanRequestSummary($owner: String) {
    onDeleteScanRequestSummary(owner: $owner) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateDfsPullRequestSummary = /* GraphQL */ `
  subscription OnCreateDfsPullRequestSummary($owner: String) {
    onCreateDfsPullRequestSummary(owner: $owner) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateDfsPullRequestSummary = /* GraphQL */ `
  subscription OnUpdateDfsPullRequestSummary($owner: String) {
    onUpdateDfsPullRequestSummary(owner: $owner) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteDfsPullRequestSummary = /* GraphQL */ `
  subscription OnDeleteDfsPullRequestSummary($owner: String) {
    onDeleteDfsPullRequestSummary(owner: $owner) {
      id
      requestId
      user
      requestCount
      completionCount
      success
      failed
      retryFor
      emailSent
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateEventTrigger = /* GraphQL */ `
  subscription OnCreateEventTrigger($owner: String) {
    onCreateEventTrigger(owner: $owner) {
      id
      eventDetails
      nextTrigger
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateEventTrigger = /* GraphQL */ `
  subscription OnUpdateEventTrigger($owner: String) {
    onUpdateEventTrigger(owner: $owner) {
      id
      eventDetails
      nextTrigger
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteEventTrigger = /* GraphQL */ `
  subscription OnDeleteEventTrigger($owner: String) {
    onDeleteEventTrigger(owner: $owner) {
      id
      eventDetails
      nextTrigger
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
