import { createAction, createReducer } from '@reduxjs/toolkit';

export const forceUserSetFieldValues = createAction('FORCE_USER_SET_FIELD_VALUE');
export const forceUserResetValues = createAction('FORCE_USER_RESET_VALUES');

const defaultState = {
  user: {}
}

const forceUserFormReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(forceUserSetFieldValues, (state, { payload }) => {
      Object.assign(state, payload);
      return state;
    }).addCase(forceUserResetValues, () => ({
      ...defaultState,
    }));
});

export default forceUserFormReducer;
