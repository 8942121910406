import Avatar from '@material-ui/core/Avatar';
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import sidebarStyle from "./SidebarNewStyles.js";
import avatarImage from '../../assets/avatar.png';
import SearchLeftSideBar from 'components/search/SearchLeftSideBar.js';
import UploadLeftSideBar from 'components/upload/UploadLeftSideBar.js';
import ScanLeftSideBar from 'components/scan/ScanLeftSideBar.js';
import SecureRoute from '../secure-route/SecureRoute';

const useStyles = makeStyles(sidebarStyle);

const SideBarRoutes = () => {
  return <Switch>
    <SecureRoute path='/upload' component={UploadLeftSideBar} />
    <SecureRoute path='/scan' component={ScanLeftSideBar} />
    <SecureRoute path='/search/progress' component={UploadLeftSideBar} />
    <Redirect path="/" to="/search" exact />
    <SecureRoute component={SearchLeftSideBar} />
  </Switch>
}

const SideBarNew = props => {
  const classes = useStyles();

  const handleMenuChange = (prop) => {
    props.handleSidebarComponent(prop.name);
  }
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes } = props;
  var text = props.value;
  
  let logoImage = classes.logoImage;
  let imgBiz = classes.img;
  if (props.bisdamz) {

    logoImage = `${classes.logoImageBiz} ${classes.logoImage}`;
    imgBiz = `${classes.imgBiz} ${classes.img}`;

  }
  const client = localStorage.getItem("currentClient");
  let versionText = "TWDC DA";
  if (client === "bizdamz") {
    versionText = "DAMz";
  }
  //Links Component
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
       
        var activePro = " ";
        var listItemClasses;
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path)
        });
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });
       
        if (text == "init") {
          text = "0";
        }
        let sideMenus = (prop.value == text);
        return (sideMenus &&
          <NavLink
            to={prop.layout + prop.path}
            className={classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem button
              className={classes.itemLink + listItemClasses}
              onClick={() => handleMenuChange(prop)}
            >
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                />
              )}
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );

  //Brand Logo Component

  var brand = (
    <div className={classes.logo}>
      <a
        className={classNames(classes.logoLink)}
      >
        <div className={logoImage}>
          <img src={logo} alt="logo" className={imgBiz} />
        </div>
        <div>
          <span className={classes.logoText}>{logoText}</span>
        </div>
      </a>
    </div>
  );


  // Avatar image
  var avatar1 = (
    <div className={classes.logo}>
      <div className={classes.logoImage}>
        <div className={classes.avatarStyle}>
          <Avatar alt="Avatar" src={avatarImage} />
        </div>
      </div>
      <span className={classes.logoText}>{logoText}</span>
    </div>
  );

  var avatar = (
    <div className={classes.logo}>
      <a
        className={classNames(classes.logoLink)}
      >
        <div>
          <span className={classes.logoText}>{logoText}</span>
        </div>
      </a>
    </div>
  );

  //Return component
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <div className={classes.imageBox} >
            {brand}
          </div>

          <div className={classes.sidebarWrapper}>
            <SideBarRoutes />
            {links}
          </div>

          <div
            className={classes.background}
          />
        </Drawer>

      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper)
          }}
        >
          <div className={classes.imageBox} >
            {brand}
          </div>

          <div className={classes.sidebarWrapper}>
            <SideBarRoutes />
            {links}
            <div className={classes.footerContainer}>
              <span className={classes.appVersion}>APP :  {versionText} v2.1.2 Beta</span>
              <span className={classes.asperaVersion}>Aspera :  4.2.2.136  & Above</span>
            </div>
          </div>
          <div
            className={classes.background}
          // style={{ backgroundImage: "url(" + image + ")" }}
          />
        </Drawer>
      </Hidden>
    </div>
  );

}



export default SideBarNew;