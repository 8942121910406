import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.background.default
  },
  icon: {
    color: theme.palette.background.default
  },
}));

export default function SidebarNavItem({ to, label, Icon, matchExtact, onClick }) {
  const classes = useStyles();
  const isActiveRoute = useRouteMatch(to);
  return <ListItem component={NavLink} to={to} selected={isActiveRoute && (!matchExtact || isActiveRoute.isExact)} className={classes.root} button onClick={onClick}>
    {Icon && <ListItemIcon className={classes.icon}><Icon /></ListItemIcon>}
    <ListItemText primary={label} />
  </ListItem>
}