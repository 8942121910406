import React, { useCallback, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import GridItem from "../grid/GridItem";
import Button from "../button/Button";
import GridContainer from "../grid/GridContainer";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import CardBody from "../card/CardBody";
import CardFooter from "../card/CardFooter";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from "@material-ui/core/Select";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchForm, { SearchBucketInput } from '../common/SearchForm';
import { createId } from "service/util.service";
import AssetBrowser from "components/aspera-connect/AssetBrowser";
import { useSelector } from "react-redux";
import { searchResetValues, searchSetFieldValue } from "store/search-reducer";
import { dispatch, goTo, store } from "redux-store";
import invoke from "service/lambda.service";
import { apiSearchMetaDatas } from "service/api.service";
import { getUserByemail } from "service/api.service";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import config from '../../aws-exports';
import { awsConfig } from '../../config';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import { useAppUserContext } from "lib/contextLib";
import {
  collapseResetValues,
} from "store/collapse-reducer";

const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      padding: theme.spacing(2),
    },
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    },
    formControl: {
      minWidth: 120,
    },
    typo: {
      marginBottom: "40px",
      position: "relative"
    },
  }),
);
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const checkIfAlreadyIndexed = async (bucket, data) => {

  const filter = {
    and:
      [
        {
          bucket: { match: bucket }
        },
        {
          or: data.map((item) => ({ mediaFilename: { eq: item.Key } }))
        }
      ]
  };
  const eligibleData = [];
  const matchedData = [];
  const resp = await apiSearchMetaDatas(filter)
  if (resp.items.length === 0) {

    data.forEach((item) => {
      if (!item.Key.match(/.(ai|numbers|xlsx|DS_Store)$/)) {
        eligibleData.push(item);
      } else {
        matchedData.push(item)
      }
    });
  } else {
    data.forEach((item) => {
      const metaObj = resp.items.find(o => o.mediaFilename === item.Key);
      /**
       * 07Dec2022: Changed as we do not want rekognition / savaed-upload-name as a critieria for scan eligibility.
       */
      // if ((!metaObj || !metaObj.thumbnail || !metaObj.keywords || !metaObj.name || !metaObj.exif || metaObj.exif.length === 0)
      //     && !item.Key.match(/.(ai|numbers|xlsx|DS_Store)$/)) {
      if ((!metaObj || !metaObj.thumbnail || !metaObj.exif || metaObj.exif.length === 0)
        && !item.Key.match(/.(ai|numbers|xlsx|DS_Store)$/)) {
        eligibleData.push(item);
      } else {
        matchedData.push(item)
      }
    });
  }

  return [eligibleData, matchedData];
}

export default function Scan() {
  const classes = useStyles();
  const selectedBucket = useSelector(state => state.search.bucket);
  const [selectedDir, setSelectedDir] = useState('/');
  const [scanLimit, setScanLimit] = useState(1000);
  const [showAssetBrowser, setShowAssetBrowser] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [analyzeStatus, setAnalyzeStatus] = useState({
    message: '',
    objects: [],
    notEligible: [],
  })
  const [transferId] = useState(createId('Scan'));
  const [showScan, setShowScan] = useState(false);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });

  const { vertical, horizontal, open, severity, message } = state;
  const [scanRequestSubmitted, setScanRequestSubmitted] = useState(false);
  const currentUser = useAppUserContext();

  useEffect(() => {
    dispatch(collapseResetValues());
    getUserByemail().then((finalData) => {
      if (finalData)
        enableScans(finalData);
    });
  }, []);
  let oldKeyword = '';
  const SearchKeywordsInput = ({ label }) => {
    const value = useSelector(state => state.search.keywords);
    return <TextField value={value} onChange={handleInputChange} onBlur={handleInputBlur} className="input-style" name="keywords"
      id="keywords" label='Keywords' variant="outlined" fullWidth />
  }

  const handleInputBlur = (e) => {
    if (e.target.name === "keywords") {
      oldKeyword += e.target.value;
    }
  }
  const enableScans = (userRolesData) => {
    const adminRole = userRolesData;
    if (adminRole.userData && adminRole.userData.length) {
      if (adminRole.userData[0].status.toLowerCase() === "pending" || (adminRole.userData[0].status.toLowerCase() !== "pending" && !adminRole.roles[0])) {
        setState({
          ...state,
          open: true,
          message: "“Your permissions must be configured by system admin,please try again later”!",
          severity: "error",
        });
        return
      }
    }
    if (adminRole && adminRole.roles) {
      if (adminRole.roles.indexOf("Super Admin") !== -1) {
        setShowScan(true);
      }
    }

    if (adminRole && adminRole.resultData && adminRole.resultData.length) {
      adminRole.resultData.forEach((val) => {
        if (val.path === "3") {
          setShowScan(true);
        }
      })
    }
  }
  useEffect(() => {
    dispatch(searchResetValues());
    return () => {
      dispatch(searchSetFieldValue({ celebrityRekog: false, objectRekog: false, textRekog: false, photo_type: '' }));
    }
  }, []);

  const analyze = useCallback(async (bucket, prefix = '/', limit = 1000, analyzed = 0, batch = [], startAfter = null, notifier = null) => {
    const s3Request = {
      activity: 'list-bucket-folder', data: {
        region: config.aws_project_region,
        bucket: bucket,
        prefix: prefix,
        startAfter: startAfter,
        maxKeys: 1000, //---do not read more than 1000 at a time.
      }
    };
    const resp = await invoke('wdtvamtoolS3Utils-' + awsConfig.environment, s3Request, currentUser.creds);
    const resBody = JSON.parse(resp['Payload']).body;
    if (resp.error) {
      console.error(resp.error);
    } else {
      if (resBody.Contents && resBody.Contents.length > 0) {
        const s3Objects = resBody.Contents.filter(o => o.Size);
        let i, chunk = 100;
        for (i = 0; i < s3Objects.length; i += chunk) {
          const contentsToCheck = s3Objects.slice(i, i + chunk);
          analyzed += contentsToCheck.length;
          const [notMatchedData, matchedData] = await checkIfAlreadyIndexed(bucket, contentsToCheck);
          notMatchedData.forEach((item) => {
            if (!limit || batch.length < Number(limit)) {
              batch.push(item);
            }
          });
          setAnalyzeStatus(state => {
            const notEligible = state.notEligible.concat(matchedData)
            return {
              message: 'Analyzed ' + (batch.length + notEligible.length) + ' Eligible: ' + batch.length + ' Not Eligible: ' + notEligible.length,
              objects: batch,
              notEligible,
            }
          });
          if (limit && batch.length >= Number(limit)) {
            break;
          }
        }
        if (s3Objects && s3Objects.length > 0 && s3Objects.length <= 1000
          && (!limit || batch.length < Number(limit))) {
          setAnalyzeStatus(state => ({ ...state, message: state.message + '... Reading more....' }));
          const lastKeyRead = s3Objects[s3Objects.length - 1].Key;
          await analyze(bucket, prefix, limit, analyzed, batch, lastKeyRead, notifier);
        }
      }
      setAnalyzeStatus(state => ({ ...state, objects: batch }));
    }
    return batch;
  }, []);

  const handleStartScan = useCallback(() => {
    setAnalyzeStatus({ message: 'Analyzing... Please wait!', objects: [], notEligible: [] });
    setShowLoading(true);
    analyze(selectedBucket, selectedDir, scanLimit).then(() => {
      setAnalyzeStatus(state => ({ ...state, message: '' }));
      setShowLoading(false);
    }).catch(err => {
      setShowLoading(false);
      console.error(err);
      setAnalyzeStatus(state => ({ ...state, message: "Unable to Analyze due to error. Please contact Support." }));
    });
  }, [analyze, scanLimit, selectedBucket, selectedDir]);

  const handleShowAssetBrowser = useCallback(() => {
    setShowAssetBrowser(true);
  }, []);
  const handleDirChange = useCallback((event) => {
    if (event.target.value) {
      setSelectedDir(event.target.value);
    }
  }, []);
  const handleDirSelect = useCallback(({ dir }) => {
    setSelectedDir(dir);
    setShowAssetBrowser(false);
  }, []);
  const handleLimitChange = useCallback((event) => {
    setScanLimit(+event.target.value);
  }, []);
  const handleRemoveEligibleItem = useCallback((objectKey) => {
    setAnalyzeStatus((oldO) => {
      const objectItem = oldO.objects.find(o => o.Key === objectKey);
      const notEligible = [...oldO.notEligible];
      if (objectItem) {
        notEligible.push(objectItem);
      }
      return {
        ...oldO,
        objects: oldO.objects.filter(o => o.Key !== objectKey),
        notEligible,
      }
    })
  }, []);

  const handleAddMeta = useCallback(async () => {
    handleStartScan();
    setShowLoading(true);
    const currentState = store.getState();
    let resp = await invoke(
      "wdtvamtoolScanUtils-" + awsConfig.environment,
      {
        "action": "scan",
        data: {
          folder: selectedDir,
          completion: null,
          user: localStorage.getItem("userEmail"),
          timestamp: new Date().toISOString(),
          sessionId: "",
          bucket: currentState.search.bucket,
          network: currentState.search.network,
          show: currentState.search.show,
          year: currentState.search.year,
          job: currentState.search.job,
          name: currentState.search.name,
          people: currentState.search.people,
          city: currentState.search.city,
          country: currentState.search.country,
          region: currentState.search.region,
          category: currentState.search.category,
          fileType: currentState.search.fileType,
          searchType: currentState.search.searchType,
          type: currentState.search.type,
          celebrityRekog: currentState.search.celebrityRekog || false,
          objectRekog: currentState.search.objectRekog || false,
          textRekog: currentState.search.textRekog || false,
          notes: currentState.search.keywords || false,
          "limit": scanLimit,
        }

      },
      currentUser.creds
    );
    if (resp) {
      setShowLoading(false);
      setTimeout(() => {

        dispatch(searchResetValues());
        dispatch(searchSetFieldValue({ bucket: currentState.search.bucket, job: currentState.search.job, name: currentState.search.name }));
        setScanRequestSubmitted(true);
        // goTo('/search/results');
      }, 5000);
    }

    // apiCreateScan({ transferId, selectedDir, selectedFiles: analyzeStatus.objects }).then(() => {
    //   setTimeout(() => {
    //     const currentState = store.getState();
    //     dispatch(searchResetValues());
    //     dispatch(searchSetFieldValue({ bucket: currentState.search.bucket, job: currentState.search.job, name: currentState.search.name }));
    //     setScanRequestSubmitted(true);
    //     // goTo('/search/results');
    //   }, 5000);
    // }).catch(err => {
    //   setShowLoading(false);
    //   let errMsg = 'Unknown Error. Please contact Support.';
    //   if (err.errors && err.errors.length > 0) {
    //     errMsg = err.errors[0]['message'];
    //   }
    //   setAnalyzeStatus(state => ({ ...state, message: errMsg }));
    //   return JSON.stringify(errMsg);
    // });
  }, [analyzeStatus.objects, selectedDir, transferId]);



  /**
   * Alert Success
   */
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };


  const handleInputChange = (e) => {
    dispatch(searchSetFieldValue({ [e.target.name]: e.target.value }));
  };


  return (
    <>
      {showScan && (
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Scan</h4>
            <p className={classes.cardCategoryWhite}>Direct on-demand scan to extract metadata and information.</p>
          </CardHeader>
          <CardBody>
            <br />
            <Typography variant="h5">Scan Targets</Typography>
            <Grid container>



              <Grid className={classes.gridItem} item md={12} m="1">
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Directory</InputLabel>
                  <OutlinedInput
                    id="upload_path"
                    value={selectedDir}
                    onChange={handleDirChange}
                    labelWidth={70}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="search directory"
                          onClick={handleShowAssetBrowser}
                          onMouseDown={handleShowAssetBrowser}
                          edge="end"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    } />
                </FormControl>
                {showAssetBrowser && selectedBucket && <AssetBrowser bucket={selectedBucket} foldersOnly onSubmit={handleDirSelect} />}
              </Grid>
              <Grid className={classes.gridItem} item md={12} m="1">
                <SearchKeywordsInput label='Keywords' />
              </Grid>
            </Grid>
            <Grid container>
              <Grid className={classes.gridItem} item md={4}>
                <SearchBucketInput pageFrom="scan" />
              </Grid>

              <Grid className={classes.gridItem} item md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">Scan Limit</InputLabel>
                  <Select label="Scan Limit" value={scanLimit} onChange={handleLimitChange}>
                    <MenuItem value="10000000">All</MenuItem>
                    <MenuItem value="100">100</MenuItem>
                    <MenuItem value="500">500</MenuItem>
                    <MenuItem value='1000'>1000</MenuItem>
                    <MenuItem value="10000">10000</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid className={classes.gridItem} item md={4}>
                <Button fullWidth color="primary" onClick={handleStartScan}>Analyze</Button>
              </Grid> */}
            </Grid>
            <Typography>{analyzeStatus.message}</Typography>
            {/* <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">
                  Eligible ({analyzeStatus.objects.length})
                </Typography>
                <ScanEligibleList list={analyzeStatus.objects} onRemove={handleRemoveEligibleItem} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">
                  Items Indexed ({analyzeStatus.notEligible.length})
                </Typography>
                <ScanEligibleList list={analyzeStatus.notEligible} />
              </Grid>
            </Grid> */}
            {/* {analyzeStatus.objects.length > 0 &&  */}
            <div>
              <SearchForm showRecognize recognizeObject recognizeCelebrities additionalSearch pageFromScan />
              <GridContainer>
                <GridItem xs={6} sm={12} md={3}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button color="primary" onClick={handleAddMeta}>Scan</Button>
                  </GridItem>
                </GridItem>
              </GridContainer>
            </div>
            {/* } */}

          </CardBody>
          <CardFooter>

          </CardFooter>
        </Card>
      )}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      <Backdrop open={showLoading} style={{ zIndex: 'auto' }}>
        <CircularProgress />
      </Backdrop>

      {scanRequestSubmitted === true ? (
        <div>
          <Dialog
            open={scanRequestSubmitted}
            onClose={() => { setScanRequestSubmitted(false); goTo('/search/results'); }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">Scan Request submitted!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The Scan Request is in progress. Please check the Scan Req.Summary for status updates.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => { setScanRequestSubmitted(false); goTo('/search/results'); }} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
    </>
  );
}
