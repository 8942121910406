import * as AWS from 'aws-sdk';

const invoke = (lambdaName, lambdaArgs, creds='') => {

    console.log('Invoking lambda: ', lambdaName);

    // console.log('Creds decoded: ', Buffer.from(creds, 'base64').toString());

    const credsJson = JSON.parse(Buffer.from(creds, 'base64').toString());

    console.log('Using ----> ', creds.substring(0, 10));

    const lambda = new AWS.Lambda({
        accessKeyId: credsJson?.accessKeyId,
        secretAccessKey: credsJson?.secretAccessKey,
        sessionToken: credsJson?.sessionToken,
        region: credsJson?.region,
    });

    //---Inject idtoken into lambda args.
    lambdaArgs['idToken'] = credsJson.idToken;

    const request = {
        FunctionName: lambdaName,   
        Payload: JSON.stringify(lambdaArgs),
        InvocationType: 'RequestResponse'
    };
    return new Promise((resolve, reject) => {
        lambda.invoke(request, (err, data) => {
            
            if (err) {              
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
};

export default invoke;