import { createAction, createReducer } from '@reduxjs/toolkit';

export const adminGroupSetFieldValue = createAction('ADMIN_GROUP_SET_FIELD_VALUE');
export const adminGroupResetValues = createAction('ADMIN_GROUP_RESET_VALUES');

const defaultState = {
  id: '',
  groupName: "",
  description: "",
  bucket: [],
  users: [],
  usersList: [],
  groupuserids: [],
  dfStudioAccess: ''
}

const adminGroupFormReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(adminGroupSetFieldValue, (state, { payload }) => {
      Object.assign(state, payload);

      return state;
    }).addCase(adminGroupResetValues, () => ({
      ...defaultState,
    }));
});

export default adminGroupFormReducer;
