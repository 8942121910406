import { createAction, createReducer } from "@reduxjs/toolkit";

export const adminRoleSetFieldValue = createAction(
  "ADMIN_ROLE_SET_FIELD_VALUE"
);
export const adminRoleResetValues = createAction("ADMIN_ROLE_RESET_VALUES");

const defaultState = {
  name: "",
  description: "",
  userAccess: JSON.stringify([]),
  paths: "",
  directory: "",
  editable: true,
  idletime: 0,
  user: "",
  bucket: "",
  userData: [],
};

const state = defaultState;

const adminRoleFormReducer = createReducer(state, (builder) => {
  builder
    .addCase(adminRoleSetFieldValue, (state, { payload }) => {
      Object.assign(state, payload);
      return state;
    })
    .addCase(adminRoleResetValues, () => ({
      ...defaultState,
    }));
});

export default adminRoleFormReducer;
