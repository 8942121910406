import { createAction, createReducer } from '@reduxjs/toolkit';

export const addDialog = createAction(
  'ADD_DIALOG_MESSAGE',
  (title = 'WD asset management', text = '', options = {}) => {
    return {
      payload: {
        id: options.id || Date.now() + Math.random(),
        title,
        content: text,
        options,
      },
    };
  }
);

export const removeAllDialog = createAction('REMOVE_DIALOG_ALL');

export const removeDialog = createAction('REMOVE_DIALOG_MESSAGE');

const dialogsReducer = createReducer([], (builder) => {
  builder
    .addCase(addDialog, (state, action) => {
      if (!state.find((o) => o.id === action.payload.id)) {
        state.push(action.payload);
      }
    })
    .addCase(removeAllDialog, () => ([]))
    .addCase(removeDialog, (state, action) => {
      // Can still return an immutably-updated value if we want to
      return state.filter((o) => o.id !== action.payload);
    });
});

export default dialogsReducer;
