import { Route, Switch } from 'react-router-dom'
import Home from './components/home/Home'
import { useEffect } from 'react'
import { goTo } from 'redux-store.js'
import { useAppUserContext } from 'lib/contextLib.js'
//import SignUpPage from 'pages/SignUpPage.js'
import LoginPage from 'pages/LoginPage.js'
import LoginVedioPage from 'pages/LoginVedioPage.js'
import ForgotPasswordPage from 'pages/ForgotPasswordPage.js'
import ForceChangePasswordPage from 'pages/ForceChangePasswordPage'

const HomePage = () => {
  const user = useAppUserContext();
  useEffect(() => {
    if (typeof user === 'object' && user.email) {
      goTo('/search');
    } else {
      const client= localStorage.getItem('currentClient');

      goTo('/login/'+ client);
    }
  }, [user]);
  return null;
}

export const RouteList = () => {
  return (
    <Switch>
      <Route path='/login' component={LoginPage} />
      <Route path='/login/:client' component={LoginPage} />
      {/* <Route path='/signup`' component={SignUpPage} /> */}
      <Route path='/forgot-password' component={ForgotPasswordPage} />
      <Route path='/force-change-password' component={ForceChangePasswordPage} />
      <Route path='/login-video' component={LoginVedioPage} />
      <Route path='/' exact component={HomePage} />
      <Route path='/' component={Home} />
      <Route path="*" component={Home} />
    </Switch>
  )
}

export default RouteList;