import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import {
  adminGroupSetFieldValue
} from "store/admin-group-reducer";

import { dispatch } from "redux-store";
import { fetchUserLists, deleteOneGroupUser, listAllUserGroups, getUserByRole } from "service/api.service";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function UsersList() {
  const [userData, setUserData] = useState([]);

  /**
   * store selector
   */
  const admingroup = useSelector((state) => state.admingroup);


  useEffect(() => {
    fetchUsers();
    fetchAllUserGroups();
  }, []);
  /**
   *
   */
  async function fetchUsers() {
    let data = [];
    await fetchUserLists().then((success) => {
      if (success && success.items) {
        success.items.forEach((val) => {
          data.push({ label: val.firstName, email: val.email, userid: val.id });
        });
      }
      setUserData(data);
      fetchAllUserGroups();
    });
  }
  /**
   *
   * @param {*} event
   */
  async function fetchAllUserGroups() {
    await listAllUserGroups().then((success) => {
      const groups = [];
      success?.items.map((val) => {
        if (val.groupId === admingroup.id) {
          groups.push({ label: val.user.firstName, email: val.user.email, userid: val.user.id, id: val.id });
        }
      });
      dispatch(adminGroupSetFieldValue({ users: groups }));
      dispatch(adminGroupSetFieldValue({ usersList: groups }));
    });
  }

  /**
   * 
   * @param  {...any} params 
   * @returns 
   */
  const handleDropdownChange = (...params) => {
    if (params && params[2] === "clear") {

      dispatch(adminGroupSetFieldValue({ users: [] }));
      return;
    }
    const changedOption = params[3].option;
    const selectionType = params[2]; //
    const allSelected = params[1]; //
    addRomoveUsers(allSelected, changedOption, selectionType);
  };
  /**
   * 
   * @param {*} allSelected 
   * @param {*} changedOption 
   * @param {*} selectionType 
   */
  const addRomoveUsers = (allSelected, changedOption, selectionType) => {
    if (selectionType === "selectOption") {

      dispatch(adminGroupSetFieldValue({ users: allSelected }));

    }
    if (selectionType === "removeOption") {

      deleteOneGroupUser(changedOption.id);

      const pos = allSelected
        .map(function (e) {
          return e.label;
        })
        .indexOf(changedOption.label);
      if (pos !== -1) {
        deleteOneGroupUser(allSelected[pos].id);
        allSelected.splice(pos, 1); // 2nd parameter means remove one item only 
      }


      dispatch(adminGroupSetFieldValue({ users: allSelected }));
    }
  };

  return (
    <FormControl sx={{ m: 1, width: 350 }}>
      <Autocomplete
        multiple
        limitTags={2}
        id="tags-standard"
        options={userData}
        getOptionLabel={(option) => option.label || ""}
        value={admingroup.users}
        onChange={handleDropdownChange}
        filterSelectedOptions
        sx={{ width: 485 }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="USERS"

          />
        )}
      />
    </FormControl>

  );
}
