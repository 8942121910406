
// @material-ui/icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import StorageIcon from '@material-ui/icons/Storage';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ManageData from '../manage-data/ManageData';
import SearchData from '../manage-data/SearchMetaData';
import Network from '../networks/Network';
import Locations from '../location/Locations';
import Upload from '../upload/Upload';
import SearchPage from '../search/SearchPage.js';
import Show from '../show/Show'
import Dayparts from '../dayparts/DayParts'
import Jobs from '../jobs/Jobs'
import Pictures from '../pictures/Pictures'
import Home from '../home/Home';
import People from '../people/People';
import News from '../news/News';
import Analytics from '../analytics/Analytics';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import TvIcon from '@material-ui/icons/Tv'; 
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import RouterIcon from '@material-ui/icons/Router';
import WorkIcon from '@material-ui/icons/Work';
import DeleteIcon from '@material-ui/icons/Delete';
import ShowAllRequest from '../help/ShowAllRequests';
import Help from '../help/Help';

const routes = [
  {
    path: "/search",
    name: "Search",
    icon: ImageSearchIcon,
    component: SearchPage,
    layout: "/main",
    value:"-1"
  },

  {
    path: "/upload",
    name: "Upload",
    icon: CloudUploadIcon,
    component: Upload,
    layout: "/main",
    value:"-1"
  },
  {
    path: "/manage-data",
    name: "Manage Data",
    icon: StorageIcon,
    component: ManageData,
    layout: "/main",
    value:"-1"
  },
  {
    path: "/manage-data",
    name: "Add Meta Data",
    icon: LibraryBooksIcon,
    component: ManageData,
    layout: "/main",
    value:"2"
  },
  {
    path: "/search-data",
    name: "Search Meta Data",
    icon: ImageSearchIcon,
    component: SearchData,
    layout: "/main",
    value:"2"
  },
  {
    path: "/network",
    name: "Network",
    icon: RouterIcon,
    component: Network,
    layout: "/main",
    value:"2"
  },
  {
    path: "/show",
    name: "Show",
    icon: TvIcon,
    component: Show,
    layout: "/main",
    value:"2"
  },
  {
    path: "/dayparts",
    name: "Dayparts",
    icon: QueryBuilderIcon,
    component: Dayparts,
    layout: "/main",
    value:"2"
  },
  {
    path: "/jobs",
    name: "Jobs",
    icon: WorkIcon,
    component: Jobs,
    layout: "/main",
    value:"2"
  },
  {
    path: "/pictures",
    name: "Pictures",
    icon: PhotoLibraryIcon,
    component: Pictures,
    layout: "/main",
    value:"2"
  },
  {
    path: "/people",
    name: "People",
    icon: PeopleIcon,
    component: People,
    layout: "/main",
    value:"2"
  },
  {
    path: "/locations",
    name: "Locations",
    icon: LocationOnIcon,
    component: Locations,
    layout: "/main",
    value:"2"
  },
 
  {
    path: "/scan",
    name: "Scan",
    icon: ImageSearchIcon,
    component: Home,
    layout: "/main",
    value:"-1"
  },
  {
    path: "/news",
    name: "News",
    icon: AnnouncementIcon,
    component: News,
    layout: "/main",
    value:"4"
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: AssessmentIcon,
    component: Analytics,
    layout: "/main",
    value:"4"
  },

  {
    path: "/help",
    name: "Show All Requests",
    icon: ImageSearchIcon,
    component: ShowAllRequest,
    layout: "/main",
    value:"-1"
  },
  {
    path: "/help",
    name: "Help",
    icon: ImageSearchIcon,
    component: Help,
    layout: "/main",
    value:"-1"
  },
  //Image Search Menu

  {
    path: "/filters",
    name: "Filters",
    icon: AssignmentIcon,
    component: SearchData,
    layout: "/main",
    value:"0"
  },
  {
    path: "/scan",
    name: "Results",
    icon: CheckBoxIcon,
    component: SearchData,
    layout: "/main",
    value:"0"
  },
  {
    path: "/save-search",
    name: "Save Search",
    icon: SaveIcon,
    component: SearchData,
    layout: "/main",
    value:"0"
  },
  {
    path: "/previous",
    name: "Prevoius",
    icon: ArrowBackIcon,
    component: SearchData,
    layout: "/main",
    value:"0"
  },
  {
    path: "/next",
    name: "Next",
    icon: ArrowForwardIcon,
    component: SearchData,
    layout: "/main",
    value:"0"
  },
  {
    path: "/delete-search",
    name: "Delete Search",
    icon: DeleteIcon,
    component: SearchData,
    layout: "/main",
    value:"0"
  },
  
];
const ImageSearchMenus = [
  {
    path: "/parameters",
    name: "Scan",
    icon: ImageSearchIcon,
    component: Home,
    layout: "/search",
    value:"0"
  },
  {
    path: "/scan",
    name: "Results",
    icon: ImageSearchIcon,
    component: Home,
    layout: "/search",
    value:"0"
  },
  {
    path: "/scan",
    name: "SaveSearch",
    icon: ImageSearchIcon,
    component: Home,
    layout: "/search",
    value:"0"
  },
  {
    path: "/scan",
    name: "Prevoius",
    icon: ImageSearchIcon,
    component: Home,
    layout: "/search",
    value:"0"
  },
  {
    path: "/scan",
    name: "Next",
    icon: ImageSearchIcon,
    component: Home,
    layout: "/main",
    value:"0"
  },

];
export default routes;
  
