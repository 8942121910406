import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import { bytesToSize } from 'service/util.service';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  }
}));

const SelectedFileItem = ({ file }) => {
  return <ListItem>
    <ListItemAvatar>
      <Avatar>
        <ImageIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={file.name}
      secondary={bytesToSize(file.size)}
    />
  </ListItem>
}

export default function ListSelectedFiles({ sourceFiles }) {
  const classes = useStyles();
  return (<List dense className={classes.root}>
    {sourceFiles.map(o => (<SelectedFileItem key={o.name} file={o} />))}
  </List>)
}