import { Route, Redirect} from 'react-router-dom'
import { useAppUserContext } from 'lib/contextLib.js'

const SecureRoute = ({component, path, ...others}) => {
  const authenticatedUser = useAppUserContext();

  const allowAccess = (authenticatedUser && authenticatedUser.email && authenticatedUser.email.length > 0) || false;

  if (allowAccess === true) {
    return (<Route component={component} path={path} {...others} />)
  } else {
    const client= localStorage.getItem('currentClient');
    return (<Redirect to = {'/login/' + client} />);
  }  
}

export default SecureRoute;