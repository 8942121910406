import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import { useFormFields } from '../../lib/hooksLib';
import { Auth } from "aws-amplify";
import Alert from '@material-ui/lab/Alert';
import { goTo } from 'redux-store';

const UserConfirmCard = ({ email, onSuccess }) => {
  const [fields, handleFieldChange] = useFormFields({
    confirmationCode: ''
  });
  const [errMsg, setErrMsg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const setErrorMessage = (message) => {
      setErrMsg(message);
      setTimeout(() => {
        setErrMsg('');
      }, 5000)
    };
    try {
      await Auth.confirmSignUp(email, fields.confirmationCode);
      if (typeof onSuccess === 'function') {
        onSuccess();
      } else {
        const client= localStorage.getItem('currentClient');
        goTo('/login/'+ client);
      }
    } catch (err) {
      if (err.message.includes('CONFIRMED')) {
        if (typeof onSuccess === 'function') {
          onSuccess();
        } else {
          const client= localStorage.getItem('currentClient');
          goTo('/login/'+ client);
        }
      } else {
        console.log("Exception in Confirm Signup: ", err);
        setErrorMessage(err.message)
      }
    }
    setIsSubmitting(false);
  }, [email, fields.confirmationCode, onSuccess]);

  return (

    <form noValidate onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="confirmationCode"
        label="Verification Code"
        autoFocus
        value={fields.confirmationCode}
        onChange={handleFieldChange}
        disabled={isSubmitting}
      />
      {errMsg && <Alert severity="error">{errMsg}</Alert>}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={isSubmitting}
      >
        Verify
      </Button>

    </form>
  );
}

export default UserConfirmCard;
