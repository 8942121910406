import React, { useEffect, useState, useRef } from "react";
import invoke from "service/lambda.service";
import { useAppUserContext } from "../../lib/contextLib";
import { PlayCircleFilled } from "@material-ui/icons/index";
import CancelIcon from '@mui/icons-material/Cancel';
import PhotoIcon from '@mui/icons-material/Photo';
import ReactPlayer from 'react-player';
import { awsConfig, Bucket } from "../../config";
import awsmobile from "../../aws-exports";
import wordImg from "../../assets/word.jpg";
import pdfImg from "../../assets/pdf.png";
import pptImg from "../../assets/ppt.jpg";
import excelImg from "../../assets/excel.jpg";
import { useVideoContext } from "../../lib/contextLib";
import MuiAlert from "@mui/material/Alert";
import Button from "../button/Button";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Wimg = (props) => {

    const currentUser = useAppUserContext();
    const videoContext = useVideoContext();
    const [waterMarkedImage, setWaterMarkedImage] = useState('');
    const [altMessage, setAltMessage] = useState('Loading...');
    const [videoUrl, setVideoUrl] = useState(null);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [videoPaused, setVideoPaused] = useState(false);
    const [videoError, setVideoError] = useState(false);
    const [controlKey, setControlKey] = useState(null);
    const [changeThumbnailRequested, setChangeThumbnailRequested] = useState(false);
    const playerRef = useRef();
    const [pauseTimeStamp, setPauseTimeStamp] = useState(null);
    
    const getRequiredUrls = () => {
        setControlKey(Math.ceil(Math.random() * 100000) +'');
        
        if (!props.fullData.thumbnail) {
            if (props.fullData.mediaFilename.match("docx") !== null || props.fullData.mediaFilename.match("doc") !== null) {
                setWaterMarkedImage(wordImg);
            }
            if (props.fullData.mediaFilename.match("pdf") !== null) {
                setWaterMarkedImage(pdfImg);
            }
            if (props.fullData.mediaFilename.match("xlsx") !== null || props.fullData.mediaFilename.match("xls") !== null) {
                setWaterMarkedImage(excelImg);
            }
            if (props.fullData.mediaFilename.match("ppt") !== null || props.fullData.mediaFilename.match("pptx") !== null) {
                setWaterMarkedImage(pptImg);
            }
        } else {
            const doWaterMark = (localStorage.getItem('watermarkImages') || 'N') === 'Y';

            if (isVideoFile(props.fullData.mediaFilename) === true) {
                invoke("wdtvamtoolS3Utils-" + awsConfig.environment, {
                        activity: 'get-download-url',
                        data: {
                            region: awsmobile.aws_project_region,
                            bucket: props.fullData.bucket,
                            key: props.fullData.mediaFilename,
                            expiryMins: 60
                        }
                    },
                    currentUser.creds
                ).then((resp) => {
                    const urlResp = JSON.parse(resp.Payload);
                    console.log('Video url Response: ', urlResp?.body?.body);
                    if (urlResp?.body) {
                        setVideoUrl(urlResp?.body?.body);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }

            if (doWaterMark) {
                invoke("wdtvamtoolS3Utils-" + awsConfig.environment, {
                        activity: 'get-watermarked-thumbnail',
                        data: {
                            region: awsmobile.aws_project_region,
                            bucket: props.fullData.bucket,
                            key: props.fullData.mediaFilename, 
                            waterMarks: props.waterMarks
                        }
                    },
                    currentUser.creds
                ).then((resp) => {
                    const wimgB64 = JSON.parse(resp.Payload);
                    console.log('Water marked image Status code: ', wimgB64.statusCode);
                    if (wimgB64?.body) {
                        setAltMessage(props.alt || 'Image Not Available');
                        setWaterMarkedImage('data:image/gif;base64,' + wimgB64?.body);
                    }
                }).catch((err) => {
                    console.log(err);
                    setAltMessage(props.alt || 'Unable to load image')
                });
            } else {
                loadThumbnail();
            }    
        }
    }

    const loadThumbnail = () => {
        const thumbnailPath = (props.fullData.bucket + '/' + props.fullData.mediaFilename).replace(/\/\//g, '/');
        //console.log('Reloading thumbnail from: ', thumbnailPath);

        invoke("wdtvamtoolS3Utils-" + awsConfig.environment, {
                activity: 'get-download-url',
                data: {
                    region: awsmobile.aws_project_region,
                    bucket: Bucket.thumbnails,
                    key: thumbnailPath,
                    expiryMins: 60
                }
            },
            currentUser.creds
        ).then((resp) => {
            const urlResp = JSON.parse(resp.Payload);
            //console.log('Reload thumbnail url Response: ', urlResp?.body?.body);
            if (urlResp?.body) {
                setWaterMarkedImage(urlResp?.body?.body);
            }
        }).catch((err) => {
            console.log('Error reloading thumbnail: ', err);
        });
    }

    useEffect(() => {
        getRequiredUrls();
    }, []);

    useEffect(() => { //---Stop playing when some other wimg starts playing... 
        if (videoContext.wimgKey !== controlKey && videoPlaying === true) {
            setVideoPlaying(false);
            console.log('Will Stop: ', props.fullData.mediaFilename);
        }
    }, [videoContext.wimgKey])

    useEffect(async () => {
        if (changeThumbnailRequested === true) {

 
        }
    }, [changeThumbnailRequested, pauseTimeStamp]);


    const videoReadyHandler = (readyParams) => {
        console.log('Video Ready: ', readyParams);
        setChangeThumbnailRequested(false);
        setVideoPaused(false);
    } 

    const videoStartHandler = (startedParams) => {
        console.log('Video Started: ', startedParams);
        setVideoPaused(false);
        setChangeThumbnailRequested(false);
    }

    const videoEndHandler = (endedParams) => {
        console.log('Video Ended: ', endedParams);
    }

    const videoErrorHandler = ()=>{
        setVideoError(true);
        setChangeThumbnailRequested(false);
    }

    const videoPauseHandler = ()=>{
        console.log('paused at: ', playerRef.current.getCurrentTime());
        setVideoPaused(true);
        setPauseTimeStamp(playerRef.current.getCurrentTime());
    }

    return (
        <div style={{height: '100%', width: '100%'}} onContextMenu={e => e.preventDefault()} >

            {videoPlaying === true && videoPaused === true && changeThumbnailRequested === true &&
                <Alert
                        severity={"info"}
                        sx={{ width: "100%" }}
                    >

                    <label>
                        Would you like to set the current frame as Thumbnail...?
                    </label>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%'}}>
                        <Button color='primary' onClick={async () => {
                                console.log('Changing video thumbnail for: ',
                                    props.fullData.bucket + props.fullData.mediaFilename,
                                    ' in bucket: ', Bucket.thumbnails);

                                await invoke("wdtvamtoolS3Utils-" + awsConfig.environment, {
                                        activity: 'change-video-thumbnail',
                                        data: {
                                            region: awsmobile.aws_project_region,
                                            bucket: props.fullData.bucket,
                                            key: props.fullData.mediaFilename,
                                            timestampSecond: pauseTimeStamp,
                                            expiryMins: 60
                                        }
                                    },
                                    currentUser.creds
                                ).then(async (resp) => {
                                    setVideoPaused(true);
                                    setVideoPlaying(false);
                                    setChangeThumbnailRequested(false);
                                    loadThumbnail();
                                    console.log('Changed thumbnail successfully. Will get the url...');
                                }).catch((err) => {
                                    console.log('Change thumbnail failed: ', err);
                                });
                            }}>
                            Yes
                        </Button>
                        <Button color='primary' onClick={()=>{
                                setChangeThumbnailRequested(false);
                                setVideoPaused(false);
                                setVideoPlaying(true);
                            }}>
                            No
                        </Button>
                    </div>
                </Alert>
            }

            { videoPlaying === true && videoError === true &&
            <Alert
                severity={"warning"}
                sx={{ width: "100%" }}
            >
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
                    <label>
                        Unable to play original video. Please try with a different browser. (or) <br/>
                        Watch short preview of the video instead...? <br/>
                    </label>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', width: '100%'}}>
                        <Button color='primary' onClick={async () => {
                                console.log('Getting signed url for: ',
                                                props.fullData.bucket + props.fullData.mediaFilename + '.preview',
                                                ' in bucket: ', Bucket.thumbnails
                                            );
                                await invoke("wdtvamtoolS3Utils-" + awsConfig.environment, {
                                        activity: 'get-download-url',
                                        data: {
                                            region: awsmobile.aws_project_region,
                                            bucket: Bucket.thumbnails,
                                            key: props.fullData.bucket + '/' + props.fullData.mediaFilename + '.preview',
                                            expiryMins: 60
                                        }
                                    },
                                    currentUser.creds
                                ).then((resp) => {
                                    const urlResp = JSON.parse(resp.Payload);
                                    console.log('Preview Video url Response: ', urlResp?.body?.body);
                                    if (urlResp?.body) {
                                        setVideoUrl(urlResp?.body?.body);
                                        setVideoPlaying(true);
                                        setVideoPaused(false);
                                        setVideoError(null);
                                    }
                                }).catch((err) => {
                                    console.log(err);
                                });
                            }}>
                            Yes
                        </Button>
                        <Button color='primary' onClick={()=>{
                                console.log('No...');
                                setVideoPlaying(false);
                            }}>
                            No
                        </Button>
                    </div>
                </div>
            </Alert>}

            <div style={{position: 'relative', display: (videoPlaying === false) ? 'block' : 'none', height: '100%', width: '100%' }}>
                <img alt={altMessage} src={`${waterMarkedImage}`} key={controlKey} 
                    style={{...props.style}}
                    onClick={() => {props.hasOwnProperty('onClick') ? props.onClick() : console.log('Nothing to do...')}}>    
                </img>

                <div onClick={()=>{
                    setVideoPlaying(true);
                    setVideoPaused(false);
                    setChangeThumbnailRequested(false);
                    videoContext.dispatchWimgKey(controlKey);
                }} 
                    style={{position: 'absolute', left: '2%', top: '2%', backgroundColor: 'rgba(0, 0, 0, 0.7)', borderRadius: 5,
                            display: videoUrl ? 'block' : 'none'}}>
                    <PlayCircleFilled size='large' style={{color: 'white'}}/>
                </div>
            </div>

            <div style={{position: 'relative', width: '100%', height: '100%', display: (videoPlaying === false) ? 'none' : 'block'}}>
                <ReactPlayer
                    ref = {playerRef}
                    className='react-player'
                    url={videoUrl}
                    playing = {videoPlaying === true && videoPaused === false}
                    controls ={true}
                    width='100%'
                    height='100%'
                    onReady={videoReadyHandler}
                    onStart={videoStartHandler}
                    onEnded={videoEndHandler}
                    onError={videoErrorHandler}
                    onPause={videoPauseHandler}
                    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                />

                <div style={{position: 'absolute', width: '100%', left: 0, top: '2%', display: 'flex', flexDirection: 'row', 
                             alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'rgba(0, 0, 0, 0.4)', borderRadius: 5}}>
                    <div onClick={()=>{
                            setVideoPlaying(false); 
                            setVideoPaused(false);
                            setChangeThumbnailRequested(false);
                        }} 
                        style={{display: (videoUrl && videoPlaying === true) ? 'block': 'none', }}>
                        <CancelIcon size='large' style={{color: 'white'}}/>
                    </div>

                    <div onClick={()=>{
                            setChangeThumbnailRequested(true);
                            setVideoPaused(true);
                        }} 
                            style={{display: (videoUrl && videoPlaying === true) ? 'block': 'none', }}>
                            <PhotoIcon size='large' style={{color: 'white'}}/>
                    </div>
                </div>

            </div>
        </div>
    );
}

const isVideoFile = (fileName) => {
    const formats =  ['3g2', '3gp', 'aa', 'aa3', 'aac', 'abc', 'ac3', 'acm', 'adf',
    'adp', 'ads', 'adx', 'aea', 'afc', 'aix', 'al', 'amf', 'ams',  
    'ans', 'ape', 'apl', 'aptx', 'aptxhd', 'aqt', 'art', 'asc', 'ast',  
    'avc', 'avi', 'avr', 'avs', 'avs2', 'bcstm', 'bfstm', 'bit', 'bmv',  
    'brstm', 'c2', 'cdata', 'cdg', 'cdxl', 'cgi', 'cif', 'daud', 'dav',  
    'dbm', 'dif', 'diz', 'dmf', 'dsm', 'dss', 'dtk', 'dts', 'dtshd',  
    'dv', 'eac3', 'fap', 'far', 'flac', 'flm', 'flv', 'flv', 'fsb',  
    'g722', 'g723_1', 'g729', 'genh', 'gsm', 'h261', 'h264', 'h265', 'h26l',  
    'hevc', 'ice', 'idf', 'idx', 'ifv', 'ircam', 'it', 'itgz', 'itr', 'itz',  
    'ivr', 'j2k', 'kux', 'lvf', 'm2a', 'm4a', 'm4v', 'mac', 'mdgz', 'mdl',  
    'mdr', 'mdz', 'med', 'mid', 'mj2', 'mjpeg', 'mjpg', 'mjpg', 'mjpg',  
    'mk3d', 'mka', 'mks', 'mkv', 'mlp', 'mod', 'mov', 'mp2', 'mp3', 'mp4',  
    'mpa', 'mpc', 'mpl2', 'mpo', 'msbc', 'msf', 'mt2', 'mtaf', 'mtm', 'musx',  
    'mvi', 'mxg', 'nfo', 'nist', 'nsp', 'nut', 'ogg', 'okt', 'oma', 'omg',  
    'paf', 'pjs', 'psm', 'ptm', 'pvf', 'qcif', 'rco', 'rcv', 'rgb', 'rsd',  
    'rsd', 'rso', 'rt', 's3gz', 's3m', 's3r', 's3z', 'sami', 'sb', 'sbc',  
    'sbg', 'scc', 'sdr2', 'sds', 'sdx', 'ser', 'sf', 'shn', 'sln', 'smi',  
    'son', 'sph', 'ss2', 'stl', 'stm', 'str', 'str', 'sub', 'sub', 'sub',  
    'sup', 'svag', 'sw', 'tak', 'tco', 'thd', 'tta', 'txt', 'txt', 'txt',  
    'ty', 'ty+', 'ub', 'ul', 'ult', 'umx', 'uw', 'v', 'v210', 'vag', 'vb',  
    'vc1', 'viv', 'vpk', 'vqe', 'vqf', 'vql', 'vt', 'vtt', 'wsd', 'xl',  
    'xm', 'xmgz', 'xmr', 'xmv', 'xmz', 'xvag', 'y4m', 'yop', 'yuv', 'yuv10'];

    const fileExtn = fileName.substring(fileName.lastIndexOf('.')+1);
    const ret = formats.includes(fileExtn);
    console.log('File extn: ', fileExtn, ' of ', fileName, ' is video: ', ret);

    return ret;
}

export default Wimg;
