import { Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useRef, useCallback, useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { dispatch, goTo, store } from "redux-store";
import { searchSetFieldValue } from "store/search-reducer";
import { adminRoleSetFieldValue } from "store/admin-roles-reducer";
import { collapseSetFieldValue } from "store/collapse-reducer";
import { useSelector } from "react-redux";
import { useAppUserContext } from "lib/contextLib";
import { Fragment } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { apiCreateShow, apiListShows } from "service/api.service";
import { searchPage } from "../search/exifData";
import "./searchform.css";

import { listUserGroupsByUserId, getUserByemail } from "service/api.service";
import { getDirectory } from "service/util.service";
const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      padding: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      width: 300,
    },
    indeterminateColor: {
      color: "#0078ba",
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
    },
  })
);
let shows = [];
let disableSearch = true;
store.subscribe(() => {
  const currentState = store.getState();
  let disbale = true;
  if (currentState && currentState.search) {
    for (const [key, value] of Object.entries(searchPage)) {
      if (currentState.search[key]) {
        disbale = false;
      }
    }
    disableSearch = disbale;
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, bucketLists, theme) {
  return {
    fontWeight:
      bucketLists.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const handleInputChange = (e, pageFrom) => {
  dispatch(searchSetFieldValue({ [e.target.name]: e.target.value }));
  if (e.target.name === "bucket") {
    dispatch(adminRoleSetFieldValue({ [e.target.name]: e.target.value }));
  }
  if (e.target.name === "bucket" && pageFrom === "search") {
    localStorage.setItem("WDAMT_bucket_selected", e.target.value);
  }
  if (e.target.name === "bucket" && pageFrom === "upload") {
    localStorage.setItem("WDAMT_bucket_selected_upload", e.target.value);
  }
  if (e.target.name === "bucket" && pageFrom === "scan") {
    localStorage.setItem("WDAMT_bucket_selected_scan", e.target.value);
  }
  if (e.target.name === "bucket" && pageFrom === "roles") {
    localStorage.setItem("WDAMT_bucket_selected_role", e.target.value);
  }
};
const handleDateFilterLimitChange = (e) => {
  localStorage.setItem("WDAMT_search_limit", e.target.value);
  dispatch(searchSetFieldValue({ [e.target.name]: e.target.value }));
};
const handleKeyDown = (e) => {
  if (disableSearch) {
    return;
  }
  if (e.key === "Enter") {
    dispatch(searchSetFieldValue({ isFromSearch: true }));
    goTo("/search/results");
  }
};

const handleCheckboxChange = (e) => {
  console.log(e.target.name, ' is ', e.target.checked);
  dispatch(searchSetFieldValue({ [e.target.name]: e.target.checked }));
};

const handleToggleChange = (actions, fieldName) => {
  let details = JSON.parse(JSON.stringify(actions));

  if (fieldName == "dfstudio") {
    details["status"] = false;
    details["datefilter"] = false;
    details["dfstudio"] = true;
  } else {
    details[fieldName] = !actions[fieldName];
    details["dfstudio"] = false;
  }
  dispatch(collapseSetFieldValue({ actions: details }));
};

const SearchNameInput = ({ disabled }) => {
  const value = useSelector((state) => state.search.name);
  return (
    <TextField
      value={value}
      disabled={disabled}
      classes={{ root: disabled ? "disabled-bg" : "" }}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="name"
      id="name"
      label="Saved Upload Name"
      variant="outlined"
      fullWidth
    />
  );
};
const SearchJobNumberInput = ({ disabled }) => {
  const value = useSelector((state) => state.search.job);
  return (
    <TextField
      value={value}
      disabled={disabled}
      classes={{ root: disabled ? "disabled-bg" : "" }}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="job"
      id="job_number"
      label="Job Number"
      variant="outlined"
      fullWidth
    />
  );
};
const SearchCityInput = () => {
  const value = useSelector((state) => state.search.city);
  return (
    <TextField
      value={value}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="city"
      id="city"
      label="City"
      variant="outlined"
      fullWidth
    />
  );
};
const SearchCountryInput = () => {
  const value = useSelector((state) => state.search.country);
  return (
    <TextField
      value={value}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="country"
      id="country"
      label="Country"
      variant="outlined"
      fullWidth
    />
  );
};

const DfFolderInput = ({ disabled }) => {
  const value = useSelector((state) => state.search.dfsFolder);
  return (
    <TextField
      value={value}
      disabled={disabled}
      classes={{ root: disabled ? "disabled-bg" : "" }}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="dfsFolder"
      id="dfsFolder"
      label="DFS Folder"
      variant="outlined"
      fullWidth
    />
  );
};
const DfsID = ({ disabled }) => {
  const value = useSelector((state) => state.search.dfsId);
  return (
    <TextField
      value={value}
      disabled={disabled}
      classes={{ root: disabled ? "disabled-bg" : "" }}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="dfsId"
      id="dfsId"
      label="Single File - DFS ID"
      variant="outlined"
      fullWidth
    />
  );
};

const DfProject = ({ disabled }) => {
  const value = useSelector((state) => state.search.dfsProject);
  return (
    <TextField
      value={value}
      disabled={disabled}
      classes={{ root: disabled ? "disabled-bg" : "" }}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="dfsProject"
      id="dfsProject"
      label="Project"
      variant="outlined"
      fullWidth
    />
  );
};

const DfSetup = ({ disabled }) => {
  const value = useSelector((state) => state.search.dfsSetup);
  return (
    <TextField
      value={value}
      disabled={disabled}
      classes={{ root: disabled ? "disabled-bg" : "" }}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="dfsSetup"
      id="dfsSetup"
      label="Setup"
      variant="outlined"
      fullWidth
    />
  );
};
const DfsLocation = ({ disabled }) => {
  const value = useSelector((state) => state.search.dfsLocation);
  return (
    <TextField
      value={value}
      disabled={disabled}
      classes={{ root: disabled ? "disabled-bg" : "" }}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="dfsLocation"
      id="dfsLocation"
      label="Location"
      variant="outlined"
      fullWidth
    />
  );
};
const DfsFreeText = () => {
  const value = useSelector((state) => state.search.dfsFreeText);
  return (
    <TextField
      value={value}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="dfsFreeText"
      id="dfsFreeText"
      label="Free Text / Contained Search"
      variant="outlined"
      fullWidth
    />
  );
};
const SearchExifInput = ({ disabled }) => {
  const value = useSelector((state) => state.search.exifDump);
  return (
    <TextField
      value={value}
      disabled={disabled}
      classes={{ root: disabled ? "disabled-bg" : "" }}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="exifDump"
      id="exifDump"
      label="Search EXIF"
      variant="outlined"
      fullWidth
    />
  );
};
const SearchShowInput = (props) => {
  const { isShowUpdated, disabled } = props;
  const [shows, setShows] = useState([]);

  useEffect(() => {
    fetchSearchResults();
  }, []);
  useEffect(() => {
    fetchSearchResults();
  }, [isShowUpdated]);

  const fetchSearchResults = useCallback(() => {
    let data = [];
    apiListShows()
      .then(({ items, nextToken, total }) => {
        if (items && items.length) {
          items.forEach((val, ind) => {
            data.push({
              show: val.name,
            });
          });
        }
        setShows(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const value = useSelector((state) => state.search.show);
  const handleDropdownChange = useCallback((e) => {
    dispatch(searchSetFieldValue({ show: e.target.textContent }));
  }, []);
  return (
    <FormControl variant="outlined" fullWidth>
      {disabled ? (
        <Autocomplete
          id="combo-box-demo"
          options={shows}
          disabled={disabled}
          classes={{ root: disabled ? "disabled-bg" : "" }}
          getOptionLabel={(option) => option.show}
          value={{ show: value }}
          onChange={handleDropdownChange}
          onKeyDown={handleKeyDown}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Show"
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: disabled,
              }}
            />
          )}
          fullWidth
        />
      ) : (
        <Autocomplete
          id="combo-box-demo"
          options={shows}
          getOptionLabel={(option) => option.show}
          value={{ show: value }}
          onChange={handleDropdownChange}
          onKeyDown={handleKeyDown}
          renderInput={(params) => (
            <TextField {...params} label="Show" variant="outlined" fullWidth />
          )}
          fullWidth
        />
      )}
    </FormControl>
  );
};
const SearchShowNewInput = (props) => {
  const value = useSelector((state) => state.search.showtitle);
  const saveShowTitle = useCallback(async () => {
    await apiCreateShow();
    props.onShowUpdated(true);
    dispatch(searchSetFieldValue({ showtitle: "" }));
  }, []);
  const handleMouseDownShowTitle = (event) => {
    event.preventDefault();
  };
  return (
    <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-show">New Show Title</InputLabel>
      <OutlinedInput
        id="outlined-adornment-show"
        type="text"
        value={value}
        onChange={handleInputChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle show visibility"
              onClick={saveShowTitle}
              onMouseDown={handleMouseDownShowTitle}
              edge="end"
            >
              <Icon color="primary">add_circle</Icon>
            </IconButton>
          </InputAdornment>
        }
        label="New Show Title"
        name="showtitle"
      />
    </FormControl>
  );
};
const SearchNetworkInput = () => {
  const value = useSelector((state) => state.search.network);
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="demo-simple-select-outlined-label">Network</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        label="Network"
        name="network"
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="ABC">ABC</MenuItem>
        <MenuItem value="FREEFORM">FREEFORM</MenuItem>
        <MenuItem value="Disney Channel">Disney Channel</MenuItem>
        <MenuItem value="Disney Junior">Disney Junior</MenuItem>
        <MenuItem value="Disney XD">Disney XD</MenuItem>
        <MenuItem value="Radio Disney">Radio Disney</MenuItem>
        <MenuItem value="Corporate">Corporate</MenuItem>
        <MenuItem value="Disney Plus">Disney Plus</MenuItem>
        <MenuItem value="Hulu">Hulu</MenuItem>{" "}
        <MenuItem value="National Geographic">National Geographic</MenuItem>
        <MenuItem value="Off Network">Off Network</MenuItem>{" "}
        <MenuItem value="SoapNet">SoapNet</MenuItem>{" "}
        <MenuItem value="Walt Disney TV Animation">
          Walt Disney TV Animation
        </MenuItem>
      </Select>
    </FormControl>
  );
};
const DfBadge = () => {
  const value = useSelector((state) => state.search.dfsBadge);
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="search-Badge-label">Badge</InputLabel>
      <Select
        labelId="search-Badge-label"
        name="dfsBadge"
        id="dfsBadge"
        label="Badge"
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="Exclusives">Exclusives</MenuItem>
        <MenuItem value="Embergoed">Embergoed</MenuItem>
        <MenuItem value="General Public">General Public</MenuItem>
        <MenuItem value="Recommended">Recommended</MenuItem>
        <MenuItem value="Killed">Killed</MenuItem>
        <MenuItem value="Restricted">Restricted</MenuItem>
      </Select>
    </FormControl>
  );
};
const SearchDaypartInput = () => {
  const value = useSelector((state) => state.search.daypart);
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="search-daypart-label">Daypart</InputLabel>
      <Select
        labelId="search-daypart-label"
        name="daypart"
        id="search-daypart"
        label="Daypart"
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="Primetime Scripted">Primetime - Scripted</MenuItem>
        <MenuItem value="Primetime Unscripted">Primetime - Unscripted</MenuItem>
        <MenuItem value="Movies">Movies</MenuItem>
        <MenuItem value="Specials">Specials</MenuItem>
        <MenuItem value="Late-Night">Late-Night</MenuItem>
        <MenuItem value="Daytime">Daytime</MenuItem>
        <MenuItem value="News">News</MenuItem>
        <MenuItem value="Sports">Sports</MenuItem>
        <MenuItem value="Mini Series">Mini Series</MenuItem>
      </Select>
    </FormControl>
  );
};
const SearchPeopleInput = () => {
  const value = useSelector((state) => state.search.people);
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">People</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        name="people"
        id="demo-simple-select-outlined"
        label="People"
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="Jimmy Kimmel">Jimmy Kimmel</MenuItem>
        <MenuItem value="Kerri Washington">Kerri Washington</MenuItem>
        <MenuItem value="Rylee Arnold">Rylee Arnold</MenuItem>
        <MenuItem value="Haylee Atwell">Haylee Atwell</MenuItem>
        <MenuItem value="Monica Aldama">Monica Aldama</MenuItem>
      </Select>
    </FormControl>
  );
};
const SearchBucketDirectory = (props) => {
  const bucketValue = useSelector((state) => state.search.bucket);
  const [bucketDirectory, setBucketDirectory] = useState([]);
  const [selected, setSelected] = useState([]);
  const userRolesData = localStorage.getItem("userRoles");
  const adminRole = JSON.parse(userRolesData);
  let buckets = [];

  useEffect(() => {
    if (adminRole.bucketBasedOnUser && adminRole.bucketBasedOnUser.length) {
      const index = adminRole.bucketBasedOnUser
        .map((object) => object.bucket)
        .indexOf(bucketValue);
      if (index !== -1) {
        if (adminRole.bucketBasedOnUser[index]?.paths) {
          adminRole.bucketBasedOnUser[index].paths.forEach((val) => {
            if (val.path) {
              buckets.push({ label: val.path });
            }
          });
        }
      }
      setBucketDirectory(buckets);
    } else {
      if (adminRole && adminRole.paths) {
        adminRole.paths.forEach((val) => {
          if (val.path) {
            buckets.push({ label: val.path });
          }
        });
      }
      setBucketDirectory(buckets);
    }
    setSelected(buckets);
    dispatch(searchSetFieldValue({ directory: buckets }));
  }, [bucketValue]);

  /**
   *
   * @param  {...any} params
   * @returns
   */
  const handleDropdownChange = (...params) => {
    if (params && params[2] === "clear") {
      dispatch(searchSetFieldValue({ directory: [] }));
      setSelected([]);
      return;
    }
    const changedOption = params[3].option;
    const selectionType = params[2]; //
    const allSelected = params[1]; //
    addRomoveUsers(allSelected, changedOption, selectionType);
  };
  /**
   *
   * @param {*} allSelected
   * @param {*} changedOption
   * @param {*} selectionType
   */
  const addRomoveUsers = (allSelected, changedOption, selectionType) => {
    if (selectionType === "select-option") {
      dispatch(searchSetFieldValue({ directory: allSelected }));
      setSelected(allSelected);
    }
    if (selectionType === "remove-option") {
      const pos = allSelected
        .map(function (e) {
          return e.label;
        })
        .indexOf(changedOption.label);
      if (pos !== -1) {
        allSelected.splice(pos, 1); // 2nd parameter means remove one item only
      }

      dispatch(searchSetFieldValue({ directory: allSelected }));
      setSelected(allSelected);
    }
  };
  return (
    <FormControl fullWidth variant="outlined">
      <Autocomplete
        multiple
        limitTags={1}
        id="tags-standard"
        options={bucketDirectory}
        disabled={props.disabled}
        classes={{ root: props.disabled ? "disabled-bg" : "" }}
        getOptionLabel={(option) => option.label || ""}
        value={selected}
        onChange={handleDropdownChange}
        filterSelectedOptions
        sx={{ width: 485 }}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label="Directory" />
        )}
      />
    </FormControl>
  );
};
export const SearchBucketInput = (
  props,
  { hideNone, disableEmpty, pageFrom, userIdFromRole, onChangeBucket }
) => {
  const value = useSelector((state) => state.search.bucket);
  const [uploadBuckets, setUploadBuckets] = useState([]);
  useEffect(() => {
    getUserByemail()
      .then((finalData) => {
        const adminRole = finalData;
        let buckets = [];
        let userId;
        if (adminRole.userData && adminRole.userData.length) {
        }
        if (props.pageFrom === "roles" && userIdFromRole) {
          userId = userIdFromRole;
        } else {
          userId = adminRole.userData[0].id;
        }
        listUserGroupsByUserId(userId)
          .then((resData) => {
            if (resData.items && resData.items.length) {
              resData.items.forEach((val) => {
                val.group.buckets.forEach((value) => {
                  let checkExist = buckets.filter((e) => e.name == value);

                  if (checkExist.length == 0) {
                    buckets.push({ name: value, displayName: value });
                  }
                });
              });
              let selectedBucket = "";
              let selectedBucketStore = "";
              if (
                localStorage.getItem("WDAMT_bucket_selected") &&
                props.pageFrom === "search"
              ) {
                selectedBucket = localStorage.getItem("WDAMT_bucket_selected");
              } else if (
                localStorage.getItem("WDAMT_bucket_selected_upload") &&
                props.pageFrom === "upload"
              ) {
                selectedBucket = localStorage.getItem(
                  "WDAMT_bucket_selected_upload"
                );
              } else if (
                localStorage.getItem("WDAMT_bucket_selected_scan") &&
                props.pageFrom === "scan"
              ) {
                selectedBucket = localStorage.getItem(
                  "WDAMT_bucket_selected_scan"
                );
              } else if (
                localStorage.getItem("WDAMT_bucket_selected_role") &&
                props.pageFrom === "roles"
              ) {
                selectedBucket = localStorage.getItem(
                  "WDAMT_bucket_selected_role"
                );
              }
              if (props.pageFrom === "search") {
                selectedBucketStore = "WDAMT_bucket_selected";
              }
              if (props.pageFrom === "upload") {
                selectedBucketStore = "WDAMT_bucket_selected_upload";
              }
              if (props.pageFrom === "scan") {
                selectedBucketStore = "WDAMT_bucket_selected_scan";
              }
              if (props.pageFrom === "roles") {
                selectedBucketStore = "WDAMT_bucket_selected_role";
              }
              if (selectedBucket) {
                dispatch(searchSetFieldValue({ bucket: selectedBucket }));
              } else {
                dispatch(
                  searchSetFieldValue({
                    bucket: value ? value : buckets[0].name,
                  })
                );
                localStorage.setItem(
                  selectedBucketStore,
                  value ? value : buckets[0].name
                );
              }
              if (props.dfstudio == "true") {
                buckets.push({ name: "DF Studio", displayName: "DF Studio" });
              }
              setUploadBuckets(buckets);
              props.setdfStudioSelectfunction(
                value == "DF Studio",
                "DF Studio"
              );
              if (props.pageFrom === "roles") {
                props.onChangeBucket(value ? value : buckets[0].name);
              }
            }
          })
          .catch(() => { });
      })
      .catch(() => { });
  }, [!uploadBuckets.length, userIdFromRole]);

  const handleInputChangeBucket = (e, pageFrom) => {
    dispatch(searchSetFieldValue({ [e.target.name]: e.target.value }));

    if (pageFrom === "search" && props?.setdfStudioSelectfunction) {
      if (e.target.value == "DF Studio") {
        clearNonDFStudioFields();
        props.setdfStudioSelectfunction(true, "DF Studio");
      } else {
        clearDFStudioFields();
        props.setdfStudioSelectfunction(false, "DF Studio");
      }
    }
    if (e.target.name === "bucket") {
      dispatch(adminRoleSetFieldValue({ [e.target.name]: e.target.value }));
      if (pageFrom === "roles") {
        props.onChangeBucket(e.target.value);
      }
    }
    if (e.target.name === "bucket" && pageFrom === "search") {
      localStorage.setItem("WDAMT_bucket_selected", e.target.value);
    }
    if (e.target.name === "bucket" && pageFrom === "upload") {
      localStorage.setItem("WDAMT_bucket_selected_upload", e.target.value);
    }
    if (e.target.name === "bucket" && pageFrom === "scan") {
      localStorage.setItem("WDAMT_bucket_selected_scan", e.target.value);
    }
    if (e.target.name === "bucket" && pageFrom === "roles") {
      localStorage.setItem("WDAMT_bucket_selected_role", e.target.value);
    }
  };

  const clearDFStudioFields = () => {
    dispatch(
      searchSetFieldValue({
        dfsId: "",
        dfsFolder: "",
        dfsProject: "",
        dfsSetup: "",
        dfsLocation: "",
        dfsFreeText: "",
      })
    );
  };

  const clearNonDFStudioFields = () => {
    dispatch(
      searchSetFieldValue({
        keywords: "",
        exifDump: "",
        job: "",
        show: "",
        name: "",
        fileType: "ALL",
        searchType:"ANY",
        category: "",
        network: "",
        country: "",
        daypart: "",
        city: "",
        year: "",
        datefilter: "",
      })
    );
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">Source</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        name="bucket"
        id="demo-simple-select-outlined"
        label="Source"
        value={value}
        onChange={(e) => {
          handleInputChangeBucket(e, props.pageFrom, props.onChangeBucket);
        }}
        onKeyDown={handleKeyDown}
      >
        {!hideNone && (
          <MenuItem value="" disabled={disableEmpty}>
            <em>None</em>
          </MenuItem>
        )}

        {uploadBuckets.map((bckt) => (
          <MenuItem key={bckt.name} value={bckt.name}>
            {bckt.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const SearchCategoryInput = ({ disabled }) => {
  const value = useSelector((state) => state.search.category);
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        name="category"
        id="demo-simple-select-outlined"
        label="Category"
        value={value}
        disabled={disabled}
        style={{ backgroundColor: disabled ? "#dfdbdb" : "" }}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="BTS">BTS</MenuItem>
        <MenuItem value="Event">Event</MenuItem>
        <MenuItem value="Gallery">Gallery</MenuItem>
        <MenuItem value="Unit">Unit</MenuItem>
        <MenuItem value="Backplates">Backplates</MenuItem>
        <MenuItem value="Selects">Selects</MenuItem>
        <MenuItem value="Full">Full</MenuItem>
      </Select>
    </FormControl>
  );
};
const DateFilterInput = ({
  handleDateFilterChange,
  handleDateFilterKeyDown,
}) => {
  const value = useSelector((state) => state.search.datefilter);

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">
        Date Filter
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        name="datefilter"
        id="demo-simple-select-outlined"
        label="Datefilter"
        value={value}
        onChange={handleDateFilterChange}
        onKeyDown={handleDateFilterKeyDown}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="before">Before</MenuItem>
        <MenuItem value="after">After</MenuItem>

        <MenuItem value="thisyear">This Year</MenuItem>
        <MenuItem value="lastyear">Last Year</MenuItem>
      </Select>
    </FormControl>
  );
};
const DateFilterLimitInput = () => {
  const value = useSelector((state) => state.search.datefilterlimit);

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">
        No Of Files
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        label="No Of Files"
        name="datefilterlimit"
        value={value}
        onChange={handleDateFilterLimitChange}
        onKeyDown={handleKeyDown}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="100">100</MenuItem>
        <MenuItem value="200">200</MenuItem>
      </Select>
    </FormControl>
  );
};

const DateSelectInput = () => {
  const value = useSelector((state) => state.search.selectedDate);
  const [datevalue, setValue] = useState(value);
  let dateRef = useRef(null);
  return (
    <FormControl variant="outlined" fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Choose date"
          value={value ? datevalue : null}
          ref={dateRef}
          onChange={(newValue) => {
            setValue(newValue);
            dispatch(searchSetFieldValue({ selectedDate: newValue }));
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

const DateRangeSelectInput = () => {
  const value = useSelector((state) => state.search.dateToDate);
  const [datevalue, setValue] = React.useState(value);
  const dateRef = useRef(null);
  useEffect(() => {
    dateRef.current.querySelector("input").value = "";
  }, [!datevalue]);
  return (
    <FormControl variant="outlined" fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Choose date"
          value={datevalue}
          ref={dateRef}
          onChange={(newValue) => {
            setValue(newValue);
            dispatch(searchSetFieldValue({ dateToDate: newValue }));
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

const SearchPhotoTypeInput = () => {
  const value = useSelector((state) => state.search.photo_type);
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="photo_type-label">Photo Type</InputLabel>
      <Select
        labelId="photo_type-label"
        name="photo_type"
        id="photo_type"
        label="Photo Type"
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="Full">Full</MenuItem>
        <MenuItem value="Selects">Selects</MenuItem>
        <MenuItem value="Exclusives">Exclusives</MenuItem>
      </Select>
    </FormControl>
  );
};
const SearchFileTypeInput = ({ disabled }) => {
  const value = useSelector((state) => state.search.fileType);
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="demo-simple-select-outlined-label">File Type</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        name="fileType"
        id="demo-simple-select-outlined"
        label="File Type"
        disabled={disabled}
        style={{ backgroundColor: disabled ? "#dfdbdb" : "" }}
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      >
        <MenuItem value="ALL">ALL</MenuItem>
        <MenuItem value="jpg">JPEG</MenuItem>
        <MenuItem value="png">PNG</MenuItem>
        <MenuItem value="raw">RAW</MenuItem>
        <MenuItem value="psd">PSD</MenuItem>
        <MenuItem value="pdf">PDF</MenuItem>
        <MenuItem value="mp4">MP4</MenuItem>
        <MenuItem value="mov">MOV</MenuItem>
        <MenuItem value="avi">AVI</MenuItem>
        <MenuItem value="wmv">WMV</MenuItem>
        <MenuItem value="webm">WEBM</MenuItem>
        <MenuItem value="flv">FLV</MenuItem>
      </Select>
    </FormControl>
  );
};

const SearcTypeInput = ({ disabled }) => {
  const value = useSelector((state) => state.search.searchType);
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        name="searchType"
        id="demo-simple-select-outlined"
        label="Type"
        disabled={disabled}
        style={{ backgroundColor: disabled ? "#dfdbdb" : "" }}
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      >
        <MenuItem value="ANY">ANY</MenuItem>
        <MenuItem value="ALL">ALL</MenuItem>
        <MenuItem value="EXACT">EXACT</MenuItem>

      </Select>
    </FormControl>
  );
};
const ScanTypeInput = ({ disabled }) => {
  const value = useSelector((state) => state.search.type);
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        name="type"
        id="demo-simple-select-outlined"
        label="Type"
        disabled={disabled}
        value={value}
        style={{ backgroundColor: disabled ? "#dfdbdb" : "" }}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      >
        <MenuItem value="Select">Select</MenuItem>
        <MenuItem value="Raw">Raw</MenuItem>
        <MenuItem value="Full">Full</MenuItem>
      </Select>
    </FormControl>
  );
};
const SearchYearInput = () => {
  const value = useSelector((state) => state.search.year);
  return (
    <TextField
      value={value}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="year"
      id="Year"
      label="Year"
      variant="outlined"
      fullWidth
    />
  );
};
const SearchRecognizeInputs = ({
  showRecognize,
  recognizeObject,
  recognizeCelebrities,
}) => {
  const classes = useStyles();
  const textRekogChecked = useSelector((state) => state.search.textRekog);
  const celebrityRekogChecked = useSelector(
    (state) => state.search.celebrityRekog
  );
  const objectRekogChecked = useSelector((state) => state.search.objectRekog);
  return (
    <Grid container>
      {showRecognize && (
        <Grid className={classes.gridItem} item xs={12} sm={6} md={4} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={textRekogChecked}
                onChange={handleCheckboxChange}
                name="textRekog"
                color="primary"
              />
            }
            label="Recognize Text"
            labelPlacement="start"
          />
        </Grid>
      )}
      {recognizeObject && (
        <Fragment>
          <Grid className={classes.gridItem} item xs={12} sm={6} md={4} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={objectRekogChecked}
                  onChange={handleCheckboxChange}
                  name="objectRekog"
                  color="primary"
                />
              }
              label="Recognize Objects"
              labelPlacement="start"
            />
          </Grid>
        </Fragment>
      )}
      {recognizeCelebrities && (
        <Fragment>
          <Grid className={classes.gridItem} item xs={12} sm={6} md={4} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={celebrityRekogChecked}
                  onChange={handleCheckboxChange}
                  name="celebrityRekog"
                  color="primary"
                />
              }
              label="Recognize Celebrities"
              labelPlacement="start"
            />
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};
const SearchKeywordsInput = ({ disabled, label }) => {
  const value = useSelector((state) => state.search.keywords);
  return (
    <TextField
      value={value}
      disabled={disabled}
      classes={{ root: disabled ? "disabled-bg" : "" }}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="input-style"
      name="keywords"
      id="keywords"
      label="Text / Keywords"
      variant="outlined"
      fullWidth
    />
  );
};
const SearchForm = (props) => {
  const {
    showBucket,
    showRecognize,
    showFileNewType,
    keywordLabel,
    showExif,
    generalSearch,
    additionalSearch,
    dateFilter,
    dfStudio,
    recognizeObject,
    recognizeCelebrities,
    pageFromScan,
  } = props;

  const classes = useStyles();
  const selectedBucket = useSelector((state) => state.search.bucket);
  const { isAdmin } = useAppUserContext();
  const actions = useSelector((state) => state.collapse.actions);
  const [selectedDir, setSelectedDir] = useState("/");
  const [showAssetBrowser, setShowAssetBrowser] = useState(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showRangePicker, setRangeDatePicker] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [uploadBuckets, setUploadBuckets] = useState([]);
  const [isShowUpdated, setIsShowUpdated] = useState(false);
  const [noOfFile, setNoOfFile] = useState(false);
  const [dfStudioSelect, setdfStudioSelect] = useState(false);
  const [showDirectory, setShowDirectory] = useState(false);
  const selectDFSIDSearchValue = useSelector((state) => state.search.dfsId);
  const selectDFSFolderSearchValue = useSelector(
    (state) => state.search.dfsFolder
  );
  const selectDFSProjectSearchValue = useSelector(
    (state) => state.search.dfsProject
  );
  const selectDFSSetupSearchValue = useSelector(
    (state) => state.search.dfsSetup
  );
  const selectDFSLocationSearchValue = useSelector(
    (state) => state.search.dfsLocation
  );
  const [activeDFSField, setDFSField] = useState("");

  /**
   * store selector
   */
  const adminrolelist = useSelector((state) => state.roleslist);

  const [watermarkImages, setWatermarkImages] = useState(localStorage.getItem('watermarkImages') || 'N');

  useEffect(() => {
    const enableDir = getDirectory(selectedBucket);
    setShowDirectory(enableDir && enableDir.length ? true : false);
  }, [selectedBucket]);
  useEffect(() => {

    if (pageFromScan) {
      handleToggleChange(actions, "status");
    } else {
      let details = JSON.parse(JSON.stringify(actions));
      details["status"] = false;
      details["dfstudio"] = true;
      dispatch(collapseSetFieldValue({ actions: details }));
    }
    if (actions.datefilter) {
      setShowDatePicker(true);
    }
    const enableDir = getDirectory(selectedBucket);
    setShowDirectory(enableDir && enableDir.length ? true : false);
    getUserByemail()
      .then((finalData) => {
        const adminRole = finalData;
        let buckets = [];
        if (adminRole.userData && adminRole.userData.length) {
        }
        listUserGroupsByUserId(adminRole.userData[0].id)
          .then((resData) => {
            if (resData.items && resData.items.length) {
              resData.items.forEach((val) => {
                val.group.buckets.forEach((value) => {
                  buckets.push({ name: value, displayName: value });
                });
              });
              setUploadBuckets(buckets);
            }
          })
          .catch(() => { });
      })
      .catch(() => { });

    store.subscribe(() => {
      const currentState = store.getState();
      let disbale = true;
      if (currentState && currentState.search) {
        for (const [key, value] of Object.entries(searchPage)) {
          if (currentState.search[key]) {
            disbale = false;
          }
        }
        setEnableSubmit(disbale);
      }
    });
  }, []);

  useEffect(() => {
    if (selectDFSIDSearchValue === undefined || selectDFSIDSearchValue === "") {
      return setDFSField("");
    } else {
      return setDFSField("dfsId");
    }
  }, [selectDFSIDSearchValue]);

  useEffect(
    (value) => {
      if (
        selectDFSFolderSearchValue === undefined ||
        selectDFSFolderSearchValue === ""
      ) {
        return setDFSField("");
      } else {
        return setDFSField("dfsFolder");
      }
    },
    [selectDFSFolderSearchValue]
  );

  useEffect(
    (value) => {
      if (
        selectDFSProjectSearchValue === undefined ||
        selectDFSProjectSearchValue === ""
      ) {
        return setDFSField("");
      } else {
        return setDFSField("dfsProject");
      }
    },
    [selectDFSProjectSearchValue]
  );

  useEffect(
    (value) => {
      if (
        selectDFSSetupSearchValue === undefined ||
        selectDFSSetupSearchValue === ""
      ) {
        return setDFSField("");
      } else {
        return setDFSField("dfsSetup");
      }
    },
    [selectDFSSetupSearchValue]
  );

  useEffect(
    (value) => {
      if (
        selectDFSLocationSearchValue === undefined ||
        selectDFSLocationSearchValue === ""
      ) {
        return setDFSField("");
      } else {
        return setDFSField("dfsLocation");
      }
    },
    [selectDFSLocationSearchValue]
  );

  const handleDirChange = useCallback((event) => {
    if (event.target.value) {
      dispatch(searchSetFieldValue({ destinationPath: event.target.value }));
      setSelectedDir(event.target.value);
    }
  }, []);
  const handleShowAssetBrowser = useCallback(() => {
    setShowAssetBrowser(true);
  }, []);
  const handleDirSelect = useCallback(({ dir }) => {
    setSelectedDir(dir);
    setShowAssetBrowser(false);
  }, []);

  const handleDateFilterChange = (e) => {
    setNoOfFile(false);
    setShowDatePicker(false);
    setRangeDatePicker(false);
    setSelectedDateFilter(e.target.value);
    if (e.target.value !== "") {
      setNoOfFile(true);
    }
    if (e.target.value === "before" || e.target.value === "after") {
      setShowDatePicker(true);
    }
    if (e.target.value === "range") {
      setShowDatePicker(true);
      setRangeDatePicker(true);
    }
    dispatch(searchSetFieldValue({ [e.target.name]: e.target.value }));
  };
  const handleDateFilterKeyDown = (e) => {
    if (enableSubmit) {
      return;
    }
    setSelectedDateFilter(e.target.value);
    dispatch(searchSetFieldValue({ [e.target.name]: e.target.value }));
    if (e.key === "Enter") {
      dispatch(searchSetFieldValue({ isFromSearch: true }));
      goTo("/search/results");
    }
  };
  const onShowUpdated = (event) => {
    setIsShowUpdated(event);
  };

  const dfStudioChangeFunction = (value, type) => {
    setdfStudioSelect(value);
    if (type == "DF Studio") {
      handleToggleChange(actions, "dfstudio");
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        {generalSearch && (
          <Fragment>
            {showBucket && (
              <Fragment>
                <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                  {dfStudio ? (
                    <SearchBucketInput
                      setdfStudioSelectfunction={dfStudioChangeFunction}
                      dfstudio="true"
                      pageFrom="search"
                    />
                  ) : (
                    <SearchBucketInput pageFrom="search" />
                  )}
                </Grid>
              </Fragment>
            )}
            {showBucket && showDirectory && (
              <Fragment>
                <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                  <SearchBucketDirectory
                    disabled={dfStudioSelect}
                    selectedBucket={selectedBucket}
                  />
                </Grid>
              </Fragment>
            )}
            {showBucket && (
              <Grid
                className={classes.gridItem}
                item
                xs={12}
                sm={6}
                md={showDirectory ? 6 : 9}
              >
                <SearchKeywordsInput
                  disabled={dfStudioSelect}
                  label={keywordLabel || "Keywords"}
                />
              </Grid>
            )}
            {showExif && (
              <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                <SearchExifInput disabled={dfStudioSelect} />
              </Grid>
            )}

            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <SearchJobNumberInput disabled={dfStudioSelect} />
            </Grid>
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <SearchShowInput
                disabled={dfStudioSelect}
                isShowUpdated={isShowUpdated}
              />
            </Grid>

            {showFileNewType && (
              <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                <SearchShowNewInput
                  disabled={dfStudioSelect}
                  onShowUpdated={onShowUpdated}
                />
              </Grid>
            )}

            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <SearchNameInput disabled={dfStudioSelect} />
            </Grid>

            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <SearchFileTypeInput disabled={dfStudioSelect} />
            </Grid>
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <SearchCategoryInput disabled={dfStudioSelect} />
            </Grid>
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <FormControlLabel
                  style={{ marginLeft: "0px !important" }}
                  control={
                      <Checkbox
                      checked={(watermarkImages || 'N') === 'Y'}
                      onChange={(e) => {
                        console.log(e.target.name + ' is ', e.target.checked);
                        setWatermarkImages((e.target.checked === true) ? 'Y' : 'N');
                        localStorage.setItem('watermarkImages', (e.target.checked === true) ? 'Y' : 'N');
                      }}
                      name="watermarkImages"
                      color="primary"
                      />
                  }
                  label="Watermark Images"
                  labelPlacement="end"
              />  
            </Grid>
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <SearcTypeInput disabled={dfStudioSelect} />
            </Grid>
          </Fragment>
        )}
        {additionalSearch && (
          <Grid className={classes.gridItem} item xs={12} sm={12} md={12}>
            <div style={{ marginBottom: "10px" }}>
              <h3>
                {dfStudioSelect ? (
                  <span style={{ paddingRight: "10px", cursor: "pointer" }}>
                    {actions.status ? "-" : "+"}
                  </span>
                ) : (
                  <span
                    style={{ paddingRight: "10px", cursor: "pointer" }}
                    onClick={() => handleToggleChange(actions, "status")}
                  >
                    {actions.status ? "-" : "+"}
                  </span>
                )}
                Additional {showBucket ? "Search" : "Data"} Fields
              </h3>
            </div>
          </Grid>
        )}
        {/* <Collapse in={actions.jobNumber}> */}
        {actions.status && (
          <Fragment>
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <SearchYearInput />
            </Grid>
            {pageFromScan && (
              <>
                <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                  <SearchJobNumberInput />
                </Grid>
                <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                  <SearchShowInput isShowUpdated={isShowUpdated} />
                </Grid>
                <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                  <SearchShowNewInput onShowUpdated={onShowUpdated} />
                </Grid>
              </>
            )}
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <SearchNetworkInput />
            </Grid>
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <SearchCountryInput />
            </Grid>
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <SearchDaypartInput />
            </Grid>
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <SearchCityInput />
            </Grid>
            {pageFromScan && (
              <>
                <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                  <ScanTypeInput />
                </Grid>
              </>
            )}
          </Fragment>
        )}
        {dfStudio && dfStudioSelect && (
          <Fragment>
            <Grid className={classes.gridItem} item xs={12} sm={12} md={12}>
              <div style={{ marginBottom: "10px" }}>
                <h3>
                  <span
                    style={{ paddingRight: "10px", cursor: "pointer" }}
                    onClick={() => handleToggleChange(actions, "dfstudio")}
                  >
                    {actions.dfstudio ? "-" : "+"}
                  </span>
                  DF Studio Fields
                </h3>
              </div>
            </Grid>
          </Fragment>
        )}

        {actions.dfstudio && dfStudioSelect && (
          <Fragment>
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <DfsID
                disabled={activeDFSField !== "dfsId" && activeDFSField !== ""}
              />
            </Grid>
            {/* <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <DfFolderInput
                disabled={
                  activeDFSField !== "dfsFolder" && activeDFSField !== ""
                }
              />
            </Grid> */}
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <DfProject
                disabled={
                  activeDFSField !== "dfsProject" && activeDFSField !== ""
                }
              />
            </Grid>
            {/* <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <DfSetup
                disabled={
                  activeDFSField !== "dfsSetup" && activeDFSField !== ""
                }
              />
            </Grid> */}
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <DfsLocation
                disabled={
                  activeDFSField !== "dfsLocation" && activeDFSField !== ""
                }
              />
            </Grid>
            <Grid className={classes.gridItem} item xs={12} sm={6} md={9}>
              <DfsFreeText />
            </Grid>
          </Fragment>
        )}
        {dateFilter && (
          <Fragment>
            <Grid className={classes.gridItem} item xs={12} sm={12} md={12}>
              <div style={{ marginBottom: "10px" }}>
                <h3>
                  {dfStudioSelect ? (
                    <span style={{ paddingRight: "10px", cursor: "pointer" }}>
                      {actions.datefilter ? "-" : "+"}
                    </span>
                  ) : (
                    <span
                      style={{ paddingRight: "10px", cursor: "pointer" }}
                      onClick={() => handleToggleChange(actions, "datefilter")}
                    >
                      {actions.datefilter ? "-" : "+"}
                    </span>
                  )}
                  Date Filtering
                </h3>
              </div>
            </Grid>
          </Fragment>
        )}
        {actions.datefilter && (
          <Fragment>
            <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
              <DateFilterInput
                handleDateFilterChange={handleDateFilterChange}
                handleDateFilterKeyDown={handleDateFilterKeyDown}
              />
            </Grid>
            {showDatePicker ? (
              <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                <DateSelectInput />
              </Grid>
            ) : null}

            {showRangePicker ? (
              <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                <DateRangeSelectInput />
              </Grid>
            ) : null}

            {/* {noOfFile ? (
              <Grid className={classes.gridItem} item xs={12} sm={6} md={3}>
                <DateFilterLimitInput />
              </Grid>
            ) : null} */}
          </Fragment>
        )}
      </Grid>
      {(showRecognize || recognizeObject || recognizeCelebrities) && (
        <Fragment>
          <h3>Enable Image Recognition as required...</h3>
          <SearchRecognizeInputs
            showRecognize={showRecognize}
            recognizeObject={recognizeObject}
            recognizeCelebrities={recognizeCelebrities}
          />
        </Fragment>
      )}
    </React.Fragment>
  );
};

export default SearchForm;
