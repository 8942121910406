import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";
import Button from "../button/Button";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import CardBody from "../card/CardBody";
import CardFooter from "../card/CardFooter";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";

import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from "@material-ui/core/InputLabel";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
   formControl: {
    minWidth: 120,
  },
};

const useStyles = makeStyles(styles);

export default function ManageData() {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Add Meta Data</h4>
        
      </CardHeader>
      <CardBody>
      <GridContainer>
              <GridItem xs={5} sm={12} md={5}>
                  <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="Name" label="Name" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                            <TextField
                    id="outlined-multiline-static"
                    label="Media Description"
                    multiline
                    rows={4}
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                  />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                          <FormControl fullWidth variant="outlined" className={classes.formControl} fullwidth>
                        <InputLabel id="demo-simple-select-outlined-label">Network</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Network"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>ABC</MenuItem>
                            <MenuItem value={20}>FREEFORM</MenuItem>
                            <MenuItem value={30}>Disney Channel</MenuItem>
                            <MenuItem value={40}>Disney Junior</MenuItem>
                            <MenuItem value={50}>Disney XD</MenuItem>
                            <MenuItem value={60}>Radio Disney</MenuItem>
                            <MenuItem value={70}>Corporate</MenuItem>
                        </Select>
                      </FormControl>
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Show</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Show"
                    >
                      <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>	A Bugs Life	</MenuItem>
                    <MenuItem value={2}>	A Charlie Brown Christmas	</MenuItem>
                    <MenuItem value={3}>	A Cinderella Story: If the Shoe Fits	</MenuItem>
                    <MenuItem value={4}>	A Million Little Things	</MenuItem>
                    <MenuItem value={6}>	A Modern Farewell	</MenuItem>
                    <MenuItem value={7}>	A Taste of Heritage Sizzle	</MenuItem>
                    <MenuItem value={8}>	A Taste of Summer	</MenuItem>
                    <MenuItem value={9}>	ABC Alternative Series Announcement	</MenuItem>
                    <MenuItem value={10}>	ABC Announcement	</MenuItem>
                    <MenuItem value={11}>	ABC Audio	</MenuItem>
                    <MenuItem value={12}>	ABC Bundle	</MenuItem>
                    <MenuItem value={13}>	ABC Celebrate Pride Month	</MenuItem>
                    <MenuItem value={14}>	ABC Comedy Premiere Dates	</MenuItem>
                    <MenuItem value={15}>	ABC Executive Bio update	</MenuItem>
                    <MenuItem value={16}>	ABC Family Oscars	</MenuItem>
                    <MenuItem value={17}>	American Housewife	</MenuItem>
                    <MenuItem value={18}>	American Idol	</MenuItem>
                    <MenuItem value={19}>	American Music Awards	</MenuItem>
                    <MenuItem value={20}>	America's Funniest Home Videos	</MenuItem>
                    <MenuItem value={21}>	Americas Funniest Videos	</MenuItem>
                    <MenuItem value={22}>	Amphibia	</MenuItem>
                    <MenuItem value={23}>	ANC Halloween 2019	</MenuItem>
                    <MenuItem value={24}>	and "She's a Good Skate, Charlie Brown"	</MenuItem>
                    <MenuItem value={25}>	Andi Mack	</MenuItem>
                    <MenuItem value={26}>	Angel Falls: A Novel Holiday	</MenuItem>
                    <MenuItem value={27}>	Angry Angel	</MenuItem>
                    <MenuItem value={28}>	Anti-Cyberbullying PSA's	</MenuItem>
                    <MenuItem value={29}>	April Highlights	</MenuItem>
                    <MenuItem value={30}>	arachnophobia	</MenuItem>
                    </Select>
                  </FormControl>
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="season" label="Season No" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                 
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Daypart</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="People"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Primetime - Scripted</MenuItem>
                        <MenuItem value={20}>Primetime - Unscripted</MenuItem>
                        <MenuItem value={30}>Movies</MenuItem>
                        <MenuItem value={40}>Specials</MenuItem>
                        <MenuItem value={50}>Late-Night</MenuItem>
                        <MenuItem value={60}>Daytime</MenuItem>
                        <MenuItem value={70}>News</MenuItem>
                        <MenuItem value={80}>Sports</MenuItem>
                    </Select>
                  </FormControl>
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="Name" label="Episode Name" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="Name" label="Job" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                  
                </GridContainer>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
              </GridItem>
              <GridItem xs={5} sm={12} md={5}>
              <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="Name" label="Media File Name" variant="outlined" />
              </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Format</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="People"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>JPEG</MenuItem>
                  <MenuItem value={20}>RAW (ARW ,CR2 , NEF) </MenuItem>
                  <MenuItem value={30}>Tiff</MenuItem>
                  <MenuItem value={40}>PDF</MenuItem>
                    
                  </Select>
                </FormControl>
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">People</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="People"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Jimmy Kimmel</MenuItem>
                    <MenuItem value={20}>Kerri Washington</MenuItem>
                    <MenuItem value={30}>Rylee Arnold</MenuItem>
                    <MenuItem value={40}>Haylee Atwell</MenuItem>
                    <MenuItem value={40}>Monica Aldama</MenuItem>
                  </Select>
                </FormControl>

                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Location</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="People"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                   
                  </Select>
                </FormControl>

                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Media Attributes Data</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="ImageAttributesData"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                   
                  </Select>
                </FormControl>

                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Camera Attributes Data</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="CameraAttributesData"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  </Select>
                </FormControl>
                  </GridItem>
                  
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="Name" label="Year" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Keyword</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Keyword"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  
                  </Select>
                </FormControl>
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Bucket</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Keyword"
                  >
                     <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Bucket Region-1</MenuItem>
                      <MenuItem value={20}>Bucket Region-2</MenuItem>
                    
                  
                  </Select>
                </FormControl>
                <span> &nbsp; </span>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Directory</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Keyword"
                  >
                     <MenuItem value="">
                        <em>None</em>
                      </MenuItem>                     
                      <MenuItem value={10}>Directory-1</MenuItem>
                      <MenuItem value={20}>Directory-2</MenuItem>              
                  </Select>
                </FormControl>
                  </GridItem>
                </GridContainer>
              </GridItem>

            </GridContainer>
            
      </CardBody>
      <CardFooter>

      <Grid container justify='center'>
      <Grid item justify='center' xs={5} sm={12} md={2}>
      <Button fullWidth color="primary">Submit</Button>
      </Grid>
      </Grid>  
            </CardFooter>
    </Card>
  );
}
