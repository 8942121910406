import React, { useState, useEffect, useCallback } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import CardBody from "../card/CardBody";
import CardFooter from "../card/CardFooter";
import CancelIcon from "@material-ui/icons/Cancel";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "../button/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import invoke from "service/lambda.service";
import { awsConfig } from "config";
import config from "../../aws-exports";
import { apiListShows, buildElasticSearchFilter, } from "service/api.service";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAppUserContext } from "lib/contextLib";
import { useSelector } from "react-redux";

const styles = {
  dialogTitle: {
    display: "flex",
    "justify-content": "center",
    padding: "35px 24px",
  },
  dialogCard: {
    "margin-top": "0",
    "min-height": "300px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontSize: "20px",
    textAlign: "center",
    width: "300px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  updateBtn: {
    fontSize: "20px",
  },
  closeBtn: {
    textAlign: "right",
    paddingRight: "40px",
    paddingBottom: "10px",
  },
};

const useStyles = makeStyles(styles);

const networkList = {
  ABC: "ABC",
  FREEFORM: "FREEFORM",
  "Disney Channel": "Disney Channel",
  "Disney Junior": "Disney Junior",
  "Disney XD": "Disney XD",
  "Radio Disney": "Radio Disney",
  Corporate: "Corporate",
  "Disney Plus": "Disney Plus",
  Hulu: "Hulu",
  "National Geographic": "National Geographic",
  "Off Network": "Off Network",
  SoapNet: "SoapNet",
  "Walt Disney TV Animation": "Walt Disney TV Animation",
};

const dayPartList = {
  "Primetime Scripted": "Primetime - Scripted",
  "Primetime Unscripted": "Primetime - Unscripted",
  Movies: "Movies",
  Specials: "Specials",
  "Late-Night": "Late-Night",
  Daytime: "Daytime",
  News: "News",
  Sports: "Sports",
  "Mini Series": "Mini Series",
};

const BulkUpdateModal = ({
  downloadType,
  selectedObj,
  modalClose,
  onSuccess,
  onFailure,
}) => {
  const classes = useStyles();

  const [bulkUpdateValue, setBulkUpdateValue] = useState({});

  const [showLoading, setShowLoading] = useState(false);

  const currentUser = useAppUserContext();
  const searchObj = useSelector((state) => state.search);
  const handleBulkUpdate = async () => {
    let selectedFileList = [];
    if (
      (selectedObj && Object.keys(selectedObj).length) ||
      downloadType === "downloadAll"
    ) {
      if (downloadType === "downloadAll") {
        selectedFileList.push("*");
      } else {
        Object.values(selectedObj).forEach((el) => {
          if (el.mediaFilename) {
            selectedFileList.push(el.mediaFilename);
          }
        });
      }

      let searObj;
      if (downloadType === "downloadAll") {
        const searchobj = Object.entries(searchObj).reduce(
          (a, [k, v]) => (v ? ((a[k] = v), a) : a),
          {}
        );

        let elasticSearchParams;
        await buildElasticSearchFilter(null, null).then((resp) => {
          elasticSearchParams = resp;
        });
        searObj = {
          region: config.aws_project_region,
          action: "metadata-update",
          files: selectedFileList,
          fields: bulkUpdateValue,
          searchParams: searchobj ? elasticSearchParams : "",
        }
      } else {
        searObj = {
          region: config.aws_project_region,
          action: "metadata-update",
          files: selectedFileList,
          fields: bulkUpdateValue,

        }
      }
      setShowLoading(true);
      invoke("wdtvamtoolCollectionUtils-" + awsConfig.environment, searObj, currentUser.creds)
        .then((cogRes) => {
          setShowLoading(false);
          onSuccess();
          setBulkUpdateValue({});
        })
        .catch((err) => {
          setShowLoading(false);
          onFailure();
        });
    }
  };

  const handlOnClose = () => {
    modalClose();
  };

  const onChangeHandler = (event) => {
    if (event.target.name !== "size") {
      setBulkUpdateValue({
        ...bulkUpdateValue,
        ...{ [event.target.name]: event.target.value },
      });
    }
    if (event.target.name === "size") {
      setBulkUpdateValue({
        ...bulkUpdateValue,
        ...{ awsS3FileLocation: { [event.target.name]: event.target.value } },
      });
    }
  };

  const SearchShowInput = () => {
    const [shows, setShows] = useState([]);
    useEffect(() => {
      fetchSearchResults();
    }, []);
    const fetchSearchResults = useCallback(() => {
      let data = [];
      apiListShows()
        .then(({ items }) => {
          if (items && items.length) {
            items.forEach((val, ind) => {
              data.push({
                show: val.name,
              });
            });
          }
          setShows(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }, []);

    const value = bulkUpdateValue.show;
    const handleDropdownChange = useCallback((e) => {
      setBulkUpdateValue({
        ...bulkUpdateValue,
        ...{ show: e.target.textContent },
      });
    }, []);

    return (
      <FormControl variant="outlined" fullWidth>
        <Autocomplete
          id="combo-box-demo"
          options={shows}
          getOptionLabel={(option) => option.show}
          value={{ show: value }}
          onChange={handleDropdownChange}
          renderInput={(params) => (
            <TextField {...params} label="Show" variant="outlined" fullWidth />
          )}
          fullWidth
        />
      </FormControl>
    );
  };
  const SearchFileTypeInput = () => {
    return (
      <FormControl variant="outlined" fullWidth>
        <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
          <CircularProgress />
        </Backdrop>
        <InputLabel id="demo-simple-select-outlined-label">
          File Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          name="fileType"
          id="demo-simple-select-outlined"
          label="File Type"
          value={bulkUpdateValue.fileType}
          onChange={onChangeHandler}
        >
          <MenuItem value="ALL">ALL</MenuItem>
          <MenuItem value="jpg">JPEG</MenuItem>
          <MenuItem value="png">PNG</MenuItem>
          <MenuItem value="raw">RAW</MenuItem>
          <MenuItem value="tiff">TIFF</MenuItem>
        </Select>
      </FormControl>
    );
  };
  const SearchCategoryInput = () => {
    return (
      <FormControl variant="outlined" fullWidth>
        <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
          <CircularProgress />
        </Backdrop>
        <InputLabel id="demo-simple-select-outlined-label">
          Category
        </InputLabel>
        <Select
          style={{ minWidth: 220 }}
          labelId="demo-simple-select-outlined-label"
          name="category"
          id="demo-simple-select-outlined"
          label="Category"
          value={bulkUpdateValue.category}
          onChange={onChangeHandler}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="BTS">BTS</MenuItem>
          <MenuItem value="Event">Event</MenuItem>
          <MenuItem value="Gallery">Gallery</MenuItem>
          <MenuItem value="Unit">Unit</MenuItem>
          <MenuItem value="Backplates">Backplates</MenuItem>
          <MenuItem value="Selects">Selects</MenuItem>
          <MenuItem value="Full">Full</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const SearchTypeInput = () => {
    return (
      <FormControl variant="outlined" fullWidth>
        <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
          <CircularProgress />
        </Backdrop>
        <InputLabel id="demo-simple-select-outlined-label">
          Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          name="type"
          id="demo-simple-select-outlined"
          label="Type"
          value={bulkUpdateValue.type}
          onChange={onChangeHandler}
        >
          <MenuItem value="Select">Select</MenuItem>
          <MenuItem value="Raw">Raw</MenuItem>
          <MenuItem value="Full">Full</MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Update Metadata</h4>
        </CardHeader>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "primary" }}>
          <Card className={classes.dialogCard}>
            <CardBody>
              <GridContainer>
                <GridItem xs={5} sm={12} md={5}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <SearchShowInput />
                    </GridItem>
                    <span> &nbsp; </span>
                    <GridItem xs={12} sm={12} md={12}>
                      <InputLabel id="search-network-label">Network</InputLabel>
                      <Select
                        fullWidth
                        value={bulkUpdateValue.network}
                        labelId="search-network-label"
                        id="search-network-label"
                        label="Network"
                        name="network"
                        onChange={onChangeHandler}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {Object.values(networkList).map((data) => (
                          <MenuItem value={data} key={data}>
                            {data}
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                    <span> &nbsp; </span>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        className="input-style"
                        fullWidth
                        id="City"
                        name="city"
                        label="City"
                        variant="outlined"
                        onChange={onChangeHandler}
                      />
                    </GridItem>
                    <span> &nbsp; </span>
                    <GridItem xs={12} sm={12} md={12}>
                      <SearchFileTypeInput />
                    </GridItem>
                    <span> &nbsp; </span>
                    <GridItem xs={12} sm={12} md={12}>
                      <SearchCategoryInput />
                    </GridItem>
                    <span> &nbsp; </span>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        className="input-style"
                        fullWidth
                        id="SavedUploadName"
                        name="name"
                        label="Saved Upload Name"
                        variant="outlined"
                        onChange={onChangeHandler}
                      />
                    </GridItem>
                    <span> &nbsp; </span>
                  </GridContainer>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}></GridItem>
                <GridItem xs={5} sm={12} md={5}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        className="input-style"
                        fullWidth
                        id="JobNumber"
                        name="job"
                        label="JobNumber"
                        variant="outlined"
                        onChange={onChangeHandler}
                      />
                    </GridItem>
                    <span> &nbsp; </span>
                    <GridItem xs={12} sm={12} md={12}>
                      <InputLabel id="search-daypart-label">
                        Day Part
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="search-daypart-label"
                        value={bulkUpdateValue.daypart}
                        label="Daypart"
                        name="daypart"
                        id="search-daypart"
                        onChange={onChangeHandler}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {Object.values(dayPartList).map((data) => (
                          <MenuItem value={data} key={data}>
                            {data}
                          </MenuItem>
                        ))}
                      </Select>
                    </GridItem>
                    <span> &nbsp; </span>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        className="input-style"
                        fullWidth
                        id="Country"
                        name="country"
                        label="Country"
                        variant="outlined"
                        onChange={onChangeHandler}
                      />
                    </GridItem>
                    <span> &nbsp; </span>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        className="input-style"
                        fullWidth
                        id="Size"
                        name="size"
                        label="Size"
                        variant="outlined"
                        onChange={onChangeHandler}
                      />
                    </GridItem>
                    <span> &nbsp; </span>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              {" "}
              <Grid container justify="center">
                <Grid item justify="center" xs={5} sm={12} md={2}>
                  {" "}
                  <Button
                    fullWidth
                    color="primary"
                    className={classes.updateBtn}
                    onClick={handleBulkUpdate}
                    disabled={
                      !Object.values(bulkUpdateValue).some(
                        (updatedValue) => updatedValue !== ""
                      )
                    }
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </CardFooter>
          </Card>
        </Box>
      </DialogContent>
      <div className={classes.closeBtn}>
        <Button
          contained
          color="secondary"
          startIcon={<CancelIcon />}
          onClick={handlOnClose}
        >
          Close Form
        </Button>
      </div>
    </>
  );
};

export default BulkUpdateModal;
