import { createAction, createReducer } from "@reduxjs/toolkit";
export const searchSetFieldValue = createAction("SEARCH_SET_FIELD_VALUE");
export const searchResetValues = createAction("SEARCH_RESET_VALUES");

const defaultState = {
  searchText: "",
  destinationPath: "",
  destinationFolder: "",
  name: "",
  network: "",
  show: "",
  showtitle: "",
  daypart: "",
  job: "",
  people: "",
  location: "",
  city: "",
  country: "",
  bucket: "",
  directory: [],
  region: "",
  category: "",
  photo_type: "",
  exifDump: "",
  fileType: "ALL",
  searchType:"ANY",
  type: "",
  year: "",
  dfName: "",
  dfId: "",
  dfProject: "",
  dfSetup: "",
  dfBadge: "",
  celebrityRekog: false,
  objectRekog: false,
  textRekog: false,
  keywords: "",
  selectedDate: null,
  dateToDate: null,
  datefilter: "",
  datefilterlimit: "",
  isFromSearch: false,
  dfsBadge: "",
  dfsId: "",
  dfsFolder: "",
  dfsProject: "",
  dfsSetup: "",
  dfsLocation: "",
  dfsFreeText: ""
};

const searchFormReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(searchSetFieldValue, (state, { payload }) => {
      Object.assign(state, payload);
      return state;
    })
    .addCase(searchResetValues, () => ({
      ...defaultState,
      bucket: localStorage.getItem("WDAMT_bucket_selected"),
    }));
});

export default searchFormReducer;
