import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      display: 'flex',
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  }),
);

const AssetBrowserBreadcrumbItem = ({ path, label, openDir }) => {
  const classes = useStyles();
  const handleClick = (event) => {
    event.preventDefault();
    openDir(path);
  }
  return (<Link color="inherit" href='#' onClick={handleClick} className={classes.link}>{label}</Link>)
}

export default function AssetBrowserBreadcrumbs({ bucket, currentPath, openDir }) {
  const classes = useStyles();
  let prefix = '/';
  const navPaths = currentPath.slice(1, -1).split('/').map(path => {
    prefix += path + '/';
    return { path: prefix, label: path };
  });
  const activePath = navPaths.pop();
  return (<Breadcrumbs aria-label="breadcrumb">
    <Link color={currentPath === '/' ? 'textPrimary' : 'inherit'} href='#' onClick={() => openDir('/')} className={classes.link}><HomeIcon className={classes.icon} />{bucket}</Link>
    {navPaths.map((item, i) => (<AssetBrowserBreadcrumbItem key={item.path} path={item.path} label={item.label} openDir={openDir} isActive={navPaths.length === i + 1} />))}
    {activePath && <Typography color="textPrimary">{activePath.label}</Typography>}
  </Breadcrumbs>)
}