import React from 'react';
import SidebarNavItem from 'components/sidebar/SidebarNavItem';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TrafficIcon from '@material-ui/icons/Traffic';

export default function UploadLeftSideBar() {
  return <>
    <SidebarNavItem to="/upload" label="Upload New" Icon={CloudUploadIcon} matchExtact />
    <SidebarNavItem to="/upload/progress" label="Transfer Status" Icon={TrafficIcon}/>
  </>
}