import { createAction, createReducer } from '@reduxjs/toolkit';

export const setUploadBucket = createAction('SET_UPLOAD_BUCKET');
export const setUploadPath = createAction('SET_UPLOAD_PATH');
export const addNewUploadTransfer = createAction('ADD_NEW_TRANSFER');
export const updateTransferStatus = createAction('UPDATE_TRANSFER_STATUS');
export const removeTransferStatus = createAction('REMOVE_TRANSFER_STATUS');

const updateFileStatus = (sourceFiles, fileStatus) => {
  let sourceFile = sourceFiles.find(o => o.name === fileStatus.file);
  if (!sourceFile) {
    sourceFile = sourceFiles[sourceFiles.push({ name: fileStatus.file, type: 'unknown', size: fileStatus.bytes_expected }) - 1];
  }
  if (sourceFile) {
    sourceFile.transferedSize = fileStatus.bytes_written;
    sourceFile.attempted = 1;
    sourceFile.completed = (fileStatus.bytes_written >= fileStatus.bytes_expected) ? 1 : 0;
    sourceFile.failed = 0;
    sourceFile.remarks = (fileStatus.error && fileStatus.error.description) ? fileStatus.error.description : '';
  } else {
    console.debug('unable to find source file', fileStatus.file)
  }
}

const defaultState = [
  {
    transferId: 0,
    bucket: '',
    destionationPath: '/',
    sourceFiles: [],
    attempted: 0,
    completed: 0,
    failed: 0,
    skipped: 0,
    status: 0,
    percentage: 0,
  }
]

const uploadReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(addNewUploadTransfer, (state, { payload }) => {
      state.unshift({
        ...payload,
        attempted: 0,
        completed: 0,
        failed: 0,
        skipped: 0,
        status: 0,
        percentage: 0,
        sourceFiles: payload.sourceFiles.map(o => ({
          ...o,
          transferedSize: 0,
          attempted: 0,
          completed: 0,
          failed: 0,
          remarks: '',
        }))
      })
      return state
    })
    .addCase(updateTransferStatus, (state, { payload }) => {
      
      payload.forEach((transferInfo) => {
        const transferState = state.find(o => o.transferId === transferInfo.transfer_spec.tags.request);
        if (transferState) {
          if (transferInfo.file_counts) {
            transferState.attempted = transferInfo.file_counts.attempted;
            transferState.completed = transferInfo.file_counts.completed;
            transferState.failed = transferInfo.file_counts.failed;
            transferState.skipped = transferInfo.file_counts.skipped;
          }
          transferState.status = transferInfo.status;
          transferState.percentage = transferInfo.percentage * 100;
          if (transferInfo.files) {
            transferInfo.files.forEach(fileStatus => {
              updateFileStatus(transferState.sourceFiles, fileStatus)
            })
          } else if (transferInfo.explorer_path) {
            updateFileStatus(transferState.sourceFiles, {
              ...transferInfo.file_counts,
              error: transferInfo.error,
              file: transferInfo.explorer_path,
            });
          }
        }
      })
      return state;
    }).addCase(removeTransferStatus, (state, {payload: transferId})=>{
      return state.filter(o => o.transferId !== transferId);
    });
});

export default uploadReducer;
