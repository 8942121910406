import React from 'react';
import { Switch } from 'react-router';
import Upload from './Upload';
import UploadTransferStatus from './UploadTransferStatus';
import SecureRoute from '../secure-route/SecureRoute';

export default function UploadContainerRoutes() {
  return <Switch>
    <SecureRoute path="/upload/progress" component={UploadTransferStatus} />
    <SecureRoute path="/upload" component={Upload} />
  </Switch>
}