import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { createStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ImageIcon from '@material-ui/icons/Image';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import { dispatch } from "redux-store";
import { removeTransferStatus } from "store/upload-reducer";

const BorderLinearProgress = withStyles((theme) =>
  createStyles({
    root: {
      height: 20,
      borderRadius: 10,
    },
  }),
)(LinearProgress);


function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={40}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const TransferProgress = ({ percentage }) => {
  return <LinearProgressWithLabel value={percentage} />
}

const TransferStatusFile = ({ o }) => {
  return <ListItem>
  <ListItemIcon>
    <ImageIcon />
  </ListItemIcon>
  <ListItemText primary={o.name} secondary={o.size} />
</ListItem>;
}

function TransferListTitle ({transferId, uploasStatus, percentage})  {
  return <>
  Transfer # {transferId} 
  {!!uploasStatus && <TransferProgress percentage={percentage} />}
  </>
}

function TransferStatus({ transfer, showFiles, handleShowFiles }) {
  const selectedFiles = transfer.sourceFiles;
  const uploasStatus = transfer.status;
  const handleRemoveTransfer = useCallback((event) => {
    event.preventDefault();
    dispatch(removeTransferStatus(transfer.transferId))
  }, [transfer.transferId]);
  if (selectedFiles.length === 0) {
    return null;
  }
  return (
    <>
    <ListItem  onClick={() => handleShowFiles(showFiles ? '' : transfer.transferId)}>
      <ListItemText primary={<TransferListTitle transferId={transfer.transferId} uploasStatus={uploasStatus} percentage={transfer.percentage} />} />
      {showFiles ? <ExpandLess /> : <ExpandMore />}

      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={handleRemoveTransfer}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
      
    </ListItem>
    {showFiles&& <Collapse in timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {selectedFiles.map(o => <TransferStatusFile key={o.name} o={o} />)}
        </List>
      </Collapse>}
    </>)
}

export default function UploadTransferStatus() {
  const transfers = useSelector(state => state.upload);
  const [showFileFor, setShowFileFor] = useState('');
  const handleShowFiles = useCallback((transferId) => {
    setShowFileFor(transferId);
  }, []);
  return <Box width="100%">
    <List>
      {transfers.map(o => <TransferStatus key={o.transferId} transfer={o} showFiles={showFileFor === o.transferId} handleShowFiles={handleShowFiles}/>)}
    </List>
  </Box>
}