import { createAction, createReducer } from "@reduxjs/toolkit";

export const collectionSetFieldValue = createAction(
  "COLLECTION_SET_FIELD_VALUE"
);
export const collectionResetValues = createAction("COLLECTION_RESET_VALUES");

const defaultState = {
  id: "",
  target: "",
  action: "",
  name: "",
  title: "",
  shareVia: "",
  systemUsers: [],
  systemGroups: [],
  email: "",
  objects: {},
  createdBy: "",
  isCollections : false,
  isEditCollections : false,
  collectionLists : []
};

const collectionFormReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(collectionSetFieldValue, (state, { payload }) => {
      Object.assign(state, payload);
      return state;
    })
    .addCase(collectionResetValues, () => ({
      ...defaultState,
    }));
});

export default collectionFormReducer;
