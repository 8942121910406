import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Auth } from "aws-amplify";
import { useFormFields } from '../../lib/hooksLib';
import { goTo } from 'redux-store';
import { useSelector } from "react-redux";

const ForceChangePasswordForm = () => {
  const forceUser = useSelector((state) => state.forceUser);

  const [fields, handleFieldChange] = useFormFields({
    newPassword: '',
    confirmPassword: '',
  });
  const [errMsg, setErrMsg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback(async(e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const setErrorMessage = (message) => {
      setErrMsg(message);
      setTimeout(() => {
        setErrMsg('');
      }, 5000)
    };

    if (fields.newPassword && fields.confirmPassword) {
      if (fields.newPassword !== fields.confirmPassword) {
        setIsSubmitting(false);
        setErrorMessage("Passwords must be the same...");
      } else {
        try {
            await Auth.completeNewPassword(forceUser.user, fields.newPassword);
            const client= await localStorage.getItem('currentClient');
            goTo('/login/'+ client);  
          
        } catch (err) {
          setErrorMessage(err.message)
          setIsSubmitting(false);
        }
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
      setErrorMessage("Please enter password and confirm it.");
    }

  }, [fields.newPassword, fields.confirmPassword]);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="newPassword"
        label="New Password"
        name="newPassword"
        type="password"
        autoComplete="New Password"
        autoFocus
        onChange={handleFieldChange}
        disabled={isSubmitting}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="confirmPassword"
        label="Confirm New Password"
        type="password"
        id="confirmPassword"
        autoComplete="Confirm New Password"
        onChange={handleFieldChange}
        disabled={isSubmitting}
      />

      {errMsg && <Alert severity="error">{errMsg}</Alert>}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={isSubmitting}
      >
        Change Password
      </Button>

    </form>
  );


}

export default ForceChangePasswordForm;
