import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "../button/Button";
import Select from "@material-ui/core/Select";
import { Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MuiGridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import PaginationItem from "@material-ui/lab/PaginationItem";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { dispatch, goTo } from "redux-store";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Bucket } from "../../config";
import {
  apiCreateAsperaTransferRequest,
  searchAsset,
  fetchUserLists,
  fetchGroupLists,
  listSelectedMetaData,
  createSearchCollection,
  updateSearchCollection,
  fetchCollectionsByName,
  buildElasticSearchFilter,
} from "service/api.service";
import { useSelector } from "react-redux";
import { createId } from "service/util.service";
import invoke from "service/lambda.service";
import {
  asperaSetSelectedFileHandler,
  initTransfer,
  launchLocalBrowser,
} from "components/aspera-connect/aspera-connect";
import { addNewUploadTransfer } from "store/upload-reducer";
import { useCallback } from "react";
import { searchSetFieldValue } from "store/search-reducer";
import {
  collectionSetFieldValue, collectionResetValues
} from "store/collections-reducer";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import { ArrowForwardIos } from "@material-ui/icons/index";
import ImageDetails from "./ImageDetails";
import BulkUpdateModal from "../modal/BulkUpdateModal";
import Typography from "@material-ui/core/Typography";
import copyToClipboard from "copy-to-clipboard";
import { FileCopy } from "../../../node_modules/@material-ui/icons/index";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useAppUserContext } from "lib/contextLib";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { awsConfig } from "config";
import config from "../../aws-exports";
import FormControl from "@material-ui/core/FormControl";
import { getUserByemail } from "service/api.service";
import { Dfstudio, ImageDetailUpdate } from "../../config";
import Wimg from "./wimg";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  dialogTitle: {
    display: "flex",
    "justify-content": "center",
    padding: "35px 24px",
  },
  dialogCard: {
    "margin-top": "0",
    "min-height": "300px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontSize: "20px",
    textAlign: "center",
    width: "300px",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formControl: {
    minWidth: 120,
  },
  gridList: {
    margin: "0 !important",
  },
  tileBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0.1) 100%)",
    height: "auto",
    paddingTop: "15px",
    paddingBottom: "15px",
    overflow: "auto",
  },
  price: {
    display: "inline",
    fontSize: "1em",
  },
  link: {
    color: "#fff",
  },
  checkAbs: {
    position: "absolute",
    zIndex: 9,
    right: 0,
    bottom: 0,
    color: "#fff",
  },
  checkAbsresult: {
    position: "absolute",
    zIndex: 9,
    right: 0,
    bottom: "35px",
    color: "#0078ba",
  },
  checkAll: {
    color: "#0078ba",
  },
  pagination: {
    marginTop: 20,
    textAlign: "right",
  },
  displayFlex: {
    display: "flex",
    marginTop: "10px",
  },
  searchFields: {
    marginTop: 20,
  },
  dowloadSection: {
    marginTop: 20,
    textAlign: "left",
  },
  modalInner: {
    position: "absolute",
    width: "600px",
    border: "2px solid #000",
    maxWidth: "calc(100% - 30px)",
  },
  subtitle: {
    fontSize: "15px",
    whiteSpace: "pre-line",
  },
  textOnly: {
    height: "100%",
    borderRadius: "5px",
    padding: "20px",
    boxSizing: "border-box",
  },
  modalHeader: {
    display: "flex",
  },
  modalTitle: {
    "flex-grow": "1",
  },
  btnBlock: {
    paddingRight: "15px",
  },
  btnBlockWidth: {
    paddingRight: "15px",
    width: "200px",
  },
  marginBottom: {
    marginBottom: "50px",
  },
  collectionLists: {
    width: "200px",
  },
  alignEditColl: {
    display: "flex",
  },
  boldItalic: {
    fontWeight: "bold",
    fontStyle: "italic",
  },
  updateBtn: {
    fontSize: "20px",
  },
  closeBtn: {
    textAlign: "right",
    paddingRight: "40px",
    paddingBottom: "10px",
  },
};

const useStyles = makeStyles(styles);

const getTransferSpec = async (
  selectedBucket,
  selectedObjs,
  downloadPath,
  creds
) => {
  const pathPairs = selectedObjs.map((file) => ({
    source: file.name,
    destination: downloadPath,
  }));
  const resp = await invoke(
    "wdtvamtoolAsperaNAPI-" + awsConfig.environment,
    {
      args: {
        service: "download",
        data: { bucket: selectedBucket, pathPairs: pathPairs },
      },
    },
    creds
  );
  const respAsObject = JSON.parse(resp["Payload"]);
  const transferSpecs = respAsObject["body"]["transfer_specs"];
  return transferSpecs;
};
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SearchPage = (props) => {
  const classes = useStyles();
  const selectedBucket = useSelector((state) => state.search.bucket);
  const searchObj = useSelector((state) => state.search);
  const [selectedObj, setSelectedObj] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [showSystem, setShowSystem] = useState("");
  const [previewStyle, setPreviewStyle] = useState("textImage");
  const [sortingType, setSortingType] = useState("Newest First");
  const [sortingBy, setSortingBy] = useState("fullFileName");
  const [downloadType, setDownloadType] = useState("None");
  const [actionType, setActionType] = useState("None");
  const [shareVia, setShareVia] = useState("None");
  const [noOfRows, setNoOfRows] = useState("None");
  const [showAction, setShowAction] = useState(false);
  const [showCollection, setShowCollection] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [showSharevia, setShowSharevia] = useState(false);
  const [collectionValue, setCollectionValue] = useState("");
  const [collectionEmail, setCollectionEmail] = useState("");
  const [showCollectionList, setShowCollectionList] = useState(false);
  const [selectAllImages, setSelectAllImages] = useState(false);
  const [pdfTitleValue, setPdfTitleValue] = useState("");
  const [enableEditCollection, setEnableEditCollection] = useState(false);
  const { email, firstName } = useAppUserContext();
  const currentUser = useAppUserContext();
  const [downloadTarget, setDownloadTarget] = useState(false);
  const [downloadOptionList, setDownloadOptionList] = useState({});
  const [shareOptionList, setShareOptionList] = useState({});
  const [disableShareOption, setDisableShareOption] = useState(false);
  const [disableShareOptionEditColl, setDisableShareOptionEditColl] = useState(false);
  const [disableDownloadOptionEditColl, setDisableDownloadOptionEditColl] = useState(false);
  const [disableActionType, setDisableActionType] = useState(false);
  const [systemUsersSelected, setSystemUsers] = useState([]);
  const [userData, setUserData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [systemgroups, setSystemGroups] = useState([]);
  let allpageData = [];
  const searchStore = useSelector((state) => state.search);
  const [largePdf, setLargePdf] = useState(false);
  const [filetype, setFiletype] = useState('');
  const [page, setpage] = useState(0);
  const [allCollectionData, setallCollectionData] = useState();

  const enableSearchResult = (userRolesData) => {
    let optionList = {
      fullsize: false,
      loresimg: false,
      savecollection: false,
      editcollection: false,
      createpdf: false,
      compressedfile: false,
      compressedzip: false,
      datatxtfile: false,
      bulkUpdate: false,
    };
    let shareList = {
      collection: false,
      shareUser: false,
      shareGroup: false,
      shareFile: false,
      downloadFile: false,
      privateLink: false,
      publickLink: false,
    };
    const adminRole = userRolesData;
    if (adminRole && adminRole.roles) {
      if (adminRole.roles.indexOf("Super Admin") !== -1) {
        setDownloadTarget(true);
        optionList = {
          fullsize: true,
          loresimg: true,
          savecollection: true,
          editcollection: true,
          createpdf: true,
          compressedfile: true,
          compressedzip: true,
          datatxtfile: true,
          bulkUpdate: true,
        };
        shareList = {
          collection: true,
          shareUser: true,
          shareGroup: true,
          shareFile: true,
          downloadFile: true,
          privateLink: true,
          publickLink: true
        };
      }
    }
    if (adminRole && adminRole.resultData && adminRole.resultData.length) {
      adminRole.resultData.forEach((data) => {
        if (data.path === "1") {
          setDownloadTarget(true);
          optionList = {
            fullsize: true,
            loresimg: true,
            savecollection: true,
            editcollection: true,
            createpdf: true,
            compressedfile: true,
            compressedzip: true,
            datatxtfile: true,
            bulkUpdate: true,
          };
          shareList = {
            collection: true,
            shareUser: true,
            shareGroup: true,
            shareFile: true,
            downloadFile: true, 
            privateLink: true,
            publickLink: true,
          };
        }
        if (data.path === "1.children.0") {
          setDownloadTarget(true);
        }
        if (data.path === "1.children.1") {
          optionList = {
            fullsize: true,
            loresimg: true,
            savecollection: true,
            editcollection: true,
            createpdf: true,
            compressedfile: true,
            compressedzip: true,
            datatxtfile: true,
            bulkUpdate: true,
          };
        }
        if (
          (data.path === "1" || data.path === "1.children.1.children.0") &&
          data.checked
        ) {
          optionList.fullsize = true;
        }
        if (
          (data.path === "1" || data.path === "1.children.1.children.1") &&
          data.checked
        ) {
          optionList.loresimg = true;
        }
        if (
          (data.path === "1" || data.path === "1.children.1.children.2") &&
          data.checked
        ) {
          optionList.savecollection = true;
        }
        if (
          (data.path === "1" || data.path === "1.children.1.children.3") &&
          data.checked
        ) {
          optionList.editcollection = true;
          setActionType("editresult");
        }
        if (
          (data.path === "1" || data.path === "1.children.1.children.4") &&
          data.checked
        ) {
          optionList.createpdf = true;
        }
        if (
          (data.path === "1" || data.path === "1.children.1.children.5") &&
          data.checked
        ) {
          optionList.compressedfile = true;
        }
        if (
          (data.path === "1" || data.path === "1.children.1.children.6") &&
          data.checked
        ) {
          optionList.datatxtfile = true;
        }
        if (
          (data.path === "1" || data.path === "1.children.1.children.7") &&
          data.checked
        ) {
          optionList.bulkUpdate = true;
        }
        if (
          (data.path === "1" || data.path === "1.children.1.children.6") &&
          data.checked
        ) {
          optionList.move = true;
        }
        //shareList
        if (data.path === "1.children.2" && data.checked) {
          shareList = {
            collection: true,
            shareUser: true,
            shareGroup: true,
            shareFile: true,
            downloadFile: true,
            privateLink: true,
            publickLink: true,
          };
        }
        if (data.path === "1.children.2.children.0" && data.checked) {
          shareList.collection = true;
        }
        if (data.path === "1.children.2.children.1" && data.checked) {
          shareList.shareUser = true;
        }
        if (data.path === "1.children.2.children.2" && data.checked) {
          shareList.shareGroup = true;
        }
        if (data.path === "1.children.2.children.3" && data.checked) {
          shareList.shareFile = true;
        }
        if (data.path === "1.children.2.children.4" && data.checked) {
          shareList.downloadFile = true;
        }
        if (data.path === "1.children.2.children.5" && data.checked) {
          shareList.privateLink = true;
        }
        if (data.path === "1.children.2.children.6" && data.checked) {
          shareList.publickLink = true;
        }
      });

      setDownloadOptionList(optionList);
      setShareOptionList(shareList);
    }
  };
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });

  /**
   * collections
   */
  const collections = useSelector((state) => state.collections);
  const { vertical, horizontal, open, severity, message } = state;

  useEffect(() => {

    if (!searchStore.isFromSearch) {
      goTo("/search");
    }
    getUserByemail().then((finalData) => {
      if (finalData) enableSearchResult(finalData);
    });
    dispatch(collectionSetFieldValue({ createdBy: email }));
    if (!collections.isCollections && !collections.isEditCollections) {
      dispatch(
        collectionSetFieldValue({
          id: "",
          target: "",
          action: "",
          name: "",
          shareVia: "",
          systemUsers: [],
          systemGroups: [],
          email: "",
          objects: {},
          isCollections: false,
        })
      );
    }
    if (collections.isCollections) {
      setEnableEditCollection(true);
      if (collections.name) {
        setShowCollection(true);
        setCollectionValue(collections.name);
      }
      if (collections.title) {
        setShowTitle(true);
        setPdfTitleValue(collections.title);
      }
      if (collections.action) {
        setShowAction(true);
        setActionType(collections.action);
        if (
          collections.action === "saveresult" &&
          !collections.isEditCollections
        ) {
          setActionType("editresult");
          setDisableShareOptionEditColl(true);
        }
      }
      if (collections.target) {
        if (collections.target === "downloadAll") {
          setDisableActionType(true);
        }
        setDownloadType(collections.target);
      }

      if (collections.email) {
        setCollectionEmail(collections.email);
      }

      if (collections.shareVia) {
        if (collections.shareVia === "systemuser") {
          setShowSystem("user");
        }
        if (collections.shareVia === "systemgroup") {
          setShowSystem("group");
        }
        if (collections.shareVia === "email") {
          setShowSystem("email");
        }
        setShareVia(collections.shareVia);
        setShowSharevia(true);
      }
      if (collections.systemGroups && collections.systemGroups.length) {
        const data = [];
        collections.systemGroups.forEach((val) => {
          data.push({
            label: val,
          });
        });
        dispatch(collectionSetFieldValue({ systemGroups: data }));
      }
      if (collections.systemUsers && collections.systemUsers.length) {
        const data = [];
        collections.systemUsers.forEach((val) => {
          data.push({
            label: val,
          });
        });
        dispatch(collectionSetFieldValue({ systemUsers: data }));
      }
      if (!collections.shareVia) {
        setShareVia("None");
        setShowSharevia(true);
      }

      if (!collections.isEditCollections) {
        fetchCollections();
      }
    }

    dispatch(collectionSetFieldValue({ isEditCollections: false }));
    dispatch(collectionSetFieldValue({ isCollections: false }));

    fetchUsers();
    fetchGroups();

    return () => {
      if (!collections.isEditCollections) {
      dispatch(collectionResetValues());
      }
      asperaSetSelectedFileHandler();
    };

  }, []);

  useEffect(() => {
    updateModel((prevState) => {
      return {
        ...prevState,
        data: [],
        pageTokens: [{ page: 1, token: "" }],
        total: 0,
        currentPage: 0,
        totalPages: 0,
      };
    });
    if (!collections.isCollections || collections.isEditCollections) {
      fetchSearchResults(
        searchStore.bucket === "DF Studio" ? 1 : "",
        sortingType
      );
    }
  }, []);

  const [dfsData, setdfsData] = useState([]);
  const [model, updateModel] = useState({
    limit: 100,
    data: [],
    nextToken: "",
    pageTokens: [{ page: 1, token: "" }],
    total: 0,
    currentPage: 0,
    totalPages: 0,
  });
  const [modalItem, setModalItem] = useState(false);

  const [bulkUpdateModalStatus, setBulkUpdateModalStatus] = useState("close");

  /**
   * downloadSelected
   */
  const downloadSelected = useCallback(
    async (downloadPath, flag) => {
      setShowLoading(true);
      let objsToDownload = [];
      let bucket = flag ? Bucket.thumbnails : selectedBucket;
      if (downloadType === "downloadAllFromPage") {
        objsToDownload = model.data;
      } else if (downloadType === "downloadAll") {
        objsToDownload = allpageData;
      } else {
        objsToDownload = Object.values(selectedObj);
      }
      if (objsToDownload.length) {
        objsToDownload = objsToDownload.map((o) => ({
          ...o,
          name: flag ? o.bucket + "/" + o.mediaFilename : o.mediaFilename,
        }));
        const transferSpecs = await getTransferSpec(
          bucket,
          objsToDownload,
          downloadPath,
          currentUser.creds
        );
        const transferId = createId("Download");
        await apiCreateAsperaTransferRequest({
          transferId: transferId,
          direction: "download",
          sourceFiles: objsToDownload,
          bucket: bucket,
          destionationPath: downloadPath,
        });
        const downloadSourcefiles = objsToDownload.map((o) => ({
          ...o,
          name: downloadPath + "/" + o.mediaFilename.split("/").pop(),
        }));
        initTransfer("", transferSpecs, transferId);
        dispatch(
          addNewUploadTransfer({
            transferId: transferId,
            sourceFiles: downloadSourcefiles,
            bucket: bucket,
            destionationPath: downloadPath,
          })
        );
        dispatch(searchSetFieldValue({ destinationPath: "" }));
        setShowLoading(false);
        goTo("/search/progress");
      }
    },
    [downloadType, model.data, selectedBucket, selectedObj, allpageData]
  );

  useEffect(() => {
    setShowLoading(true);
    toGetPagewiseData(model.currentPage, model.limit);
  }, [allCollectionData, model.limit]);

  useEffect(() => {
    setActionType(selectedBucket === "DF Studio" ? "move" : "None");
  }, [selectedBucket]);

  const toGetPagewiseData = (currentPage, limit) => {
    if (allCollectionData && allCollectionData.length == 0) {
      onInfoalert();
      setShowLoading(false);
    } else if (allCollectionData && allCollectionData.length > 0) {
      let starting = currentPage * limit;
      let ending = (currentPage + 1) * limit;
      let arrayOfData = allCollectionData.slice(starting, ending);
      fetchSelectedMetaData(arrayOfData);
    }
  };
  /**
   * fetchCollections
   */
  const fetchCollections = async () => {
    setShowLoading(true);
    await fetchCollectionsByName()
      .then((val) => {
        setShowLoading(false);

        updateModel((prevState) => {
          return {
            ...prevState,
            total: val?.items[0].objects.length,
            totalPages:
              val?.items[0].objects.length / prevState.limit +
              (val?.items[0].objects.length % prevState.limit > 0 ? 1 : 0),
          };
        });
        setallCollectionData(val?.items[0].objects);
      })
      .catch(() => {
        setShowLoading(false);
      });
  };

  const sortButtonClicked = () => {
    // dispatch(searchSetFieldValue({ sortButton: true }));
    updateModel((prevState) => {
      return {
        ...prevState,
        data: [],
        pageTokens: [{ page: 1, token: "" }],
        total: 0,
        currentPage: 0,
        totalPages: 0,
      };
    });
    if (!collections.isCollections || collections.isEditCollections) {
      fetchSearchResults("", sortingType);
    }
  };
  /**
   * fetchSelectedMetaData
   * @param {*} objects
   */
  const fetchSelectedMetaData = async (objects, collection = {}) => {
    let resultAray = [];
    await listSelectedMetaData(objects)
      .then((results) => {
        resultAray = results;
        if (results && results.length) {
          let newState = { ...selectedObj };

          results.forEach((itemObj, i) => {
            if (itemObj.thumbnail) {
              itemObj.thumbnail_url =
                awsConfig.ThumbnailDomain +
                encodeURIComponent(itemObj.thumbnail);
            }

            if (newState[itemObj.id]) {
              delete newState[itemObj.id];
            } else {
              newState[itemObj.id] = itemObj;
            }
            if (i == results.length - 1) {
              setSelectedObj(newState);
              dispatch(
                collectionSetFieldValue({
                  objects: { ...collection, ...newState },
                })
              );
            }
          });

          Object.keys(collection).forEach((val) => {
            resultAray.push(collection[val]);
          });

          updateModel((prevState) => {
            return {
              ...prevState,
              data: resultAray,
              total: enableEditCollection
                ? allCollectionData.length
                : resultAray.length,
            };
          });
          if (resultAray.length == 0) {
            dispatch(searchSetFieldValue({ isFromSearch: false }));
          }
        }
        setShowLoading(false);
        setSelectAllImages(true);
      })
      .catch(() => {
        setShowLoading(true);
      });
  };
  /**
   * handleDownloadBtn
   */
  const handleDownloadBtn = useCallback(
    (flag) => {
      const handleFileSelected = ([dirInfo]) => {
        dispatch(searchSetFieldValue({ destinationPath: dirInfo.name }));
        downloadSelected(dirInfo.name.trim().replace(/\/$/, ""), flag);
      };
      asperaSetSelectedFileHandler(handleFileSelected);
      launchLocalBrowser("folder");
    },
    [downloadSelected]
  );

  /**
   * createCollection
   */
  const createCollectionRecord = () => {
    if (
      downloadType === "downloadSelected" &&
      collections?.objects && // 👈 null and undefined check
      Object.keys(collections?.objects).length == 0 &&
      Object.getPrototypeOf(collections?.objects) === Object.prototype
    ) {
      setState({
        ...state,
        open: true,
        message: "Please Select Image!",
        severity: "error",
      });
      return;
    }

    if (
      downloadType === "downloadAll" &&
      (actionType === "downloadAllFromPage" || actionType === "lowresimage")
    ) {
      allpageData = [];
      fetchSearchResultsAllPage("", "", 200);
      return;
    }

    if (actionType === "move") {
      goTo("/upload");
    }

    if (actionType === "downloadAllFromPage") {
      handleDownloadBtn(false);
      return;
    }
    if (actionType === "lowresimage") {
      handleDownloadBtn(true);
      return;
    }

    if (!collectionValue) {
      setState({
        ...state,
        open: true,
        message: "Please Enter Name!",
        severity: "error",
      });
      return;
    }
    if (collections?.action === "None") {
      setState({
        ...state,
        open: true,
        message: "Please Select Action!",
        severity: "error",
      });
      return;
    }

    if (
      (collections?.action === "pdf" ||
        collections?.action === "compressedfiles" ||
        collections?.action === "txt") &&
      (collections?.shareVia === "download" ||
        collections?.shareVia === "privatelink" ||
        collections?.shareVia === "publiclink")
    ) {

      console.log('----------------', collections?.action, ' --- ', collections?.shareVia);
      setShowLoading(true);
      createCollectionPdfZip();
      return;
    }
    if (shareVia === "systemuser") {
      dispatch(collectionSetFieldValue({ systemGroups: [] }));
    }
    if (shareVia === "systemgroup") {
      dispatch(collectionSetFieldValue({ systemUsers: [] }));
    }
    setShowLoading(true);
    if (
      (actionType === "saveresult" && collections.shareVia === "collection") ||
      collections.shareVia === "sharefile" ||
      (actionType === "saveresult" && shareVia === "systemgroup") ||
      (actionType === "saveresult" && shareVia === "systemuser")
    ) {
      createCollection();
    } else if (collections.id) {
      updateCollection();
    }
  };

  /**
   *createCollection
   */
  async function createCollection() {
    await createSearchCollection(showSystem)
      .then((success) => {
        setState({
          ...state,
          open: true,
          message: "Collection created Successfully!",
          severity: "success",
        });
        createCollectionPdfZip(success);
        setShowLoading(false);
      })
      .catch(() => {
        setShowLoading(false);
      });
  }
  /**
   * updateCollection
   */
  async function updateCollection() {
    await updateSearchCollection()
      .then((success) => {
        setState({
          ...state,
          open: true,
          message: "Collection updated Successfully!",
          severity: "success",
        });

        setShowLoading(false);
      })
      .catch(() => {
        setShowLoading(false);
      });
  }
  /**
   * Create a zip file of PDFs for the collection. Each PDF will correspond to a mediaFile and will have the thumbnail image plus exif information.
   * @param {*} mediaFilesList
   */

  const createCollectionPdfZip = async (collectionObj) => {
    let mediaFilesList = [];
    let action = "";
    let ext = ".zip";

    let permaLink = "";
    if (collections?.shareVia === 'privatelink' || collections?.shareVia === 'publiclink') {
      permaLink = collections.shareVia;
    }

    Object.keys(collections.objects).forEach((val) => {
      mediaFilesList.push(collections.objects[val].mediaFilename);
    });

    if (collections?.action === "txt") {
      action = "txt";
      ext = ".txt";
    }
    if (collections?.action === "pdf") {
      ext = ".pdf";
    }
    if (collections?.action === "compressedfiles") {
      action = "hires";
      ext = ".zip";
    }
    if (
      collections?.action === "saveresult" &&
      downloadType === "downloadAll"
    ) {
      action = "save-allpage-collection";
    }

    const searchobj = Object.entries(searchObj).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    );

    let elasticSearchParams;
    await buildElasticSearchFilter(null, null).then((resp) => {
      elasticSearchParams = resp;
    });
    let resp;
    const requestId = collectionValue + "-" + Math.ceil(Math.random() * 10000);

    if (
      (downloadType === "downloadAll" || Object.keys(selectedObj).length > 100) && collections?.action !== "compressedfiles"
    ) {
      //---Large PDF. Do not wait for the lambda. Show popup. Lambda will send email to user.

      resp = invoke(
        "wdtvamtoolCollectionUtils-" + awsConfig.environment,
        {
          region: config.aws_project_region,
          bucket: selectedBucket,
          files: downloadType === "downloadAll" ? ["*"] : mediaFilesList,
          collectionName: collectionValue,
          collectionId: collectionObj?.id || "",
          action: action ? action : "",
          public: collections?.shareVia === "sharefile" ? true : false,
          searchParams: searchobj ? elasticSearchParams : "",
          title: pdfTitleValue,
          pdfCols: noOfRows,
          pdfRows: noOfRows,
          email: email,
          userName: firstName,
          requestId: requestId,
          permaLink: permaLink
        },
        currentUser.creds
      );

      setShowLoading(false);
      if (collections?.action !== "saveresult") {
        setLargePdf(true);
        if (collections?.action === "pdf") {
          setFiletype('PDF')
        }
        if (collections?.action === "compressedfiles") {
          setFiletype('Compressed Share File')
        }
        if (collections?.action === "txt") {
          setFiletype('Date Text File')
        }
      }
    } else {
      //---Not a large PDF. Wait for lambda to get over. Kick off aspera download.
      setLargePdf(false);

      resp = await invoke(
        "wdtvamtoolCollectionUtils-" + awsConfig.environment,
        {
          region: config.aws_project_region,
          bucket: selectedBucket,
          files: downloadType === "downloadAll" ? ["*"] : mediaFilesList,
          collectionName: collectionValue,
          collectionId: collectionObj?.id || "",
          action: action ? action : "",
          public: collections?.shareVia === "sharefile" ? true : false,
          searchParams: searchobj ? elasticSearchParams : "",
          title: pdfTitleValue,
          pdfCols: noOfRows,
          pdfRows: noOfRows,
          email: email,
          userName: firstName,
          requestId: requestId,
          permaLink: permaLink
        },
        currentUser.creds
      );
      setShowLoading(false);
      if (collections?.action === "compressedfiles") {
        setLargePdf(true);
        setFiletype('Compressed Share File');
      }
      if (permaLink.trim().length > 0) {

        if (collections?.action === "pdf") {
          setFiletype('PDF')
        }
        if (collections?.action === "compressedfiles") {
          setFiletype('Compressed Share File')
        }
        if (collections?.action === "txt") {
          setFiletype('Date Text File')
        }

        setLargePdf(true);
      }
      if (
        (collections?.action === "pdf" ||
          collections?.action === "txt") &&
        collections?.shareVia === "download"
      ) {
        downloadCollectionPdfZip([collections.name], action, ext);
      }
    }
  };

  const downloadCollectionPdfZip = useCallback(
    (collectionNames, action, ext) => {
      const handleFileSelected = ([dirInfo]) => {
        downloadCollectionPdfZipApi(
          dirInfo.name.trim().replace(/\/$/, ""),
          collectionNames,
          action,
          ext
        );
      };
      asperaSetSelectedFileHandler(handleFileSelected);
      launchLocalBrowser("folder");
    },
    []
  );

  /**
   * Download one zip file for each collection that is given in the parameter collectionNames.
   * Each zip file will contain a PDF with the thumbnail image and exif information.
   * @param {*} collectionNames : Names of saved collections in an array
   */
  const downloadCollectionPdfZipApi = async (
    downloadPath,
    collectionNames,
    action,
    ext
  ) => {
    const collectionsBucket = Bucket.collection;
    // const downloadPath = downloadPath; //---replace this with the output of a simple savefile dialog...
    const transferId = createId("Download");
    // const collectionNames = [collections.name]
    const collections = [];
    let dmaction = "";
    if (action) {
      dmaction = "-" + action;
    }
    collectionNames.map((cn) => {
      collections.push({ name: cn + dmaction + ext });
    });

    const transferSpecs = await getTransferSpec(
      collectionsBucket,
      collections,
      downloadPath,
      currentUser.creds
    );

    const downloadSourcefiles = collections.map((c) => ({
      collection: c,
      name: downloadPath + "/" + c,
    }));

    await apiCreateAsperaTransferRequest({
      transferId: transferId,
      direction: "download",
      sourceFiles: collections,
      bucket: collectionsBucket,
      destionationPath: downloadPath,
    });

    initTransfer("", transferSpecs, transferId);

    dispatch(
      addNewUploadTransfer({
        transferId: transferId,
        sourceFiles: downloadSourcefiles,
        bucket: collectionsBucket,
        destionationPath: downloadPath,
      })
    );
    dispatch(searchSetFieldValue({ destinationPath: "" }));
    setShowLoading(false);
    goTo("/search/progress");
  };
  /**
   * getGridListCols
   * @returns
   */
  const getGridListCols = () => {
    if (isWidthUp("xl", props.width)) {
      return 5;
    }
    if (isWidthUp("lg", props.width)) {
      return 4;
    }

    if (isWidthUp("md", props.width)) {
      return 2;
    }

    return 1;
  };
  /**
   * updateLimit
   * @param {*} event
   * @param {*} child
   */
  const updateLimit = (event, child) => {
    if (!collections.isEditCollections) {
      updateModel((prevState) => {
        return {
          ...prevState,
          limit: event.target.value,
        };
      });
      fetchCollections();
      return;
    }
    setSelectAllImages(false);
    setSelectedObj({});
    updateModel((prevState) => {
      return {
        ...prevState,
        limit: event.target.value,
      };
    });
    localStorage.setItem("WDAMT_search_limit", event.target.value);
    fetchSearchResults("", sortingType, event.target.value);
  };
  /**
   * fetchSearchResults
   */
  const fetchSearchResults = async (nextCursor = "", sort_type, limit) => {
    setShowLoading(true);
    if (searchStore.bucket === "DF Studio") {
      let dfsQuery = {};
      if (searchStore.dfsProject) {
        dfsQuery.project = searchStore.dfsProject;
      }
      if (searchStore.dfsSetup) {
        dfsQuery.setup = searchStore.dfsSetup;
      }
      if (searchStore.dfsId) {
        dfsQuery.dfsId = searchStore.dfsId;
      }
      if (searchStore.dfsLocation) {
        dfsQuery.location = searchStore.dfsLocation;
      }
      let resp = await invoke(
        "wdtvamtoolDFUtils-" + awsConfig.environment,
        {
          action: "search",
          dfsQuery: dfsQuery,
          offset: (nextCursor - 1) * 25,
          freeText: searchStore.dfsFreeText,
          user: localStorage.getItem("userEmail"),
        },
        currentUser.creds
      );
      let jsonData = JSON.parse(resp.Payload);

      if (jsonData.data) {
        setdfsData(jsonData.data);
        updateModel((prevState) => {
          return {
            ...prevState,
            total: jsonData.total,
            totalPages: jsonData.total / 25,
            currentPage: nextCursor,
            limit: 25,
          };
        });
        console.log("lamda response3", resp.Payload.data);
      }
      setShowLoading(false);
    } else {
      let sorting = {};
      const limitPerPage = limit ? limit : model.limit;
      let sortBy = sortingBy;
      if (searchObj?.job) {
        sortBy = "fullFileName"
      }

      sorting =
        sort_type == "Newest First"
          ? { field: sortBy, direction: "asc" }
          : { field: sortBy, direction: "desc" };

      searchAsset(limitPerPage, nextCursor, sorting)
        .then(({ items, nextToken, total }) => {
          if (total == 0) {
            dispatch(searchSetFieldValue({ isFromSearch: false }));
          }
          updateModel((prevState) => {
            return {
              ...prevState,
              data: items,
              nextToken,
              total,
              totalPages: total / prevState.limit + (total % limit > 0 ? 1 : 0),
              currentPage:
                prevState.currentPage === 0 ? 1 : prevState.currentPage,
            };
          });
          const data = Object.keys(selectedObj).filter((b) =>
            items.some((a) => b === a.id)
          );
          if (items && items.length && data && data.length) {
            setSelectAllImages(true);
          }
          setShowLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setShowLoading(false);
        });
    }
  };

  /**
   * fetchSearchResults
   */
  const fetchSearchResultsAllPage = async (token = "", sort_type, limit) => {
    setShowLoading(true);
    let sorting = {};
    const limitPerPage = limit ? limit : model.limit;

    sorting =
      sort_type == "Newest First"
        ? { field: "timestamp", direction: "desc" }
        : { field: "timestamp", direction: "asc" };

    searchAsset(limitPerPage, token, sorting, false) //--->True will ensure search result will have only 2 flds : bucket and mediaFilename rather than the whole record.
      .then(({ items, nextToken, total }) => {
        allpageData = [...allpageData, ...items];
        if (nextToken) {
          fetchSearchResultsAllPage(nextToken, "", 200);
        }
        if (!nextToken) {
          if (actionType === "downloadAllFromPage") {
            handleDownloadBtn(false);
          }
          if (actionType === "lowresimage") {
            handleDownloadBtn(true);
          }

          setShowLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setShowLoading(false);
      });
  };

  /**
   * updatePreviewfselectedObj
   */
  const updatePreview = useCallback((event) => {
    setPreviewStyle(event.target.value);
  }, []);
  /**
   * updateSorting
   */
  const updateSorting = (event) => {
    setSortingType(event.target.value);
  };
  const updateSortingBy = (event) => {

    setSortingBy(event.target.value);
  };
  /**
   * updateDownloadType
   */
  const updateDownloadType = (event) => {
    setDisableActionType(false);
    if (event.target.value === "downloadAllFromPage") {
      handleSelectAllImage({ target: { checked: true } });
    }
    if (event.target.value === "downloadAll") {
      handleSelectAllImage({ target: { checked: true } });
      setDisableActionType(true);
    }

    dispatch(collectionSetFieldValue({ target: event.target.value }));
    setDownloadType(event.target.value);
    setShowAction(true);
    setActionType("None")
  };
  /**
   *
   * handleActionType
   */
  const handleActionType = (event) => {
    setShareVia("None");
    setShowTitle(false);
    setDisableShareOption(false);
    setDisableDownloadOptionEditColl(false);
    setDisableShareOptionEditColl(false);
    dispatch(collectionSetFieldValue({ action: event.target.value }));
    setActionType(event.target.value);
    if (
      event.target.value === "downloadAllFromPage" ||
      event.target.value === "lowresimage"
    ) {
      setShowCollection(false);
      setShowSharevia(true);
      setShareVia("download");
      dispatch(collectionSetFieldValue({ shareVia: "download" }));
      setShowSystem("");
      setDisableShareOptionEditColl(true);
      setDisableShareOption(true);
      return;
    }
    if (
      event.target.value == "pdf" ||
      event.target.value == "compressedfiles" ||
      event.target.value == "txt"
    ) {
      setShowSharevia(true);
    }
    if (event.target.value == "compressedfiles") {
      setDisableShareOption(true);
      setDisableDownloadOptionEditColl(true)
      setShareVia("sharefile");
      dispatch(collectionSetFieldValue({ shareVia: "sharefile" }));
    }

    if (event.target.value === "bulkUpdate") {
      if (
        downloadType === "downloadSelected" &&
        Object.keys(selectedObj).length === 0
      ) {
        setActionType("None");
        setState({
          ...state,
          open: true,
          message: "Atleast an Media should be selected",
          severity: "error",
        });
      } else {
        setShowCollection(false);
        setBulkUpdateModalStatus("open");
      }
      return;
    }
    if (event.target.value === "saveresult") {
      setShareVia("collection");
      dispatch(collectionSetFieldValue({ shareVia: "collection" }));
      setShowSystem("");
      setDisableShareOptionEditColl(true);
    } else if (
      event.target.value === "pdf" ||
      event.target.value === "txt"
    ) {
      setShareVia("download");
      dispatch(collectionSetFieldValue({ shareVia: "download" }));
      setDisableShareOption(true);
    } else if (event.target.value === "editresult") {
      setDisableShareOptionEditColl(true);
    }
    setShowCollection(true);
    if (event.target.value === "pdf") {
      setShowTitle(true);
    }
    if (event.target.value === "move") {
      setShowCollection(false);
    }
  };
  /**
   * handleShareVia
   */
  const handleShareVia = useCallback((event) => {
    dispatch(collectionSetFieldValue({ shareVia: event.target.value }));
    setShowSystem("");
    if (event.target.value === "systemuser") {
      setShowSystem("user");
    }
    if (event.target.value === "systemgroup") {
      setShowSystem("group");
    }
    if (event.target.value === "email") {
      setShowSystem("email");
    }
    setShareVia(event.target.value);
  }, []);
  /**
   * handleNavNextPage
   */
  const handleNavNextPage = useCallback(() => {
    setSelectAllImages(false);
    const currPage = +model.currentPage;
    const targetPage =
      model.currentPage < model.totalPages
        ? model.currentPage + 1
        : model.currentPage;

    if (currPage < targetPage) {
      const pageTokens =
        model.pageTokens.length < targetPage
          ? [...model.pageTokens, { page: targetPage, token: model.nextToken }]
          : [...model.pageTokens];

      updateModel((prevState) => {
        return {
          ...prevState,
          pageTokens,
          currentPage: targetPage,
        };
      });
      if (enableEditCollection) {
        setShowLoading(true);
        toGetPagewiseData(targetPage, model.limit);
      } else {
        setShowLoading(true);
        fetchSearchResults(
          searchStore.bucket === "DF Studio"
            ? targetPage
            : pageTokens[targetPage - 1]?.token,
          sortingType
        );
      }
    }
  }, [
    enableEditCollection ? toGetPagewiseData : fetchSearchResults,
    model.nextToken,
    model.pageTokens,
    model.currentPage,
    model.totalPages,
    sortingType,
  ]);
  /**
   * handleNavPrevPage
   */
  const handleNavPrevPage = useCallback(() => {
    setSelectAllImages(false);
    const currPage = model.currentPage;
    const targetPage =
      model.currentPage > 1 ? model.currentPage - 1 : model.currentPage;

    if (currPage > targetPage) {
      updateModel((prevState) => {
        return {
          ...prevState,
          currentPage: targetPage,
        };
      });
      if (enableEditCollection) {
        setShowLoading(true);
        toGetPagewiseData(targetPage, model.limit);
      } else {
        setShowLoading(true);
        fetchSearchResults(
          searchStore.bucket === "DF Studio"
            ? targetPage
            : model.pageTokens[targetPage - 1].token,
          sortingType
        );
      }
    }
  }, [
    enableEditCollection ? toGetPagewiseData : fetchSearchResults,
    model.pageTokens,
    model.currentPage,
    sortingType,
  ]);

  const handleCopyText = useCallback((text) => {
    copyToClipboard(text);
  }, []);

  /**
   * Handle Checkbox update
   */
  const handleSelectItem = (itemObj, isDFS) => {
    const collection = { ...collections?.objects };
    setSelectedObj((prevState) => {
      const newState = { ...prevState };
      const id = isDFS ? itemObj.dfsId : itemObj.id;
      if (downloadType === "downloadAll") {
        setDownloadType("downloadSelected");
      }
      if (prevState[id]) {
        delete newState[id];
        delete collection[id];
      } else {
        newState[id] = itemObj;
      }
      dispatch(
        collectionSetFieldValue({ objects: { ...collection, ...newState } })
      );
      return newState;
    });
  };
  /**
   * handleSelectAllImage
   */
  const handleSelectAllImage = (event) => {
    setSelectAllImages(event.target.checked);
    const collection = { ...collections?.objects };
    if (model.data.length) {
      model.data.forEach((itemObj) => {
        setSelectedObj((prevState) => {
          const newState = { ...prevState };
          if (!event.target.checked) {
            delete newState[itemObj.id];
            delete collection[itemObj.id];
          } else {
            newState[itemObj.id] = itemObj;
          }
          dispatch(
            collectionSetFieldValue({ objects: { ...collection, ...newState } })
          );

          return newState;
        });
      });
    } else {
      dfsData.forEach((itemObj) => {
        setSelectedObj((prevState) => {
          const newState = { ...prevState };
          if (!event.target.checked) {
            delete newState[itemObj.dfsId];
            delete collection[itemObj.dfsId];
          } else {
            newState[itemObj.dfsId] = itemObj;
          }
          dispatch(
            collectionSetFieldValue({ objects: { ...collection, ...newState } })
          );

          return newState;
        });
      });
    }
  };

  /**
   * handleOpen
   * @param {*} item
   * @param {*} index
   */
  const handleOpen = (item, index) => {
    const data = JSON.parse(JSON.stringify(item));
    data.expand = false;
    data.selectedObj = selectedObj;
    data.selectedIndex = index - 1;
    setModalItem(data);
  };
  /**
   * handleClose
   */
  const handleClose = () => {
    setSelectedObj([]);
    setModalItem({});
  };

  const handleBulkUpdateModalClose = () => {
    setActionType("None");
    setBulkUpdateModalStatus("close");
  };

  const onBulkModalClose = useCallback(handleBulkUpdateModalClose, []);

  const navigateToSearch = () => {
    dispatch(collectionSetFieldValue({ isEditCollections: true }));
    goTo("/search");
  };
  /**
   * setExifData
   * @param {*} data
   * @returns
   */
  const setExifData = (data, tile) => {
    let value = {};
    for (let inc = 0; inc < data.length; inc++) {
      let dateValues = data[inc].split(":");
      dateValues.splice(0, 1);
      value[data[inc].split(":")[0].trim()] = dateValues
        .join(":")
        .trim()
        .split("+")[0];
    }
    let returnValue = "";
    if (typeof value["Creator"] == "string") {
      returnValue = value["Creator"];
    }

    if (typeof tile.timestamp == "string") {
      returnValue = returnValue != "" ? returnValue + " / " : "";
      const getVal = tile.timestamp;
      const splitData = getVal.split(" ")[0].split(":");
      const dateVal = splitData[1] + "/" + splitData[2] + "/" + splitData[0];
      returnValue = returnValue + dateVal;
    }

    if (
      typeof value["Country-Primary Location Name"] == "string" ||
      typeof value["Country-Primary Location Code"] == "string"
    ) {
      returnValue = returnValue != "" ? returnValue + " / " : "";
      if (typeof value["Country-Primary Location Name"] == "string") {
        returnValue = returnValue + value["Country-Primary Location Name"];
      } else {
        returnValue = returnValue + value["Country-Primary Location Code"];
      }
    }
    return returnValue;
  };
  /**
   * setFileName
   * @param {*} thumbnail
   * @returns
   */
  const setFileName = (thumbnail) => {
    let thumbArray = thumbnail
      .split("/")
    [thumbnail.split("/").length - 1].split(".");
    thumbArray.splice(-1);
    return thumbArray.join(".");
  };
  /**
   * getDateValue
   * @param {*} dateIn
   * @returns
   */
  const getDateValue = (dateIn) => {
    let date_ob = new Date(dateIn);
    let date1 = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let hours = date_ob.getHours();
    let minutes = date_ob.getMinutes();
    let seconds = date_ob.getSeconds();
    return (
      year +
      "-" +
      month +
      "-" +
      date1 +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  };

  /**
   * Alert Success
   */
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };
  /**
   *fetchUsers
   */
  async function fetchUsers() {
    let data = [];
    await fetchUserLists().then((success) => {
      if (success && success.items) {
        success.items.forEach((val) => {
          data.push({ label: val.firstName, email: val.email });
        });
      }
    });
    setUserData(data);
  }
  /**
   * handleDropdownChange
   * @param  {...any} params
   * @returns
   */
  const handleDropdownChange = (...params) => {
    if (params && params[2] === "clear") {
      setSystemUsers([]);
      dispatch(collectionSetFieldValue({ systemUsers: [] }));
      return;
    }
    const changedOption = params[3].option;
    const selectionType = params[2]; //
    const allSelected = params[1]; //
    addRomoveUsers(allSelected, changedOption, selectionType);
  };
  const addRomoveUsers = (allSelected, changedOption, selectionType) => {
    if (selectionType === "selectOption") {
      setSystemUsers(allSelected);
      dispatch(collectionSetFieldValue({ systemUsers: allSelected }));
    }
    if (selectionType === "removeOption") {
      const pos = allSelected
        .map(function (e) {
          return e.label;
        })
        .indexOf(changedOption.label);
      if (pos !== -1) {
        allSelected.splice(pos, 1); // 2nd parameter means remove one item only
      }
      setSystemUsers(allSelected);
      dispatch(collectionSetFieldValue({ systemUsers: allSelected }));
    }
  };
  /**
   *fetchGroups
   */
  async function fetchGroups() {
    let data = [];
    await fetchGroupLists().then((success) => {
      if (success && success.items) {
        success.items.forEach((val) => {
          data.push({ label: val.groupName });
        });
      }
    });
    setGroupData(data);
  }
  /**
   * handleDropdownChangeGroup
   * @param  {...any} params
   */
  const handleDropdownChangeGroup = (...params) => {
    const changedOption = params[3].option;
    const selectionType = params[2]; //
    const allSelected = params[1]; //
    addRomoveGroups(allSelected, changedOption, selectionType);
  };
  /**
   * addRomoveGroups
   * @param {*} allSelected
   * @param {*} changedOption
   * @param {*} selectionType
   */
  const addRomoveGroups = (allSelected, changedOption, selectionType) => {
    if (selectionType === "selectOption") {
      setSystemGroups(allSelected);
      dispatch(collectionSetFieldValue({ systemGroups: allSelected }));
    }
    if (selectionType === "removeOption") {
      const pos = allSelected
        .map(function (e) {
          return e.label;
        })
        .indexOf(changedOption.label);
      if (pos !== -1) {
        allSelected.splice(pos, 1); // 2nd parameter means remove one item only
      }
      setSystemGroups(allSelected);
      dispatch(collectionSetFieldValue({ systemGroups: allSelected }));
    }
  };
  /**
   * handleCollectionInputChange
   * @param {*} e
   * @param {*} value
   */
  const handleCollectionInputChange = (e, value) => {
    setCollectionValue(e.target.value);
    dispatch(collectionSetFieldValue({ [e.target.name]: e.target.value }));
    if (e.target.value) setShowSharevia(true);
  };
  const handleCollectionTitleChange = (e, value) => {
    setPdfTitleValue(e.target.value);
    dispatch(collectionSetFieldValue({ [e.target.name]: e.target.value }));
    if (e.target.value) setShowSharevia(true);
  };
  const noOfRowsSelect = (event) => {
    dispatch(collectionSetFieldValue({ noOfRows: event.target.value }));
    setNoOfRows(event.target.value);
  };
  const chnageCollection = (e) => {
    setShowCollectionList(true);
    setShowCollection(false);
  };
  /**
   * handleEmailInputChange
   * @param {*} e
   * @param {*} value
   */
  const handleEmailInputChange = (e, value) => {
    setCollectionEmail(e.target.value);
    dispatch(collectionSetFieldValue({ [e.target.name]: e.target.value }));
  };
  /**
   * SearchSystemUserInput
   * @returns
   */
  const SearchSystemUserInput = () => {
    return (
      <Autocomplete
        disablePortal
        multiple
        id="combo-box-demo"
        options={userData}
        getOptionLabel={(option) => option.label}
        defaultValue={collections.systemUsers}
        onChange={handleDropdownChange}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="System Users" />}
      />
    );
  };
  /**
   * SearchSystemGroupInput
   * @returns
   */
  const SearchSystemGroupInput = () => {
    return (
      <Autocomplete
        disablePortal
        multiple
        id="combo-box-demo"
        options={groupData}
        getOptionLabel={(option) => option.label}
        defaultValue={collections.systemGroups}
        onChange={handleDropdownChangeGroup}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="System Group" />}
      />
    );
  };
  /**
   * SortingTypeEle
   */
  const SortingTypeEle = (
    <Grid>
      <InputLabel shrink style={{ display: "inline-block" }}>
        Sort Order
      </InputLabel>
      <Select
        labelId="preview-type-label"
        id="preview-type"
        label="Sorting Type"
        labelPlacement="start"
        value={sortingType}
        onChange={updateSorting}
      >
        <MenuItem value="Newest First">Asc</MenuItem>
        <MenuItem value="Oldest First">Desc</MenuItem>
      </Select>
    </Grid>
  );
  const SortingByEle = (
    <Grid>
      <InputLabel shrink style={{ display: "inline-block" }}>
        Sort by
      </InputLabel>
      <Select
        labelId="preview-type-label"
        id="preview-type"
        label="Sorting Type"
        labelPlacement="start"
        value={sortingBy}
        onChange={updateSortingBy}
      >
        <MenuItem value="fullFileName">File Name</MenuItem>
        <MenuItem value="job">Job Number</MenuItem>
        <MenuItem value="timestamp">Date</MenuItem>
      </Select>
    </Grid>
  );
  /**
   * ActionEle
   */
  const ActionEle = (
    <Grid>

      <Select
        labelId="preview-type-label"
        id="preview-type"
        label="Action"
        labelPlacement="start"
        value={actionType}
        className={actionType === "None" ? classes.boldItalic : ""}
        onChange={handleActionType}
      >
        <MenuItem value="None" className={classes.boldItalic}>
          Choose an Option :{" "}
        </MenuItem>
        {downloadOptionList.fullsize && (
          <MenuItem
            value="downloadAllFromPage"
            disabled={selectedBucket === "DF Studio"}
          >
            Full Size Media
          </MenuItem>
        )}
        {downloadOptionList.loresimg && (
          <MenuItem
            value="lowresimage"
            disabled={selectedBucket === "DF Studio"}
          >
            Low-Res Media
          </MenuItem>
        )}
        {downloadOptionList.savecollection && (
          <MenuItem
            value="saveresult"
            disabled={selectedBucket === "DF Studio"}
          >
            Save a Collection
          </MenuItem>
        )}
        {enableEditCollection && downloadOptionList.editcollection && (
          <MenuItem
            value="editresult"
            disabled={selectedBucket === "DF Studio"}
          >
            Edit/Update Collection
          </MenuItem>
        )}
        {downloadOptionList.createpdf && (
          <MenuItem value="pdf" disabled={selectedBucket === "DF Studio"}>
            Create a PDF
          </MenuItem>
        )}

        {downloadOptionList.compressedfile && (
          <MenuItem
            disabled={disableActionType || selectedBucket === "DF Studio"}
            value="compressedfiles"
          >
            Compressed Zip
          </MenuItem>
        )}
        {downloadOptionList.datatxtfile && (
          <MenuItem
            disabled={disableActionType || selectedBucket === "DF Studio"}
            value="txt"
          >
            Data Text File
          </MenuItem>
        )}
        {downloadOptionList.bulkUpdate &&
          ImageDetailUpdate.enableBulkUpdate &&
          (
            <MenuItem
              value="bulkUpdate"
              disabled={selectedBucket === "DF Studio"}
            >
              Update Metadata
            </MenuItem>
          )}
        {Dfstudio.enable && selectedBucket === "DF Studio" &&
          (<MenuItem value="move">Move</MenuItem>
          )}
      </Select>
    </Grid>
  );
  /**
   * downloadOption
   */
  const downloadOption = (
    <Grid>
      {downloadTarget && (
        <Select
          labelId="dowload-option-label"
          id="download-optionn"
          label="target"
          labelPlacement="start"
          defaultValue={downloadType}
          value={downloadType}
          className={downloadType === "None" ? classes.boldItalic : ""}
          onChange={updateDownloadType}
        >
          <MenuItem value="None" className={classes.boldItalic}>
            Choose an Asset :{" "}
          </MenuItem>
          <MenuItem value="downloadSelected">Selected Image(s)</MenuItem>
          <MenuItem value="downloadAllFromPage">Current Page</MenuItem>
          <MenuItem value="downloadAll">All Pages</MenuItem>
        </Select>
      )}
    </Grid>
  );
  const SearchShowInput = () => {
    const value = useSelector((state) => state.collections.name);
    let collectionLists = useSelector(
      (state) => state.collections.collectionLists
    );
    if (!collectionLists) {
      collectionLists = [];
    }
    const handleDropdownChange = (e) => {
      const selectedCollection = collectionLists.filter((val) => {
        return val.name === e.target.textContent;
      });
      if (selectedCollection && selectedCollection.length) {
        dispatch(collectionSetFieldValue({ id: selectedCollection[0].id }));
      }
      dispatch(collectionSetFieldValue({ name: e.target.textContent }));
    };
    const handleCollectionInputChange = (e, value) => {
      if (e?.target?.value) {
        setCollectionValue(e.target.value);
        dispatch(collectionSetFieldValue({ [e.target.name]: e.target.value }));
      }
    };
    return (
      <FormControl
        variant="outlined"
        fullWidth
        className={classes.collectionLists}
      >
        <Autocomplete
          id="combo-box-demo"
          options={collectionLists}
          getOptionLabel={(option) => option.name}
          value={value && collectionLists.find((o) => o.name === value)}
          onChange={handleDropdownChange}
          renderInput={(params) => (
            <TextField {...params} label="Name" variant="outlined" fullWidth />
          )}
          fullWidth
        />
      </FormControl>
    );
  };
  /**
   * SearchFields
   */
  const SearchFields = (
    <Box>
      <Box className={classes.pagination} ml={5} mt={2} pb={2}>
        <Grid container spacing={6} justify="flex-start">
          <Grid item>{downloadOption}</Grid>
          {showAction && <Grid item>{ActionEle}</Grid>}
          {showCollection && (
            <Grid item className={classes.alignEditColl}>
              <TextField
                value={collectionValue}
                onChange={handleCollectionInputChange}
                className="input-style"
                name="name"
                id="collection"
                label="Name"
                variant="outlined"
                fullWidth
              />
              {enableEditCollection && (
                <div>
                  <Icon
                    color="primary"
                    onClick={() => {
                      chnageCollection();
                    }}
                  >
                    edit_circle
                  </Icon>
                </div>
              )}
            </Grid>
          )}
          {showTitle && (
            <Grid item className={classes.alignEditColl}>
              <TextField
                value={pdfTitleValue}
                onChange={handleCollectionTitleChange}
                className="input-style"
                name="title"
                id="title"
                label="Title"
                variant="outlined"
                fullWidth
              />
            </Grid>
          )}
          {showTitle && (
            <Grid item>
              <Select
                labelId="preview-type-label"
                id="preview-type"
                label="No.of Columns"
                labelPlacement="start"
                value={noOfRows}
                className={noOfRows === "None" ? classes.boldItalic : ""}
                onChange={noOfRowsSelect}
              >
                <MenuItem value="None" className={classes.boldItalic}>
                  No.of Columns:
                </MenuItem>

                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </Grid>
          )}
          {showCollectionList && !showCollection && (
            <Grid item>
              <SearchShowInput />
            </Grid>
          )}

          {showSharevia && (
            <Grid item>
              <Select
                labelId="preview-type-label"
                id="preview-type"
                label="Target"
                labelPlacement="start"
                value={shareVia}
                className={shareVia === "None" ? classes.boldItalic : ""}
                onChange={handleShareVia}
              >
                <MenuItem value="None" className={classes.boldItalic}>
                  Choose an Action:
                </MenuItem>
                {shareOptionList.collection && (
                  <MenuItem disabled={disableShareOption} value="collection">
                    Collection
                  </MenuItem>
                )}
                {shareOptionList.shareUser && (
                  <MenuItem disabled={disableShareOption} value="systemuser">
                    Share W/User{" "}
                  </MenuItem>
                )}
                {shareOptionList.shareGroup && (
                  <MenuItem disabled={disableShareOption} value="systemgroup">
                    Share W/Group(s){" "}
                  </MenuItem>
                )}
                {shareOptionList.shareFile && (
                  <MenuItem
                    disabled={disableShareOptionEditColl}
                    value="sharefile"
                  >
                    Share File
                  </MenuItem>
                )}
                {shareOptionList.downloadFile && (
                  <MenuItem
                    disabled={
                      (disableShareOptionEditColl && shareVia !== "download") || disableDownloadOptionEditColl
                    }
                    value="download"
                  >
                    Download File
                  </MenuItem>
                )}
                {shareOptionList.privateLink && (
                <MenuItem
                       disabled={disableShareOptionEditColl} value="privatelink"
                  >
                    Private Link
                  </MenuItem>
                  )}
                  {shareOptionList.publickLink && (
                  <MenuItem  
                   disabled={disableShareOptionEditColl}
                    value="publiclink"
                  >
                    Public Link
                  </MenuItem>
                 )}
              </Select>
            </Grid>
          )}
        </Grid>
        <Grid className={classes.displayFlex}>
          {showSystem === "user" && (
            <Grid item>
              <SearchSystemUserInput />
            </Grid>
          )}
          {showSystem === "group" && (
            <Grid item>
              <SearchSystemGroupInput />
            </Grid>
          )}
          {showSystem === "email" && (
            <Grid item xs={8} sm={8} md={8} lg={12}>
              <TextField
                value={collectionEmail}
                onChange={handleEmailInputChange}
                className="input-style"
                name="email"
                id="Email"
                label="Email"
                variant="outlined"
                fullWidth
              />
            </Grid>
          )}
          <Grid container className={classes.btnBlockWidth} justify="flex-end">
            <Button
              color="primary"
              disabled={downloadType === "None" || actionType === "bulkUpdate"}
              onClick={createCollectionRecord}
            >
              Go
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
  /**
   * pagination
   */
  const pagination = (
    <Box
      className={classes.pagination}
      style={{ paddingBottom: "20px", overflow: "hidden" }}
      ml={5}
      mt={2}
      pb={2}
    >
      <Grid container spacing={3} justify="flex-start">
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={selectAllImages}
                onChange={(e) => {
                  handleSelectAllImage(e);
                }}
                checked={selectAllImages}
                className={classes.checkAll}
              />
            }
            label="Select Page"
          />
        </Grid>
        {selectedBucket !== "DF Studio" &&
          <>
            <Grid item>{SortingByEle}</Grid>
            <Grid item>{SortingTypeEle}</Grid>
            <Grid item>
              <InputLabel shrink style={{ display: "inline-block" }}>
                Preview Type
              </InputLabel>
              <Select
                labelId="preview-type-label"
                id="preview-type"
                label="Preview Type"
                labelPlacement="start"
                value={previewStyle}
                onChange={updatePreview}
              >
                <MenuItem value="textImage">Text below Media</MenuItem>
                <MenuItem value="img">Media & Text</MenuItem>
                <MenuItem value="text">Text Only</MenuItem>
                <MenuItem value="imgOnly">Media Only</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <InputLabel shrink style={{ display: "inline-block" }}>
                Per Page
              </InputLabel>
              {searchStore.bucket === "DF Studio" && (
                <Select
                  labelId="demo-simple-select-outlined-label1"
                  id="demo-simple-select-outlined1"
                  label="Per Page"
                  labelPlacement="start"
                  defaultValue={model?.limit}
                  value={model?.limit}
                  onChange={updateLimit}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                </Select>
              )}
              {searchStore.bucket !== "DF Studio" && (
                <Select
                  labelId="demo-simple-select-outlined-label1"
                  id="demo-simple-select-outlined1"
                  label="Per Page"
                  labelPlacement="start"
                  defaultValue={model?.limit}
                  value={model?.limit}
                  onChange={updateLimit}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              )}
            </Grid>
            <Grid item>
              <Button color="primary" onClick={() => sortButtonClicked()}>
                Sort
              </Button>
            </Grid>
          </>
        }
        <Grid item>
          <Box display="flex">
            <PaginationItem
              type="previous"
              size="large"
              color="primary"
              variant="outlined"
              onClick={handleNavPrevPage}
              disabled={model.currentPage <= 1}
            />
            <PaginationItem
              type="page"
              page={
                <>
                  More&nbsp;&nbsp;
                  <ArrowForwardIos className="MuiPaginationItem-icon" />
                </>
              }
              size="large"
              shape="primary"
              variant="outlined"
              onClick={handleNavNextPage}
              disabled={model.currentPage >= model.totalPages}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const onSuccess = (isBulkUpdate) => {
    if (isBulkUpdate) {
      onBulkModalClose();
      fetchSearchResults("", sortingType);
    } else {
      if (Object.keys(selectedObj).length <= 1) handleClose();
      fetchSearchResults("", sortingType);
    }
    setState({
      ...state,
      open: true,
      message: "Media metadata updated successfully!..",
      severity: "success",
    });
  };

  const onFailure = () => {
    setState({
      ...state,
      open: true,
      message: "Something went wrong.Try again!..",
      severity: "error",
    });
  };
  const onInfoalert = () => {
    setState({
      ...state,
      open: true,
      message:
        "Your request to Create a collection has been queued. Please try after a few mins",
      severity: "info",
    });
  };
  const refreshResult = () => {
    fetchSearchResults("", sortingType);
  }

  /**
   * Result
   */
  const Result = (
    <div>
      <Grid item>
        <Box ml="10px">
          <Typography>
            Total item(s): {model.total} Selected item(s):{" "}
            {downloadType === "downloadAll"
              ? model.total
              : Object.keys(selectedObj).length}
          </Typography>
        </Box>
      </Grid>
      <Grid container>
        <Grid item xs>
          {pagination}
        </Grid>
      </Grid>
      <Grid container justify="flex-start" className={classes.marginBottom}>
        <Grid item Xs>
          {SearchFields}
        </Grid>
      </Grid>

      <div style={{ marginLeft: "50px" }}>
        <div className="refresh-icon">
          <Icon
            color="primary"
            onClick={() => {
              refreshResult();
            }}
          >
            refresh
          </Icon>
        </div>

        {previewStyle === "textImage" && (
          <MuiGridList
            cellHeight={265}
            cols={getGridListCols()}
            className={classes.gridList}
          >
            {model?.data?.length > 0
              ? model?.data.map((tile, index) => (
                <GridListTile
                  key={tile.id}
                  style={{
                    width: "23%",
                    backgroundColor: "#f5f1f0",
                    marginRight: "1px",
                    marginLeft: "1px",
                    marginBottom: "1px",
                  }}
                >
                  <Checkbox
                    color="primary"
                    value={tile.id}
                    onChange={(e) => {
                      handleSelectItem(tile);
                    }}
                    checked={
                      selectedObj[tile.id] || downloadType === "downloadAll"
                        ? true
                        : false
                    }
                    className={classes.checkAbsresult}
                  />
                  <Box>
                    <div
                      className="item"
                      style={{
                        width: "100%",
                        marginRight: "1px",
                        marginLeft: "1px",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ height: "200px" }}>
                        {/*----Text below Image----*/}
                        <Wimg
                          fullData = {tile}
                          src={tile.thumbnail_url}
                          alt={tile.name || "Unknown"}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          waterMarks={[
                            {position: 'right-bottom', text: 'BIZDAMZ', size: 48},
                            {position: 'diagonal', text: email, size: 24 }
                          ]}
                          onClick={() => handleOpen(tile, index)}
                        />
                      </div>
                      <Box
                        display="flex"
                        style={{ fontSize: "11px" }}
                        flexDirection="column"
                      >
                        <span>
                          <strong>Media File Name : </strong>
                          {tile.thumbnail != "" &&
                            setFileName(tile.thumbnail) + "." + tile.mimeType}
                        </span>
                        {tile.exif.length > 0 && (
                          <span>
                            <strong>Creator / Date / Place : </strong>
                            {setExifData(tile.exif, tile)}
                          </span>
                        )}
                        <span>
                          <strong>Bucket / Saved Name : </strong>
                          {tile.awsS3FileLocation.bucket}{" "}
                          {tile.awsS3FileLocation.bucket != "" &&
                            tile.name != ""
                            ? "/"
                            : ""}{" "}
                          {tile.name}
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopyText(tile.mediaFilename);
                            }}
                          ></IconButton>
                        </span>
                      </Box>
                    </div>
                  </Box>
                </GridListTile>
              ))
              : dfsData.map((tile, index) => (
                <GridListTile
                  key={tile.dfsId}
                  style={{
                    width: "23%",
                    backgroundColor: "#f5f1f0",
                    marginRight: "1px",
                    marginLeft: "1px",
                    marginBottom: "1px",
                  }}
                >
                  <Checkbox
                    color="primary"
                    value={tile.dfsId}
                    onChange={(e) => {
                      handleSelectItem(tile, true);
                    }}
                    checked={selectedObj[tile.dfsId] ? true : false}
                    className={classes.checkAbsresult}
                  />
                  <Box>
                    <div
                      className="item"
                      style={{
                        width: "100%",
                        marginRight: "1px",
                        marginLeft: "1px",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ height: "200px" }}>
                        <img
                          src={`data:image/jpeg;base64,${tile.thumbnail?.data}`}
                          alt={tile.name || "Unknown"}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          onClick={() => handleOpen(tile, index)}
                        />
                      </div>
                      <Box
                        display="flex"
                        style={{ fontSize: "11px" }}
                        flexDirection="column"
                      >
                        <span>
                          <strong>Media File Name : </strong>
                          {tile.name != "" &&
                            tile.name + "." + tile.fileType}
                        </span>
                        {tile.addedToStudio > 0 && (
                          <span>
                            <strong>Creator / Date / Place : </strong>
                            {tile.addedToStudio}{" "}
                          </span>
                        )}
                        <span>
                          <strong>Bucket / Saved Name1 : </strong>
                          {searchStore.bucket}{" "}
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopyText(tile.name);
                            }}
                          ></IconButton>
                        </span>
                      </Box>
                    </div>
                  </Box>
                </GridListTile>
              ))}
          </MuiGridList>
        )}
        {(previewStyle === "text" || previewStyle === "imgOnly") && (
          <MuiGridList cols={getGridListCols()} className={classes.gridList}>
            {model.data?.length > 0
              ? model?.data.map((tile, index) => (
                <GridListTile
                  key={tile.id}
                  style={{
                    width: "23%",
                    backgroundColor: "#f5f1f0",
                    marginRight: "5px",
                    marginLeft: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <Checkbox
                    color="primary"
                    value={tile.id}
                    onChange={(e) => {
                      handleSelectItem(tile);
                    }}
                    checked={selectedObj[tile.id] ? true : false}
                    className={classes.checkAbs}
                  />
                  {previewStyle === "text" ? (
                    <div
                      className={classes.textOnly}
                      onClick={() => handleOpen(tile, index)}
                    >
                      <Box display="flex" flexDirection="column">
                        {tile.thumbnail && (
                          <span style={{ fontSize: "11px" }}>
                            <strong>Media Name:</strong>{" "}
                            {setFileName(tile.thumbnail) +
                              "." +
                              tile.mimeType}{" "}
                          </span>
                        )}
                        {tile.name && (
                          <span style={{ fontSize: "11px" }}>
                            <strong>Saved Upload Name:</strong> {tile.name}{" "}
                          </span>
                        )}
                        <span style={{ fontSize: "11px" }}>
                          <strong>Path:</strong>
                          {tile.mediaFilename}{" "}
                          <IconButton
                            style={{ transform: "scale(0.6)" }}
                            size="small"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopyText(tile.mediaFilename);
                            }}
                          >
                            <FileCopy />
                          </IconButton>
                        </span>
                        <span style={{ fontSize: "11px" }}>
                          <strong>Bucket:</strong>
                          {tile.awsS3FileLocation.bucket}
                        </span>

                        {tile.keywords && (
                          <span style={{ fontSize: "11px" }}>
                            <strong>Keywords:</strong>{" "}
                            {tile.keywords.substring(0, 50)}{" "}
                          </span>
                        )}
                        {tile.createdAt && (
                          <span style={{ fontSize: "11px" }}>
                            <strong>Date Time:</strong>{" "}
                            {getDateValue(tile.createdAt)}{" "}
                          </span>
                        )}
                      </Box>
                    </div>
                  ) : (
                    <Box>
                      <div
                        className="item"
                        style={{
                          width: "100%",
                          backgroundColor: "#f5f1f0",
                          marginRight: "5px",
                          marginLeft: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        {/* <img
                          src={tile.thumbnail_url}
                          alt={tile.name || "Unknown"}
                          style={{ width: "100%" }}
                          onClick={() => handleOpen(tile, index)}
                        /> */}
                        {/*----Image Only----*/}
                        <div style={{ height: "200px" }}>
                          <Wimg
                            fullData = {tile}
                            src={tile.thumbnail_url}
                            alt={tile.name || "Unknown"}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                            waterMarks={[
                              {position: 'right-bottom', text: 'BIZDAMZ', size: 48},
                              {position: 'diagonal', text: email, size: 24 }
                            ]}
                            onClick={() => handleOpen(tile, index)}
                          />
                        </div>
                      </div>
                    </Box>
                  )}
                </GridListTile>
              ))
              : dfsData.map((tile, index) => (
                <GridListTile
                  key={tile.dfsId}
                  style={{
                    width: "23%",
                    backgroundColor: "#f5f1f0",
                    marginRight: "5px",
                    marginLeft: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <Checkbox
                    color="primary"
                    value={tile.dfsId}
                    onChange={(e) => {
                      handleSelectItem(tile, true);
                    }}
                    checked={selectedObj[tile.dfsId] ? true : false}
                    className={classes.checkAbs}
                  />
                  {previewStyle === "text" ? (
                    <div
                      className={classes.textOnly}
                      onClick={() => handleOpen(tile, index)}
                    >
                      <Box display="flex" flexDirection="column">
                        {tile.name && (
                          <span style={{ fontSize: "11px" }}>
                            <strong>Media Name:</strong> {tile.name}{" "}
                          </span>
                        )}
                        {tile.name && (
                          <span style={{ fontSize: "11px" }}>
                            <strong>Saved Upload Name:</strong> {tile.name}{" "}
                          </span>
                        )}
                        <span style={{ fontSize: "11px" }}>
                          <strong>Path:</strong>
                          {tile.location}{" "}
                          <IconButton
                            style={{ transform: "scale(0.6)" }}
                            size="small"
                            color="primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopyText(tile.name);
                            }}
                          >
                            <FileCopy />
                          </IconButton>
                        </span>
                        <span style={{ fontSize: "11px" }}>
                          <strong>Bucket:</strong>
                          {searchStore.bucket}{" "}
                        </span>

                        {tile.keywords && (
                          <span style={{ fontSize: "11px" }}>
                            <strong>Keywords:</strong>{" "}
                            {tile.name.substring(0, 50)}{" "}
                          </span>
                        )}
                        {tile.addedToStudio && (
                          <span style={{ fontSize: "11px" }}>
                            <strong>Date Time:</strong> {tile.addedToStudio}{" "}
                          </span>
                        )}
                      </Box>
                    </div>
                  ) : (
                    <Box>
                      <div
                        className="item"
                        style={{
                          width: "100%",
                          backgroundColor: "#f5f1f0",
                          marginRight: "5px",
                          marginLeft: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          src={`data:image/jpeg;base64,${tile.thumbnail?.data}`}
                          alt={tile.name || "Unknown"}
                          style={{ width: "100%" }}
                          onClick={() => handleOpen(tile, index)}
                        />
                      </div>
                    </Box>
                  )}
                </GridListTile>
              ))}
          </MuiGridList>
        )}
        {previewStyle === "img" && (
          <MuiGridList
            cellHeight={220}
            cols={getGridListCols()}
            className={classes.gridList}
          >
            {model.data?.length > 0
              ? model.data.map((tile, index) => (
                <GridListTile key={tile.id}>
                  <Checkbox
                    color="primary"
                    value={tile.id}
                    onChange={(e) => {
                      handleSelectItem(tile);
                    }}
                    checked={selectedObj[tile.id] ? true : false}
                    className={classes.checkAbs}
                  />
                  <Box>
                    <div style={{ backgroundColor: "#ccc", width: "100%" }}>
                      {/* <img
                        src={tile.thumbnail_url}
                        alt={tile.name || "Unknown"}
                        style={{ width: "100%", height: "100%" }}
                        onClick={() => handleOpen(tile, index)}
                      /> */}
                      {/*----Text over image----*/}
                      <div style={{ height: "200px" }}>
                        <Wimg
                            fullData = {tile}
                            src={tile.thumbnail_url}
                            alt={tile.name || "Unknown"}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                            waterMarks={[
                              {position: 'right-bottom', text: 'BIZDAMZ', size: 48},
                              {position: 'diagonal', text: email, size: 24 }
                            ]}
                            onClick={() => handleOpen(tile, index)}
                          />
                        </div>

                    </div>
                    <GridListTileBar
                      onClick={() => handleOpen(tile, index)}
                      className={classes.tileBar}
                      title={
                        <>
                          {" "}
                          {
                            <Typography
                              variant="body2"
                              style={{
                                whiteSpace: "normal",
                                wordBreak: "break-all",
                                fontSize: "11px",
                              }}
                            >
                              <span>
                                <strong>Media File Name : </strong>{" "}
                                {tile.thumbnail != "" &&
                                  setFileName(tile.thumbnail) +
                                  "." +
                                  tile.mimeType}
                              </span>
                            </Typography>
                          }
                        </>
                      }
                      subtitle={
                        <Box
                          display="flex"
                          style={{ fontSize: "11px" }}
                          flexDirection="column"
                        >
                          {tile.exif.length > 0 && (
                            <span>
                              <strong>Creator / Date / Place : </strong>
                              {setExifData(tile.exif, tile)}{" "}
                            </span>
                          )}
                          <span>
                            <strong>Bucket / Saved Name : </strong>{" "}
                            {tile.awsS3FileLocation.bucket}{" "}
                            {tile.awsS3FileLocation.bucket != "" &&
                              tile.name != ""
                              ? "/"
                              : ""}{" "}
                            {tile.name}{" "}
                          </span>
                        </Box>
                      }
                      actionIcon={
                        <Checkbox
                          color="primary"
                          value={tile.id}
                          style={{ opacity: 0 }}
                        />
                      }
                    />
                  </Box>
                </GridListTile>
              ))
              : dfsData.map((tile, index) => (
                <GridListTile key={tile.dfsId}>
                  <Checkbox
                    color="primary"
                    value={tile.dfsId}
                    onChange={(e) => {
                      handleSelectItem(tile, true);
                    }}
                    checked={selectedObj[tile.dfsId] ? true : false}
                    className={classes.checkAbs}
                  />
                  <Box>
                    <div style={{ backgroundColor: "#ccc" }}>
                      <img
                        src={`data:image/jpeg;base64,${tile.thumbnail?.data}`}
                        alt={tile.name || "Unknown"}
                        style={{ width: "100%", height: "100%" }}
                        onClick={() => handleOpen(tile, index)}
                      />
                    </div>
                    <GridListTileBar
                      onClick={() => handleOpen(tile, index)}
                      className={classes.tileBar}
                      title={
                        <>
                          {" "}
                          {
                            <Typography
                              variant="body2"
                              style={{
                                whiteSpace: "normal",
                                wordBreak: "break-all",
                                fontSize: "11px",
                              }}
                            >
                              <span>
                                <strong>Media File Name1 : </strong>{" "}
                                {tile.name != "" &&
                                  tile.name + "." + tile.fileType}
                              </span>
                            </Typography>
                          }
                        </>
                      }
                      subtitle={
                        <Box
                          display="flex"
                          style={{ fontSize: "11px" }}
                          flexDirection="column"
                        >
                          {tile.addedToStudio && (
                            <span>
                              <strong>Creator / Date / Place : </strong>
                              {tile.addedToStudio}{" "}
                            </span>
                          )}
                          <span>
                            <strong>Bucket / Saved Name : </strong>{" "}
                            {searchStore.bucket}{" "}
                          </span>
                        </Box>
                      }
                      actionIcon={
                        <Checkbox
                          color="primary"
                          value={tile.dfsId}
                          style={{ opacity: 0 }}
                        />
                      }
                    />
                  </Box>
                </GridListTile>
              ))}
          </MuiGridList>
        )}
      </div>

      <Grid container justify="flex-start">
        <Grid item xs>
          {pagination}
        </Grid>
      </Grid>
      <Grid container justify="flex-start" className={classes.marginBottom}>
        <Grid item Xs>
          {SearchFields}
        </Grid>
      </Grid>

      <Dialog
        open={!!modalItem.id}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        maxWidth="lg"
      >
        <ImageDetails
          imageItem={modalItem}
          onSuccess={() => onSuccess(false)}
          onFailure={onFailure}
        />
      </Dialog>

      <Dialog
        disableEnforceFocus
        open={bulkUpdateModalStatus === "open"}
        onClose={handleBulkUpdateModalClose}
        aria-labelledby="bulkupdate-image-modal"
        aria-describedby="Update details for selected images"
        fullWidth="true"
        maxWidth="lg"
        classes={{ paper: classes.modalWrapper }}
      >
        <BulkUpdateModal
          downloadType={downloadType}
          selectedObj={selectedObj}
          modalClose={onBulkModalClose}
          onSuccess={() => onSuccess(true)}
          onFailure={onFailure}
        ></BulkUpdateModal>
      </Dialog>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          autoHideDuration={1000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
  return (
    <div>
      <div style={{ textAlign: "right" }}>
        <Button contained color="secondary" onClick={navigateToSearch}>
          Modify Search
        </Button>
      </div>
      {/* Start of search image */}
      {Result}
      <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
        <CircularProgress />
      </Backdrop>

      {largePdf === true ? (
        <div>
          <Dialog
            open={largePdf}
            onClose={() => {
              setLargePdf(false);
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">
              Request submitted!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your request to create {filetype} has been queued. <br />
                Once the {filetype}  is ready, an email will be sent to {email} with a
                link to download.
                <br />
                Do check your spam or junk folder, if it doesn't arrive in your
                email inbox.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  setLargePdf(false);
                  goTo("/search/results");
                }}
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
    </div>
  );
};

export default withWidth()(SearchPage);
