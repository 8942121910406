import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../button/Button";
import withWidth from "@material-ui/core/withWidth";
import { dispatch, goTo } from "redux-store";
import {
  fetchCollectionList,
  deleteSearchCollection,
  listUserGroupsByUserId,
  collectionsSharedWithGroups,
  getUserByemail
} from "service/api.service";
import { collectionSetFieldValue } from "store/collections-reducer";
import { useAppUserContext } from "lib/contextLib";
import "./tooltip.css"
import invoke from "service/lambda.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { searchSetFieldValue } from "store/search-reducer";
import config from '../../aws-exports';
import { awsConfig, Bucket } from '../../config';
import copyToClipboard from "copy-to-clipboard";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const styles = {
  opacityTr: {
    opacity: "0.5"

  },
  container: {
    maxHeight: 440,
  },
};

const useStyles = makeStyles(styles);


const MyCollections = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [showForm, toggleFormState] = React.useState(false);
  const [showUsersModal, setShowUsersModal] = React.useState(false);
  const [showDownloadLinkError, setShowDownloadLinkError] = React.useState(false);
  const [userGroupTitle, setUserGroupTitle] = React.useState('');
  const [sharedUsersByMe, setSharedUsersByMe] = React.useState([]);
  const [modalItem, setModalItem] = useState(false);
  const [collectionData, setCollectionData] = useState([]);
  const [savedCollectionData, setSavedCollectionData] = useState([]);
  const [shareMeCollectionData, setShareMeCollectionData] = useState([]);
  const [shareGroupCollectionData, setShareGroupCollectionData] = useState([]);
  const [shareFileCollectionData, setShareFileCollectionData] = useState([]);
  const [showLoading, setShowLoading] = useState(false);

  const { email } = useAppUserContext();
  const currentUser = useAppUserContext();

  useEffect(() => {

    dispatch(collectionSetFieldValue({ isEditCollections: false }));
    fetchCollectionLists();
  }, []);

  const [userRoles, setUserRoles] = useState(false);

  const [saveCollection, setSaveCollection] = useState(false);
  const [savedWithMe, setSavedWithMe] = useState(false);
  const [savedWithGroup, setSavedWithGroup] = useState(false);
  const [sharedFile, setSharedFile] = useState(false);

  useEffect(() => {
    setShowLoading(true);
    getUserByemail().then((finalData) => {
      if (finalData)
        enableMyCollection(finalData);
      setShowLoading(false);
    }).catch(() => {
      setShowLoading(false);
    });

  }, []);
  const enableMyCollection = (userRolesData) => {
    const adminRole = userRolesData;
    if (adminRole.userData && adminRole.userData.length) {
      listUserGroupsByUserId(adminRole.userData[0].id).then((res) => {
        let groupsName = [];
        if (res.items && res.items.length) {
          res.items.forEach((value) => {
            groupsName.push({ sharedGroups: { contains: value.group.groupName } });
          });
        }
        collectionsSharedWithGroups(groupsName).then((collectionGroup) => {
          setShareGroupCollectionData(collectionGroup.items);
        }).catch(() => {
          setShowLoading(false);
        });
      }).catch(() => {
        setShowLoading(false);
      });
    }
    if (adminRole && adminRole.roles) {
      if (adminRole.roles.indexOf("Super Admin") !== -1) {
        setSaveCollection(true);
        setSavedWithMe(true);
        setSavedWithGroup(true);
        setUserRoles(true);
        setSharedFile(true);
      }
    }
    if (adminRole && adminRole.resultData && adminRole.resultData.length) {
      adminRole.resultData.forEach((val) => {
        if (val.path === "1" || val.path === "1.children.3") {
          setSaveCollection(true);
          setSavedWithMe(true);
          setSavedWithGroup(true);
          setUserRoles(true);
          setSharedFile(true);
          setValue(0);
        }
        if (val.path === "1.children.3.children.0") {
          setSaveCollection(true);
          setUserRoles(true);
          setValue(0);
        }
        if (val.path === "1.children.3.children.1") {
          setSavedWithMe(true);
          setUserRoles(true);
          setValue(1);
        }
        if (val.path === "1.children.3.children.2") {
          setSavedWithGroup(true);
          setUserRoles(true);
          setValue(2);
        }
        if (val.path === "1.children.3.children.3") {
          setSharedFile(true);
          setUserRoles(true);
          setValue(3);
        }

      })
    }
  }
  /**
   *
   */
  const fetchCollectionLists = async () => {
    await fetchCollectionList().then((collectionData) => {
      let shareMeCollectionData = [];
      collectionData?.items.forEach((val) => {
        if (val && val.updatedAt) {
          const date = new Date(val.updatedAt);
          val.updatedAt = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        }
      })
      const savedCollectionData = collectionData?.items.filter((value) => {
        if (
          (value.createdBy === email || (value.createdBy === email && value.sharedUsers) || (value.createdBy === email && value.sharedGroups)) &&
          !value.email &&
          (!value.remarks || value.remarks === "None" || value.remarks === "collection")
        )
          return true;

        return false;
      });
      collectionData?.items.forEach((collection) => {
        if (collection?.sharedUsers && collection?.sharedUsers.length) {
          const result = collection?.sharedUsers.filter(
            (value) => value === email
          );
          if (result && result.length) {
            shareMeCollectionData.push(collection);
          }
        }
      });
      const shareFileCollectionData = collectionData?.items.filter((value) => {
        if (value.createdBy === email && value.remarks === "sharefile") return true;

        return false;
      });
      dispatch(collectionSetFieldValue({ collectionLists: collectionData?.items }))
      setSavedCollectionData(savedCollectionData);
      setShareMeCollectionData(shareMeCollectionData);
      setShareFileCollectionData(shareFileCollectionData)
      setCollectionData(collectionData?.items);
    });
  };

  const handleChange = (event, newValue) => {
    if (event.target.innerHTML === 'SAVED') {
      newValue = 0;
    }
    if (event.target.innerHTML === 'SHARE WITH ME') {
      newValue = 1;
    }
    if (event.target.innerHTML === 'SHARE WITH GROUPS') {
      newValue = 2;
    }
    if (event.target.innerHTML === 'SHARED FILE') {
      newValue = 3;
    }
    setValue(newValue);
    // setpg(0);
    // setrpg(10)
  };
  const deleteData = (event) => {
    toggleFormState(true);
    dispatch(collectionSetFieldValue({ id: event?.id }));
  };
  const handleClose = () => {
    toggleFormState(false);
    setShowUsersModal(false);
    setShowDownloadLinkError(false);
    setModalItem({});
  };
  const deleteCollectionData = async (evt) => {
    await deleteSearchCollection().then(() => {
      fetchCollectionLists();
      toggleFormState(false);
      setModalItem({});
    });
  };
  const tableRowCopy = async (rowData) => {
    let ext = ''
    setShowLoading(true);
    if (rowData?.type === "compressedfiles") {
      ext = "-hires.zip"
    } else if (rowData?.type === "txt") {
      ext = "-txt.txt"
    } else {
      ext = '.' + rowData?.type
    }

    const resp = await invoke("wdtvamtoolCollectionUtils-" + awsConfig.environment, {
      "region": config.aws_project_region,
      "bucket": Bucket.collection,
      "action": "url",
      "expiryMins": 3600,
      "key": rowData?.name + ext
    }, currentUser.creds);

    const respPayload = JSON.parse(resp.Payload);


    if (respPayload.statusCode == 500) {
      setShowLoading(false);
      setShowDownloadLinkError(true);
    } else {
      copyToClipboard(respPayload.body);
      setShowLoading(false);
      return
    }



  }
  const isDisabled = (index, flag) => {
    if (flag) {
      return false;
    } else {
      return true;
    }

  }

  const navigateToResult = (rowData, flag) => {

    dispatch(
      collectionSetFieldValue({
        id: rowData?.id,
        name: rowData?.name,
        email: rowData?.email,
        target: rowData?.target,
        action: rowData?.type,
        shareVia: rowData?.remarks,
        systemGroups: rowData.sharedGroups ? rowData.sharedGroups : [],
        objects: rowData?.objects,
        systemUsers: rowData.sharedUsers ? rowData.sharedUsers : [],
        isCollections: true,
      })
    );
    dispatch(searchSetFieldValue({ isFromSearch: true }));
    goTo("/search/results");
  };
  const showUsers = (rowData, flag) => {
    setShowUsersModal(true);
    if (flag === 'user') {
      setUserGroupTitle('Shared Users')
      setSharedUsersByMe(rowData?.sharedUsers ? rowData?.sharedUsers : []);
    } else {
      setUserGroupTitle('Shared Groups')
      setSharedUsersByMe(rowData?.sharedGroups ? rowData?.sharedGroups : []);
    }
  }
  const saveCollectionColumns = [
    {
      field: "name", type: "actions", headerName: "Collection Name", width: 270, align: 'left', headerAlign: "left",
      getActions: (rowData) => [
        <div>
          <a
            href="#"
            onClick={() => {
              navigateToResult(rowData?.row);
            }}
          >
            {rowData?.row.name}
          </a>
        </div>,
      ]
    },
    // {
    //   field: "sharedto", type: "actions", headerName: "Shared To", width: 200,
    //   getActions: (rowData) => [
    //     <div>
    //       {(rowData?.row.sharedUsers?.length || rowData?.row.sharedGroups?.length) &&
    //         <GridActionsCellItem
    //           icon={
    //             <Icon
    //               color="primary"
    //               onClick={() => {
    //                 showUsers(rowData?.row, rowData?.row.sharedUsers?.length ? 'user' : 'group');
    //               }}
    //             >
    //               open_in_new
    //             </Icon>
    //           }
    //           label="open"
    //         />
    //       }

    //     </div>,
    //   ]
    // },
    { field: "updatedAt", headerName: "Saved Date", width: 280 },
    {
      field: "actions", headerName: "Action", type: "actions", width: 100,
      getActions: (rowData) => [
        <div>
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  deleteData(rowData?.row);
                }}
              >
                delete_circle
              </Icon>
            }
            label="Delete"
          />
        </div>,
      ]
    }
  ];
  const shareMeCollectionColumns = [
    {
      field: "name", type: "actions", headerName: "Collection Name", width: 270, align: 'left', headerAlign: "left",
      getActions: (rowData) => [
        <div>
          <a
            href="#"
            onClick={() => {
              navigateToResult(rowData?.row);
            }}
          >
            {rowData?.row.name}
          </a>
        </div>,
      ]
    },
    { field: "updatedAt", headerName: "Saved Date", width: 280 },
    {
      field: "actions", headerName: "Action", type: "actions", width: 100,
      getActions: (rowData) => [
        <div>
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  deleteData(rowData?.row);
                }}
              >
                delete_circle
              </Icon>
            }
            label="Delete"
          />
        </div>,
      ]
    }
  ];
  const shareGroupCollectionColumns = [
    {
      field: "name", type: "actions", headerName: "Collection Name", width: 270, align: 'left', headerAlign: "left",
      getActions: (rowData) => [
        <div>
          <a
            href="#"
            onClick={() => {
              navigateToResult(rowData?.row);
            }}
          >
            {rowData?.row.name}
          </a>
        </div>,
      ]
    },
    { field: "updatedAt", headerName: "Saved Date", width: 280 },
    {
      field: "actions", headerName: "Action", type: "actions", width: 100,
      getActions: (rowData) => [
        <div>
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  deleteData(rowData?.row);
                }}
              >
                delete_circle
              </Icon>
            }
            label="Delete"
          />
        </div>,
      ]
    }
  ];
  const shareFileCollectionColumns = [
    {
      field: "name", type: "actions", headerName: " Name", width: 270, align: 'left', headerAlign: "left",
      getActions: (rowData) => [
        <div>
          <a className="share_file_link"
            href="#"

          >
            <span id="copy_file">{rowData?.row.name}</span>

          </a>
          <Icon className="copy-icon" color="primary" onClick={() => tableRowCopy(rowData?.row)}>
            content_copy
          </Icon>

        </div>,
      ]
    },
    { field: "type", headerName: "Type", width: 280 },
    { field: "updatedAt", headerName: "Date", width: 280 },
    {
      field: "actions", headerName: "Action", type: "actions", width: 100,
      getActions: (rowData) => [
        <div>
          <GridActionsCellItem
            icon={
              <Icon
                color="primary"
                onClick={() => {
                  deleteData(rowData?.row);
                }}
              >
                delete_circle
              </Icon>
            }
            label="Delete"
          />
        </div>,
      ]
    }
  ];
  return (

    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "primary" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab disabled={isDisabled(0, saveCollection)} label="MY COLLECTION" {...a11yProps(0)} />
          <Tab disabled={isDisabled(1, savedWithMe)} label="SHARE WITH ME" {...a11yProps(1)} />
          <Tab disabled={isDisabled(2, savedWithGroup)} label="SHARE WITH GROUPS" {...a11yProps(2)} />
          <Tab disabled={isDisabled(3, sharedFile)} label="SHARED FILE" {...a11yProps(3)} />


        </Tabs>
      </Box>
      {saveCollection &&
        <TabPanel value={value} index={0}>
          <div style={{ height: 500, width: "100%", overflow: "auto" }}>
            <DataGrid rows={savedCollectionData} columns={saveCollectionColumns} />
          </div>
        </TabPanel>
      }
      {savedWithMe &&
        <TabPanel value={value} index={1}>
          <div style={{ height: 500, width: "100%", overflow: "auto" }}>
            <DataGrid rows={shareMeCollectionData} columns={shareMeCollectionColumns} />
          </div>
        </TabPanel>
      }
      {savedWithGroup &&
        <TabPanel value={value} index={2}>
          <div style={{ height: 500, width: "100%", overflow: "auto" }}>
            <DataGrid rows={shareGroupCollectionData} columns={shareGroupCollectionColumns} />
          </div>
        </TabPanel>
      }
      {sharedFile &&
        <TabPanel value={value} index={3}>
          <div style={{ height: 500, width: "100%", overflow: "auto" }}>
            <DataGrid rows={shareFileCollectionData} columns={shareFileCollectionColumns} />
          </div>
        </TabPanel>
      }
      {showForm ? (
        <div>
          <Dialog
            open={showForm}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={deleteCollectionData}>
                YES
              </Button>
              <Button color="primary" onClick={handleClose} autoFocus>
                NO
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}

      {showDownloadLinkError === true ? (
        <div>
          <Dialog
            open={showDownloadLinkError}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">Download is not yet ready!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The shared file is still getting ready for download. Please try after some time. If you continue to have issues, please contact Support.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleClose} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}


      {showUsersModal ? (
        <div>
          <Dialog
            open={showUsersModal}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">{userGroupTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {sharedUsersByMe.map((row, index) => (
                  <div className="sharedUsersByMe"> <span>{index + 1} . {row}</span></div>
                ))}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleClose} autoFocus>
                CLOSE
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}

      <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default withWidth()(MyCollections);
