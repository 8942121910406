import React from "react";
// @material-ui/core components
// core components
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";
import Button from "../button/Button";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import CardBody from "../card/CardBody";
import CardFooter from "../card/CardFooter";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";

import Grid from "@material-ui/core/Grid";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from "@material-ui/core/InputLabel";
import CancelIcon from '@material-ui/icons/Cancel';

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
   formControl: {
    minWidth: 120,
  },
};

const useStyles = makeStyles(styles);

export default function Help() {

  /**
   * Control to show or hide form
   */
  const [showForm, toggleFormState] = React.useState(false); // Show / hide form 
  const handleShowForm = () => {
    toggleFormState(true) 
  } 
  const handleCloseForm = () => {
    toggleFormState(false) 
  } 

  const classes = useStyles();
  return (
    <div style={{width: '100%'}}>
    { showForm ? 
    <div>
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Help and Support </h4>
        {/* <p className={classes.cardCategoryWhite}>
          Created using Roboto Font Family
        </p> */}
      </CardHeader>
      <CardBody>
      <GridContainer>
      <GridItem xs={2} sm={2} md={3}>
              </GridItem>
              
              <GridItem xs={5} sm={12} md={6}>
                  <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField className="input-style" fullWidth id="issue" label="Submit a request or issue" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                            <TextField
                    id="issueDescription"
                    label="Description"
                    multiline
                    rows={4}
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                  />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                  {/* <TextField className="input-style" fullWidth id="Name" label="Day Part" variant="outlined" /> */}
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Type of Request</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Choose a question"
                      
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Accessablity issue</MenuItem>
                      <MenuItem value={20}>Network issue</MenuItem>
                      <MenuItem value={20}>others</MenuItem>
                    </Select>
                  </FormControl>
                  </GridItem>
                 
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>                 
                    <Button color="primary" variant="contained" component="label">
                        Choose Media file <input type="file" hidden />
                    </Button>                  
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField className="input-style" fullWidth id="orgAppID" label="Organization App ID" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField className="input-style" fullWidth id="userId" label="UserId" variant="outlined" />
                  </GridItem>
                  <span> &nbsp; </span>
                </GridContainer>
              </GridItem>
              <GridItem xs={2} sm={2} md={2}>
              </GridItem>
              <Grid container justify='center'>
      <Grid item justify='center' xs={5} sm={12} md={2}>
      <Button fullWidth color="primary">Submit</Button>
      </Grid>
      </Grid>  
            </GridContainer>
                  
      </CardBody>
      <CardFooter>

      
            </CardFooter>
    </Card>
    <div style={{textAlign: 'right'}}>
      <Button contained color="secondary"  
        startIcon={<CancelIcon/>}
      onClick={() => handleCloseForm()}>Close Form</Button>
    </div>
    </div>
    : <div style={{textAlign: 'right'}}>
        <Button contained color="primary"
         onClick={() => handleShowForm()}>Raise New Request</Button>
      </div> } 
    </div> 
  
  );
}
