import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import ReactPlayer from 'react-player';
import IntroVedio from '../assets/PMT-DAMS_Logo_Intro.mp4'; 
import IntroVeidoBizdamz from '../assets/bizDAMz_Intro_Video.mp4'
import { goTo } from 'redux-store.js'
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: "100vh",
      background: "#0078BA"
    }
}));

const LoginVedioPage = () => {
    const classes = useStyles();
    
  const [modalItem, setModalItem] = useState(false);
  const [playing, updatePlaying] = useState(false);
  const client = localStorage.getItem("currentClient");
  const isShowedLoginVideo = localStorage.getItem("isShowedLoginVideo");
  const isLoginError = localStorage.getItem("isLoginError");

  if(isLoginError === 'true'){
    goTo('/login/' + client);
   }
   if(isShowedLoginVideo === 'true' && isLoginError === 'false'){
     goTo('/search');
    }

    const handleOpen = (item) => {
        setModalItem(item);
      };
      
      const handleClose = () => {
        setModalItem({});
      };
      const handleReady = () =>{
        updatePlaying(true);
        
      }
      const handleStart = () =>{
        
      }
      const handleEnded = () =>{
        localStorage.setItem("isShowedLoginVideo","true");
        goTo('/search');
      }
     
      let currentVedio = IntroVedio;
      
      if (client === "bizdamz") {
        currentVedio = IntroVeidoBizdamz;
      }

  return (
    <Grid container justify="center" align="center" component="main" className={classes.root}>
     
      <Box display="flex" justifyContent="center" alignItems="center">
    <Dialog fullScreen
        open
        
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        >
      <ReactPlayer
          className='react-player'
          url={currentVedio}
          playing = {playing}
          controls ={false}
          width='100%'
          height='100%'
          onReady={handleReady}
          onStart={handleStart}
          onEnded={handleEnded}
        />


      </Dialog> 
    </Box></Grid>
  );
}

export default LoginVedioPage;
