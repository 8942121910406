import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import createRootReducer from './store/rootReducer';

export const history = createBrowserHistory();
export const goTo = history.push;
export const { goBack } = history;
export const historyReplace = history.replace;

const rootReducer = createRootReducer(history);

const router = routerMiddleware(history);
const middleware = [...getDefaultMiddleware(), router];

const excludeLoggerEnvs = ['test', 'production'];
const shouldIncludeLogger = !excludeLoggerEnvs.includes(
  process.env.NODE_ENV || ''
);

if (shouldIncludeLogger) {
  const logger = createLogger({
    level: 'info',
    collapsed: true,
  });
  middleware.push(logger);
}

  // Create Store
export const store =  configureStore({
  reducer: rootReducer,
  middleware,
  preloadedState: window.initialState || {},
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept(
    './store/rootReducer',
    // eslint-disable-next-line global-require
    () => store.replaceReducer(require('./store/rootReducer').default)
  );
}
export const { dispatch } = store;
