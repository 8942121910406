import { dispatch } from "redux-store";
import { updateTransferStatus } from "store/upload-reducer";

let asperaWeb;
let asperaInstaller;
const connectInstaller = "https://d3gcli72yxqn2z.cloudfront.net/connect/v4";
let asperaSelectedFileHandler;


const asperaStatusListener = (eventType, data) => {
  if (eventType === window.AW4.Connect.EVENT.STATUS && data == window.AW4.Connect.STATUS.INITIALIZING) {
    asperaInstaller.showLaunching();
  } else if (eventType === window.AW4.Connect.EVENT.STATUS && data == window.AW4.Connect.STATUS.FAILED) {
    asperaInstaller.showDownload();
  } else if (eventType === window.AW4.Connect.EVENT.STATUS && data == window.AW4.Connect.STATUS.OUTDATED) {
    asperaInstaller.showUpdate();
  } else if (eventType === window.AW4.Connect.EVENT.STATUS && data == window.AW4.Connect.STATUS.RUNNING) {
    asperaInstaller.connected();
  }
  console.log('Aspera Event Listeners set up...');
}

export const initAsperaConnnect = () => {
  asperaWeb = new window.AW4.Connect({ sdkLocation: connectInstaller, minVersion: "3.9.0", dragDropEnabled: true });
  asperaInstaller = new window.AW4.ConnectInstaller({ sdkLocation: connectInstaller });
  asperaWeb.addEventListener(window.AW4.Connect.EVENT.STATUS, asperaStatusListener);
  asperaWeb.initSession();
}

export const asperaSetSelectedFileHandler = (callback = () => {}) => {
  asperaSelectedFileHandler = callback
}

const asperaHandleSelectedFiles = (pathArray) => {
  const files = pathArray.dataTransfer.files;
  asperaSelectedFileHandler(files);
}

const dropListener = (dragDropData) => {
  if (dragDropData.event.type === 'dragenter') {
    
  } else if (dragDropData.event.type === 'dragleave') {
    dragDropData.event.preventDefault();
  } else if (dragDropData.event.type === 'dragover') {
    
  } else if (dragDropData.event.type === 'drop') {
     if (dragDropData.files) {
      
      asperaHandleSelectedFiles(dragDropData.files);
    }
  }
}


const transferListener = (event, allTransferInfo) => {
  if (allTransferInfo && allTransferInfo.transfers) {
    dispatch(updateTransferStatus(allTransferInfo.transfers));
    allTransferInfo.transfers.forEach((transfer) => {
      if (['completed', 'failed'].includes(transfer.status)) {
        removeTransfer(transfer.uuid)
      }
    })
  }
}


const startListeningTransfer = () => {
  asperaWeb.addEventListener('transfer', transferListener);
}
const removeTransfer = (transferId) => {
  asperaWeb.removeTransfer(transferId);
}

/**
 * 
 * @param sourceFiles 
 * @param transferSummary Passed on by the aspera-connect-component so that status can be updated during transfer(for display)
 * @param transferSpecs Passed on by the aspera-connect-component so that status can be updated during transfer(for display)
 */
// initTransfer(enclosingPage, sourceFiles, transferSummary, transferSpecs, requestId) {
export const initTransfer = (enclosingPage, transferSpecs, requestId) => {
  transferSpecs.forEach((item)=>{
    item['transfer_spec']['create_dir'] = true;
    item['transfer_spec']['authentication'] = 'token';
    item['transfer_spec']['tags'] = { request: requestId};  
  });

  const request = asperaWeb.startTransfer(transferSpecs[0]['transfer_spec'],
    { "allow_dialogs": false, use_absolute_destination_path: true },
    {
      success: (obj) => {
        startListeningTransfer();
      },
      error: (err) => {
        console.log('Transfer failed: ' + JSON.stringify(err));
      }
    });

};


/**
 * 
 * @param dragStatus Passed from aspera-connect-component so values can be set here for displaying border around drop area when active,
 */
export const createDragDropListener = (dragStatus) => {
  asperaWeb.setDragDropTargets('#dropArea', { dragEnter: false, dragLeave: true, dragOver: true, drop: true }, dropListener);
}

export const disableDragDrop = () => {
  asperaWeb.setDragDropTargets('#dropArea', { dragEnter: false, dragLeave: false, dragOver: false, drop: false }, dropListener);
}


export const launchLocalBrowser = (files_or_folders = 'files') => {
  if (files_or_folders === 'files') {
    asperaWeb.showSelectFileDialog({ success: asperaHandleSelectedFiles });
  } else {
    asperaWeb.showSelectFolderDialog({ success: asperaHandleSelectedFiles });
  }
}
