import React, { useCallback, useEffect, useState } from "react";
import { NavLink, Switch, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Profile from "../profile/Profile";
import Admin from "../admin/Admin";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpIcon from "@material-ui/icons/Help";
import Help from "../help/Help";
import UploadContainerRoutes from "components/upload/UploadContainerRoutes";
import SearchContainerRoutes from "components/search/SearchContainerRoutes";
import ScanContainerRoutes from "components/scan/ScanContainerRoutes";
import { useAppUserContext } from "lib/contextLib";
import AdminOnly from "components/auth/AdminOnly";
import logopmt from "../../assets/PMT_Logo.jpeg";
import logobizdamz from "../../assets/bizDAMz_Logo.png";
import SecureRoute from '../secure-route/SecureRoute';
import Cookies from 'universal-cookie';
import {
  getUserByemail, updateUserActiveTime,
} from "service/api.service";
import { SecretStorageService } from "service/sss.service";

const cookies = new Cookies();

const newTheme = createTheme({
  palette: {
    primary: {
      main: "#0078ba",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  palette: {
    primary: {
      main: "#00ff00",
    },
  },
  tabs: {
    flexGrow: 1,
  },
  profileIcon: {
    float: "right",
  },
  avatarText: {
    color: "#3393c8",
    fontSize: "12px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  logoText: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  avatarImg: {},
  avatarContainer: {
    marginLeft: "20px",
  },
}));

const ContainerRoutes = (isSuperAdmin) => {

  return <Switch>
    <SecureRoute path='/search' component={SearchContainerRoutes} />
    <SecureRoute path='/upload' component={UploadContainerRoutes} />
    

    <SecureRoute path='/scan' render={(props) => (
      <AdminOnly redirect><ScanContainerRoutes {...props} /></AdminOnly>
    )} />

    
    <SecureRoute path='/admin' render={(props) => (
      <AdminOnly redirect><Admin {...props} /></AdminOnly>
    )} />
    <SecureRoute path='/help' component={Help} />
    
    <SecureRoute path='/profile' render={(props) => (
      <AdminOnly redirect><Profile {...props} /></AdminOnly>
    )} />
  </Switch>
}

const UserToolbar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { email, id } = useAppUserContext();
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = useCallback(async () => {
    try {
      cookies.remove('federation_response');
      cookies.remove('refresh_token');
      cookies.remove('refresh_token_nonmyid');
      console.log('Cookies removed...');
      localStorage.clear();

      // console.dir(user);
      await updateUserActiveTime({
        id:id,
        currentTab: '',
      });      

      setTimeout(async () => { //---Let's give some time for the cookie to clear...
        localStorage.clear();
        SecretStorageService.clear();
        console.log('Signout complete...');
        await Auth.signOut();
      }, 3000);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }, []);

  const handleProfileClose = () => {
    setAnchorEl(null);
  };
  const client = localStorage.getItem("currentClient");
  let currentTool = logopmt;
  if (client === "bizdamz") {
    currentTool = logobizdamz;
  }

  const imgStyle = {
    width: "100px",
    height: "70px",
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <div onClick={handleMenu}>
        <img
          src={currentTool}
          alt="logo"
          style={imgStyle}
          className={classes.img}
        />
      </div>
     
      <span className={classes.avatarText}>{email}</span>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleProfileClose}
      >
        <MenuItem onClick={handleLogout}>Log-Out</MenuItem>
      </Menu>
    </Box>
  );
};


export default function TabsNew() {

  const [isSuperAdmin, seIsSuperAdmin] = useState(true);
  const [menuItems, setMenuItems] = useState([
    { label: "Search", path: "/search", Icon: ImageSearchIcon, isShow: true },
    { label: "Upload", path: "/upload", Icon: CloudUploadIcon, isShow: true },
    // {label: 'Manage Data', path: '/manage-data', Icon: StorageIcon},
    { label: "Scan", path: "/scan", Icon: FindInPageIcon, adminOnly: true, isShow: true },
    { label: "Admin", path: "/admin", Icon: SettingsIcon, adminOnly: true, isShow: true },
    {
      label: "Help",
      path: "https://bizcloudz.com/requests-and-support/",
      Icon: HelpIcon,
      isShow: true
    },
    {
      label: "Profile",
      path: "/profile",
      Icon: AccountCircleIcon,
      adminOnly: true,
      isShow: true
    },
  ]);
  useEffect(() => {
    getUserByemail().then((finalData) => {
      if (finalData)
        if (finalData.userData && finalData.userData.length) {
          if (finalData.userData[0].roles[0] !== "Super Admin") {
            menuItems[2].isShow = false;
            setMenuItems(menuItems)
            seIsSuperAdmin(false);
          }
        }
    }).catch(() => {

    });
  }, []);

  const location = useLocation();
  React.useEffect(() => {
    const currentPath = location.pathname;
    const currentPathIndex = menuItems.findIndex((o) =>
      currentPath.startsWith(o.path)
    );
    if (currentPathIndex !== -1) {
      setValue(currentPathIndex);
    }
  }, [location]);
  function urlRedirect(url) {
    window.open(url, "_blank");
  }
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { isAdmin } = useAppUserContext();
  return (
    <ThemeProvider theme={newTheme}>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Toolbar>
            <Tabs
              value={value}
              className={classes.tabs}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {menuItems.map((o) =>
                o.adminOnly && !isAdmin ? null : o.path.indexOf("http") >= 0 ? (
                  <Tab
                    key={o.path}
                    className={o.className}
                    label={o.label}
                    component={NavLink}
                    to={"#"}
                    onClick={() => urlRedirect(o.path)}
                    icon={<o.Icon />}
                  />
                ) : (
                  <div>
                    {o.isShow && 
                    <Tab
                    key={o.path}
                    label={o.label}
                    component={NavLink}
                    to={o.path}
                    icon={<o.Icon />}
                  />
                  }
                  </div>
                  
                )
              )}
            </Tabs>
            <UserToolbar />
          </Toolbar>
        </AppBar>
        <ContainerRoutes />
      </div>
    </ThemeProvider>
  );
}
