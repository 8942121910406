import Cookies from "../../node_modules/universal-cookie/cjs/Cookies";

const cookies = new Cookies();
const keyList = [];
let syncPromise = null;

export const SecretStorageService = {
    setItem: (key, value) => {
        cookies.set(key, 
            value, 
            {
                path: '/', 
                maxAge: 3600,
                secure: true,
                sameSite: true,
                domain: window.location.hostname
            });
    
        if (!keyList.includes(key)) {
            keyList.push(key);
        }
    
        cookies.set('key-list', keyList.join('~'));    
        return value;
    },
    
    getItem: (key) => {
        const val = cookies.get(key);
        return val;
    },
    
    removeItem: (key) => {

        cookies.remove(key);
        return true;
    },
    
    clear: () => {

        keyList.forEach((key) => {
            cookies.remove(key);
            console.log('Cleared cookie - first 10: ', key.substring(0, 10));
        })
        cookies.remove('key-list');
        return {};
    },
    
    sync: async () => {

    
        if (!syncPromise) {
            syncPromise = new Promise((res, rej) => {
                const authInfo = {};
                let keyList = [];
            
                const keys = cookies.get('key-list');
                if (keys) {
                    keyList = keys.split('~');
            
                    keyList.forEach((key) => {
                        authInfo[key] = cookies.get(key);
                       
                    })
                }
                res();
            
            });
        }
    
        return syncPromise;
    }
}
