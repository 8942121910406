/* eslint-disable default-case */
import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";
import Button from "../button/Button";
import Box from "@material-ui/core/Box";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import CardBody from "../card/CardBody";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import SearchForm from "../common/SearchForm";
import Grid from "@material-ui/core/Grid";
import { useEffect } from "react";
import { createId } from "service/util.service";
import invoke from "service/lambda.service";
import AssetBrowser from "components/aspera-connect/AssetBrowser";
import { addNewUploadTransfer } from "store/upload-reducer";
import { dispatch, goTo } from "redux-store";
import {
  asperaSetSelectedFileHandler,
  createDragDropListener,
  disableDragDrop,
  initTransfer,
  launchLocalBrowser,
} from "components/aspera-connect/aspera-connect";
import ListSelectedFiles from "./ListSelectedFiles";
import { searchResetValues, searchSetFieldValue } from "store/search-reducer";
import { collectionResetValues } from "store/collections-reducer";
import {
  collapseSetFieldValue,
  collapseResetValues,
} from "store/collapse-reducer";
import { useAppUserContext } from "lib/contextLib";
import { apiCreateAsperaTransferRequest } from "service/api.service";
import { awsConfig, Bucket } from "../../config";
import { SearchBucketInput } from "components/common/SearchForm";
import { useSelector } from "react-redux";
import { getUserByemail } from "service/api.service";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

let oldKeyword = "";
const SearchKeywordsInput = ({ label }) => {
  const value = useSelector((state) => state.search.keywords);
  return (
    <TextField
      value={value}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      className="input-style"
      name="keywords"
      id="keywords"
      label="Keywords"
      variant="outlined"
      fullWidth
    />
  );
};
const SearchSubDirectory = ({ label }) => {
  const value = useSelector((state) => state.collapse.subDirectory);
  return (
    <TextField
      value={value}
      onChange={handleInputChange1}
      className="input-style"
      name="subDirectory"
      id="subDirectory"
      label="New Sub Directory"
      variant="outlined"
      fullWidth
    />
  );
};
const handleInputChange = (e) => {
  dispatch(searchSetFieldValue({ [e.target.name]: e.target.value }));
};
const handleInputBlur = (e) => {
  if (e.target.name === "keywords") {
    // oldKeyword += e.target.value;
  }
};
const handleInputChange1 = (e) => {
  dispatch(collapseSetFieldValue({ [e.target.name]: e.target.value }));
};
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formControl: {
    minWidth: 120,
  },
  uploadDirectory: {
    paddingRight: "15px",
    paddingLeft: "15px",
  },
  typo: {
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  btnBlock: {
    display: "flex",
  },
};

const useStyles = makeStyles(styles);
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Upload() {
  const enclosingPage = "";
  const classes = useStyles();
  const selectedBucket = useSelector((state) => state.search.bucket);
  const searchData = useSelector((state) => state.search);
  const [selectedDir, setSelectedDir] = useState("/");
  const selectedSubDir = useSelector((state) => state.collapse.subDirectory);
  const [showAssetBrowser, setShowAssetBrowser] = useState(false);
  const [sourceFiles, setSourceFiles] = useState([]);
  const [transferId] = useState(createId("Upload"));
  const { email } = useAppUserContext();
  const currentUser = useAppUserContext();
  const [userRoles, setUserRoles] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [recognizeText, setRecognizeText] = useState(false);
  const [recognizeObject, setRecognizeObject] = useState(false);
  const [recognizeCelebrities, setRecognizeCelebrities] = useState(false);
  const [uploadDestination, setUploadDestination] = useState(false);
  const [keywords, setKetwords] = useState(false);
  const [directory, setDirectory] = useState(false);
  const [gSearch, setGSearch] = useState(false);
  const [uploadFiles, setUploadFiles] = useState(false);
  const [specificPath, setSpecificPath] = useState(false);
  const [restrictPath, setRestrictPath] = useState(false);
  const [pageActive, setPageActive] = useState(true);
  const [error, setError] = useState(false);
  const [isLoading, setLoadingState] = useState(false);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });
  const collections = useSelector((state) => state.collections);
  const { vertical, horizontal, open, severity, message } = state;
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  useEffect(() => {
    //dispatch(searchResetValues());
    getUserByemail().then((finalData) => {
      if (finalData) enableUpload(finalData);
    });
    return () => {
      dispatch(collectionResetValues());
      dispatch(searchResetValues());
    };
  }, []);
  const enableUpload = (userRolesData) => {
    const adminRole = userRolesData;
    if (adminRole.userData && adminRole.userData.length) {
      if (
        adminRole.userData[0].status.toLowerCase() === "pending" ||
        (adminRole.userData[0].status.toLowerCase() !== "pending" &&
          !adminRole.roles[0])
      ) {
        setPageActive(false);
        setState({
          ...state,
          open: true,
          message:
            "“Your permissions must be configured by system admin,please try again later”!",
          severity: "error",
        });
        return;
      }
    }
    if (adminRole && adminRole.roles) {
      if (adminRole.roles.indexOf("Super Admin") !== -1) {
        setUploadDestination(true);
        setKetwords(true);
        setDirectory(true);
        setGSearch(true);
        setUploadFiles(true);
        setRecognizeText(true);
        setRecognizeObject(true);
        setDateFilter(true);
        setRecognizeCelebrities(true);
        setSpecificPath(true);
        setRestrictPath(true);
        setUserRoles(true);
      }
    }
    if (adminRole && adminRole.resultData && adminRole.resultData.length) {
      adminRole.resultData.forEach((val) => {
        if (val.path === "4") {
          setUploadDestination(true);
          setKetwords(true);
          setDirectory(true);
          setGSearch(true);
          setUploadFiles(true);
          setRecognizeText(true);
          setRecognizeObject(true);
          setDateFilter(true);
          setRecognizeCelebrities(true);
          setSpecificPath(true);
          setRestrictPath(true);
          setUserRoles(true);
        }
        if (val.path === "4.children.0") {
          setUploadDestination(true);
          setUserRoles(true);
        }
        if (val.path === "4.children.1") {
          setKetwords(true);
          setUserRoles(true);
        }
        if (val.path === "4.children.2") {
          setDirectory(true);
          setUserRoles(true);
        }
        if (val.path === "4.children.3") {
          setGSearch(true);
          setUserRoles(true);
        }
        if (val.path === "4.children.4") {
          setUploadFiles(true);
          setUserRoles(true);
        }
        if (val.path === "4.children.5") {
          setDateFilter(true);
          setUserRoles(true);
        }
        if (val.path === "4.children.6") {
          setRecognizeText(true);
          setUserRoles(true);
        }
        if (val.path === "4.children.7") {
          setRecognizeObject(true);
          setUserRoles(true);
        }
        if (val.path === "4.children.8") {
          setRecognizeCelebrities(true);
          setUserRoles(true);
        }
        if (val.path === "4.children.9") {
          setSpecificPath(true);
          setUserRoles(true);
        }
        if (val.path === "4.children.10") {
          setRestrictPath(true);
          setUserRoles(true);
        }
      });
    }
    if (Object.keys(collections.objects).length) {
      setUploadFiles(false);
      Object.keys(collections.objects).forEach((key) => {
        let byteCharacters = b64toBlob(collections.objects[key].thumbnail.data);
        byteCharacters["name"] = collections.objects[key].name;
        handleFileSelected([byteCharacters]);
      });
    }
    if (
      collections.target === "downloadAll" &&
      searchData.bucket === "DF Studio"
    ) {
      setUploadFiles(false);
    }
  };
  useEffect(() => {
    createDragDropListener();

    dispatch(collapseResetValues());
    return () => {
      disableDragDrop();
      asperaSetSelectedFileHandler();
      dispatch(collapseResetValues());
    };
  }, [uploadFiles]);
  const handleFileSelected = useCallback(
    (newFiles) => {
      let updatedSourceFiles = [];
      if (Object.keys(collections.objects).length) {
        Object.keys(collections.objects).forEach((el) => {
          updatedSourceFiles.push(collections.objects[el]);
        });
      } else {
        updatedSourceFiles = [...sourceFiles];
        newFiles.forEach((o) => {
          if (!updatedSourceFiles.some((so) => so.name === o.name)) {
            updatedSourceFiles.push(o);
          }
        });
      }

      const d = new Date();
      let dateYear =
        d.getFullYear().toString() +
        (d.getMonth() + 1 < 10
          ? "0" + (d.getMonth() + 1)
          : d.getMonth() + 1
        ).toString() +
        (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()).toString() +
        (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()).toString() +
        (d.getMinutes() < 10
          ? "0" + d.getMinutes()
          : d.getMinutes()
        ).toString();
      let name = dateYear + " - " + email.split("@")[0];
      let keyword = dateYear + " , " + email.split("@")[0];

      dispatch(searchSetFieldValue({ keywords: keyword, name: name }));
      setSourceFiles(updatedSourceFiles);
    },
    [sourceFiles]
  );

  useEffect(() => {
    asperaSetSelectedFileHandler(handleFileSelected);
  }, [handleFileSelected, uploadFiles]);

  const getTransferSpec = async (activity = "upload") => {
    const pathPairs = sourceFiles.map((file) => ({
      source: file.name,
      destination: "/" + transferId,
    }));
    const resp = await invoke(
      "wdtvamtoolAsperaNAPI-" + awsConfig.environment,
      {
        args: {
          service: activity,
          data: { bucket: Bucket.uploadTemp, pathPairs: pathPairs },
        },
      },
      currentUser.creds
    );
    const respAsObject = JSON.parse(resp["Payload"]);
    const transferSpecs = respAsObject["body"]["transfer_specs"];
    return transferSpecs;
  };
  let destionationPath = selectedDir.trim().replace(/\/$/, "");

  const initiateTransfer = async (activity = "upload") => {
    const transferSpecs = await getTransferSpec(activity);
    await apiCreateAsperaTransferRequest({
      transferId: transferId,
      direction: "download",
      sourceFiles,
      bucket: selectedBucket,
      destionationPath: destionationPath,
    });
    initTransfer(enclosingPage, transferSpecs, transferId);
    dispatch(
      addNewUploadTransfer({
        transferId: transferId,
        sourceFiles,
        bucket: selectedBucket,
        destionationPath: destionationPath,
      })
    );
    goTo("/upload/progress");
  };

  const handleFileChooser = () => {
    launchLocalBrowser();
  };

  const handleFolderChooser = useCallback(() => {
    launchLocalBrowser("directory");
  }, []);

  const handleUpload = async (event) => {
    if (!selectedDir.length || selectedDir === "/") {
      setError(true);
      setState({
        ...state,
        open: true,
        message: "Please choose the directory for upload!",
        severity: "error",
      });
      return;
    }
    if (collections.target === "downloadAll") {
      setLoadingState(true);

      let dfsQuery = {};
      if (searchData.dfsProject) {
        dfsQuery.project = searchData.dfsProject;
      }
      if (searchData.dfsSetup) {
        dfsQuery.setup = searchData.dfsSetup;
      }
      if (searchData.dfsId) {
        dfsQuery.dfsId = searchData.dfsId;
      }
      if (searchData.dfsLocation) {
        dfsQuery.location = searchData.dfsLocation;
      }
      console.log(localStorage.getItem("userEmail"));
      let resp = await invoke(
        "wdtvamtoolDFUtils-" + awsConfig.environment,
        {
          action: "pull",
          dfsQuery: dfsQuery,
          customData: searchData,
          freeText: searchData.dfsFreeText,
          user: localStorage.getItem("userEmail"),
        },
        currentUser.creds
      )
        .then((res) => {
          setState({
            ...state,
            open: true,
            message: "The DFS pull request is in Progress. Please check DFS Req.Summary for status updates.",
            severity: "success",
          });
        })
        .catch((error) => {
          setState({
            ...state,
            open: true,
            message: "The DFS pull request is in Progress. Please check DFS Req.Summary for status updates.",
            severity: "error",
          });
        });
      setLoadingState(false);
      return;
    }
    if (Object.keys(collections.objects).length) {
      setLoadingState(true);
      let resp = await invoke(
        "wdtvamtoolDFUtils-" + awsConfig.environment,
        {
          action: "pull",
          dfsQuery: {
            dfsId: Object.keys(collections.objects),
          },
          customData: searchData,
          user: localStorage.getItem("userEmail"),
        },
        currentUser.creds
      )
        .then((res) => {
          setState({
            ...state,
            open: true,
            message: "The DFS pull request is in Progress. Please check DFS Req.Summary for status updates.",
            severity: "success",
          });
        })
        .catch((error) => {
          setState({
            ...state,
            open: true,
            message: "The DFS pull request is Failed. Please check DFS Req.Summary for status updates.",
            severity: "error",
          });
        });
      setLoadingState(false);
      return;
    }

    if (selectedDir.length > 1) {
      setError(false);
      event.preventDefault();
      initiateTransfer("upload");
    }
  };
  const handleShowAssetBrowser = useCallback(() => {
    setShowAssetBrowser(true);
  }, []);
  const handleDirChange = useCallback((event) => {
    if (event.target.value) {
      dispatch(searchSetFieldValue({ destinationFolder: event.target.value }));
      setSelectedDir(event.target.value);
    }
  }, []);
  const handleDirSelect = useCallback(({ dir }) => {
    dispatch(searchSetFieldValue({ destinationFolder: dir }));
    setSelectedDir(dir);
    setShowAssetBrowser(false);
  }, []);

  /**
   * Alert Success
   */
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };
  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Upload Assets</h4>
        <p className={classes.cardCategoryWhite}>Add Optional Metadata.</p>
      </CardHeader>
      <CardBody>
        <br />
        {uploadDestination && (
          <GridContainer>
            <GridItem xs={12} sm={2} md={2}>
              <InputLabel id="demo-simple-select-outlined-label">
                Upload Destination
              </InputLabel>
            </GridItem>
            <GridItem md={3}>
              <SearchBucketInput hideNone pageFrom="upload" />
            </GridItem>
          </GridContainer>
        )}
        <br />

        <br />
        {directory && (
          <GridContainer>
            <Grid
              item
              xs={12}
              sm={3}
              md={12}
              className={classes.uploadDirectory}
            >
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="upload_path">Directory</InputLabel>
                <OutlinedInput
                  id="upload_path"
                  value={selectedDir}
                  error={error}
                  onChange={handleDirChange}
                  labelWidth={70}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="search directory"
                        onClick={handleShowAssetBrowser}
                        onMouseDown={handleShowAssetBrowser}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {showAssetBrowser && selectedBucket && (
                <AssetBrowser
                  bucket={selectedBucket}
                  foldersOnly
                  onSubmit={handleDirSelect}
                  isUpload={true}
                />
              )}
            </Grid>
          </GridContainer>
        )}

        <br />
        {uploadFiles && (
          <GridContainer>
            <GridItem xs={6} sm={12} md={12}>
              <Button color="primary" onClick={handleFileChooser}>
                Click here to select the files
                <input type="file" hidden />
              </Button>
              <Button color="primary" onClick={handleFolderChooser}>
                Click here to select folder
              </Button>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <Box
                id="dropArea"
                className="pointer"
                align="center"
                border="dashed rgba(0, 0, 0, 0.12)"
                minHeight="250px"
              >
                <Typography
                  variant="h5"
                  style={{ marginTop: "20px" }}
                  gutterBottom
                >
                  Drag and drop an Media here or click here to choose file
                </Typography>
                <CloudUploadIcon style={{ fontSize: 50 }} />
              </Box>
            </GridItem>
          </GridContainer>
        )}
        <ListSelectedFiles sourceFiles={sourceFiles} />
        {keywords && (
          <GridContainer>
            <GridItem
              item
              xs={12}
              sm={3}
              md={12}
              style={{ paddingRight: "10px" }}
            >
              <SearchKeywordsInput label="Keywords" />
            </GridItem>
          </GridContainer>
        )}
        <br />
        {gSearch && (
          <h3>
            Please modify data to add general information about your upload:
          </h3>
        )}
        {/* Search Form - common component */}
        <SearchForm
          showRecognize={recognizeText}
          recognizeObject={recognizeObject}
          recognizeCelebrities={recognizeCelebrities}
          showFileNewType
          generalSearch={gSearch}
          additionalSearch={dateFilter}
        />
        {pageActive && (
          <GridContainer>
            <GridItem xs={6} sm={12} md={3}>
              <GridItem xs={12} sm={12} md={12}>
                <div className={classes.btnBlock}>
                  <Button color="primary" onClick={handleUpload}>
                    Upload
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setSourceFiles([]);
                      dispatch(searchResetValues());
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </GridItem>
            </GridItem>
          </GridContainer>
        )}
      </CardBody>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      <Backdrop open={isLoading} style={{ zIndex: "auto" }}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
}
