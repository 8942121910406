export const exifRemoveList = [
  "Directory",
  "File Access Date/Time",
  "File Inode Change Date/Time",
  "File Permissions",
  "File Type Extension",
  "MIME Type",
  "JFIF Version",
  "Exif Byte Order",
  "Y Cb Cr Positioning",
  "Sensitivity Type",
  "Exif Version",
  "Create Date",
  "Offset Time",
  "Offset Time Original",
  "Offset Time Digitized",
  "Components Configuration",
  "WB Shift AB GM",
  "WB Shift AB GM Precise",
  "Face Info Offset",
  "Meta Version",
  "Face 1 Position",
  "Creative Style",
  "Zone Matching",
  "Dynamic Range Optimizer",
  "Image Stabilization",
  "Color Mode",
  "Picture Effect",
  "Soft Skin Effect",
  "Vignetting Correction",
  "Lateral Chromatic Aberration",
  "Distortion Correction Setting",
  "Auto Portrait Framed",
  "Electronic Front Curtain Shutter",
  "AF Area Mode Setting",
  "Flexible Spot Position",
  "AF Point Selected",
  "AF Points Used",
  "AF Tracking",
  "Focal Plane AF Points Used",
  "Sequence Image Number",
  "Sequence File Number",
  "Sequence Length",
  "Picture Effect 2",
  "Distortion Correction",
  "Distortion Corr Params",
  "Vignetting Corr Params",
  "Chromatic Aberration Corr Params",
  "Battery Temperature",
  "Battery Level",
  "Lens Firmware Version",
  "Release Mode 3",
  "Self Timer",
  "Flash Mode",
  "HDR Setting",
  "Picture Profile",
  "WB RGB Levels",
  "Release Mode 2",
  "Internal Serial Number",
  "Lens Mount",
  "Lens Spec Features",
  "Shutter Count 3",
  "APS-C Size Capture",
  "Sub Sec Time",
  "Sub Sec Time Original",
  "Sub Sec Time Digitized",
  "Flashpix Version",
  "File Source",
  "Scene Type",
  "Custom Rendered",
  "Exposure Mode",
  "Digital Zoom Ratio",
  "Serial Number",
  "PrintIM Version",
  "Current IPTC Digest",
  "Application Record Version",
  "Time Created",
  "Country-Primary Location Code",
  "Writer-Editor",
  "Displayed Units X",
  "Displayed Units Y",
  "Copyright Flag",
  "XMP Toolkit",
  "Category",
  "Authors Position",
  "Marked",
  "Rating",
  "Color Class",
  "PM Version",
  "Profile Class",
  "Profile Connection Space",
  "Profile File Signature",
  "Primary Platform",
  "CMM Flags",
  "Device Manufacturer",
  "Device Model",
  "Device Attributes",
  "Rendering Intent",
  "Connection Space Illuminant",
  "Profile Creator",
  "Profile ID",
  "Profile Copyright",
  "Profile Description",
  "Media White Point",
  "Media Black Point",
  "Red Matrix Column",
  "Green Matrix Column",
  "Blue Matrix Column",
  "Device Mfg Desc",
  "Device Model Desc",
  "Viewing Cond Desc",
  "Viewing Cond Illuminant",
  "Viewing Cond Surround",
  "Viewing Cond Illuminant Type",
  "Luminance",
  "Measurement Observer",
  "Measurement Backing",
  "Measurement Geometry",
  "Technology",
  "Red Tone Reproduction Curve",
  "Green Tone Reproduction Curve",
  "Blue Tone Reproduction Curve",
  "Image Width",
  "Image Height",
  "Encoding Process",
  "Bits Per Sample",
  "Color Components",
  "Y Cb Cr Sub Sampling",
];

export const removeExif = [
  "Print Position",
  "Print Scale",
  "Global Angle",
  "Global Altitude",
  "URL List",
  "Slices Group Name",
  "Num Slices",
  "Pixel Aspect Ratio",
  "Photoshop Thumbnail",
  "ICC Profile Name",
  "History",
  "State",
  "Country",
  "Instructions",
  "Creator Tool",
  "Country Code",
  "History Action",
  "History When",
  "History Software Agent"
]

export const summaryDetails = {
  Description: "",
  Category: "",
  "Person In Image": "",
  "Caption-Abstract": "",
  "Special Instructions": "",
  "Copyright Notice": "",
  Credit: "",
  Source: "",
  "Caption Writer": "",
  Headline: ""
};

export const summaryDetailsMapper={
  Description:'description',
  Category:'category',
  'Person In Image':'personInImage',
  'Caption-Abstract':'captionAbstract',
  'Special Instructions':'specialInstructions',
  'Copyright Notice':'copyrightNotice',
  'Credit':'credit',
  'Source':'source',
  'Caption Writer':'captionWriter',
  'Headline':'headline'
}

export const searchPage = {
  category: "",
  city: "",
  daypart: "",
  destinationPath: "",
  exifDump: "",
  job: "",
  keywords: "",
  location: "",
  name: "",
  network: "",
  people: "",
  photo_type: "",
  region: "",
  searchText: "",
  show: "",
  year: '',
  country: '',
  selectedDate: '',
  dateToDate: '',
  datefilter: '',
  datefilterlimit: '',
  type:'',
  dfsBadge: '',
  dfsId: '',
  dfsName: '',
  dfsProject: '',
  dfsSetup: '',
  dfsLocation: '',
  dfsFolder: '',
  dfsFreeText:''
};