import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SideBar from '../sidebar/Sidebar';
import routes from '../routes/routes';
import TabsNew from '../tab-menu/Tabs';
import disneyLogo from '../../assets/dinsey-new-logo.png';
import logobiz from "../../assets/bizDAMz_Logo._Hz.png";
import styles from './homeStyle.js';

import SecureRoute from '../secure-route/SecureRoute';

const useStyles = makeStyles(styles);
var component;
const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (true) {
        return (
          <SecureRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

export default function Home() {
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const client = localStorage.getItem("currentClient");
  let currentLogo = disneyLogo;
  let logoText = "General Entertainment Content";
 let isBisdamz = false;
  if (client === "bizdamz") {
    currentLogo = logobiz;
     logoText = "";
     isBisdamz = true
  }

  return (

    <div className={classes.wrapper}>
      <SideBar
        routes={routes}
        logoText={logoText}
        logo={currentLogo}
        open={false}
        bisdamz ={isBisdamz}
      />

      <div className={classes.mainPanel} ref={mainPanel}>
        <TabsNew />
      </div>
    </div>
  );
}
