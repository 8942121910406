import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  bottomGrid: {
    marginTop: 10,
  }
});

const LoginFooter = (props) => {
  const classes = useStyles();
  const isBizdamz= localStorage.getItem('currentClient');

  return (
    <Grid container className={classes.bottomGrid}>
      <Grid item xs>
        <Link style={{textDecoration:'none', color:"#524DFE", float:"left"}} to="/forgot-password" variant="body2">
          Forgot password?
        </Link>
      </Grid>
      {isBizdamz === 'bizdamz' && (
         <Grid item>
         <Link style={{textDecoration:'none', color:"#524DFE"}} to="/signup" variant="body2">
           {"Don't have an account? Sign Up"}
         </Link>
       </Grid>

      )}
     
    </Grid>
  );
}

export default LoginFooter;