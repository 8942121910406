export const awsConfig = {
  ThumbnailDomain: "https://dq0g542lsu7c5.cloudfront.net/", 
  environment: "test",
  MAX_ATTACHMENT_SIZE: 1000000
};

export const Bucket = {
  collection: "pmt-dams-collections-test",
  thumbnails: "pmt-dams-thumbnails-test",
  uploadTemp: "pmt-dams-upload-test",
};

export const Dfstudio = {
  enable: true,
};

export const ImageDetailUpdate = {
  enableIndividualImageUpdate: true,
  enableBulkUpdate: true,
};
