import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { dispatch } from 'redux-store';
import { removeDialog } from 'store/dialogs-reducer';

const Transition = React.forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogContent = withStyles((theme) => ({
  root: {
    minWidth: '300px',
  },
}))(MuiDialogContent);

export default function WDDialog({id, title, content, btnText, onClose, cancelText, onCancel}) {
  const handleClose = () => {
    if(onClose) {
      onClose(id);
    } else {
      dispatch(removeDialog(id));
    }
  }
  return (<Dialog id={id} open disableBackdropClick onClose={handleClose} TransitionComponent={Transition}>
    <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {content}
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" disableFocusRipple autoFocus onClick={handleClose}>{btnText || 'OK'}</Button>
      {onCancel && <Button variant="contained" color="primary" disableFocusRipple autoFocus onClick={onCancel}>{ cancelText || 'Cancel'}</Button>}
    </DialogActions>
  </Dialog>);
}