import "./App.css";
import React, { useCallback, useEffect, useState, useMemo, useReducer } from "react";
import Amplify, { Auth, Hub, Logger } from "aws-amplify";
import awsExports from "./aws-exports";
import { RouteList } from "./RouteList";
import { AuthUserContext } from "./lib/contextLib";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { goTo } from "redux-store";
import { useSelector } from "react-redux";
import { initAsperaConnnect } from "components/aspera-connect/aspera-connect";
import { useLocation } from "react-router";
import * as AWS from 'aws-sdk';
import awsmobile from './aws-exports';
import { getUserByEmail, getUserByemail, onUserLoggedIn, updateUserActiveTime, getRolesByNames } from "service/api.service";
import jwt_decode from "jwt-decode";
import { SecretStorageService } from "service/sss.service";
import Cookies from 'universal-cookie';
import { updateUser } from "graphql/mutations";
import { useIdleTimer } from 'react-idle-timer'
import MuiAlert from "@mui/material/Alert";
import {CognitoRefreshToken} from 'amazon-cognito-identity-js';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {CognitoUserPool} from 'amazon-cognito-identity-js';
import { VideoContext } from "./lib/contextLib";

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const logger = new Logger("Auth-Logger");

console.log('Entering App.js... will check if federated info available in Cook...');
const federatedInfo = cookies.get('federation_response');
console.log('Federation resp length from cookie: ', federatedInfo?.length, 
                  '. Access Code received in url: ', window.location.href.includes('?code='));

if (window.location.href.includes('?code=') || federatedInfo) {
  //---For Federated myID users, cognito definitely requires to store access tokens in local storage which is a vulnerability.
  //---We overcome this vulnerability by removing federated user info from local-storage post login. (See method: createCognitoCreds) 
  console.log('Configuring amplify for myId login...');
  Amplify.configure(awsExports);
  Auth.configure(awsExports);
} else {
  //---For Non-myID users, force cognito to store sensitive info in cookies rather than local storage. (Done for Disney pentest)
  //---This is implemented in SecretStorageService.
  console.log('Configuring amplify for non-myId login...');
  Amplify.configure({...awsExports, storage: SecretStorageService});
  Auth.configure({...awsExports, storage: SecretStorageService});  
}

const App = () => {
  const classes = useStyles();
  const [user, setAuthUser] = useState({});
  const [authChecked, setAuthChecked] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [duplicateSession, setDuplicateSession] = useState(false);
  const [possibleDuplicateSession, setPossibleDuplicateSession] = useState(false);
  const [remainingIdleTime, setRemainingIdleTime] = useState(new Date()); //--Used only for display - in the duplicate session message.
  const [allowedIdleMinutes, setAllowedIdleMinutes] = useState(15);
  const location = useLocation();
  const transfers = useSelector(state => state.upload);


  //---------------For Video Context.-------------------------------------------------
  //---This is to ensure that only one video plays at any given time - when search 
  //---result has multiple videos. Each wimg control has a random number as key. The
  //---video context will have the key of the currently playing video. When user tries
  //---to play a second video simultaneously (while the first one is playing), the first
  //---one has to be stopped. This is achieved by way of sharing a videoContext across 
  //---all wimg.

  const [wimgKey, dispatchWimgKey] = useReducer((oldWimgKey, newWimgKey) => {
    return newWimgKey;
  }, null);
  //---------------For Video Context.-------------------------------------------------


  const onIdle = async (event) => {
    //---Here because user has been idle for a minute...
    const now = new Date();
    const currentMin = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), 0);

    if (user?.email) {
      //---Calculate idle minutes....
      let idleMinutes = 0;
      if (user.lastActiveMinute) {
        idleMinutes = Math.floor((currentMin.getTime() - new Date(user.lastActiveMinute).getTime()) / 60000);
        console.log('Idle Mins: ', idleMinutes + ' / ' + allowedIdleMinutes);
      }

      if (idleMinutes > allowedIdleMinutes) {
        //----Check if a transfer is in progress....
        let transferInProgress = false;
        let transferPerc = 0;
        let i = 0
        for (i = 0; i < transfers?.length; i ++) {
          if (transfers[i].percentage > 0 && transfers[i].percentage < 100) {
            transferInProgress = true;
            transferPerc += transfers[i].percentage; //---just for info...
          }
        }
        console.log('Idle and ' + i + ' Transfer(s) : ---> ', transferInProgress, ' -> ', transferPerc);

        //---If no transfer in progress force logout, else update last active minute (as if user is active)
        if (transferInProgress === true) {
          setAuthUser((latestUserState)=>{
            return {
              ...latestUserState,
              lastActiveMinute: currentMin
            }
          });

          updateUserActiveTime({
            id: user.id,
            lastActiveMinute: new Date(currentMin).toISOString()
          }).then((updatedUser) => {
            console.log('Logout prevented since transfer in progress...');

            if (updatedUser.currentTab.trim() !== user.currentTab.trim()) {
              console.log('Possible duplicate session: [', updatedUser.currentTab.trim(), '] VS [', user.currentTab.trim() + ']');
              setPossibleDuplicateSession(true);
            } else {
              console.log('Session ok: [', updatedUser.currentTab.trim(), '] VS [', user.currentTab.trim() + ']');
              setPossibleDuplicateSession(false);
            }
    
          }).catch((err) => {
            console.log(err);
          });
        } else {
          console.log('Logging out due to idle time...');
          handleLogout();
        }
      } 

      //---refresh idle token - incase the 45 minute limit for refreshing id-token is reached while the user is idle within idletime limit....
      await refreshTokenIfRequired();

      //---Idle timer is dead since onIdle was invoked. Start idle timer again for the next minute!!!.
      idleTimer.start();
    }
  }

  const onAction = async (event) => {

    if (user?.lastActiveMinute) {
      const now = new Date();
      const currentMin = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), 0);
  
      // console.log(user.lastActiveMinute.getTime() + ' vs ' + currentMin.getTime());
      if (user.lastActiveMinute.getTime() !== currentMin.getTime() && user?.currentTab && user?.currentTab.trim().length > 0) {
        console.log('It has been a minute since user table update... Remaining idle mins: ', idleTimer.getRemainingTime() / 60000);
        setAuthUser({
          ...user,
          lastActiveMinute: currentMin
        });

        await refreshTokenIfRequired();

        const updatedUser = await updateUserActiveTime({
          id: user.id,
          lastActiveMinute: new Date(currentMin).toISOString()
        });

        console.log('-----------Updated active min in user table ---------------');

        if (updatedUser.currentTab.trim() !== user.currentTab.trim()) {
          console.log('Possible duplicate session: [', updatedUser.currentTab.trim(), '] VS [', user.currentTab.trim() + ']');
          setPossibleDuplicateSession(true);
        } else {
          console.log('Session ok: [', updatedUser.currentTab.trim(), '] VS [', user.currentTab.trim() + ']');
          setPossibleDuplicateSession(false);
        }
      }
    }
  }

  //---Set idletimer to 1 minute so that every minute you can check if accumulated idle time is greater than allowed idle time.
  //---You can also use the idletime handler to check other aspects such as id-token expiry every minute.
  const idleTimer = useIdleTimer({ onIdle, onAction, timeout: 60000});
  
  if (!cookies.get('refresh_token_in_progress') && !cookies.get('duplicate_session')) {
    localStorage.setItem("showTitle", "false");
    if (location.pathname === "/login/bizdamz") {
      localStorage.setItem("currentClient", "bizdamz");
    } else if (location.pathname === "/login/disney") {
      localStorage.setItem("currentClient", "disney");
    } else if (location.pathname === "/login") {
      //window.location.href = window.location.origin + "/login";
      localStorage.setItem("currentClient", "disney");
    } else if (
      location.pathname === "/upload" ||
      location.pathname === "/scan"
    ) {
      localStorage.setItem("showTitle", "true");
    }
  
    console.log('In App.js ---> current url : ' + window.location.href + ' and current client: ' + localStorage.getItem('currentClient'));  
  }

  const setAuthUserClbk = useCallback((user) => {
    console.log("Into auth user callback", user?.email);

    localStorage.setItem('userEmail', user.email);
    setAuthUser(user);

  }, []);

  const handleLogout = async () => {
    try {
      cookies.remove('federation_response');
      cookies.remove('refresh_token');
      cookies.remove('refresh_token_nonmyid');
      console.log('Cookies removed...');
      localStorage.clear();
      idleTimer.pause();
      console.log('Idle timer stopped...');

      // console.dir(user);
      await updateUserActiveTime({
        id: user.id,
        currentTab: '',
      });

      setTimeout(() => { //---Let's give some time for the cookie to clear...
        Auth.signOut();
        SecretStorageService.clear();
        console.log('Signout complete...');
        setAuthUser({isAdmin: false});
      }, 3000);
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const authListener = async (data) => {
    //alert(JSON.stringify(data));
    // eslint-disable-next-line default-case
    switch (data.payload.event) {
      case "signIn":

        console.log("Sign success... Refresh token in progress: ", cookies.get('refresh_token_in_progress'), 
                      " duplicate session: ", cookies.get('duplicate_session'));

        if (!cookies.get('refresh_token_in_progress') && !cookies.get('duplicate_session')) {
          setRedirecting(true);
          const user = await createCognitoCreds(data);
  
          // setAuthUserClbk(data.payload.data);
          setAuthUserClbk(user);
  
          console.log('Location and path: ', location);
          localStorage.setItem("isLoginError", 'false');
          const isLoginDisney = localStorage.getItem("isFirstLoginDisney");
          const isLoginBizdamz = localStorage.getItem("isFirstLoginbizdamz");
          if (location.pathname === "/login/disney" && !isLoginDisney) {
            localStorage.setItem("isFirstLoginDisney", "true");
            localStorage.setItem("isShowedLoginVideo", "false");
            console.log('Rededirecting to login video - disney');
            setRedirecting(false);
            goTo("/login-video");
          } else if (location.pathname === "/login/bizdamz" && !isLoginBizdamz) {
            localStorage.setItem("isFirstLoginbizdamz", "true");
            localStorage.setItem("isShowedLoginVideo", "false");
            console.log('Rededirecting to login video');
            setRedirecting(false);
            goTo("/login-video");
  
          } else {
            console.log('Rededirecting to search');
            setRedirecting(false);
  
            goTo(location.pathname.includes('login') ? '/search' : location.pathname);
          }
  
          logger.info("user signed in", data);  
        } else {
          console.log('Not redirecting(signin success) anywhere since refresh token in progress');
        }
        break;

      case "signUp":
        logger.info("user signed up");
        break;
      case "signOut":
        if (!cookies.get('refresh_token_in_progress')) {

          cookies.remove('federation_response');
          cookies.remove('refresh_token');
          cookies.remove('refresh_token_nonmyid');
          console.log('Cookies removed...');
    
          idleTimer.pause();
          console.log('Idle timer stopped...');
    
          setAuthUser({isAdmin: false});

          const client = localStorage.getItem("currentClient");
          
          localStorage.clear();
          SecretStorageService.clear();
          console.log('Logout complete...');

          if (client) {
            goTo("/login/" + client);
          } else {
            goTo("/login");
          }
          
          logger.info("user signed out");
        }
        break;  
      case "signIn_failure":
        logger.error("user sign in failed");
        console.log("user sign in failed");
        break;
      case "tokenRefresh":
        logger.info("token refresh succeeded");
        break;
      case "tokenRefresh_failure":
        logger.error("token refresh failed");
        break;
      case "configured":
        logger.info("the Auth module is configured");
    }
  };
  
  useEffect(() => {
    document.title = "Disney- Photo management tool";

    Hub.listen("auth", authListener); // listen for login/signup events

    console.log('Getting Current user from local-storage');
    
    const currentUser = localStorage.getItem('userEmail');

    if (currentUser) {
      Auth.currentAuthenticatedUser()
      .then(async (data) => {
        console.log('Checking current authenticated user : yes... Refresh token in progress: ', cookies.get('refresh_token_in_progress')
                                          , ' duplicate session: ', cookies.get('duplicate_session'));

        if (!cookies.get('refresh_token_in_progress')  && !cookies.get('duplicate_session')) {
          console.log('Current authenticated user... and so will try not to bother user login again...');
          // console.log('---------------Authenticated user------------------:', data);
  
          const user = await createCognitoCreds(data);
          setAuthUser(user);

          console.log('Redirecting already authenticated user to path...:', location.pathname);
          goTo(location.pathname.includes('login') ? '/search' : location.pathname);
        } else {
          console.log('Not redirecting(authenticated user available) anywhere since refresh token in progress');
        }
      })
      .catch(async (err) => {
        console.error('Error getting current authenticated user: ', err);
      
        // console.log("Logged in user in LS: ", localStorage.getItem("userEmail"));
        if (err === "The user is not authenticated") {
          goTo(window.location.href.replace(window.location.origin, ""));    
        }
      })
      .finally(() => {
        setAuthChecked(true);
      });
    } else {
      console.log('No current user in local-storage and so redirecting to login page ...');
      goTo(window.location.href.replace(window.location.origin, ""));    
    }

    return () => Hub.remove("auth", authListener); // cleanup
  }, [setAuthUserClbk]);

  
  useEffect(() => {
    initAsperaConnnect();
  }, []);

  console.log("authChecked: ", authChecked);

  const createCognitoCreds = async (data) => {

    console.log('Creating new creds...: ', awsExports.aws_appsync_region);
  
    AWS.config.region = awsmobile.aws_appsync_region;
  
    const identity = "cognito-idp.<region>.amazonaws.com/<YOUR_USER_POOL_ID>"
      .replace('<region>', awsmobile.aws_appsync_region)
      .replace('<YOUR_USER_POOL_ID>', awsmobile.aws_user_pools_id);
  
    let returnUser = {};
  
    return new Promise(async (resolve, reject) => {
      try {
        const token = data.payload?.data?.signInUserSession?.idToken?.jwtToken //---When there is no federation and login performed
                || data.signInUserSession?.idToken?.jwtToken  //---When auto login performed due to unexpired access token
                || data.payload?.data?.token //---myid federation
                || data;
    
        //---Below is for non-my-id authentication. We use temporary credentials via STS (Security Token Service).
        //---Nothing but the access key and secret generated after cognito login. These expire after 1 hour.
        const signInUserSession = data.payload?.data?.signInUserSession || data.signInUserSession;
        if (signInUserSession) {
          console.log('--------------------------->Signin User session: ', signInUserSession?.refreshToken?.token?.length || 0);
          const nonMyIdRefreshToken = signInUserSession?.refreshToken?.token;
          if (nonMyIdRefreshToken) {
            cookies.set('refresh_token_nonmyid', nonMyIdRefreshToken, {path: '/', maxAge: 86400}); //---cognito refresh_token expiry set to 24 hours in cognito console.
          }
        }
        //----------Above is for non-my-id users...

        if (!token) {
          console.log('Access Token could not be obtained ...');
          resolve(returnUser);
        } else {
    
          console.log('Will process token: ');
  
          const params = {
            IdentityPoolId: awsmobile.aws_cognito_identity_pool_id,
            Logins: {}
          }
          params.Logins[identity] = token;
        
          const cognitoIdentity = new AWS.CognitoIdentity();
          cognitoIdentity.getId(params);
        
          params['IdentityId'] = cognitoIdentity;
            
          AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);
    
          await AWS.config.credentials.get(async () => {
            const accessKeyId = AWS.config.credentials.accessKeyId;
            const secretAccessKey = AWS.config.credentials.secretAccessKey;
            const sessionToken = AWS.config.credentials.sessionToken;
            
            const creds = Buffer.from(JSON.stringify({
              accessKeyId, 
              secretAccessKey, 
              sessionToken, 
              region: awsExports.aws_appsync_region,
              idToken: token, 
            })).toString('base64');
            // console.log('Creds from tok: ', creds);
              
            await AWS.config.update({
              accessKeyId: accessKeyId,
              secretAccessKey: secretAccessKey,
              sessionToken: sessionToken,
              region: awsExports.aws_appsync_region
            });
            
            // console.log('Cog creds successfully obtained and updated ....', AWS.config);
    
            //---This key is stored by Cognito during the federated login processs for myID users.
            //---This has sensitive info. So get rid of it after succesful login.
            await localStorage.removeItem('aws-amplify-federatedInfo');
    
            console.log('Will now get user by email - post myid login success...');
            
            const decodedToken = jwt_decode(token);
      
            console.log('Decoded token expiring at : ', ((decodedToken.exp) ? new Date(decodedToken.exp).toLocaleString() : 'none') );
  
            if (decodedToken?.email) {
              returnUser = {
                email: decodedToken?.email,
                isAdmin: false,
                isSuperAdmin: false,
                authData: data,
                idToken: token,
              };
        
              const users = await getUserByEmail(decodedToken?.email);
        
              console.log('Got user by email - App.js');        
        
              if (users && users.userByEmail && users.userByEmail.items && users.userByEmail.items.length > 0) {
      
                console.log('Checking if admin... Current tab: ', users.userByEmail.items[0].currentTab);
                let isAdmin = false;
                let isSuperAdmin = false;
                
                if (users.userByEmail.items[0].roles && users.userByEmail.items[0].roles?.length > 0) {
                  users.userByEmail.items[0].roles?.forEach((role) => {
                    if (role.toLowerCase().includes('admin')) {
                      isAdmin = true;
                    }
                    if (role.toLowerCase().includes('super') &&  role.toLowerCase().includes('admin')) {
                      isSuperAdmin = true;
                    }
                  });
                }
  
                const now = new Date();
                const currentMin = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes());
                
                const loggedInUser = users.userByEmail.items[0];
                const idleMinsAllowedForRole = await setIdleTimeoutInMinutes(loggedInUser?.roles);
                setAllowedIdleMinutes(idleMinsAllowedForRole);
  
                console.log('Checking dup sess(db vs new)...: ', 
                      loggedInUser.currentTab, ' vs ', token.substring(token.length-20), 
                      loggedInUser.lastActiveMinute, currentMin);
                let dupSess = false;
                if (!loggedInUser.currentTab 
                    || loggedInUser.currentTab.trim().length === 0 
                    || loggedInUser.currentTab === token.substring(token.length-20)) {
                  setDuplicateSession(false);
                  dupSess = false;
                } else {
                  const idleTime = now.getTime() - new Date(loggedInUser.lastActiveMinute).getTime();
                  if (idleTime <= idleMinsAllowedForRole * 60 * 1000) {
                    setDuplicateSession(true);
                    dupSess = true;
                    await cookies.set('duplicate_session', 'Y', {path: '/', maxAge: 15});          
  
                    setRemainingIdleTime(()=>{ //---recall that remaining idle time is used only for display - in the duplicate session message.
                      const remainingMilliSecs = (idleMinsAllowedForRole * 60 * 1000) - idleTime;
                      console.log('Remaining idle time: ', new Date(now.getTime() + remainingMilliSecs));
                      return new Date(now.getTime() + remainingMilliSecs);
                    });
  
                    Auth.signOut();
  
                  } else {
                    setDuplicateSession(false);
                    dupSess = false;
                  }
                }

                console.log('THIS IS A DUPLICATE SESSION ----> : ', dupSess);

                if (dupSess === false) {
    
                  loggedInUser.lastLogin = now.toISOString();
                  loggedInUser.lastActiveMinute = currentMin;
                  loggedInUser.currentTab = token.substring(token.length - 20);
                  returnUser = {
                    ...loggedInUser,
                    lastRefresh: now,
                    lastLogin: now,
                    isAdmin: isAdmin,
                    isSuperAdmin: isSuperAdmin,
                    authData: data,
                    creds: creds
                  };
        
                  /**
                   *----Any user who has logged in successfully can console log AWS.config.credentials and use the information to create a temporary
                   *----cognito user with assumed role (using aws cli) and then access appsync api. Found by Disney Security team.
                   *----So nullify it .... 
                   *----Note that the credentials have been salvaged in returnUser.creds. (returnUser ultimately becomes part of the AppContext). 
                   *----Similar operation is done in "refreshToken" method.
                   */
                  AWS.config.credentials = {};
                  
                  console.log('Worked out user context... Will update last login for the user...');
  
                  // await subscribeToUserLogins(user);

                  console.log('Starting idle timer...');
                  idleTimer.start();
                  console.log('Started idle timer...');

                  await updateUserActiveTime({
                    id: returnUser.id,
                    lastLogin: loggedInUser.lastLogin,
                    lastActiveMinute: loggedInUser.lastActiveMinute,
                    currentTab: loggedInUser.currentTab,
                  });
                  
                  resolve(returnUser);
                } else {
                  console.log('Duplicate Session')
                  resolve({});
                }
              }
            } else {
              console.log('JWT token does not contain an email. ');
              resolve(returnUser);
            }
          });
        }
      } catch (ex) { 
        console.log('Exception while trying to get token:', ex);
        resolve(returnUser);
      }
    });
  }
  
  const refreshTokenIfRequired = async () => {
    await refreshTokenIfRequiredMyId();
    await refreshTokenIfRequiredNonMyId();
  }

  const refreshTokenIfRequiredMyId = async () => {
    try {
      const fedRes = await cookies.get('federation_response'); //---nothing but the old id-token
      const refreshToken = cookies.get('refresh_token');
      const now = new Date();

      console.log('MyId: Checking if refresh token required... RefreshToken Length ', refreshToken?.length || 0);

      if (refreshToken && fedRes) {
        const timeSinceLastRefresh = now.getTime() - user.lastRefresh.getTime();
        console.log('Minutes since last refresh: ', timeSinceLastRefresh / 60000);

        if (timeSinceLastRefresh > 2700 * 1000) { //---If 45 minutes since last token refresh.
        // if (now.getTime() - user.lastRefresh.getTime() > 180 * 1000) { //---If 3 minutes since last token refresh. use this for testing....
          //---15 second cookie expiry is to prevent interruption of a user session (by way of navigating to some where else) due to 
          //--- (a) signIn success event of authListener.
          //--- (b) Auth.currentAuthenticatedUser succeeding ...
          //---When a succesful federated login happens, the above 2 come into the execution path and we do not want 
          //---any interruption to the user. Refresh token should be transparent to user. So the cookie is checked in the above mentioned
          //---execution paths.
          await cookies.set('refresh_token_in_progress', 'Y', {path: '/', maxAge: 15});          

          const token_endpoint = ('https://wdpmt.auth.<region>.amazoncognito.com/oauth2/token').replace('<region>', awsExports.aws_project_region);
  
          const formData = new URLSearchParams();
          formData.append('grant_type', 'refresh_token');
          formData.append('client_id', awsExports.aws_user_pools_web_client_id);
          formData.append('client_secret', "");
          formData.append('refresh_token', refreshToken);
      
          console.log('Will now post to myid to get access token... Current Url: ', window.location.href);
          await fetch(token_endpoint, {
            method: 'POST',
            mode: 'cors',
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData}
          ).then((result) => {
              // console.dir(result);
              const reader = result.body.getReader();
              reader.read().then(async (data) => {
                const ui8a = new Uint8Array(data.value);
                console.log('Buff len: ', ui8a.length);
                const tokens = await new TextDecoder("utf-8").decode(ui8a.buffer);
                console.log('Got tokens: ', tokens.length);
                
                const cognitoProviderId = 'cognito-idp.' 
                                            + awsExports.aws_cognito_region
                                            + '.amazonaws.com/'
                                            + awsExports.aws_user_pools_id;
      
                const idToken = JSON.parse(tokens).id_token;
                const decodedIdToken = jwt_decode(idToken);

                const exp = new Date(0);
                exp.setSeconds(decodedIdToken.exp);
                const expiryInSeconds = (exp.getTime() - now.getTime()) / 1000;
                console.log('Expiry in seconds - refreshed id token: ', expiryInSeconds);

                const expiresAt = new Date(now.getTime() + (expiryInSeconds * 1000));
                console.log('Setting token expiry to numeric: ', expiresAt);
                console.log('Setting token expiry to: ', new Date(expiresAt).toLocaleString());
      
                await Auth.signOut();
                console.log('Signout done...');

                Auth.federatedSignIn(
                  cognitoProviderId, {
                    token: idToken,
                    expires_at: expiresAt //--This is supposed to be set to the expiry in the token provided by myId - which is 1 hour.
                  },
                  {email: decodedIdToken.email, sub: decodedIdToken.sub}
                ).then(async (cred)=>{
                  console.log('Rerfresh token Federation success...');
                  // console.dir(cred);

                  const secrets = {
                    accessKeyId: cred.accessKeyId,
                    secretAccessKey: cred.secretAccessKey,
                    sessionToken: cred.sessionToken,
                    region: awsExports.aws_appsync_region,
                    idToken: idToken,
                  };
      
                  await AWS.config.update(secrets);
                  
                  console.log('Updated AWS config Credentials...');
      
                  setAuthUser(() => {
                    console.log('User context updated post token refresh....');
                    return {
                      ...user,
                      lastRefresh: new Date(),
                      authData: idToken,
                      currentTab: idToken.substring(idToken.length - 20),
                      creds: Buffer.from(JSON.stringify(secrets)).toString('base64')
                    }
                  });
      
                  await updateUserActiveTime({
                    id: user.id,
                    currentTab: idToken.substring(idToken.length - 20),
                  });

                  await cookies.set('federation_response', idToken, {path: '/', maxAge: expiryInSeconds}); //---expiry taken from decoded id token          

                  console.log('Updated user record and the federation cookie for secs: ', expiryInSeconds);
                }).catch((err)=>{
                  console.log('Federation failed:', err);
                });
      
              });
            }).catch((err) => {
              console.log('Error while posting to token endpoint: ', err);
            });
        } else {
          console.log('Not refreshing - minutes since last refresh < 45');
        }
      } else {
        console.log('MyId: Not refreshing - either refresh token not there or this is not a myid login...');
      }
    } catch(ex) {
      console.log('Exception during refresh token: ', ex);
    }
  } 
  
  const refreshTokenIfRequiredNonMyId = async () => {
    try {
      const fedRes = await cookies.get('federation_response'); //---nothing but the old id-token
      const refreshToken = cookies.get('refresh_token_nonmyid');
      const now = new Date();

      console.log('NonMyId: Checking if refresh token required... RefreshToken Length ', refreshToken?.length || 0);

      if (refreshToken && !fedRes) {
        const timeSinceLastRefresh = now.getTime() - user.lastRefresh.getTime();
        console.log('Minutes since last refresh: ', timeSinceLastRefresh / 60000);

        if (timeSinceLastRefresh > 2700 * 1000) { //---If 45 minutes since last token refresh.
        // if (now.getTime() - user.lastRefresh.getTime() > 180 * 1000) { //---If 3 minutes since last token refresh. use this for testing....
          //---15 second cookie expiry is to prevent interruption of a user session (by way of navigating to some where else) due to 
          //--- (a) signIn success event of authListener.
          //--- (b) Auth.currentAuthenticatedUser succeeding ...
          //---When a succesful federated login happens, the above 2 come into the execution path and we do not want 
          //---any interruption to the user. Refresh token should be transparent to user. So the cookie is checked in the above mentioned
          //---execution paths.
          await cookies.set('refresh_token_in_progress', 'Y', {path: '/', maxAge: 15});          
      
          console.log('Will now use refresh token to get a new Id Token: ', window.location.href);

          const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });

          const userPool = new CognitoUserPool({ 
            UserPoolId : awsmobile.aws_user_pools_id,
            ClientId : awsmobile.aws_user_pools_web_client_id
          });

          const cognitoUser = new CognitoUser({
            Username : user?.email, 
            Pool : userPool
          });

          cognitoUser.refreshSession(cognitoRefreshToken, async (err, session) => {
              if (err) {
                console.log('Error while refreshing non myid refresh token: ', err);
              } else {
                const newIdToken = session?.idToken?.jwtToken || null;
                const newRefreshToken = session?.refreshToken?.token || null;

                console.log('Non My Id Refresh token successful: New idt: ', newIdToken.substring(0,10),
                                                                 'New rt: ', newRefreshToken.substring(0, 10));

                //console.log('RT: ', refreshToken);
                //console.log('NRT: ', newRefreshToken);
                console.log('RT = NRT: ', (refreshToken == newRefreshToken));

                if (newRefreshToken) {
                  cookies.set('refresh_token_nonmyid', newRefreshToken, {path: '/', maxAge: 86400}); //---cognito refresh_token expiry set to 24 hours in cognito console.
                }

                const identity = "cognito-idp.<region>.amazonaws.com/<YOUR_USER_POOL_ID>"
                                  .replace('<region>', awsmobile.aws_appsync_region)
                                  .replace('<YOUR_USER_POOL_ID>', awsmobile.aws_user_pools_id);

                const params = {
                  IdentityPoolId: awsmobile.aws_cognito_identity_pool_id,
                  Logins: {}
                }
                params.Logins[identity] = newIdToken;
              
                const cognitoIdentity = new AWS.CognitoIdentity();
                cognitoIdentity.getId(params);
              
                params['IdentityId'] = cognitoIdentity;
                  
                AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);
          
                await AWS.config.credentials.get(async () => {
                  const accessKeyId = AWS.config.credentials.accessKeyId;
                  const secretAccessKey = AWS.config.credentials.secretAccessKey;
                  const sessionToken = AWS.config.credentials.sessionToken;
                  
                  const secrets = {
                    accessKeyId, 
                    secretAccessKey, 
                    sessionToken, 
                    region: awsExports.aws_appsync_region,
                    idToken: newIdToken, 
                  };

                  console.log('App Context updated... Creds from tok: lengths of aki/sak/set: ', 
                              accessKeyId.length, '/', secretAccessKey.length, '/', sessionToken.length);
                    
                  await AWS.config.update(secrets);

                  setAuthUser(() => {
                    console.log('User context updated post token refresh....');
                    return {
                      ...user,
                      lastRefresh: new Date(),
                      authData: newIdToken,
                      currentTab: newIdToken.substring(newIdToken.length - 20),
                      creds: Buffer.from(JSON.stringify(secrets)).toString('base64')
                    }
                  });
      
                  await updateUserActiveTime({
                    id: user.id,
                    currentTab: newIdToken.substring(newIdToken.length - 20),
                  });

                  console.log('Updated user record with last active time...');
                });
              }
          });
        } else {
          console.log('Not refreshing - minutes since last refresh < 45');
        }
      } else {
        console.log('NonMyId: Not refreshing - either refresh token not there or this is a myid login...');
      }
    } catch(ex) {
      console.log('Exception during refresh token: ', ex);
    }
  }

  const setIdleTimeoutInMinutes = async (roles) => {
  
    let idleTime = 1; //--15 mins by default.
  
    try {
      const userRoles = await getRolesByNames(roles);
      console.log('Roles for user: ', JSON.stringify(userRoles));
      if (userRoles?.listRoles?.items) {
        if (Array.isArray(userRoles.listRoles.items) && userRoles.listRoles.items.length > 0) {
          userRoles.listRoles.items.map((ur) => { //---incase user has multiple roles
            const idleTimeArr = ur.idletime?.split(':') || [];
            let hours = 0;
            let mins = 0;
            if (idleTimeArr.length > 0) {
              hours = Number(idleTimeArr[0]);
            }
            if (idleTimeArr.length > 1) {
              mins = Number(idleTimeArr[1]);
            }
            if ((hours * 60) + mins > idleTime) {
              idleTime = (hours * 60) + mins;
            }
          });  
        } else {
          idleTime = userRoles.idletime;
        }
      }
    } catch (ex) {
      console.log('Exception while working out idle time: ', ex);
    } finally {
      console.log('Worked out idle time: ', idleTime || 15);
      return idleTime || 15; //---returning it 
    }
  
  }
  
  const subscribeToUserLogins = async () => {
  
    console.log('Subscribing to user login event...');
    await onUserLoggedIn(user.owner, (connection, data) => {
  
      console.log('User login happened...:', data);
  
    });
  }
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

  return (
    
    <AuthUserContext.Provider value={user}>
      <VideoContext.Provider value={{wimgKey, dispatchWimgKey}}>
        { duplicateSession === true &&          
          <Alert
            severity={"error"}
            sx={{ width: "100%" }}
          >
            You have an active session elsewhere. The active session will expire at {(remainingIdleTime) ? remainingIdleTime.toLocaleString(): ''} if left inactive. <br/>
            You can retry after {(remainingIdleTime) ? remainingIdleTime.toLocaleString(): ''}. 
            If urgent, please submit a support ticket by clicking <a href='http://support.biznetz.com/'>here</a>. 
          </Alert>
        }

        { possibleDuplicateSession === true &&          
          <Alert
          onClose={() => {setPossibleDuplicateSession(false)}}
            severity={"warning"}
            sx={{ width: "70%", marginLeft: "15%" }}
          >
            There may be an active duplicate session elsewhere. If it is your session, please logout from the duplicate session. <br/>
            If not please close this message and ignore... If you continue to see this message, please logout and login. <br/>
            If you need further help, please submit a support ticket by clicking <a href='http://support.biznetz.com/'>here</a>.  
          </Alert>
        }

        <RouteList />
        <Backdrop className={classes.backdrop} open={redirecting === true}>
          <CircularProgress />
        </Backdrop>
      </VideoContext.Provider>
    </AuthUserContext.Provider>
  );
};


export default App;
