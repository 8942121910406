import { createAction, createReducer } from '@reduxjs/toolkit';

export const collapseSetFieldValue = createAction('COLLAPSE_SET_FIELD_VALUE');
export const collapseResetValues = createAction('COLLAPSE_RESET_VALUES');
var d = new Date();
var n = d.getFullYear();

const defaultState = {
  directory: '',
  subDirectory: '',
  actions: {datefilter: false,status: false, jobNumber: true, network: false, show: false, dayPart: false, year: false, city: false, country: false, category: false}
}


const collapseFormReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(collapseSetFieldValue, (state, { payload }) => {
      Object.assign(state, payload);
      return state;
    }).addCase(collapseResetValues, () => ({
      ...defaultState
    }));
});

export default collapseFormReducer;
