import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../grid/GridItem";
import Button from "../button/Button";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import CardBody from "../card/CardBody";
import withWidth from "@material-ui/core/withWidth";
import SearchForm from "../common/SearchForm";
import { dispatch, goTo, store } from "redux-store";
import { searchResetValues, searchSetFieldValue } from "store/search-reducer";
import { searchPage } from "./exifData";
import { Grid } from "@material-ui/core";
import { getUserByemail, getGroupByUser } from "service/api.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { Dfstudio } from "../../config";

import MuiAlert from "@mui/material/Alert";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  btnBlock: {
    display: "flex",
  },
  btnRight: {
    float: "right",
  },
};

const useStyles = makeStyles(styles);
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const SearchPage = () => {

  const classes = useStyles();
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [userRoles, setUserRoles] = useState(false);
  const [generalSearch, setGeneralSearch] = useState(false);
  const [additionalSearch, setAdditionalSearch] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [enableDfstudio, setEnableDfstudio] = useState(false);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    severity: "",
    message: "",
  });

  const { vertical, horizontal, open, severity, message } = state;

  useEffect(() => {
    store.subscribe(() => {
      const currentState = store.getState();
      let disbale = true;
      if (currentState && currentState.search) {
        for (const [key, value] of Object.entries(searchPage)) {
          if (currentState.search[key]) {
            disbale = false;
          }
        }
        setEnableSubmit(disbale);
      }
    });
    setShowLoading(true);
    getUserByemail().then(async (finalData) => {
      if (finalData) {
        enableSearch(finalData);
        let dfdata = await getGroupByUser(finalData?.userData[0]?.id);

        if (dfdata.userGroupByUser.items.length) {
          dfdata?.userGroupByUser?.items?.map((data) => {
            if (data?.group?.dfStudioAccess) {
              setEnableDfstudio(true)
            }
          })
        }
      }
      setShowLoading(false);
    }).catch((error) => {
      setShowLoading(false);
    })
  }, []);
  const enableSearch = (userRolesData) => {
    const adminRole = userRolesData;
    if (adminRole.userData && adminRole.userData.length) {
      if (adminRole.userData[0].status.toLowerCase() === "pending" || (adminRole.userData[0].status.toLowerCase() !== "pending" && !adminRole.roles[0])) {
        setState({
          ...state,
          open: true,
          message: "“Your permissions must be configured by system admin,please try again later”!",
          severity: "error",
        });
        return
      }
    }
    if (adminRole && adminRole.roles) {
      if (adminRole.roles.indexOf("Super Admin") !== -1) {
        setGeneralSearch(true);
        setAdditionalSearch(true);
        setDateFilter(true)
        setUserRoles(true);
      }
    }
    if (adminRole && adminRole.resultData && adminRole.resultData.length) {
      adminRole.resultData.forEach((val) => {
        if (val.path === "0") {
          setGeneralSearch(true);
          setAdditionalSearch(true);
          setDateFilter(true)
          setUserRoles(true);
        }
        if (val.path === "0.children.0") {
          setGeneralSearch(true);
          setUserRoles(true);
        }
        if (val.path === "0.children.1") {
          setAdditionalSearch(true);
          setUserRoles(true);
        }
        if (val.path === "0.children.2") {
          setDateFilter(true);
          setUserRoles(true);
        }
      })
    }
  }
  /**
   * Alert Success
   */
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ ...state, open: false });
  };
  return (
    <Grid>
      {
        userRoles && (

          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Search Assets</h4>
              <p className={classes.cardCategoryWhite}>
                Use multiple fields to narrow results.
              </p>
            </CardHeader>
            <CardBody>
              {/* Search Form - common component */}
              {enableDfstudio && Dfstudio.enable ?
                <SearchForm showBucket showExif keywordLabel="Text/Keywords" generalSearch={generalSearch} additionalSearch={additionalSearch} dateFilter={dateFilter} dfStudio={dateFilter} /> :
                <SearchForm showBucket showExif keywordLabel="Text/Keywords" generalSearch={generalSearch} additionalSearch={additionalSearch} dateFilter={dateFilter} />}

              {/* Bottom Grid */}
              <div className={classes.btnRight}>
                <GridItem xs={12} sm={12} md={12}>
                  <div className={classes.btnBlock}>
                    <Button
                      color="secondary"
                      onClick={() => {
                        dispatch(searchResetValues())
                      }}

                    >
                      Clear
                    </Button>
                    <Button
                      color="primary"
                      disabled={enableSubmit}
                      onClick={async () => {
                        dispatch(searchSetFieldValue({ isFromSearch: true }));
                        goTo("/search/results");
                      }}
                    >
                      Search
                    </Button>
                  </div>
                </GridItem>
              </div>
            </CardBody>
          </Card>
        )}

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
      <Backdrop open={showLoading} style={{ zIndex: "auto" }}>
        <CircularProgress />
      </Backdrop>

    </Grid>
  );
};

export default withWidth()(SearchPage);
