import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FolderOpenIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

export default function AssetBrowserListItem({ item, openDir }) {
  const handleObjSelect = () => {
    if(item.type === 'directory'){
      openDir(item.path);
    }
  }
  return (<ListItem button key={item.path} onClick={handleObjSelect} path={item.path} disableGutters>
    <ListItemIcon>{item.type === 'directory' ? <FolderOpenIcon /> : <InsertDriveFileIcon />} </ListItemIcon>
    <ListItemText primary={item.basename} />
  </ListItem>)
}