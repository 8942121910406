import { useAppUserContext } from 'lib/contextLib';
import { useEffect } from 'react';
import { goTo } from 'redux-store';

const AdminOnly = ({ redirect, children }) => {
  const { isAdmin } = useAppUserContext();
  useEffect(() => {
    if (!isAdmin && redirect) {
      goTo('/search');
    }
  }, [isAdmin, redirect])
  if (isAdmin) {
    return children;
  }
  return null;
}

export default AdminOnly