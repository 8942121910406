import React, { useEffect, useState } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import GridItem from "../grid/GridItem";
import GridContainer from "../grid/GridContainer";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import "./styles.css";
import Icon from "@material-ui/core/Icon";
import AssetBrowser from "components/aspera-connect/AssetBrowser";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { getUserByemail } from "service/api.service";
import { adminRoleSetFieldValue } from "store/admin-roles-reducer";

import { traverseAminRolesList } from "service/api.service";
import { dispatch } from "redux-store";
import { TimeInput } from "./TimeInput";
import { SearchBucketInput } from "../common/SearchForm";
import { searchSetFieldValue } from "store/search-reducer";

const styles = {
  formControl: {
    minWidth: 120,
  },
  mdlDemo: {
    "min-width": "400px",
  },
  paddingBottom: {
    paddingBottom: "15px",
  },
  formControl: {
    minWidth: 120,
  },
};
const useStyles = makeStyles(styles);

const RolesDataList = [
  {
    label: "Search",
    tagClassName: "special",
    children: [
      {
        label: "General Search",
      },
      {
        label: "Additional Fields",
      },
      {
        label: "Date Fields",
      },
    ],
  },
  {
    label: "Results",
    children: [
      {
        label: "Download Target",
      },
      {
        label: "Download Options",
        children: [
          {
            label: "Full Size Media",
          },
          {
            label: "Low-Res Media",
          },
          {
            label: "Save a Collection",
          },
          {
            label: "Edit/Update Collection",
          },
          {
            label: "Create a PDF",
          },
          {
            label: "Compressed Zip",
          },

          {
            label: "Text Data File",
          },
          {
            label: "Update Metadata",
          },
        ],
      },
      {
        label: "Share (Delivery Method)",
        children: [
          {
            label: "Collection",
          },
          {
            label: "Share W/User",
          },
          {
            label: "Share W/Group(s)",
          },
          {
            label: "Share File",
          },
          {
            label: "Downloads File",
          },
          {
            label: "Private Link",
          },
          {
            label: "Public Link",
          },
        ],
      },
      {
        label: "Collections",
        children: [
          {
            label: "Saved",
          },
          {
            label: "Shared with Me",
          },
          {
            label: "Shared with My Groups",
          },
          {
            label: "Shared File",
          },
        ],
      },
    ],
  },
  {
    label: "Admin",
    children: [
      {
        label: "Groups",
      },
      {
        label: "Roles",
      },
      {
        label: "Users",
      },
      {
        label: "User Log",
      },
      {
        label: "Scan Req.Summary",
      },
      {
        label: "DFS Req.Summary",
      },
    ],
  },
  {
    label: "Scan",
  },
  {
    label: "Upload",
    children: [
      {
        label: "Upload Destination",
      },
      {
        label: "Keywords",
      },
      {
        label: "Directory",
      },
      {
        label: "Upload Files",
      },
      {
        label: "General Search",
      },
      {
        label: "Additional Data Fields",
      },
      {
        label: "Recognize Text",
      },
      {
        label: "Recognize Objects",
      },
      {
        label: "Recognize Celebrities",
      },
      {
        label: "*Specific  Path(s)",
      },
      {
        label: "*Restrict to Path",
      },
    ],
  },
  {
    label: "Media Request Form",
  },
];

export default function RolesTable() {
  const classes = useStyles();
  /**
   * store selector
   */
  let paths = [{ path: "" }];
  let userAccess = [];
  let showPath = [];
  let showAdd = [];

  const adminrole = useSelector((state) => state.adminrole);
  const [selectedDir, setSelectedDir] = useState("/");
  const [showAssetBrowser, setShowAssetBrowser] = useState(false);
  const selectedBucket = useSelector((state) => state.search.bucket);
  const [formValues, setFormValues] = useState(paths);
  const [selectedNodes, setSelectedNodes] = useState(userAccess);
  const [showPathList, setShowPathList] = useState(
    showPath?.length ? true : false
  );
  const [showAddIcon, setShowAddIcon] = useState(
    showAdd?.length ? false : true
  );
  const [showPartiallySelected, setShowPartiallySelected] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(true);
  const [userId, setUserId] = useState(adminrole?.user?.userId);

  const assignObjectPaths = (obj, stack) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      const found = userAccess.some((el) => el.label === node?.label);
      if (node && node.label) {
        node["isDefaultValue"] = false;
      }
      if (found) {
        node.isDefaultValue = true;
      }
      if (typeof node === "object") {
        node.path = stack ? `${stack}.${k}` : k;
        assignObjectPaths(node, node.path);
      }
    });
  };

  useEffect(() => {
    getUserByemail().then((finalData) => {
      if (finalData) disbaleRolesData(finalData);
    });
  }, []);

  useEffect(
    (event) => {
      console.log("formValues", formValues);
    },
    [formValues]
  );

  const disbaleRolesData = (userRolesData) => {
    const adminRole = userRolesData;
    if (adminRole && adminRole.roles) {
      if (adminRole.roles.indexOf("Super Admin") === -1) {
        RolesDataList[3].className = "display-none";
        RolesDataList[4].children[6].className = "display-none";
        RolesDataList[4].children[7].className = "display-none";
        RolesDataList[4].children[8].className = "display-none";
        lodaRolesData();
      }
    }
  };

  const handleRoleChange = (e) => {
    dispatch(adminRoleSetFieldValue({ [e.target.name]: e.target.value }));
  };
  useEffect(() => {
    const allData = traverseAminRolesList(RolesDataList);
    allData.map((val) => (val.expanded = false));
  }, []);
  useEffect(() => {
    lodaRolesData();
  }, [adminrole]);

  const lodaRolesData = () => {
    if (
      adminrole &&
      adminrole.userAccess &&
      typeof adminrole.userAccess === "string"
    ) {
      userAccess = JSON.parse(adminrole.userAccess);
      showPath = userAccess.filter(
        (val) =>
          val.path === "4" ||
          val.path === "4.children.9" ||
          val.path === "4.children.10"
      );
      const showPathEnable = userAccess.filter(
        (val) => val.path === "4" || val.path === "4.children.9"
      );
      showAdd = userAccess.filter(
        (val) => val.path === "4" || val.path === "4.children.10"
      );

      RolesDataList[4].children[10].disabled =
        showPathEnable && showPathEnable.length ? true : false;

      RolesDataList[4].children[9].disabled =
        showAdd && showAdd.length ? true : false;
    }
    if (adminrole && adminrole.paths && typeof adminrole.paths === "string") {
      paths = JSON.parse(adminrole.paths);
    }
    if (adminrole && adminrole.paths && adminrole.paths.length) {
      paths = adminrole.paths;
    }
    let expNode = localStorage.getItem("expandedNode");
    if (expNode) {
      expNode = JSON.parse(expNode);
    }

    if (adminrole.userData && adminrole.userData.length) {
      dispatch(
        searchSetFieldValue({
          bucket: adminrole.bucket
            ? adminrole.bucket
            : adminrole.userData[0].bucket,
        })
      );
      //Commenting beacuse of below snippet always sets path to empty path
      // const index = adminrole.userData
      //   .map((object) => object.bucket)
      //   .indexOf(
      //     adminrole.bucket ? adminrole.bucket : adminrole.userData[0].bucket
      //   );
      // if (index !== -1) {
      //   paths = adminrole.userData[index].paths;
      // } else {
      //   paths = [{ path: "" }];
      // }
      paths = adminrole.paths;
    }

    setSelectedNodes(userAccess);
    setShowAddIcon(showAdd?.length ? false : true);
    setShowPathList(showPath?.length ? true : false);
    setFormValues(showPathList && !paths.length ? [{ path: "" }] : paths);
    assignObjectPaths(RolesDataList);
  };
  /**
   *
   * @param {*} currentNode
   * @param {*} selectedNodes
   */
  const onChange = (currentNode, selectedNodes) => {
    localStorage.setItem("selectedNodes", JSON.stringify(selectedNodes));
    dispatch(
      adminRoleSetFieldValue({ userAccess: JSON.stringify(selectedNodes) })
    );
    addShowPathList(currentNode);
    console.log("onChange::", currentNode, selectedNodes);
  };
  const addShowPathList = (currentNode) => {
    const data = JSON.parse(JSON.stringify(currentNode));
    if (
      (data.path === "4" ||
        data.path === "4.children.9" ||
        data.path === "4.children.10") &&
      data.checked
    ) {
      setShowPathList(true);
    }
    if (
      (data.path === "4" ||
        data.path === "4.children.9" ||
        data.path === "4.children.10") &&
      !data.checked
    ) {
      setShowPathList(false);
    }
    if (data.path === "4" || data.path === "4.children.10") {
      RolesDataList[4].children[9].disabled =
        !RolesDataList[4].children[9].disabled;
      setShowAddIcon(false);
    } else if (data.path === "4" || data.path === "4.children.9") {
      setShowAddIcon(true);
    }

    if (data.path === "4" || data.path === "4.children.9") {
      RolesDataList[4].children[10].disabled =
        !RolesDataList[4].children[10].disabled;
    }
  };

  const onNodeToggle = (currentNode) => {
    const allData = traverseAminRolesList(RolesDataList);
    allData.filter((val) => val.label === currentNode.label)[0].expanded =
      currentNode.expanded;
  };

  const addFormFields = () => {
    setFormValues([...formValues, { path: "" }]);
  };

  const removeFormFields = (index) => {
    const array = [...formValues]; // make a separate copy of the array
    if (index !== -1) {
      array.splice(index, 1);
      setFormValues(array);
      dispatch(adminRoleSetFieldValue({ paths: array }));
      const roleUserData = [...adminrole.userData];
      if (roleUserData && roleUserData.length) {
        const index = roleUserData
          .map((object) => object.bucket)
          .indexOf(adminrole.bucket);
        if (index !== -1) {
          roleUserData.splice(index, 1);
        }
      }

      dispatch(adminRoleSetFieldValue({ userData: roleUserData }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const handleShowAssetBrowser = (index) => {
    setCurrentIndex(index);
    setShowAssetBrowser(true);
  };
  const handleDirChange = (event, index) => {
    if (event.target.value) {
      setSelectedDir(event.target.value);
      let pathObj;
      if (event.target.value) {
        pathObj = { path: event.target.value };
      }
      const array = [...formValues]; // make a separate copy of the array
      if (array[index]) {
        array[index] = pathObj;
        setFormValues(array);
      }
      dispatch(adminRoleSetFieldValue({ paths: array }));
      const userData = {
        paths: array,
        user: adminrole.user,
        bucket: adminrole.bucket,
      };
      const roleUserData = [...adminrole.userData];
      if (roleUserData && roleUserData.length) {
        roleUserData.forEach((val) => {
          if (val.bucket === adminrole.bucket) {
            val.paths = array;
          }
        });
      } else {
        roleUserData.push(userData);
      }

      dispatch(adminRoleSetFieldValue({ userData: roleUserData }));
    }
  };
  const handleDirSelect = ({ dir }) => {
    setSelectedDir(dir);
    let pathObj;
    if (dir) {
      pathObj = { path: dir };
    }
    const array = [...formValues]; // make a separate copy of the array
    if (array[currentIndex] && pathObj) {
      array[currentIndex] = pathObj;
      setFormValues(array);
    }
    dispatch(adminRoleSetFieldValue({ paths: array }));
    const userData = {
      paths: array,
      user: adminrole.user,
      bucket: adminrole.bucket,
    };
    let roleUserData = [];
    if (adminrole.userData && adminrole.userData.length) {
      roleUserData = JSON.parse(JSON.stringify(adminrole.userData));
    }
    const index = roleUserData
      .map((object) => object.bucket)
      .indexOf(adminrole.bucket);
    if (index !== -1) {
      if (roleUserData && roleUserData.length) {
        roleUserData.forEach((val) => {
          if (val.bucket === adminrole.bucket) {
            val.paths = array;
          }
        });
      }
    } else {
      roleUserData.push(userData);
    }

    dispatch(adminRoleSetFieldValue({ userData: roleUserData }));
    setShowAssetBrowser(false);
  };
  const onChangeUser = (user) => {
    setUserId(user.userid);
  };
  const onChangeBucket = (bucket) => {
    dispatch(adminRoleSetFieldValue({ bucket: bucket }));
  };
  return (
    <GridContainer>
      <GridItem xs={5} sm={12} md={5}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              className={`input-style ${classes.paddingBottom}`}
              fullWidth
              id="Role Name"
              label="Role Name"
              variant="outlined"
              name="name"
              value={adminrole.name}
              onChange={handleRoleChange}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              className={`input-style ${classes.paddingBottom}`}
              fullWidth
              id="Description"
              label="Description"
              variant="outlined"
              name="description"
              value={adminrole.description}
              onChange={handleRoleChange}
            />
          </GridItem>
          <span> &nbsp; </span>
          <span> &nbsp; </span>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <DropdownTreeSelect
              data={RolesDataList}
              initial={selectedNodes}
              showPartiallySelected={showPartiallySelected}
              onChange={onChange}
              className={`mdl-demo ${classes.mdlDemo}`}
              onNodeToggle={onNodeToggle}
            />
          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem xs={5} sm={12} md={5}>
        <GridContainer>
          {/* {showPathList && (
            <GridItem xs={12} sm={12} md={12}>
              <UsersListRole roleName={adminrole.name} onChangeUser={onChangeUser} />
            </GridItem>
          )} */}

          {showPathList && (
            <GridItem xs={12} sm={12} md={12}>
              <span> &nbsp; </span>
              <SearchBucketInput
                pageFrom="roles"
                userIdFromRole={userId}
                onChangeBucket={onChangeBucket}
              />
            </GridItem>
          )}
          <span> &nbsp; </span>
          <GridItem xs={12} sm={12} md={12}>
            <TimeInput />
          </GridItem>
          <span> &nbsp; </span>

          <GridItem xs={12} sm={12} md={12} lg={12}>
            {showPathList && (
              <form onSubmit={handleSubmit}>
                {formValues.map((element, index) => (
                  <div className="display" key={index}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="upload_path">Directory</InputLabel>
                      <OutlinedInput
                        id="upload_path"
                        value={element.path || ""}
                        onChange={(event) => handleDirChange(event, index)}
                        labelWidth={70}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="search directory"
                              onClick={(event) => handleShowAssetBrowser(index)}
                              onMouseDown={(event) =>
                                handleShowAssetBrowser(index)
                              }
                              edge="end"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    {index ? (
                      <div>
                        <Icon color="primary" onClick={() => addFormFields()}>
                          add_circle
                        </Icon>
                        <Icon
                          color="primary"
                          onClick={() => removeFormFields(index)}
                        >
                          delete_circle
                        </Icon>
                      </div>
                    ) : (
                      <div>
                        {showPathList && showAddIcon && (
                          <Icon color="primary" onClick={() => addFormFields()}>
                            add_circle
                          </Icon>
                        )}
                      </div>
                    )}
                  </div>
                ))}
                {showAssetBrowser && selectedBucket && (
                  <AssetBrowser
                    bucket={selectedBucket}
                    foldersOnly
                    onSubmit={(event) => handleDirSelect(event)}
                  />
                )}
              </form>
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}
