import { dispatch } from "redux-store";
import { addDialog, removeDialog } from "store/dialogs-reducer";

const enableConsoleLogging = false;

export const messageAlert = (header, message, options = {}) => {
  return new Promise((resolve) => {
    const dialogOptions = {
      ...options,
      onClose: (id) => {
        resolve(true);
        dispatch(removeDialog(id));
      }
    }
    if (dialogOptions.cancelText) {
      dialogOptions.onCancel = (id) => {
        resolve(false);
        dispatch(removeDialog(id));
      }
    }
    dispatch(addDialog(header, message, dialogOptions));
  });
}

export const yesOrNoAlert = (header, message, options = {}) => {
  return messageAlert(header, message, {
    ...options,
    btnText: 'Yes',
    cancelText: 'NO',
  });
}

export const createId = (prefix = 'TX') => {
  return prefix
    + '-'
    + new Date().toISOString().replace(/:/g, '-').replace(/[A-Z]/g, '-')
    + Math.ceil((Math.random() * 10000));
}

export const consoleLog = (msg, obj = null) => {
  if (enableConsoleLogging) {
    if (obj) {
      console.log(msg, obj)
    } else {
      console.log(msg);
    }
  }
}

export const consoleDir = (obj) => {
  if (enableConsoleLogging) {
    console.dir(obj);
  }
}

export const fileNameFromPath = (path = '') => {
  let cleanedPath = path.trim().replace('\\', '/'); /**--incase of windows file... */
  if (cleanedPath.endsWith('/')) {  //*--remove trailing slash--*/
    cleanedPath = cleanedPath.substr(0, cleanedPath.length - 1);
  }
  return cleanedPath.substr(cleanedPath.lastIndexOf('/') + 1);
}

export const mimeType = (path = '') => {
  if (path && path.includes('.')) {
    return path.substr(path.lastIndexOf('.') + 1);
  }
  return '';
}

export const uint8ArrayToBuffer = (arr = []) => {
  let buf = new ArrayBuffer(arr.length);
  buf = arr.map((byte) => {
    return byte;
  });
  return buf;
}

export const bytesToSize = (bytes = 0) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
export const getDirectory = (selectedBucket) => {
  const userRolesData = localStorage.getItem("userRoles");
  const adminRole = JSON.parse(userRolesData);
  let buckets = [];
  if (adminRole.bucketBasedOnUser && adminRole.bucketBasedOnUser.length) {
    const index = adminRole.bucketBasedOnUser.map(object => object.bucket).indexOf(selectedBucket);
    if (index !== -1) {
      if (adminRole.bucketBasedOnUser[index]?.paths) {
        adminRole.bucketBasedOnUser[index].paths.forEach((val) => {
          if (val.path) {
            buckets.push({ name: val.path, displayName: val.path });
          }
        })
      }
    }
  } else {
    if (adminRole && adminRole.paths) {
      adminRole.paths.forEach((val) => {
        if (val.path) {
          buckets.push({ name: val.path, displayName: val.path });
        }
      })
    }
  }
  return buckets;
}